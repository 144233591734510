// @ts-strict-ignore
import _ from 'lodash';
import { DISPLAY_MODE } from '@/main/app.constants';
import { INVESTIGATE_TOOLS, InvestigateToolType, TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { InitializeMode, PersistenceLevel, Store } from '@/core/flux.service';
import i18next from 'i18next';

export class InvestigateStore extends Store {
  persistenceLevel: PersistenceLevel = 'WORKSHEET';
  static readonly storeName = 'sqInvestigateStore';

  initialize(initializeMode: InitializeMode) {
    const saveState = this.state && initializeMode !== 'FORCE';
    this.state = this.immutable(
      {
        item: {},
        activeTool: TREND_TOOLS.OVERVIEW,
        toolFilter: '',
        displayMode: DISPLAY_MODE.NEW,
        derivedDataPanelOpen: false,
        // Add-on tools need to maintain their state across soft and hard initialization so reloading previous
        // worksteps does not clear add-on tools. Add-on tools are set whenever a new Analysis worksheet is
        // loaded so they're guaranteed to be updated for the current user whenever the user displays a worksheet.
        addOnTools: (this.state && this.state.get('addOnTools')) || [],
        pluginTools: (this.state && this.state.get('pluginTools')) || [],
        // Save asynchronously loaded data to prevent fast follow flicker
        derivedDataTree: saveState ? this.state.get('derivedDataTree') : null,
        investigateTools: this.translateTools(),
        allTools: this.monkey(
          ['addOnTools'],
          ['pluginTools'],
          ['investigateTools'],
          (addOnTools, pluginTools) =>
            this.state && this.state.get('investigateTools').concat(addOnTools).concat(pluginTools),
        ),
        filteredTools: this.monkey(
          ['item'],
          ['toolFilter'],
          ['activeTool'],
          ['addOnTools'],
          ['pluginTools'],
          ['allTools'],
          ['investigateTools'],
          (item, toolFilter, activeTool, addOnTools, pluginTools, allTools) => {
            const filterByParent = (tool) => tool.parentId === activeTool;
            const filterByText = (tool) =>
              _.includes(_.toLower(tool.name), _.toLower(toolFilter)) ||
              _.includes(_.toLower(tool.description), _.toLower(toolFilter));
            const toolFilterFunc = _.isEmpty(toolFilter) ? filterByParent : filterByText;
            const isAddOnToolsGroupWithNoAddOnTools = (tool) =>
              tool.id === TREND_TOOLS.ADDON_TOOLS_GROUP && addOnTools.length === 0 && pluginTools.length === 0;

            const filteredTools = _.chain(allTools)
              .reject('hideFromSearch')
              .filter(toolFilterFunc)
              .reject(isAddOnToolsGroupWithNoAddOnTools)
              .value();

            // show scorecard tool when boundaries is searched for
            if (
              toolFilter &&
              'boundaries'.indexOf(_.toLower(toolFilter)) !== -1 &&
              _.findIndex(filteredTools, { id: 'threshold-metric' }) === -1
            ) {
              filteredTools.push(_.find(allTools, { id: 'threshold-metric' }));
            }

            return filteredTools;
          },
          { immutable: false },
        ),

        breadcrumbs: this.monkey(['activeTool'], ['toolFilter'], ['allTools'], (activeTool, toolFilter, allTools) => {
          const breadcrumbs = [];
          let crumb = _.find(allTools, ['id', activeTool]) as any;

          if (toolFilter) {
            return [];
          }

          while (crumb) {
            breadcrumbs.push(crumb);
            crumb = _.find(allTools, ['id', crumb.parentId]);
          }

          return _.reverse(breadcrumbs);
        }),
        isCapsulePickingMode: this.monkey(['activeTool'], (activeTool) =>
          _.includes([TREND_TOOLS.MANUAL_CONDITION], activeTool),
        ),
        activeToolName: this.monkey(['activeTool'], ['allTools'], (activeTool, allTools) => {
          return _.get(_.find(allTools, ['id', activeTool]), 'name');
        }),
      },
      { immutable: false },
    );
  }

  get item() {
    return this.state.get('item');
  }

  get activeTool() {
    // ensure the active tool is a valid tool. in case a tool gets renamed it will typically be caught in the
    // config upgrader, however, if we missed adding an upgrade or if the affected tool was the active tool
    // the config upgrade will not be applied and we can end up with a blank tool pane. To avoid this we check
    // the active tool and revert to the overview if necessary
    const activeTool = this.state.get('activeTool');
    if (activeTool !== TREND_TOOLS.OVERVIEW) {
      const isActiveToolInvalid = !_.chain(this.state.get('allTools'))
        .map('id')
        .includes(this.state.get('activeTool'))
        .value();
      if (isActiveToolInvalid) {
        this.state.set('activeTool', TREND_TOOLS.OVERVIEW);
      }
    }

    return this.state.get('activeTool');
  }

  get activeToolName() {
    return this.state.get('activeToolName');
  }

  get filteredTools(): InvestigateToolType[] {
    return this.state.get('filteredTools');
  }

  get breadcrumbs(): InvestigateToolType[] {
    return this.state.get('breadcrumbs');
  }

  get toolFilter() {
    return this.state.get('toolFilter');
  }

  get displayMode() {
    return this.state.get('displayMode');
  }

  get derivedDataPanelOpen() {
    return this.state.get('derivedDataPanelOpen');
  }

  get derivedDataTree() {
    return this.state.get('derivedDataTree');
  }

  get isCapsulePickingMode() {
    return this.state.get('isCapsulePickingMode');
  }

  get allTools() {
    return this.state.get('allTools');
  }

  get addOnTools() {
    return this.state.get('addOnTools');
  }

  get pluginTools() {
    return this.state.get('pluginTools');
  }

  dehydrate() {
    return _.omit(this.state.serialize(), [
      'toolFilter',
      'derivedDataTree',
      'addOnTools',
      'pluginTools',
      'investigateTools',
    ]);
  }

  rehydrate(dehydratedState) {
    this.state.merge(dehydratedState);
  }

  /**
   * Utility function that translates the investigate tools headline and description.
   */
  translateTools() {
    return _.map(INVESTIGATE_TOOLS, (tool) =>
      _.assign({}, tool, {
        name: i18next.t(tool.nameTranslationKey),
        description: i18next.t(tool.descriptionTranslationKey),
      }),
    );
  }

  /**
   * Since tools can change a lot, only keep the persisted active tool if it is still valid
   */
  validateActiveTool() {
    const isActiveToolInvalid = !_.chain(this.state.get('allTools'))
      .map('id')
      .includes(this.state.get('activeTool'))
      .value();

    if (isActiveToolInvalid) {
      this.state.set('activeTool', TREND_TOOLS.OVERVIEW);
    }
  }

  protected readonly handlers = {
    INVESTIGATE_SET_ITEM: (payload) => {
      this.state.set(
        'item',
        _.assign(
          {},
          payload.props,
          _.pick(payload.item, ['id', 'name', 'itemType', 'iconClass', 'calculationType', 'isArchived']),
        ),
      );
    },
    INVESTIGATE_CLEAR_ITEM: () => {
      this.state.set('item', {});
    },
    INVESTIGATE_SET_ACTIVE_TOOL: (payload) => {
      this.state.set('activeTool', payload.tool);
      this.state.set('toolFilter', '');
    },
    INVESTIGATE_SET_TOOL_FILTER: (payload) => {
      this.state.set('toolFilter', payload.filter);
      this.state.set('item', {});
      this.state.commit();
    },
    INVESTIGATE_SET_DISPLAY_MODE: (payload) => {
      this.state.set('displayMode', payload.mode);
    },
    INVESTIGATE_SET_DERIVED_DATA_PANEL_OPEN: (payload) => {
      this.state.set('derivedDataPanelOpen', payload.derivedDataPanelOpen);
    },
    /**
     * Sets the derived data tree. The tree is not persisted as part of the workstep.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object[]} payload.derivedDataTree - Array of items that form the derived data tree
     */
    INVESTIGATE_SET_DERIVED_DATA_TREE: (payload) => {
      this.state.set('derivedDataTree', payload.derivedDataTree);
    },
    /**
     * Sets the array of add-on tools and validates the active tool. Must be called after rehydrate.
     */
    INVESTIGATE_SET_ADDON_TOOLS: (payload: { addOnTools: InvestigateToolType[] }) => {
      this.state.set('addOnTools', payload.addOnTools);
      this.validateActiveTool();
    },
    /**
     * Sets the array of plugin tools and validates the active tool. Must be called after rehydrate.
     */
    INVESTIGATE_SET_PLUGIN_TOOLS: (payload: { pluginTools: InvestigateToolType[] }) => {
      this.state.set('pluginTools', payload.pluginTools);
      this.validateActiveTool();
    },
    TREND_SET_COLOR: ({ id, color }) => {
      if (_.find(this.state.get('derivedDataTree'), { id })) {
        const dependencyTree = [...this.state.get('derivedDataTree')];
        const entry: any = _.find(dependencyTree, { id });
        entry.color = color;
        this.state.set('derivedDataTree', dependencyTree);
      }
    },
    INVESTIGATE_TRIGGER_INVESTIGATE_TOOL_TRANSLATION: () => {
      const translatedTools = this.translateTools();
      this.state.set('investigateTools', translatedTools);
    },
  };
}
