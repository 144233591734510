// @ts-strict-ignore
import { HOME_SCREEN_TABS } from '@/main/app.constants';
import _ from 'lodash';
import { PersistenceLevel, Store } from '@/core/flux.service';
import { HOME_SCREEN_SORT, HOME_SCREEN_TABLE_TYPE, TrainingPromptDisplay } from '@/homescreen/homescreen.constants';

export class HomeScreenStore extends Store {
  persistenceLevel: PersistenceLevel = 'WORKBENCH';
  static readonly storeName = 'sqHomeScreenStore';

  initialize() {
    this.state = this.immutable({
      isExact: false,
      currentTab: HOME_SCREEN_TABS.HOME,
      currentFolderId: '',
      currentlyLoading: '',
      tabFolders: {},
      pageNumberByTable: [],
      expandedFolderIds: [],
      folderTree: [],
      searchParams: {},
      loadingTable: [],
      itemsByTable: [],
      breadcrumbs: [],
      sortByTable: [
        {
          key: HOME_SCREEN_TABLE_TYPE.TAB,
          sortProperty: HOME_SCREEN_SORT.UPDATED_AT,
          sortAsc: false,
        },
        {
          key: HOME_SCREEN_TABLE_TYPE.PINNED,
          sortProperty: HOME_SCREEN_SORT.NAME,
          sortAsc: true,
        },
        {
          key: HOME_SCREEN_TABLE_TYPE.RECENT,
          sortProperty: HOME_SCREEN_SORT.OPENED_AT,
          sortAsc: false,
        },
        {
          key: HOME_SCREEN_TABLE_TYPE.SEARCH,
          sortProperty: HOME_SCREEN_SORT.NAME,
          sortAsc: true,
        },
      ],
      pageSizeByTable: [
        { key: HOME_SCREEN_TABLE_TYPE.TAB, size: 15 },
        { key: HOME_SCREEN_TABLE_TYPE.SEARCH, size: 15 },
        { key: HOME_SCREEN_TABLE_TYPE.PINNED, size: 5 },
        { key: HOME_SCREEN_TABLE_TYPE.RECENT, size: 5 },
      ],
      trainingPromptDisplay: TrainingPromptDisplay.HOME_SCREEN,
      displayedAddOnIdentifier: '',
    });
  }

  get currentTab(): HOME_SCREEN_TABS {
    return this.state.get('currentTab');
  }

  get currentFolderId() {
    return this.state.get('currentFolderId');
  }

  get tabFolders() {
    return this.state.get('tabFolders');
  }

  get expandedFolderIds() {
    return this.state.get('expandedFolderIds');
  }

  get folderTree() {
    return this.state.get('folderTree');
  }

  get breadcrumbs() {
    return this.state.get('breadcrumbs');
  }

  get searchParams() {
    return this.state.get('searchParams');
  }

  get isExact(): boolean {
    return this.state.get('isExact');
  }

  get trainingPromptDisplay(): TrainingPromptDisplay {
    return this.state.get('trainingPromptDisplay');
  }

  get displayedAddOnIdentifier() {
    return this.state.get('displayedAddOnIdentifier');
  }

  getPageNumberForTable(table) {
    return _.get(_.find(this.state.get('pageNumberByTable'), { key: table }), 'pageNumber', 1);
  }

  getSortForTable(table): any {
    return _.find(this.state.get('sortByTable'), { key: table });
  }

  getPageSizeByTable(table) {
    return _.get(_.find(this.state.get('pageSizeByTable'), { key: table }), 'size', 15);
  }

  getItemsForTable(table) {
    return _.get(_.find(this.state.get('itemsByTable'), { key: table }), 'items', null);
  }

  getItemTotalForTable(table) {
    return _.get(_.find(this.state.get('itemsByTable'), { key: table }), 'totalResults', 0);
  }

  isTableLoading(table) {
    return _.indexOf(this.state.get('loadingTable'), table) > -1;
  }

  private folderIndex(folders) {
    const index = {};
    const indexFolders = (folders) => {
      _.forEach(folders, (folder) => {
        index[_.toLower(folder.id)] = folder;
        indexFolders(folder.subfolders);
      });
    };

    indexFolders(folders);

    return index;
  }

  private setPageNumberForTable({ pageNumber, table }) {
    const idx = _.findIndex(this.state.get('pageNumberByTable'), {
      key: table,
    });
    const cursor = this.state.select('pageNumberByTable', idx);

    if (cursor.exists()) {
      cursor.merge({ key: table, pageNumber });
    } else {
      this.state.set('pageNumberByTable', [...this.state.get('pageNumberByTable'), { key: table, pageNumber }]);
    }
  }

  protected readonly handlers = {
    SET_CURRENT_HOME_SCREEN_TAB: ({ tab }) => {
      this.state.set('currentTab', tab);
    },
    SET_CURRENT_HOME_SCREEN_FOLDER: ({ folderId }) => {
      this.state.set('currentFolderId', folderId);
    },
    SET_TAB_FOLDER: ({ key, folder }) => {
      this.state.set('tabFolders', {
        ...this.state.get('tabFolders'),
        [key]: folder,
      });
    },
    ADD_EXPANDED_FOLDER_IDS: ({ expandedFolderIds }) => {
      const ids = this.state.get('expandedFolderIds');
      this.state.set('expandedFolderIds', _.uniq([...ids, ...expandedFolderIds]));
    },
    REMOVE_EXPANDED_FOLDER_IDS: ({ collapsedFolderIds }) => {
      this.state.set('expandedFolderIds', _.without(this.state.get('expandedFolderIds'), ...collapsedFolderIds));
    },
    MERGE_HOME_SCREEN_FOLDER_TREE: (payload) => {
      const tree = _.cloneDeep(this.state.get('folderTree'));
      const index = this.folderIndex(tree);
      _.forEach(payload.tree, (folder) => {
        const existing = index[_.toLower(folder.id)];
        if (existing) {
          _.merge(existing, folder);
        } else {
          tree.push(folder);
        }
      });
      this.state.set('folderTree', _.sortBy(tree, [(folder) => _.lowerCase(folder.name), 'name']));
    },
    SET_HOME_SCREEN_TABLE_SORT: (payload) => {
      const idx = _.findIndex(this.state.get('sortByTable'), {
        key: payload.table,
      });
      const cursor = this.state.select('sortByTable', idx);
      if (cursor.exists()) {
        cursor.merge(_.omit(payload, 'table'));
      }
    },
    SET_HOME_SCREEN_TABLE_LOADING: ({ table }) => {
      const currentlyLoading = [...this.state.get('loadingTable')];
      const tableIndex = _.indexOf(currentlyLoading, table);
      if (tableIndex > -1) {
        currentlyLoading.splice(tableIndex, 1);
      } else {
        currentlyLoading.push(table);
      }
      this.state.set('loadingTable', currentlyLoading);
    },
    SET_HOME_SCREEN_ITEMS_FOR_TABLE: ({ table, items, totalResults }) => {
      const idx = _.findIndex(this.state.get('itemsByTable'), { key: table });
      const cursor = this.state.select('itemsByTable', idx);
      if (cursor.exists()) {
        cursor.merge({ items, totalResults });
      } else {
        this.state.set('itemsByTable', [...this.state.get('itemsByTable'), { key: table, items, totalResults }]);
      }

      const pageNumber = _.get(_.find(this.state.get('pageNumberByTable'), { key: table }), 'pageNumber');
      if (items.length === 0 && pageNumber > 1) {
        this.setPageNumberForTable({ pageNumber: pageNumber - 1, table });
      }
    },
    SET_HOME_SCREEN_PAGE_NUMBER: this.setPageNumberForTable,
    SET_HOME_SCREEN_SEARCH_PARAMS: ({ searchParams }) => {
      this.state.set('searchParams', searchParams);
    },
    SET_PAGE_SIZE_FOR_TABLE: ({ table, size }) => {
      const idx = _.findIndex(this.state.get('pageSizeByTable'), {
        key: table,
      });
      const cursor = this.state.select('pageSizeByTable', idx);
      if (cursor.exists()) {
        cursor.merge({ key: table, size });
      } else {
        this.state.set('pageSizeByTable', [...this.state.get('pageSizeByTable'), { key: table, size }]);
      }
    },
    CLEAR_HOME_SCREEN_ITEMS: () => {
      this.state.set('folderTree', []);
      this.state.set('expandedFolderIds', []);
      this.state.set('itemsByTable', []);
    },
    SET_BREADCRUMBS: ({ crumbs }) => {
      this.state.set('breadcrumbs', crumbs);
    },
    CLEAR_BREADCRUMBS: () => {
      this.state.set('breadcrumbs', []);
    },
    RESET_HOME_SCREEN_STORE: () => {
      const stateToSave = _.pick(this.state.serialize(), ['sortByTable', 'pageSizeByTable']);
      this.state.merge(
        _.assign(stateToSave, {
          pageNumberByTable: [],
          searchParams: { textSearch: '' },
        }),
      );
    },
    SET_HOME_SCREEN_SEARCH_IS_EXACT: ({ isExact }) => {
      this.state.set('isExact', isExact);
    },
    SET_HOME_SCREEN_TRAINING_PROMPT_DISPLAY: ({ trainingPromptDisplay }) => {
      this.state.set('trainingPromptDisplay', trainingPromptDisplay);
    },
    SET_HOME_SCREEN_DISPLAYED_ADDON_IDENTIFIER: ({ displayedAddOnIdentifier }) => {
      this.state.set('displayedAddOnIdentifier', displayedAddOnIdentifier);
    },
  };

  /**
   * Exports state so it can be used to re-create the state later using `rehydrate`.
   *
   * @returns {Object} The dehydrated state.
   */
  dehydrate() {
    return _.pick(this.state.serialize(), ['sortByTable', 'pageSizeByTable', 'trainingPromptDisplay']);
  }

  /**
   * Re-creates the workbench state.
   *
   * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
   * @return {Object} A promise that is fulfilled when the workbench is completely rehydrated.
   */
  rehydrate(dehydratedState) {
    this.state.merge(dehydratedState);
  }
}
