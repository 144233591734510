import _ from 'lodash';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TooltipPosition } from '@seeqdev/qomponents/dist/Tooltip/Tooltip.types';
import { useTranslation } from 'react-i18next';
import { isProtractor, isSystemTest } from '@/core/utilities';

export const DEFAULT_TOOL_TIP_DELAY = 500;

export interface HoverTooltipProps {
  placement?: TooltipPosition;
  delay?: number;
  text?: string;
  formattedText?: JSX.Element;
  translateParams?: object;
  skipMemo?: boolean;
  children?: JSX.Element;
}

/**
 * Generic tooltip that appears after hover delay
 */
const HoverTooltipUnwrapped: React.FunctionComponent<HoverTooltipProps> = ({
  text = '',
  delay = DEFAULT_TOOL_TIP_DELAY,
  placement,
  children = <></>,
  formattedText,
  translateParams,
}) => {
  const { t } = useTranslation();

  if (_.isEmpty(text) && !formattedText) {
    return <>{children}</>;
  }

  const tooltip = <Tooltip id={text}>{formattedText ? formattedText : t(text, translateParams ?? {})}</Tooltip>;

  return (
    <OverlayTrigger placement={placement} delay={isProtractor() ? 0 : { show: delay, hide: 0 }} overlay={tooltip}>
      {isSystemTest() ? <span data-testid="hover-tooltip">{children}</span> : children}
    </OverlayTrigger>
  );
};

export const HoverTooltip = React.memo(
  HoverTooltipUnwrapped,
  (prev, next) =>
    !(
      !_.isEqual(prev.translateParams, next.translateParams) ||
      !_.isEqual(prev.formattedText, next.formattedText) ||
      !_.isEqual(prev.children, next.children) ||
      prev.placement !== next.placement ||
      prev.delay !== next.delay ||
      prev.text !== next.text ||
      prev.skipMemo
    ),
);
