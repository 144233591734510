import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { ContainerWithHTML } from '@/core/ContainerWithHTML.atom';
import { formulaTypeClass } from '@/tools/formula/formulaTool.actions';
import { FormulaParameter } from '@/formula/formula.types';

interface FormulaParametersProps {
  parameters: FormulaParameter[];
  requestDocumentation: (href: string) => any;
}

export const FormulaParameters: React.FunctionComponent<FormulaParametersProps> = ({
  parameters,
  requestDocumentation,
}) => {
  const { t } = useTranslation();

  const isLast = (index: number) => _.toNumber(index) === parameters.length - 1;

  if (parameters?.length > 0) {
    return (
      <>
        <h2 className="mb5">{t('FORMULA.DOCUMENTATION.PARAMETERS')}</h2>
        {_.map(parameters, (param, index) => (
          <div className={classNames(isLast(index) ? 'mb15' : 'mb10')} key={index} data-testid="parameter">
            <span className="flexColumnContainer flexBaseline">
              <h3 className="mb0 mt3">{param.name}: </h3>
              <span className={classNames('pl5', formulaTypeClass(param.type))}>{param.type}</span>
              {param.optional && (
                <span className="pl2">
                  (
                  {t('FORMULA.DOCUMENTATION.OPTIONAL', {
                    DEFAULT: param.defaultValue,
                  })}
                  )
                </span>
              )}
            </span>
            <div className="pl10">
              <ContainerWithHTML content={param.description} />
            </div>
          </div>
        ))}
      </>
    );
  }

  return <></>;
};
