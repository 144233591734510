import React from 'react';
import { TableHeaderConditionProps } from '@/tableBuilder/tableBuilder.types';
import { TableBuilderTextHeader } from '@/tableBuilder/tableComponents/TableBuilderTextHeader.atom';
import _ from 'lodash';
import { useUpdateHeaderBackground } from '@/tableBuilder/ag/hooks/useUpdateHeaderBackground.hook';

export const TableHeaderCondition: React.FunctionComponent<TableHeaderConditionProps> = (props) => {
  const { eGridHeader, headerBackgroundColor, resetColumnWidth = _.noop, column } = props;

  useUpdateHeaderBackground(headerBackgroundColor, eGridHeader);

  return (
    <TableBuilderTextHeader
      {...props}
      autoSizeColumn={() => resetColumnWidth(column?.getColId())}
      autoSizeAllColumns={() => props.columnApi.getColumnState().forEach((state) => resetColumnWidth(state.colId))}
      menuActions={props.menuActions}
    />
  );
};
