// @ts-strict-ignore
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Icon } from '@seeqdev/qomponents';
import { DateTimeEntry } from '@/core/DateTimeEntry.atom';
import { DurationEntry } from '@/core/DurationEntry.atom';
import { AutoUpdate } from '@/trend/AutoUpdate.molecule';
import { RangeExport } from '@/trendData/duration.store';
import { Timezone } from '@/datetime/timezone.service';
import { isPresentationWorkbookMode } from '@/utilities/utilities';
import {
  autoUpdate,
  displayRange as displayRangeDurationActions,
  investigateRange,
} from '@/trendData/duration.actions';

interface DisplayRangeSelectorProps {
  formMode?: boolean;
  autoUpdateDisabled?: boolean;
  rangeEditingEnabled?: boolean;
  durationEditingEnabled?: boolean;
  investigateRangeCopyDisabled?: boolean;
  investigateRangeCopyNonExistent?: boolean;
  timeZoneEditingEnabled?: boolean;
  label?: string;
  halfRangeButtonDisabled?: boolean;
  trackCategory?: string;
  trackAction?: string;
  inputExtraClassNames?: string;
  displayRange: RangeExport;
  isInTopic?: boolean;
  timezone: Timezone;
}

export const DisplayRangeSelector: React.FunctionComponent<DisplayRangeSelectorProps> = (props) => {
  const {
    label,
    formMode,
    autoUpdateDisabled,
    rangeEditingEnabled,
    durationEditingEnabled,
    timeZoneEditingEnabled = true,
    investigateRangeCopyDisabled,
    investigateRangeCopyNonExistent,
    halfRangeButtonDisabled,
    trackCategory,
    trackAction,
    inputExtraClassNames,
    displayRange,
    isInTopic = false,
    timezone,
  } = props;

  const { t } = useTranslation();

  const isPresentationMode = isPresentationWorkbookMode();
  const displayRangeActions = displayRangeDurationActions;

  return (
    <div
      className={classNames('flexColumnContainer flexSpaceBetween displayRangeSelector pb2')}
      data-testid="displayRangeSelector">
      <div className="flexRowContainer">
        {formMode && (
          <label
            className={classNames(
              'font-size-smaller',
              { ml15: !investigateRangeCopyNonExistent },
              { disabled: !rangeEditingEnabled },
            )}>
            {t('START')}
          </label>
        )}
        <div className="flexColumnContainer">
          {!isPresentationMode && !investigateRangeCopyDisabled && (
            <span onClick={investigateRange.copyFromDisplayRange} className="copyToInvestigateIcon">
              <Icon
                icon="fc-arrow-down"
                tooltip={t('RANGE_SELECTOR.COPY_TO_INVESTIGATE')}
                tooltipDelay={1000}
                tooltipPlacement="bottom"
                extraClassNames="displayRangeIcon btn btn-link sq-btn-xs cursorPointer link-no-underline"
              />
            </span>
          )}
          {isPresentationMode && <div className="ml35" />}

          <div
            id="displayRangeStart"
            className={classNames('displayRangeDateTime displayRangeStart', {
              'ml15': investigateRangeCopyNonExistent ? false : !isPresentationMode && investigateRangeCopyDisabled,
              'min-width-150': !formMode && !isInTopic,
            })}>
            <DateTimeEntry
              extraClassName={classNames('small', {
                readOnly: !rangeEditingEnabled,
              })}
              testId="specDisplayRangeStart"
              date={displayRange.start}
              updateDate={(date) => setTimeout(() => displayRangeActions.updateStart(date))}
              inputExtraClassNames="'textAlignLeft'"
              updateBothDates={displayRangeActions.updateTimes}
              timezone={timezone}
              otherDate={displayRange.end}
              fieldIdentifier="'DISPLAY_RANGE_START'"
              readOnly={!rangeEditingEnabled}
              timeZoneReadOnly={!timeZoneEditingEnabled}
              trackCategory="'RangeSelector'"
              trackAction="'DateTimeEntry'"
              trackInformation="'Start Date set'"
            />
          </div>
        </div>
      </div>

      <div className="flexRowContainer">
        {label && <em>{label}</em>}
        {formMode && (
          <label
            className={classNames('flexSelfCenter font-size-smaller text-center mr20', { mr20: rangeEditingEnabled })}>
            {t('DURATION')}
          </label>
        )}

        <div
          className={classNames('flexColumnContainer', {
            flexCenter: formMode,
          })}
          id="displayRangeNavigation">
          {rangeEditingEnabled && (
            <span
              onClick={displayRangeActions.stepBack}
              data-testid="rangeSelectorStepBackFull"
              className="rangeSelectorStep">
              <Icon
                icon="fc-step-back-full"
                tooltip={t('RANGE_SELECTOR.STEP_BACK_FULL')}
                tooltipDelay={1000}
                tooltipPlacement="bottom"
                extraClassNames="displayRangeIcon btn btn-link sq-btn-xs cursorPointer link-no-underline btn-transparent"
              />
            </span>
          )}
          {rangeEditingEnabled && !halfRangeButtonDisabled && (
            <span
              onClick={displayRangeActions.stepBackHalf}
              data-testid="rangeSelectorStepBackHalf"
              className="rangeSelectorStep">
              <Icon
                icon="fc-step-back-half"
                tooltip={t('RANGE_SELECTOR.STEP_BACK_HALF')}
                tooltipDelay={1000}
                tooltipPlacement="bottom"
                extraClassNames="displayRangeIcon btn btn-link sq-btn-xs cursorPointer link-no-underline btn-transparent"
              />
            </span>
          )}

          {(rangeEditingEnabled || durationEditingEnabled) && (
            <DurationEntry
              extraClassNames={classNames({ 'min-width-50': formMode, 'min-width-75': !formMode })}
              inputExtraClassNames={classNames(inputExtraClassNames, 'text-center', {
                'min-width-40': formMode,
                'min-width-65': !formMode,
              })}
              startDate={displayRange.start}
              endDate={displayRange.end}
              updateDuration={displayRangeActions.updateDuration}
              updateDates={displayRangeActions.updateTimes}
              timezone={timezone}
              read-only={!rangeEditingEnabled && !durationEditingEnabled}
            />
          )}
          {rangeEditingEnabled && !halfRangeButtonDisabled && (
            <span
              onClick={displayRangeActions.stepForwardHalf}
              data-testid="rangeSelectorStepForwardHalf"
              className="rangeSelectorStep">
              <Icon
                icon="fc-step-fore-half"
                tooltip={t('RANGE_SELECTOR.STEP_FORE_HALF')}
                tooltipDelay={1000}
                tooltipPlacement="bottom"
                extraClassNames="displayRangeIcon btn btn-link sq-btn-xs cursorPointer link-no-underline btn-transparent"
              />
            </span>
          )}
          {rangeEditingEnabled && (
            <>
              <span
                onClick={displayRangeActions.stepForward}
                data-testid="rangeSelectorStepForward"
                className="rangeSelectorStep">
                <Icon
                  icon="fc-step-fore-full"
                  tooltip={t('RANGE_SELECTOR.STEP_FORE_FULL')}
                  tooltipDelay={1000}
                  tooltipPlacement="bottom"
                  extraClassNames="displayRangeIcon btn btn-link sq-btn-xs cursorPointer link-no-underline btn-transparent"
                />
              </span>
              <span
                onClick={displayRangeActions.stepToEnd}
                data-testid="rangeSelectorStepToEnd"
                className="rangeSelectorStep">
                <Icon
                  icon="fc-arrow-right-right"
                  tooltip={t('RANGE_SELECTOR.STEP_TO_END')}
                  tooltipDelay={1000}
                  tooltipPlacement="bottom"
                  extraClassNames="displayRangeIcon btn btn-link sq-btn-xs cursorPointer link-no-underline btn-transparent"
                />
              </span>
            </>
          )}
          {!isPresentationMode && !autoUpdateDisabled && autoUpdate.allowed() && <AutoUpdate />}
          {!isPresentationMode && rangeEditingEnabled && (
            <span
              onClick={displayRangeActions.copyToAllWorksheets}
              data-testid="rangeSelectorAllWorksheets"
              className="rangeSelectorStep ml5">
              <Icon
                icon="fc-copy-date"
                tooltip={t('RANGE_SELECTOR.COPY_TO_ALL_WORKSHEETS')}
                tooltipDelay={500}
                tooltipPlacement="bottom"
                extraClassNames="displayRangeIcon btn btn-link sq-btn-xs cursorPointer link-no-underline btn-transparent"
              />
            </span>
          )}
        </div>
      </div>
      <div className="flexRowContainer">
        {formMode && (
          <label
            className={classNames('font-size-smaller mr10', {
              disabled: !rangeEditingEnabled,
            })}>
            {t('END')}
          </label>
        )}
        <div
          className={classNames('flexColumnContainer displayRangeDateTime displayRangeEnd', {
            'min-width-165': !formMode && !isInTopic,
          })}
          id="displayRangeEnd">
          <DateTimeEntry
            testId="specDisplayRangeEnd"
            extraClassName={classNames('small flexFill', {
              'min-width-150': !isInTopic,
              'pl26': isInTopic,
              'text-right': !formMode,
              'mr15': !formMode,
              'readOnly': !rangeEditingEnabled,
            })}
            date={displayRange.end}
            updateDate={displayRangeActions.updateEnd}
            updateBothDates={displayRangeActions.updateTimes}
            timezone={timezone}
            otherDate={displayRange.start}
            fieldIdentifier="'DISPLAY_RANGE_END'"
            readOnly={!rangeEditingEnabled}
            timeZoneReadOnly={!timeZoneEditingEnabled}
            trackCategory={trackCategory}
            trackAction={trackAction}
            trackInformation="'End Date set'"
          />
        </div>
      </div>
    </div>
  );
};
