import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { Icon } from '@seeqdev/qomponents';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import { ContainerWithHTML } from '@/core/ContainerWithHTML.atom';
import { formulaTypeClass } from '@/tools/formula/formulaTool.actions';
import { FormulaParameter } from '@/formula/formula.types';

type FormulaFunction = {
  name: string;
  fluent: boolean;
  parameters: FunctionParameters[];
  returnType: string;
  description: string;
};

type FunctionParameters = {
  name: string;
  optional: boolean;
  type: string;
};

interface FormulaFunctionsProps {
  insertFormulaSnippet: (snippet: string) => void;
  functions: FormulaFunction[];
  requestDocumentation: (href: string) => any;
}

interface FunctionParametersProps {
  parameters: FunctionParameters[];
  hideFirst?: boolean;
}

// Exported for testing purposes only
export const FunctionParameters: React.FunctionComponent<FunctionParametersProps> = ({
  parameters,
  hideFirst = false,
}) => {
  return (
    <>
      {_.chain(hideFirst ? _.tail(parameters) : parameters)
        .map((param) => (
          <HoverTooltip key={param.name} text={param.optional ? 'FORMULA.PARAM_OPTIONAL' : ''}>
            <span
              key={`${param.name}-name`}
              className={classNames({ 'text-italic': param.optional }, formulaTypeClass(param.type))}>
              {param.name}
            </span>
          </HoverTooltip>
        ))
        .reduce((previous, current) => [previous, ', ', current])
        .value()}
    </>
  );
};

export const FormulaFunctions: React.FunctionComponent<FormulaFunctionsProps> = ({
  functions,
  insertFormulaSnippet,
  requestDocumentation,
}) => {
  const { t } = useTranslation();

  const isLast = (index: number) => _.toNumber(index) === functions.length - 1;

  const addToFormula = (formula: any) => {
    const parameters = _.reduce<FormulaParameter, string[]>(
      formula.parameters,
      (parameters, parameter) =>
        _.concat(parameters, parameter.name + (_.endsWith(parameter.type, '...') ? '...' : '')),
      [],
    );
    const fancyFormula = `${formula.name}(${_.join(parameters, ', ')})`;
    insertFormulaSnippet(fancyFormula);
  };

  if (functions?.length > 0) {
    return (
      <>
        <h2>{t('FORMULA.DOCUMENTATION.VARIATIONS')}</h2>
        {_.map(functions, (operator, index) => (
          <React.Fragment key={`operator_${index}`}>
            <div className={classNames({ mb15: isLast(index) })}>
              <div className="mt5 flexColumnContainer flexSpaceBetween">
                {!operator.fluent && (
                  <div data-testid="nonFluentOperator">
                    <b className="highlightedText">{operator.name}</b>
                    (<FunctionParameters parameters={operator.parameters} />
                    ):
                    <span className={classNames(formulaTypeClass(operator.returnType), 'pl2')}>
                      {operator.returnType}
                    </span>
                  </div>
                )}
                {operator.fluent && (
                  <div data-testid="fluentOperator">
                    {operator.parameters.length > 0 && (
                      <>
                        <span className={classNames(formulaTypeClass(operator.parameters[0].type), 'pl2')}>
                          {operator.parameters[0].name}.
                        </span>
                        <b className="highlightedText">{operator.name}</b>
                      </>
                    )}
                    (
                    <FunctionParameters parameters={operator.parameters} hideFirst={true} />
                    ):
                    <span className={formulaTypeClass(operator.returnType)}>{operator.returnType}</span>
                  </div>
                )}
                <Icon
                  icon="fc-copy"
                  tooltip={t('COPY')}
                  testId="addFunctionIcon"
                  extraClassNames="cursorPointer pt3 pr5"
                  onClick={() => addToFormula(operator)}
                />
              </div>
              <div className="pl10 functionDescription">
                <ContainerWithHTML content={operator.description} />
              </div>
            </div>
          </React.Fragment>
        ))}
      </>
    );
  }

  return <></>;
};
