// @ts-strict-ignore
import _ from 'lodash';
import BroadcastChannel from 'broadcast-channel';

/**
 * A [BroadcastChannel](https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API)
 * allows communication between windows and tabs within the same browsing context (same browser).
 * The 'broadcast-channel' package is used to provide support to IE 11.
 *
 * Unlike the websocket connection, the broadcast channel is only shared within tabs in the
 * same browser and doesn't require participation from the backend - this makes it ideal for
 * indicating that localStorage has changed
 *
 * Note that messages emitted from a browser tab will not trigger handlers for the originating browser tab
 */

export const AUTH_CHANGE_BROADCAST_CHANNEL = 'auth-change-broadcast-channel';

export const ACL_MODAL_CHANGE_CHANNEL = 'acl-modal-change-channel';

interface BroadcastChannelSubscription {
  /** The name of the channel to listen subscribe to */
  channelId: string;
  /** The callback to invoke when a message is received */
  onMessage: (message: any) => void;
}

const channels = {} as Record<string, BroadcastChannel>;

// Close the channels so no more messages are emitted after the app is destroyed
export function cleanupBroadcastChannels() {
  _.chain(channels)
    .keys()
    .forEach((channelId) => {
      channels[channelId].close();
      delete channels[channelId];
    })
    .value();
}

/**
 * Get or create a channel identified by {@param channelId}
 */
export function getBroadcastChannel(channelId: string) {
  if (!channels[channelId]) {
    channels[channelId] = new BroadcastChannel(`seeq-${channelId}`, {
      webWorkerSupport: true,
    });
  }

  return channels[channelId];
}

/**
 * Send a message, with json serializable contents of {@param data}, to the channel identified by {@param channelId}
 */
export function emitBroadcastChannelMessage(channelId: string, data?) {
  const channel = getBroadcastChannel(channelId);
  channel.postMessage(data);
}

/**
 * Subscribe to a channel, the returned callback should be called to unsubscribe when no longer needed
 */
export function subscribeToBroadcastChannel({ channelId, onMessage }: BroadcastChannelSubscription): () => void {
  const channel = getBroadcastChannel(channelId);

  function messageHandler(data) {
    // Ensure that the callback is called within a digest cycle
    onMessage(data);
  }

  channel.addEventListener('message', messageHandler);
  return () => channel.removeEventListener('message', messageHandler);
}
