import { ColumnDefinitionInputV1, ColumnTypeEnum } from '@/sdk/model/ColumnDefinitionInputV1';
import { ColumnDefinitionOutputV1 } from '@/sdk/model/ColumnDefinitionOutputV1';
import { ColumnRuleAncestorInputV1 } from '@/sdk/model/ColumnRuleAncestorInputV1';
import { ColumnRuleConcatInputV1 } from '@/sdk/model/ColumnRuleConcatInputV1';
import { ColumnRuleDescendantInputV1, PropertyMatchOperatorEnum } from '@/sdk/model/ColumnRuleDescendantInputV1';
import { ColumnRuleEventPropertyInputV1 } from '@/sdk/model/ColumnRuleEventPropertyInputV1';
import { ColumnRuleInputV1 } from '@/sdk/model/ColumnRuleInputV1';
import { ColumnRuleItemPropertyInputV1 } from '@/sdk/model/ColumnRuleItemPropertyInputV1';
import { ColumnRuleOutputV1 } from '@/sdk/model/ColumnRuleOutputV1';
import { ColumnRulePathInputV1 } from '@/sdk/model/ColumnRulePathInputV1';
import { TableDefinitionInputV1 } from '@/sdk/model/TableDefinitionInputV1';
import { TableDefinitionOutputV1 } from '@/sdk/model/TableDefinitionOutputV1';
import {
  ColumnRuleAssetCreatorInputV1,
  ColumnRuleFormulaCreatorInputV1,
  ColumnRuleTreePathCreatorInputV1,
  GraphQLInputV1,
  sqGraphQLApi,
  sqTableDefinitionsApi,
} from '@/sdk';
import { addTableDefinition } from '@/workbook/workbook.actions';
import { FormulaCompileResult, MaterializedTable } from './tableDefinition.types';
import { runFormula as compileFormulaOrThrowError } from '@/formula/formula.utilities';
import { FormulaErrorInterface } from '@/formula/FormulaEditor.molecule';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { ID_COLUMN } from '@/tableDefinitionEditor/tableDefinition.store';
import { ColumnRuleConstantInputV1 } from '@/sdk/model/ColumnRuleConstantInputV1';

export const DEFAULT_TABLE_DEFINITION_NAME = 'New Table';

//TODO CRAB-40929 figure out actual labels and helpText and put in translation file
export const ColumnRulesWithLabels: readonly {
  readonly rule: ColumnRule;
  readonly label: string;
}[] = [
  {
    rule: SeeqNames.MaterializedTables.Rules.Ancestor as ColumnRule,
    label: 'SCALING.RULES.ANCESTOR',
  },
  {
    rule: SeeqNames.MaterializedTables.Rules.ConcatColumns as ColumnRule,
    label: 'SCALING.RULES.CONCAT',
  },
  {
    rule: SeeqNames.MaterializedTables.Rules.EventProperty as ColumnRule,
    label: 'SCALING.RULES.EVENT_PROPERTY',
  },
  {
    rule: SeeqNames.MaterializedTables.Rules.ItemProperty as ColumnRule,
    label: 'SCALING.RULES.ITEM_PROPERTY',
  },
  {
    rule: SeeqNames.MaterializedTables.Rules.Path as ColumnRule,
    label: 'SCALING.RULES.PATH',
  },
  {
    rule: SeeqNames.MaterializedTables.Rules.FormulaCreator as ColumnRule,
    label: 'SCALING.RULES.FORMULA_CREATOR',
  },
  {
    rule: 'assetCreator',
    label: 'Asset Creator',
  },
  {
    rule: 'treePathCreator',
    label: 'Tree Path Creator',
  },
  {
    rule: 'descendant',
    label: 'Descendant',
  },
  {
    rule: 'constant',
    label: 'Constant',
  },
] as const;

export type ColumnRuleWithLabel = typeof ColumnRulesWithLabels[number];
export type ColumnRule = keyof ColumnRuleInputV1;
const AllColumnRules: ColumnRule[] = ColumnRulesWithLabels.map((rule) => rule.rule);

export const ColumnRuleToRequiredParameters: Record<ColumnRule, RuleParameter[]> = {
  ancestor: [SeeqNames.MaterializedTables.Parameters.ColumnIndex as RuleParameter, 'level'],
  concatColumns: [SeeqNames.MaterializedTables.Parameters.ColumnIndexes as RuleParameter],
  eventProperty: [SeeqNames.MaterializedTables.Parameters.PropertyName as RuleParameter],
  itemProperty: [SeeqNames.MaterializedTables.Parameters.ColumnIndex as RuleParameter, 'propertyName'],
  path: [SeeqNames.MaterializedTables.Parameters.ColumnIndex as RuleParameter, 'level', 'separator'],
  formulaCreator: [
    SeeqNames.MaterializedTables.Parameters.ColumnIndexes as RuleParameter,
    'formula',
    'name',
    'parameters',
  ],
  assetCreator: ['columnIndex'],
  treePathCreator: ['columnIndexes'],
  descendant: ['columnIndex', 'propertyName', 'propertyValue', 'propertyMatchOperator'],
  constant: ['constant'],
};

export const ColumnRuleToAllParameters: Record<ColumnRule, RuleParameter[]> = {
  ancestor: ['columnIndex', 'level'],
  concatColumns: ['columnIndexes'],
  eventProperty: ['propertyName'],
  itemProperty: ['columnIndex', 'propertyName'],
  path: ['columnIndex', 'level', 'separator'],
  formulaCreator: [
    'columnIndexes',
    'formula',
    'name',
    'description',
    'parameters',
    'scopedTo',
    'identityId',
    'permissionString',
    'securityString',
  ],
  assetCreator: [
    'columnIndex',
    'description',
    'scopedTo',
    'identityId',
    'permissionString',
    'securityString',
    'isRoot',
  ],
  treePathCreator: ['columnIndexes', 'separator'],
  descendant: ['columnIndex', 'itemType', 'propertyName', 'propertyValue', 'propertyMatchOperator'],
  constant: ['constant'],
};

const RulesAllowedOnAllColumns: ColumnRule[] = ['itemProperty', 'eventProperty'];

// TODO CRAB-41437 unrestrict these permissions options
export const restrictedParams = ['identityId', 'permissionString', 'securityString'];

/** Taken from the return type of execute for the backend implementations of each rule */
export const ColumnTypeToAllowedRules: Record<ColumnTypeEnum, ColumnRule[]> = {
  [ColumnTypeEnum.TEXT]: [...RulesAllowedOnAllColumns, 'concatColumns', 'path', 'treePathCreator', 'constant'],
  [ColumnTypeEnum.NUMERIC]: [...RulesAllowedOnAllColumns, 'constant'],
  [ColumnTypeEnum.UUID]: [
    ...RulesAllowedOnAllColumns,
    'ancestor',
    'formulaCreator',
    'assetCreator',
    'descendant',
    'constant',
  ],
  [ColumnTypeEnum.TIMESTAMPTZ]: [...RulesAllowedOnAllColumns, 'constant'],
  [ColumnTypeEnum.BOOLEAN]: [...RulesAllowedOnAllColumns, 'constant'],
};

/** Taken from backend implementations of each rule's getInputDataType. If a rule can only have one input, then
 *  column input type is defined on columnIndex. If it can have multiple, it is defined on columnIndexes. In the
 *  future, when we allow different column input types, a new structure will need to be chosen */
export const ColumnRuleToAllowedColumnInputTypes: Record<
  ColumnRule,
  { columnIndex?: ColumnTypeEnum; columnIndexes?: ColumnTypeEnum }
> = {
  ancestor: { columnIndex: ColumnTypeEnum.UUID },
  concatColumns: { columnIndexes: ColumnTypeEnum.TEXT },
  eventProperty: {},
  itemProperty: { columnIndex: ColumnTypeEnum.UUID },
  path: { columnIndex: ColumnTypeEnum.UUID },
  formulaCreator: { columnIndexes: ColumnTypeEnum.UUID },
  assetCreator: { columnIndex: ColumnTypeEnum.TEXT },
  treePathCreator: { columnIndexes: ColumnTypeEnum.UUID },
  descendant: { columnIndex: ColumnTypeEnum.UUID },
  constant: {},
} as const;

const MultiInputColumnRules: string[] = Object.entries(ColumnRuleToAllParameters)
  .filter(([_, parameters]) => parameters.includes('columnIndexes'))
  .map(([key, _]) => key);

export type ColumnRuleInputParameters =
  | ColumnRuleItemPropertyInputV1
  | ColumnRulePathInputV1
  | ColumnRuleAncestorInputV1
  | ColumnRuleConcatInputV1
  | ColumnRuleDescendantInputV1
  | ColumnRuleEventPropertyInputV1
  | ColumnRuleFormulaCreatorInputV1
  | ColumnRuleAssetCreatorInputV1
  | ColumnRuleTreePathCreatorInputV1
  | ColumnRuleConstantInputV1;

export type CombinedColumnRuleInputParameters = ColumnRuleItemPropertyInputV1 &
  ColumnRulePathInputV1 &
  ColumnRuleAncestorInputV1 &
  ColumnRuleConcatInputV1 &
  ColumnRuleDescendantInputV1 &
  ColumnRuleEventPropertyInputV1 &
  ColumnRuleFormulaCreatorInputV1 &
  ColumnRuleAssetCreatorInputV1 &
  ColumnRuleTreePathCreatorInputV1 &
  ColumnRuleConstantInputV1;
export type RuleParameter = keyof CombinedColumnRuleInputParameters;

export const PropertyMatchOperatorEnumToLabel: Record<PropertyMatchOperatorEnum, string> = {
  [PropertyMatchOperatorEnum.EQUALS]: 'Equals',
  [PropertyMatchOperatorEnum.EQUALSIGNORECASE]: 'Equals Ignore Case',
  [PropertyMatchOperatorEnum.NOTEQUALS]: 'Not Equals',
  [PropertyMatchOperatorEnum.WITHIN]: 'Within',
  [PropertyMatchOperatorEnum.STRINGCONTAINS]: 'String Contains',
};

export type RuleParameterToInputType = 'text' | 'columnDropdown' | 'number' | 'boolean' | 'enumDropdown';
export const RuleParametersWithLabels: readonly {
  readonly parameter: RuleParameter;
  readonly label: string;
  readonly inputType: RuleParameterToInputType;
  // This field is required if the inputType is enumDropdown
  readonly enumOptions?: { value: string; label: string }[];
}[] = [
  {
    parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndex as RuleParameter,
    inputType: 'columnDropdown',
    label: 'SCALING.PROPERTIES.COLUMN_INPUT',
  },
  {
    parameter: SeeqNames.MaterializedTables.Parameters.PropertyName as RuleParameter,
    inputType: 'text',
    label: 'SCALING.PROPERTIES.PROPERTY_NAME',
  },
  {
    parameter: SeeqNames.MaterializedTables.Parameters.Level as RuleParameter,
    inputType: 'number',
    label: 'SCALING.PROPERTIES.LEVEL',
  },
  {
    parameter: SeeqNames.MaterializedTables.Parameters.Separator as RuleParameter,
    inputType: 'text',
    label: 'SCALING.PROPERTIES.SEPARATOR',
  },
  {
    parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndexes as RuleParameter,
    inputType: 'columnDropdown',
    label: 'SCALING.PROPERTIES.COLUMN_INPUT',
  },
  {
    parameter: SeeqNames.MaterializedTables.Parameters.Formula as RuleParameter,
    inputType: 'text',
    label: 'SCALING.PROPERTIES.FORMULA',
  },
  {
    parameter: SeeqNames.MaterializedTables.Parameters.ParametersName as RuleParameter,
    inputType: 'text', // this is actually a list in the API
    label: 'SCALING.PROPERTIES.PARAMETERS',
  },
  {
    parameter: SeeqNames.MaterializedTables.Parameters.Name as RuleParameter,
    inputType: 'text',
    label: 'SCALING.PROPERTIES.NAME',
  },
  {
    parameter: SeeqNames.MaterializedTables.Parameters.Description as RuleParameter,
    inputType: 'text', // this is actually nullable in the API
    label: 'SCALING.PROPERTIES.DESCRIPTION',
  },
  {
    parameter: SeeqNames.MaterializedTables.Parameters.ScopedTo as RuleParameter,
    inputType: 'text', // this is actually a UUID in the API
    label: 'SCALING.PROPERTIES.SCOPED_TO',
  },
  {
    parameter: SeeqNames.MaterializedTables.Parameters.IdentityId as RuleParameter,
    inputType: 'text', // this is actually a UUID in the API
    label: 'SCALING.PROPERTIES.IDENTITY_ID',
  },
  {
    parameter: SeeqNames.MaterializedTables.Parameters.PermissionString as RuleParameter,
    inputType: 'text',
    label: 'SCALING.PROPERTIES.PERMISSION_STRING',
  },
  {
    parameter: SeeqNames.MaterializedTables.Parameters.SecurityString as RuleParameter,
    inputType: 'text',
    label: 'SCALING.PROPERTIES.SECURITY_STRING',
  },
  {
    parameter: 'isRoot',
    inputType: 'boolean',
    label: 'Root',
  },
  {
    parameter: 'itemType',
    inputType: 'text',
    label: 'Item Type',
  },
  {
    parameter: 'propertyValue',
    inputType: 'text',
    label: 'Property Value',
  },
  {
    parameter: 'propertyMatchOperator',
    inputType: 'enumDropdown',
    enumOptions: (Object.values(PropertyMatchOperatorEnum) as PropertyMatchOperatorEnum[]).map((enumValue) => {
      const label = PropertyMatchOperatorEnumToLabel[enumValue];
      const value = enumValue.toString();
      return { value, label };
    }),
    label: 'Match Operator',
  },
  {
    parameter: 'constant',
    inputType: 'text',
    label: 'Constant',
  },
] as const;

export type RuleParameterWithLabel = typeof RuleParametersWithLabels[number];

export const columnTypeOptions = [
  { value: ColumnTypeEnum.TEXT, label: 'Text' },
  { value: ColumnTypeEnum.NUMERIC, label: 'Number' },
  { value: ColumnTypeEnum.UUID, label: 'UUID' },
  { value: ColumnTypeEnum.TIMESTAMPTZ, label: 'Date' },
  { value: ColumnTypeEnum.BOOLEAN, label: 'Boolean' },
] as const;

export const getRuleParameterWithLabels = (parameter: RuleParameter): RuleParameterWithLabel | undefined =>
  RuleParametersWithLabels.find((parameterWithLabels) => parameterWithLabels.parameter === parameter);

export const getRuleTypeAndParameters = (
  columnRuleInput: ColumnRuleInputV1,
): {
  ruleType: ColumnRule;
  parameters: Partial<CombinedColumnRuleInputParameters>;
} => {
  // Each rule input should have only one field filled out, so we can just get the first one as the rule type
  const ruleType = Object.keys(columnRuleInput)[0] as ColumnRule;
  const parameters = columnRuleInput[ruleType]!;
  return { ruleType, parameters };
};

export const getColumnTypeFromText = (text: string): ColumnTypeEnum => {
  const columnType = columnTypeOptions.find((option) => option.label === text);
  if (!columnType) {
    throw new Error(`Column type ${text} not found`);
  }
  return columnType.value;
};

export const getColumnRuleWithLabelFromLabel = (label: string): ColumnRuleWithLabel => {
  const columnRule = ColumnRulesWithLabels.find((rule) => rule.label === label);
  if (!columnRule) {
    throw new Error(`Column rule ${label} not found`);
  }
  return columnRule;
};

export const getColumnRuleWithLabelFromRule = (rule: ColumnRule): ColumnRuleWithLabel => {
  // In the case of Constant rules, the rule comes back like "stringConstant" whereas the ruleWithLabel is just
  // "constant", causing the exception to be thrown. Instead, we match on whether the rule contains "constant" as a
  // substring
  const columnRule = ColumnRulesWithLabels.find(
    (ruleWithLabel) =>
      ruleWithLabel.rule === rule ||
      (ruleWithLabel.rule === 'constant' && rule.toLowerCase().includes(ruleWithLabel.rule.toLowerCase())),
  );
  if (!columnRule) {
    throw new Error(`Column rule ${rule} not found`);
  }
  return columnRule;
};

export const buildColumnDefsForAgGrid = (currentTableDefinitionOutput?: TableDefinitionOutputV1) => {
  if (!currentTableDefinitionOutput) {
    return [];
  }
  const hiddenColumns = [SeeqNames.MaterializedTables.ItemIdColumn, SeeqNames.MaterializedTables.DatumIdColumn];
  return currentTableDefinitionOutput.columnDefinitions
    .filter((column) => !hiddenColumns.some((name) => name === column.columnName))
    .map((tableDefinitionColumnDef) => {
      return {
        field: tableDefinitionColumnDef.id,
        headerName: tableDefinitionColumnDef.columnName,
      };
    });
};

export const buildRowDataForAgGrid = (
  agGridColumnDefinitions: ScalingTableColumnDefinition[],
  materializedTable: MaterializedTable,
) => {
  const materializedTableHeaders = materializedTable.headers;
  const rows: string[][] = materializedTable.rows;
  // TODO: CRAB-41194 Account for column uom shenanigans
  return rows.map((row) => {
    const rowData: Record<string, any> = {};
    materializedTableHeaders.forEach((header: any, index: number) => {
      const field = agGridColumnDefinitions.find((columnDef) => columnDef.headerName === header.name)?.field;
      if (field) {
        rowData[field] = row[index];
      }
    });
    return rowData;
  });
};

export interface ScalingTableColumnDefinition {
  field: string;
  headerName: string;
}

export const tableDefinitionOutputToTableDefinitionInput = (
  tableDefinition: TableDefinitionOutputV1,
): TableDefinitionInputV1 => {
  return {
    name: tableDefinition.name,
    description: tableDefinition.description,
    dataId: tableDefinition.dataId,
    datasourceClass: tableDefinition.datasourceClass,
    datasourceId: tableDefinition.datasourceId,
    scopedTo: tableDefinition.scopedTo,
    subscriptionId: tableDefinition.subscription?.id,
    columnDefinitions: tableDefinition.columnDefinitions.map((columnDef) =>
      columnDefinitionOutputToColumnDefinitionInput(columnDef, tableDefinition.columnDefinitions),
    ),
  };
};

export const columnDefinitionOutputToColumnDefinitionInput = (
  columnDefinition: ColumnDefinitionOutputV1,
  otherColumns: ColumnDefinitionOutputV1[],
): ColumnDefinitionInputV1 => {
  return {
    columnType: columnDefinition.columnType,
    columnUom: columnDefinition.columnUom,
    columnName: columnDefinition.columnName,
    columnRules: columnDefinition.rules.map((rule) => columnRuleOutputToColumnRuleInput(rule, otherColumns)),
    isIndexed: columnDefinition.isIndexed,
  };
};
export const columnRuleOutputToColumnRuleInput = (
  output: ColumnRuleOutputV1,
  otherColumns: ColumnDefinitionOutputV1[],
): ColumnRuleInputV1 => {
  const ruleType = output.rule;
  const inputColumns = output.inputs || [];

  const oneColumnIndex = !MultiInputColumnRules.includes(ruleType);
  const firstColumnIndex = otherColumns.findIndex((columnDef) => columnDef.id === inputColumns[0]);
  const columnInputIndexesIfMultiple = MultiInputColumnRules.includes(ruleType)
    ? inputColumns
        .map((columnId) => {
          const columnIndex = otherColumns.findIndex((columnDef) => columnDef.id === columnId);
          return columnIndex + 1; // 1-indexed
        })
        .filter((index) => index > 0)
    : [];

  const parametersObject: Partial<CombinedColumnRuleInputParameters> = {
    columnIndex: oneColumnIndex ? firstColumnIndex + 1 : undefined,
    propertyName: output.arguments.propertyName,
    level: output.arguments.level ? Number(output.arguments?.level) : undefined,
    separator: output.arguments.separator,
    columnIndexes: columnInputIndexesIfMultiple.length > 0 ? columnInputIndexesIfMultiple : undefined,
    formula: output.arguments.formula,
    name: output.arguments.name,
    parameters: output.arguments.parameters ? output.arguments.parameters.split('|') : undefined,
    scopedTo: output.arguments.scopedTo,
    // TODO CRAB-41437 identityId is a restricted parameter currently, but it gets set on the backend. This means
    //  that when editing the rule the identityId will pulled from the backend but a permissionString is not.
    //  PermissionString and identityId are required together on the backend, so if only one is set, the column is
    //  uneditable. This is a quick fix until we unrestrict these arguments
    identityId: undefined,
    permissionString: undefined,
    securityString: output.arguments.securityString,
    description: output.arguments.description,
    isRoot: output.arguments.isRoot ? output.arguments.isRoot === 'true' : undefined,
    itemType: output.arguments.itemType,
    propertyValue: output.arguments.propertyValue,
    propertyMatchOperator: output.arguments.propertyMatchOperator as unknown as PropertyMatchOperatorEnum,
    constant: output.arguments.constant,
  };

  return {
    [ruleType]: parametersObject,
  };
};

export const removeColumnDefinition = async (tableDefinitionId: string, columnDefinitionId: string) => {
  const { data: tableDefinitionOutput } = await sqTableDefinitionsApi.deleteColumnFromTableDefinition({
    id: tableDefinitionId,
    columnId: columnDefinitionId,
  });
  return tableDefinitionOutput;
};

export const addOrUpdateColumnDefinition = async (
  tableDefinitionId: string,
  columnDefinition: ColumnDefinitionInputV1,
  columnDefinitionId?: string,
) => {
  const addOrUpdatePromise = columnDefinitionId
    ? sqTableDefinitionsApi.modifyColumnInTableDefinition(columnDefinition, {
        columnId: columnDefinitionId,
        id: tableDefinitionId,
        recompute: true,
      })
    : sqTableDefinitionsApi.addColumnsToTableDefinition(
        { columnDefinitions: [columnDefinition] },
        { id: tableDefinitionId, recompute: true },
      );
  const { data: tableDefinitionOutput } = await addOrUpdatePromise;
  return tableDefinitionOutput;
};

/**
 * When updating, this function will update properties of the table definition except for columns. For that, use
 * {@link addOrUpdateColumnDefinition}
 */
export const createOrUpdateTableDefinition = async (
  tableDefinition: TableDefinitionInputV1,
  tableDefinitionId?: string,
): Promise<TableDefinitionOutputV1 | undefined> => {
  const createOrUpdatePromise = tableDefinitionId
    ? sqTableDefinitionsApi.updateTableDefinition(tableDefinition, { id: tableDefinitionId })
    : sqTableDefinitionsApi.createTableDefinition(tableDefinition);
  try {
    const { data: tableDefinitionOutput } = await createOrUpdatePromise;
    addTableDefinition(tableDefinitionOutput);
    return tableDefinitionOutput;
  } catch (e) {
    console.log('error creating table definition', e); // todo: CRAB-40758 throw error toast or do creation
  }
};

export const getMaterializedTable = async (tableDefinitionId: string, columnsToInclude: string[]) => {
  const GET_MATERIALIZED_TABLE_QUERY =
    'query GetTable($id: String!, $filter: FilterInput, $limit: Int!, $columnsToInclude: [String!]) {' +
    ' table(id: $id, filter: $filter, limit: $limit, columnsToInclude: $columnsToInclude) { rows headers { name type }' +
    ' hasMore } }';
  const inputObject: GraphQLInputV1 = {
    query: GET_MATERIALIZED_TABLE_QUERY,
    variables: {
      id: tableDefinitionId,
      limit: 1000,
      columnsToInclude,
    },
  };
  const response = await sqGraphQLApi.graphql(inputObject);
  return response.data;
};

/**
 * Validates a formula for an instance of a formulaCreatorRule. In order to compile the formula, this function will
 * find the first viable item in each column that the formula references and use those as parameters. If no viable
 * items are found, the function will return an error. If viable parameters are found but there is a compilation
 * error, the compilation error will be returned.
 */
export const validateFormulaForCreator = async (
  formulaRule: ColumnRuleFormulaCreatorInputV1,
  materializedTable?: MaterializedTable,
  tableDefinitionOutput?: TableDefinitionOutputV1,
): Promise<FormulaCompileResult> => {
  if (!materializedTable || !tableDefinitionOutput) {
    return {
      success: false,
      errors: [{ message: 'No items in the table to run the formula on', column: -1, line: -1 }],
    };
  }
  const errors: FormulaErrorInterface[] = [];
  const materializedTableHeaders = materializedTable.headers;
  const parameters = formulaRule.parameters || [];
  const columnIndexes = formulaRule.columnIndexes || [];

  const parametersForCompile = parameters.map((parameter, index) => {
    const indexOfColumnInTableDefinition = columnIndexes[index] - 1;
    const columnName = tableDefinitionOutput.columnDefinitions[indexOfColumnInTableDefinition].columnName;
    const indexOfColumnInMaterializedTable = materializedTableHeaders.findIndex((header) => header.name === columnName);
    const rowWithValidItem = materializedTable.rows.find((row) => row[indexOfColumnInMaterializedTable]);
    const idOfFirstValidItemInGivenColumn = rowWithValidItem
      ? rowWithValidItem[indexOfColumnInMaterializedTable]
      : undefined;

    if (idOfFirstValidItemInGivenColumn === undefined) {
      errors.push({
        message: `No valid items in column ${columnName} which is referred to by parameter: ${parameter}`,
        column: -1,
        line: -1,
      });
    }
    return `${parameter}=${idOfFirstValidItemInGivenColumn}`;
  });

  if (errors.length > 0) {
    return { success: false, errors };
  }

  try {
    await compileFormulaOrThrowError(formulaRule.formula, parametersForCompile);
    return { success: true, errors: [] };
  } catch (e: any) {
    return { success: false, errors: e };
  }
};

// Depending on the results of CRAB-41436 this may not be necessary in the future
export const getLabelForItemIdColumn = (allColumnDefinitions: ColumnDefinitionOutputV1[]) => {
  const idColumn = allColumnDefinitions.find((column) => column.columnName === ID_COLUMN.columnName);
  const textColumnWithNameForThisColumn = allColumnDefinitions.find((column) => {
    return column.rules.some((rule) => {
      const refersToUUIDColumn = rule.inputs?.includes(idColumn!.id);
      const isItemPropertyRule = rule.rule === SeeqNames.MaterializedTables.Rules.ItemProperty;
      const propertyIsName = rule.arguments.propertyName === 'name';
      return refersToUUIDColumn && isItemPropertyRule && propertyIsName;
    });
  });
  return textColumnWithNameForThisColumn?.columnName;
};

export const isNameColumnForItemIDColumn = (
  column: ColumnDefinitionInputV1,
  existingColumns: ColumnDefinitionOutputV1[],
) => {
  // Depending on the results of CRAB-41436 this may be removed.
  // Currently, columns can't be moved, so the third column will always be the name column until we allow column
  // movement.
  const columnIsThird = existingColumns[2].columnName === column?.columnName;
  const isTextColumn = column.columnType === ColumnTypeEnum.TEXT;
  const firstRuleIsNameRule = column.columnRules[0]?.itemProperty?.propertyName === 'name';
  const firstRuleHasItemIDAsInput = column.columnRules[0]?.itemProperty?.columnIndex === 1;
  return isTextColumn && columnIsThird && firstRuleIsNameRule && firstRuleHasItemIDAsInput;
};
