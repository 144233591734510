// @ts-strict-ignore

import { isObject, trim } from 'lodash';
import { CleanupOnDestroyOnce } from 'plugin/pluginApiCleanupLogic';
import { DurationStore, RangeExport } from '@/trendData/duration.store';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { formatSimpleDuration } from '@/datetime/dateTime.utilities';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { TrendCapsuleStore } from '@/trendData/trendCapsule.store';
import { editNewFormula } from '@/tools/formula/formulaTool.actions';
import { OrganizerContentId, PluginIdentifier } from './pluginHost.constants';
import { setContentPluginRenderComplete, setDisplayPaneRenderComplete, setPluginState } from './plugin.actions';
import { API_VERSION } from './pluginApiVersion';
import { TrendScalarStore } from '@/trendData/trendScalar.store';
import { TrendMetricStore } from '@/trendData/trendMetric.store';
import { sqFormulasApi } from '@/sdk/api/FormulasApi';
import { sqMetricsApi } from '@/sdk/api/MetricsApi';
import { findItemIn, getTrendStores } from '@/trend/trendDataHelper.utilities';
import { PUSH_IGNORE } from '@/core/flux.service';
import { logDebug, logError, logFatal, logInfo, logTrace, logWarn } from '@/utilities/logger';
import {
  encodeParameters,
  isPresentationWorkbookMode as isPresentationWorkbookModeSqUtilities,
  validateGuid,
} from '@/utilities/utilities';
import { ITEM_TYPES, TREND_PANELS } from '@/trendData/trendData.constants';
import {
  sqDurationStore,
  sqLicenseManagementStore,
  sqPluginStore,
  sqTrendCapsuleSetStore,
  sqTrendCapsuleStore,
  sqTrendMetricStore,
  sqTrendScalarStore,
  sqTrendSeriesStore,
  sqTrendStore,
  sqTrendTableStore,
  sqWorkbenchStore,
  sqWorkbookStore,
  sqWorksheetStore,
  sqWorkstepsStore,
} from '@/core/core.stores';
import { PluginStore } from '@/plugin/plugin.store';
import { TrendTableStore } from '@/trendData/trendTable.store';
import { PluginWorkerApi } from 'plugin/pluginApiTypes';
import { WorkstepsStore } from '@/worksteps/worksteps.store';
import { WorkbenchStore } from '@/workbench/workbench.store';
import { WorksheetStore } from '@/worksheet/worksheet.store';
import { Timezone as ServiceTimezone } from '@/datetime/timezone.service';
import { PluginOutputV1 } from '@/sdk/model/PluginOutputV1';
import { formatAsSeeqError } from '@/webWorkers/plugin.worker.utilities';
import { closeInvestigationTool } from '@/toolSelection/investigate.actions';
import {
  catchItemDataFailure,
  clearPointerValues,
  removeTrendSelectedRegion,
  setCustomizationProps,
  setItemSelected,
  setPointerValues,
  setTrendSelectedRegion,
} from '@/trendData/trend.actions';
import { pluginShowColumn } from '@/worksheet/worksheet.actions';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { displayRange, investigateRange } from '@/trendData/duration.actions';
import { TrendStore } from '@/trendData/trend.store';
import { provideVisualizationData } from '@/annotation/ckEditorPlugins/components/content.utilities';
import { Visualization } from '@/annotation/ckEditorPlugins/components/content.utilities.constants';

import { isEqual, isUndefined, omitBy, pick } from 'lodash';
import { getCleanupLogic } from 'plugin/pluginApiCleanupLogic';
import { flux } from '@/core/flux.module';

interface SeeqErrorData {
  statusMessage: string;
  errorType?: string;
  errorCategory?: string;
  inaccessible?: string[];
}

// Error object returned from Seeq Workbench when rejecting a promise
interface SeeqError {
  data: SeeqErrorData;
  status?: number;
  xhrStatus?: string;
}

interface DateRange {
  start: number; // as unix timestamp in milliseconds
  end: number; // as unix timestamp in milliseconds
  duration: string; // as shown in the workbench
}

interface Asset {
  id: string;
  name: string;
  formattedName: string;
}

interface TrendItem {
  id: string;
  name: string;
  dataStatus?: string;
  lastFetchRequest?: string;
  selected: boolean;
  color: string;
  assets: Asset[];
}

interface TrendSignal extends TrendItem {
  isStringSignal: boolean;
  autoScale: boolean;
  axis: string;
  axisMin: number;
  axisMax: number;
  valueUnitOfMeasure: string;
}

interface TrendScalar extends TrendItem {
  isStringScalar: boolean;
  autoScale: boolean;
  axis: string;
  axisMin: number;
  axisMax: number;
  valueUnitOfMeasure: string;
}

interface TrendCondition extends TrendItem {
}

interface TrendMetric extends TrendItem {
  autoScale: boolean;
  axis: string;
  axisMin: number;
  axisMax: number;
}

interface TrendTable extends TrendItem {
  stack: boolean;
}

interface TrendSample {
  key: number;
  value: number | string;
  isUncertain: boolean;
}

interface DataStatusResults {
  id: string;
  samples?: TrendSample[];
  value?: number | string;
  timingInformation?: string;
  meterInformation?: string;
  valueUnitOfMeasure?: string;
  warningCount?: number;
  warningLogs?: object;
}

type LogSeverity = 'TRACE' | 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'FATAL';

type PluginState = object;

type FormulaArgs = object;

interface CapsuleProperty {
  /** name of the property (first argument of `setProperty`) */
  name: string;
  /** value of the property in a scalar type */
  value: number | string | boolean;
  /** an optional unit, this is omitted if there is no units or the value is a boolean */
  unitOfMeasure?: string;
}

/** A capsule used in a `condition` formula */
interface TrendCapsule {
  /** An id may be encoded as a property (CAPSULE_SOURCE_ID_PROPERTY_NAME) in the capsule */
  id?: string;
  startTime: number;
  endTime: number;
  properties: Record<string, any>;
  isUncertain: boolean;
}

interface RunFormulaArgs {
  start?: string;
  end?: string;
  formula?: string;
  _function?: string;
  parameters?: Record<string, string>;
  fragments?: Record<string, string>;
  limit?: number;
  cancellationGroup?: string;
}

interface FormulaParameter {
  identifier: string;
  item: {
    id: string;
    name: string;
  };
}

/** A YValue element used in setPointerValues yValues Array */
interface YValue {
  id: string;
  pointValue: string | number;
}

interface PluginResponse<T> {
  data: T;
  status: number;
  info: PluginResponseInfo;
}

interface PluginResponseInfo {
  timingInformation: string;
  meterInformation: string;
}

interface Workstep {
  id: string;
}

type Timezone = {
  name: string;
  displayName: string;
  offset: string;
  offsetMinutes: number;
};

interface PluginInfo {
  category: string;
  identifier: string;
  name: string;
  description?: string;
  host?: any;
  icon?: string;
  options?: any;
  version?: string;
}

interface SelectedRegion {
  min: number;
  max: number;
}

/**
 * Parameters supplied when calling an API
 */
interface CallApiParams {
  /**
   * The request path
   */
  path: string;
  /**
   * Optional request method (defaults to 'GET')
   */
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  /**
   * Optional query parameter
   */
  query?: Record<string, unknown>;
  /**
   * Optional body
   */
  body?: Record<string, unknown>;
  /**
   * Optional headers
   */
  headers?: Record<string, unknown>;
}

/**
 * Parameters supplied when calling a Data Lab API
 */
interface CallDataLabApiParams extends CallApiParams {
  /**
   * The project ID
   */
  projectId: string;
  /**
   * The name of the Notebook
   */
  notebookName: string;
}

interface EmailInput {
  /**
   * An array with all the emails that will be added in to field
   */
  to: string[];
  /**
   * An array with all the emails that will be added in cc field
   */
  cc?: string[];
  /**
   * An array with all the emails that will be added in bcc field
   */
  bcc?: string[];
  /**
   * The email subject
   */
  subject: string;
  /**
   * The email body
   */
  body: string;
}

type DetailsPaneColumnKey = 'axis' | 'autoScale' | 'axisMin' | 'axisMax';

/**
 * The following section contains API types extracted from our Seeq typescript SDK
 */
interface FormulaRunOutput {
  /**
   * Capsules from the formula result
   */
  capsules?: CapsulesOutput;
  /**
   * Metadata describing the compiled formula's result
   */
  metadata?: Record<string, string>;
  /**
   * Regression output from the formula result. Note that the `table` will also contain values.
   */
  regressionOutput?: RegressionOutput;
  /**
   * The data type of the compiled formula's result
   */
  returnType?: string;
  /**
   * Samples from the formula result
   */
  samples?: GetSamplesOutput;
  /**
   * Scalar from the formula result
   */
  scalar?: ScalarValueOutput;
  /**
   * A plain language status message with information about any issues that may have been encountered during an
   * operation. Null if the status message has not been set.
   */
  statusMessage?: string;
  /**
   * Table from the formula result
   */
  table?: GenericTableOutput;
  /**
   * Contains upgrade information if the formula contains legacy syntax that was automatically updated
   */
  upgradeDetails?: FormulaUpgradeOutput;
  /**
   * Errors (if any) from the formula
   */
  errors?: Array<FormulaCompilerErrorOutput>;
  /**
   * The total number of warnings that have occurred
   */
  warningCount?: number;
  /**
   * The Formula warning logs, which includes the text, line number, and column number where the warning occurred in
   * addition to the warning details
   */
  warningLogs?: Array<FormulaLog>;
}

interface FormulaCompilerErrorOutput {
  /**
   * The column of the formula that resulted in an error
   */
  column?: number;
  /**
   * The category of the formula error, i.e. when it was encountered
   */
  errorCategory?: string;
  /**
   * The type of formula error that occurred
   */
  errorType?: string;
  /**
   * The function where the error occurred
   */
  functionId?: string;
  /**
   * The line of the formula that resulted in an error
   */
  line?: number;
  /**
   * An error message for the compiled formula
   */
  message?: string;
  /**
   * The itemId that is the cause of the error
   */
  itemId?: string;
}

interface CapsulesOutput {
  /**
   * The list of capsules
   */
  capsules: Array<Capsule>;
  /**
   * A token that can be used to fetch the next page of results. Submit the same query with the continuationToken set
   * to this returned value. Null if all results have been returned.
   */
  continuationToken?: string;
  /**
   * The unit of measure for the capsule starts and ends. If left empty, input is assumed to be in ISO8601 format.
   */
  keyUnitOfMeasure?: string;
  /**
   * The pagination limit, the total number of collection items that will be returned in this page of results
   */
  limit?: number;
  /**
   * The href of the next set of paginated results
   */
  next?: string;
  /**
   * A plain language status message with information about any issues that may have been encountered during an
   * operation. Null if the status message has not been set.
   */
  statusMessage?: string;
  /**
   * The total number of warnings that have occurred
   */
  warningCount?: number;
  /**
   * The Formula warning logs, which includes the text, line number, and column number where the warning occurred in
   * addition to the warning details
   */
  warningLogs?: Array<FormulaLog>;
}

interface Capsule {
  /**
   * The point at which the capsule becomes uncertain. For a time, an ISO 8601 date string
   * (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm), or a whole number of nanoseconds since the unix epoch (if the units are
   * nanoseconds). For a numeric (non-time), a double-precision number.
   */
  cursorKey?: any;
  /**
   * The end of the capsule. For a time, an ISO 8601 date string (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm), or a whole
   * number of nanoseconds since the unix epoch (if the units are nanoseconds). For a numeric (non-time), a
   * double-precision number.
   */
  end?: any;
  /**
   * The id of the capsule
   */
  id: string;
  /**
   * True if this capsule is fully or partially uncertain
   */
  isUncertain?: boolean;
  /**
   * A list of the capsule's properties
   */
  properties?: Array<ScalarProperty>;
  /**
   * The start of the capsule. For a time, an ISO 8601 date string (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm), or a whole
   * number of nanoseconds since the unix epoch (if the units are nanoseconds). For a numeric (non-time), a
   * double-precision number.
   */
  start: any;
}

interface ScalarProperty {
  /**
   * Human readable name.  Null or whitespace names are not permitted
   */
  name: string;
  /**
   * The unit of measure to apply to this property's value. If no unit of measure is set and the value is numeric, it
   * is assumed to be unitless
   */
  unitOfMeasure?: string;
  /**
   * The value to assign to this property. If the value is surrounded by quotes, it is interpreted as a string and no
   * units are set
   */
  value: any;
}

interface ItemPreviewList {
  /**
   * The list of items requested
   */
  items?: Array<ItemPreview>;
  /**
   * The pagination limit, the total number of collection items that will be returned in this page of results
   */
  limit?: number;
  /**
   * The href of the next set of paginated results
   */
  next?: string;
  /**
   * The pagination offset, the index of the first collection item that will be returned in this page of results
   */
  offset?: number;
  /**
   * The href of the previous set of paginated results
   */
  prev?: string;
  /**
   * A plain language status message with information about any issues that may have been encountered during an
   * operation. Null if the status message has not been set.
   */
  statusMessage?: string;
  /**
   * The total number of items
   */
  totalResults: number;
}

interface ItemPreview {
  /**
   * The ID that can be used to interact with the item
   */
  id: string;
  /**
   * Whether item is archived
   */
  isArchived?: boolean;
  /**
   * Whether item is redacted
   */
  isRedacted?: boolean;
  /**
   * The human readable name
   */
  name: string;
  /**
   * The type of the item
   */
  type: string;
  /**
   * The item's translation key, if any
   */
  translationKey?: string;
}

interface RegressionOutput {
  /**
   * The measure of how close the data is to the regression line, adjusted for the number of input signals and samples
   */
  adjustedRSquared: number;
  /**
   * The standard error for the sum squares
   */
  errorSumSquares: number;
  /**
   * The constant offset to add. 0 if forceThroughZero was true. This is the intercept for the output signal rather
   * than the individual coefficients.
   */
  intercept: number;
  /**
   * The standard error for the intercept
   */
  interceptStandardError: number;
  /**
   * True if this regression is uncertain
   */
  isUncertain: boolean;
  /**
   * The measure of how well the model matches the target
   */
  regressionSumSquares: number;
  rSquared?: number;
  /**
   * The value which the regression method suggests for ignoring coefficients
   */
  suggestedPValueCutoff: number;
}

interface GetSamplesOutput {
  /**
   * A token that can be used to fetch the next page of results. Submit the same query with the continuationToken set
   * to this returned value. Null if all results have been returned.
   */
  continuationToken?: string;
  /**
   * The unit of measure for the series keys
   */
  keyUnitOfMeasure?: string;
  /**
   * The pagination limit, the total number of collection items that will be returned in this page of results
   */
  limit?: number;
  /**
   * The href of the next set of paginated results
   */
  next?: string;
  /**
   * The list of samples
   */
  samples?: Array<SampleOutput>;
  /**
   * A plain language status message with information about any issues that may have been encountered during an
   * operation. Null if the status message has not been set.
   */
  statusMessage?: string;
  /**
   * The unit of measure for the series values
   */
  valueUnitOfMeasure?: string;
  /**
   * The total number of warnings that have occurred
   */
  warningCount?: number;
  /**
   * The Formula warning logs, which includes the text, line number, and column number where the warning occurred in
   * addition to the warning details
   */
  warningLogs?: Array<FormulaLog>;
}

interface SampleOutput {
  /**
   * True if this sample is uncertain
   */
  isUncertain?: boolean;
  /**
   * The key of the sample. For a time series, an ISO 8601 date string(YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm). For a
   * numeric (non-time) series, a double-precision number.
   */
  key?: any;
  /**
   * The value of the sample
   */
  value?: any;
}

interface ScalarValueOutput {
  /**
   * True if this scalar is uncertain
   */
  isUncertain?: boolean;
  /**
   * The unit of measure of the scalar
   */
  uom: string;
  /**
   * The value of the scalar
   */
  value: any;
}

interface ScalarEvaluateOutput {
  /**
   * True if this scalar is uncertain
   */
  isUncertain?: boolean;
  /**
   * Metadata describing the compiled formula's result
   */
  metadata?: Record<string, string>;
  /**
   * The data type of the compiled formula's result
   */
  returnType?: string;
  /**
   * A plain language status message with information about any issues that may have been encountered during an
   * operation. Null if the status message has not been set.
   */
  statusMessage?: string;
  /**
   * The unit of measure of the scalar
   */
  uom: string;
  /**
   * Contains upgrade information if the formula contains legacy syntax that was automatically updated
   */
  upgradeDetails?: FormulaUpgradeOutput;
  /**
   * The value of the scalar
   */
  value: any;
  /**
   * Violations (if any) from the formula
   */
  errors?: Array<FormulaCompilerErrorOutput>;
  /**
   * The total number of warnings that have occurred
   */
  warningCount?: number;
  /**
   * The Formula warning logs, which includes the text, line number, and column number where the warning occurred in
   * addition to the warning details
   */
  warningLogs?: Array<FormulaLog>;
}

interface GenericTableOutput {
  /**
   * The list of data rows, each row being a list of cell contents.
   */
  data: Array<Array<any>>;
  /**
   * The list of headers.
   */
  headers: Array<TableColumnOutput>;
}

interface TableColumnOutput {
  /**
   * The name of the column
   */
  name: string;
  /**
   * The type of the column. Valid values include 'string', 'number', and 'date'. Booleans are reported as 'number'
   */
  type: string;
  /**
   * The units of the column. Only provided if type is 'number'
   */
  units?: string;
}

interface FormulaUpgradeOutput {
  /**
   * The resulting changed formula
   */
  afterFormula?: string;
  /**
   * The original input formula
   */
  beforeFormula?: string;
  /**
   * Details about the specific changes
   */
  changes?: Array<FormulaUpgradeChange>;
}

interface FormulaUpgradeChange {
  /**
   * Description of the change
   */
  change?: string;
  /**
   * A link to the Knowledge Base for more explanation of why this was applied
   */
  moreDetailsUrl?: string;
}

interface FormulaLog {
  /**
   * The detailed Formula log entries which occurred at this token
   */
  formulaLogEntries: Record<string, FormulaLogEntry>;
  /**
   * The token where the event took place in the Formula
   */
  formulaToken: FormulaToken;
}

interface FormulaLogEntry {
  logDetails?: Array<FormulaLogEntryDetails>;
  logTypeCount?: number;
}

interface FormulaLogEntryDetails {
  context?: string;
  message?: string;
}

interface FormulaToken {
  column?: number;
  line?: number;
  text?: string;
}

interface ThresholdMetricOutputV1 {
  /**
   * Additional properties of the item
   */
  additionalProperties?: Array<ScalarPropertyV1>;
  /**
   * The ID of the aggregation condition representing metric value information
   */
  aggregationConditionId?: string;
  /**
   * Aggregation formula that aggregates the measured item
   */
  aggregationFunction?: string;
  /**
   * The condition that, if present, will be used to aggregate the measured item
   */
  boundingCondition?: ItemPreviewWithAssetsV1;
  /**
   * The maximum capsule duration that is applied to the bounding condition if it does not have one
   */
  boundingConditionMaximumDuration?: ScalarValueOutputV1;
  /**
   * The data ID of this asset. Note: This is not the Seeq ID, but the unique identifier that the remote datasource
   * uses.
   */
  dataId?: string;
  /**
   * The datasource class, which is the type of system holding the item, such as OSIsoft PI
   */
  datasourceClass?: string;
  /**
   * The datasource identifier, which is how the datasource holding this item identifies itself
   */
  datasourceId?: string;
  /**
   * Clarifying information or other plain language description of this item
   */
  description?: string;
  /**
   * A signal or formula function that evaluates to a signal that can be used to visualize the metric
   */
  displayItem: ItemPreviewV1;
  /**
   * The duration over which to calculate a moving aggregation
   */
  duration?: ScalarValueOutputV1;
  /**
   * The permissions the current user has to the item.
   */
  effectivePermissions?: PermissionsV1;
  /**
   * The ID that can be used to interact with the item
   */
  id: string;
  /**
   * Whether item is archived
   */
  isArchived?: boolean;
  /**
   * Whether item is redacted
   */
  isRedacted?: boolean;
  /**
   * The input Signal or Condition to measure
   */
  measuredItem: ItemPreviewWithAssetsV1;
  /**
   * The human readable name
   */
  name: string;
  /**
   * Either the custom-set neutral color for this metric or the color of the neutral Priority
   */
  neutralColor?: string;
  /**
   * The format string used for numbers associated with this signal.
   */
  numberFormat?: string;
  /**
   * The period at which to sample when creating the moving aggregation
   */
  period?: ScalarValueOutputV1;
  /**
   * The process type of threshold metric. Will be Continuous if duration and period are specified, Condition if
   * boundingCondition is specified, and otherwise Simple.
   */
  processType: ProcessTypeEnum;
  /**
   * The ID of the workbook to which this item is scoped or null if it is in the global scope.
   */
  scopedTo?: string;
  /**
   * A plain language status message with information about any issues that may have been encountered during an
   * operation
   */
  statusMessage?: string;
  /**
   * The list of thresholds that are scalars, signals, or conditions along with the associated priority. These
   * thresholds are those that were used as inputs and which are used to generate the condition thresholds
   */
  thresholds?: Array<ThresholdOutputV1>;
  /**
   * The type of the item
   */
  type: string;
  /**
   * The unit of measure of the metric
   */
  valueUnitOfMeasure?: string;
  /**
   * The item's translation key, if any
   */
  translationKey?: string;
}

interface ScalarPropertyV1 {
  /**
   * Human readable name.  Null or whitespace names are not permitted
   */
  name: string;
  /**
   * The unit of measure to apply to this property's value. If no unit of measure is set and the value is numeric, it
   * is assumed to be unitless
   */
  unitOfMeasure?: string;
  /**
   * The value to assign to this property. If the value is surrounded by quotes, it is interpreted as a string and no
   * units are set
   */
  value: any;
}

interface ItemPreviewWithAssetsV1 {
  /**
   * The list of ancestors in the asset tree, ordered with the root ancestor first, if the item is in an asset tree. If
   * an item is in more than one asset tree an arbitrary one will be chosen.
   */
  ancestors?: Array<ItemPreviewV1>;
  /**
   * A boolean indicating whether or not child items exist for this item in the asset tree; the value will be true even
   * if the child items are archived unless the tree for this item is deleted.
   */
  hasChildren?: boolean;
  /**
   * The ID that can be used to interact with the item
   */
  id: string;
  /**
   * Whether item is archived
   */
  isArchived?: boolean;
  /**
   * Whether item is redacted
   */
  isRedacted?: boolean;
  /**
   * The human readable name
   */
  name: string;
  /**
   * The type of the item
   */
  type: string;
  /**
   * The item's translation key, if any
   */
  translationKey?: string;
}

interface ScalarValueOutputV1 {
  /**
   * True if this scalar is uncertain
   */
  isUncertain?: boolean;
  /**
   * The unit of measure of the scalar
   */
  uom: string;
  /**
   * The value of the scalar
   */
  value: any;
}

interface ThresholdOutputV1 {
  isGenerated?: boolean;
  /**
   * The threshold item
   */
  item?: ItemPreviewV1;
  /**
   * The priority associated with the threshold. If a custom color has been specified for this threshold it will be set
   * as the color
   */
  priority?: PriorityV1;
  /**
   * The scalar value, only if the item is a scalar
   */
  value?: ScalarValueOutputV1;
}

interface ItemPreviewV1 {
  /**
   * The ID that can be used to interact with the item
   */
  id: string;
  /**
   * Whether item is archived
   */
  isArchived?: boolean;
  /**
   * Whether item is redacted
   */
  isRedacted?: boolean;
  /**
   * The human readable name
   */
  name: string;
  /**
   * The type of the item
   */
  type: string;
  /**
   * The item's translation key, if any
   */
  translationKey?: string;
}

interface PermissionsV1 {
  manage?: boolean;
  read?: boolean;
  write?: boolean;
}

enum ProcessTypeEnum {
  // This is required because the typescript SDK doesn't properly handle enums.
  // @ts-ignore TS1066 (In ambient enum declarations member initializer must be constant expression)
  Simple = 'Simple' as any,
  // @ts-ignore TS1066
  Condition = 'Condition' as any,
  // @ts-ignore TS1066
  Continuous = 'Continuous' as any
}

interface FormulaLogV1 {
  /**
   * The detailed Formula log entries which occurred at this token
   */
  formulaLogEntries: Record<string, FormulaLogEntry>;
  /**
   * The token where the event took place in the Formula
   */
  formulaToken: FormulaToken;
}

interface PriorityV1 {
  /**
   * A hex code (including pound sign) representing the color assigned to this priority
   */
  color: string;
  /**
   * An integer representing the priority level. 0 is used for neutral, positive numbers are used for high thresholds
   * and negative numbers for low thresholds
   */
  level: number;
  /**
   * The name of this priority
   */
  name: string;
}

interface LicensedFeatureStatusOutputV1 {
  /**
   * The number of days left before the current licensed feature will expire
   */
  daysToExpiration?: number;
  /**
   * The licensed feature name
   */
  name?: string;
  /**
   * The final day this licensed feature will be valid for
   */
  validThrough?: string;
  /**
   * Validity status
   */
  validity: ValidityEnum;
}

enum ValidityEnum {
  // This is required because the typescript SDK doesn't properly handle enums.
  // @ts-ignore TS1066 (In ambient enum declarations member initializer must be constant expression)
  Valid = 'Valid' as any,
  // @ts-ignore TS1066
  NoLicense = 'NoLicense' as any,
  // @ts-ignore TS1066
  Expired = 'Expired' as any,
  // @ts-ignore TS1066
  WrongHost = 'WrongHost' as any,
  // @ts-ignore TS1066
  BadSignature = 'BadSignature' as any,
  // @ts-ignore TS1066
  ClockTampering = 'ClockTampering' as any,
  // @ts-ignore TS1066
  OverLimit = 'OverLimit' as any,
  // @ts-ignore TS1066
  UnknownError = 'UnknownError' as any
}

interface CurrentWorkstep extends Workstep {
  state: unknown;
}

const exportSignal = (s: any): TrendSignal => {
  const { id, name, dataStatus, lastFetchRequest, selected, color, assets, isStringSeries: isStringSignal, axisAutoScale: autoScale, axisAlign: axis, yAxisMin: axisMin, yAxisMax: axisMax, valueUnitOfMeasure, } = s;
  return {
    id,
    name,
    dataStatus,
    lastFetchRequest,
    selected,
    color,
    assets,
    isStringSignal,
    autoScale,
    axis,
    axisMin,
    axisMax,
    valueUnitOfMeasure
  };
};

const exportScalar = (s: any): TrendScalar => {
  const { id, name, dataStatus, lastFetchRequest, selected, color, assets, isStringSeries: isStringScalar, axisAutoScale: autoScale, axisAlign: axis, yAxisMin: axisMin, yAxisMax: axisMax, valueUnitOfMeasure, } = s;
  return {
    id,
    name,
    dataStatus,
    lastFetchRequest,
    selected,
    color,
    assets,
    isStringScalar,
    autoScale,
    axis,
    axisMin,
    axisMax,
    valueUnitOfMeasure
  };
};

const exportCondition = (s: any): TrendCondition => {
  const { id, name, dataStatus, lastFetchRequest, selected, color, assets } = s;
  return {
    id,
    name,
    dataStatus,
    lastFetchRequest,
    selected,
    color,
    assets
  };
};

const exportMetric = (s: any): TrendMetric => {
  const { id, name, dataStatus, lastFetchRequest, selected, color, assets, axisAutoScale: autoScale, axisAlign: axis, yAxisMin: axisMin, yAxisMax: axisMax, } = s;
  return {
    id,
    name,
    dataStatus,
    lastFetchRequest,
    selected,
    color,
    assets,
    autoScale,
    axis,
    axisMin,
    axisMax
  };
};

const exportTable = (s: any): TrendTable => {
  const { id, name, dataStatus, lastFetchRequest, selected, color, assets, stack } = s;
  return {
    id,
    name,
    dataStatus,
    lastFetchRequest,
    selected,
    color,
    assets,
    stack
  };
};

const exportPluginInfo = (s: PluginOutputV1): PluginInfo => {
  if (!s)
    return;
  const { category, description, host, icon, identifier, name, options, version } = s;
  return {
    category,
    description,
    host,
    icon,
    identifier,
    name,
    options,
    version
  };
};

function mapDetailsPaneColumnKey(key: DetailsPaneColumnKey) {
  switch (key) {
    case 'autoScale':
      return 'axisAutoScale';
    case 'axis':
      return 'axisAlign';
    case 'axisMin':
      return 'yAxisMin';
    case 'axisMax':
      return 'yAxisMax';
    default:
      throw new Error(`Invalid column key: ${key}`);
  }
}

interface DataExport {
  dataExport: string;
  path: string;
  functionName: string;

  transform(data: any): any;
}

export function generatePluginApi(
  contentId: OrganizerContentId,
  pluginIdentifier: PluginIdentifier,
  worker: PluginWorkerApi,
  messagePort: MessagePort,
  onInitialized?: () => void,
) {
  let initialized = false;
  // can replace this with 'console' to get debug logs to console
  const debug = { log(...x) { }, warn(...x) { } };

  const sendMessage = (type: string, payload?: any, nonce?: string, transfer?: ArrayBuffer[]) => {
    if (initialized) {
      messagePort.postMessage({ type, payload, nonce }, transfer);
      debug.log('message sent to plugin', { type, payload, nonce });
    } else {
      debug.warn('trying to send message on a not initialized connection');
    }
  };

  const cleanupLogic = getCleanupLogic();

  const storeListenersUnsubscribes = {};

  function destroy() {
    Object.keys(storeListenersUnsubscribes).forEach((storeId) => storeListenersUnsubscribes[storeId]());
    cleanupLogic.destroy();
  }

  const unsubscribes = {};

  function unsubscribe(callName: string) {
    unsubscribes[callName]();
  }

  const API = {
    clientConfig: { properties: [], functions: [] },
    destroy,
    unsubscribe,
  };

  API.clientConfig.properties.push({ path: 'ROOT', name: 'version', value: API_VERSION });
  API.clientConfig.properties.push({
    path: 'ROOT', name: 'pluginInfo', value: (function(): PluginInfo {
      const pluginInfo = sqPluginStore.getPlugin(pluginIdentifier);
      return exportPluginInfo(pluginInfo);
    })()
  });
  API.clientConfig.properties.push({
    path: 'ROOT', name: 'queryParam', value: (function(): string {
      return sqPluginStore.queryParam;
    })()
  });
  API.clientConfig.properties.push({
    path: 'ROOT', name: 'isPresentationWorkbookMode', value: (function(): boolean {
      let isPresentationWorkbookMode = false;
      try {
        isPresentationWorkbookMode =
          isPresentationWorkbookModeSqUtilities() ||
          // Being embedded as interactive content in a report should also function like presentation mode
          sqWorkbookStore.isReportBinder;
      }
      catch {
        // Expected to fail for home screen addons
      }
      return isPresentationWorkbookMode;
    })()
  });
  API.clientConfig.properties.push({
    path: 'ROOT', name: 'isHeadlessRenderMode', value: (function(): boolean {
      return headlessRenderMode();
    })()
  });
  API.clientConfig.properties.push({
    path: 'ROOT', name: 'workbook', value: (function(): {
      id: string;
      name: string;
      description: string;
    } {
      return {
        id: sqWorkbookStore.workbookId,
        name: sqWorkbookStore.name,
        description: sqWorkbookStore.description
      };
    })()
  });
  API.clientConfig.properties.push({
    path: 'ROOT', name: 'worksheet', value: (function(): {
      id: string;
      name: string;
    } {
      const id = sqWorkbenchStore.stateParams.worksheetId;
      const name = sqWorkbookStore.getWorksheetName(id);
      return { id, name };
    })()
  });

  API.clientConfig.functions.push({ path: 'ROOT', name: 'setDisplayRange' });
  API.clientConfig['ROOT -> setDisplayRange'] = { type: 'invokeVoid' };
  API['ROOT -> setDisplayRange'] = function(start: number, end: number): void {
    displayRange.updateTimes(start, end);
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'setTrendSelectedRegion' });
  API.clientConfig['ROOT -> setTrendSelectedRegion'] = { type: 'invokeVoid' };
  API['ROOT -> setTrendSelectedRegion'] = function(min: number, max: number): void {
    setTrendSelectedRegion(min, max);
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'removeTrendSelectedRegion' });
  API.clientConfig['ROOT -> removeTrendSelectedRegion'] = { type: 'invokeVoid' };
  API['ROOT -> removeTrendSelectedRegion'] = function(): void {
    removeTrendSelectedRegion();
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'setInvestigateRange' });
  API.clientConfig['ROOT -> setInvestigateRange'] = { type: 'invokeVoid' };
  API['ROOT -> setInvestigateRange'] = function(start: number, end: number): void {
    investigateRange.updateTimes(start, end);
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'selectItem' });
  API.clientConfig['ROOT -> selectItem'] = { type: 'invokeVoid' };
  API['ROOT -> selectItem'] = function(id: string, selected: boolean) {
    const item = findItemIn(getTrendStores(), id);
    if (item) {
      setItemSelected(item, selected);
    }
    else {
      logError(`An item with ID "${id}" was not found in the details pane`, pluginIdentifier);
    }
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'log' });
  API.clientConfig['ROOT -> log'] = { type: 'invokeVoid' };
  API['ROOT -> log'] = function(severity: LogSeverity, message: string): void {
    const logger: {
      [message in LogSeverity]: (message: string, category?: string) => void;
    } = {
      DEBUG: logDebug,
      ERROR: logError,
      FATAL: logFatal,
      INFO: logInfo,
      TRACE: logTrace,
      WARN: logWarn
    };
    logger[severity](message, pluginIdentifier);
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'setPluginState' });
  API.clientConfig['ROOT -> setPluginState'] = { type: 'invokeVoid' };
  API['ROOT -> setPluginState'] = function(pluginState: PluginState): void {
    setPluginState(pluginIdentifier, pluginState);
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'runFormula' });
  API.clientConfig['ROOT -> runFormula'] = { type: 'invokeAsync' };
  API['ROOT -> runFormula'] = function({
    start,
    end,
    formula,
    _function,
    parameters,
    fragments,
    limit,
    cancellationGroup,
  }: RunFormulaArgs): Promise<PluginResponse<FormulaRunOutput>> {
    const parametersEncoded = encodeParameters(parameters);
    const fragmentsEncoded = encodeParameters(fragments);
    return sqFormulasApi
      .runFormula({
        start,
        end,
        formula,
        _function,
        parameters: parametersEncoded,
        fragments: fragmentsEncoded,
        limit
      }, {
        cancellationGroup
      })
      .then((result) => {
        const { data, status } = result;
        const { 'server-timing': timingInformation, 'server-meters': meterInformation } = result.headers;
        return {
          data,
          status,
          info: { timingInformation, meterInformation }
        };
      }) as Promise<PluginResponse<FormulaRunOutput>>;
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'fetchMetric' });
  API.clientConfig['ROOT -> fetchMetric'] = { type: 'invokeAsync' };
  API['ROOT -> fetchMetric'] = function(id: string): Promise<PluginResponse<ThresholdMetricOutputV1>> {
    return sqMetricsApi.getMetric({ id }).then((result) => {
      const { data, status } = result;
      const { 'server-timing': timingInformation, 'server-meters': meterInformation } = result.headers;
      return {
        data,
        status,
        info: { timingInformation, meterInformation }
      };
    }) as Promise<PluginResponse<ThresholdMetricOutputV1>>;
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'openUrl' });
  API.clientConfig['ROOT -> openUrl'] = { type: 'invokeVoid' };
  API['ROOT -> openUrl'] = function(url: string, windowFeatures: string): void {
    windowFeatures = windowFeatures || '';
    let parsedUrl;
    try {
      parsedUrl = new URL(url);
    }
    catch (err) {
      if (err.message.includes('Invalid URL')) {
        parsedUrl = new URL(url, window.location.origin);
      }
      else {
        console.error(`openUrl : ${err.message}`);
        return;
      }
    }
    if (parsedUrl.host !== window.location.host) {
      console.error('openUrl : Opening non-seeq urls or seeq urls of a different origin is not supported by the API.');
      return;
    }
    parsedUrl.protocol = window.location.protocol;
    const newWindow = window.open(parsedUrl.href, '_blank', windowFeatures);
    newWindow.focus();
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'downloadContent' });
  API.clientConfig['ROOT -> downloadContent'] = { type: 'invokeVoid' };
  API['ROOT -> downloadContent'] = function(file: File): void {
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = URL.createObjectURL(file);
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      URL.revokeObjectURL(link.href);
      link.parentNode.removeChild(link);
    }, 1);
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'setItemInLocalStorage' });
  API.clientConfig['ROOT -> setItemInLocalStorage'] = { type: 'invokeVoid' };
  API['ROOT -> setItemInLocalStorage'] = function(key: string, value: string): void {
    const userId = sqWorkbenchStore?.currentUser?.id;
    localStorage.setItem(`${pluginIdentifier}|${userId}|${key}`, value);
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'getItemFromLocalStorage' });
  API.clientConfig['ROOT -> getItemFromLocalStorage'] = { type: 'invoke' };
  API['ROOT -> getItemFromLocalStorage'] = function(key: string): string | null {
    const userId = sqWorkbenchStore?.currentUser?.id;
    return localStorage.getItem(`${pluginIdentifier}|${userId}|${key}`);
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'setTrendDataStatusLoading' });
  API.clientConfig['ROOT -> setTrendDataStatusLoading'] = { type: 'invokeVoid' };
  API['ROOT -> setTrendDataStatusLoading'] = function(id: string) {
    flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id }, PUSH_IGNORE);
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'setTrendDataStatusSuccess' });
  API.clientConfig['ROOT -> setTrendDataStatusSuccess'] = { type: 'invokeVoid' };
  API['ROOT -> setTrendDataStatusSuccess'] = function(results: DataStatusResults) {
    const trendedItem = findItemIn(getTrendStores(), results.id);
    if (trendedItem) {
      switch (trendedItem.itemType) {
        case ITEM_TYPES.SERIES:
          flux.dispatch('TREND_SERIES_RESULTS_SUCCESS', results, PUSH_IGNORE);
          break;
        case ITEM_TYPES.SCALAR:
          flux.dispatch('TREND_SCALAR_RESULTS_SUCCESS', results, PUSH_IGNORE);
          break;
        default:
          flux.dispatch('TREND_SET_DATA_STATUS_PRESENT', results, PUSH_IGNORE);
      }
    }
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'shouldProvideOrganizerVisualizationData' });
  API.clientConfig['ROOT -> shouldProvideOrganizerVisualizationData'] = { type: 'invoke' };
  API['ROOT -> shouldProvideOrganizerVisualizationData'] = function(): boolean {
    return headlessRenderMode() && isPresentationWorkbookModeSqUtilities();
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'provideOrganizerVisualizationData' });
  API.clientConfig['ROOT -> provideOrganizerVisualizationData'] = { type: 'invokeVoid' };
  API['ROOT -> provideOrganizerVisualizationData'] = function(data: any): void {
    provideVisualizationData({
      visualization: Visualization.PLUGIN,
      data,
      pluginIdentifier
    });
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'shouldLoadDataFromOrganizer' });
  API.clientConfig['ROOT -> shouldLoadDataFromOrganizer'] = { type: 'invoke' };
  API['ROOT -> shouldLoadDataFromOrganizer'] = function(): boolean {
    return sqWorkbookStore.isReportBinder;
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'loadDataFromOrganizer' });
  API.clientConfig['ROOT -> loadDataFromOrganizer'] = { type: 'invoke' };
  API['ROOT -> loadDataFromOrganizer'] = function(): any {
    return sqPluginStore.contentPluginData[contentId];
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'pluginRenderComplete' });
  API.clientConfig['ROOT -> pluginRenderComplete'] = { type: 'invokeVoid' };
  API['ROOT -> pluginRenderComplete'] = function(): void {
    if (contentId) {
      setContentPluginRenderComplete(contentId, true);
    }
    else {
      setDisplayPaneRenderComplete(true);
    }
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'setPointerValues' });
  API.clientConfig['ROOT -> setPointerValues'] = { type: 'invokeVoid' };
  API['ROOT -> setPointerValues'] = (function() {
    const onDestroy = cleanupLogic.cleanupOnDestroyOnce('ROOT -> setPointerValues');
    return function(xValue: number, yValues: YValue[]): void {
      setPointerValues(xValue, yValues);
      onDestroy(() => clearPointerValues());
    };
  })();

  API.clientConfig.functions.push({ path: 'ROOT', name: 'clearPointerValues' });
  API.clientConfig['ROOT -> clearPointerValues'] = { type: 'invokeVoid' };
  API['ROOT -> clearPointerValues'] = function(): void {
    clearPointerValues();
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'setYAxis' });
  API.clientConfig['ROOT -> setYAxis'] = { type: 'invokeVoid' };
  API['ROOT -> setYAxis'] = function(id: string, axisMin: number, axisMax: number): void {
    setCustomizationProps([
      { id, yAxisMin: axisMin },
      { id, yAxisMax: axisMax },
    ]);
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'setYAxisAutoScale' });
  API.clientConfig['ROOT -> setYAxisAutoScale'] = { type: 'invokeVoid' };
  API['ROOT -> setYAxisAutoScale'] = function(id: string, autoScale: boolean): void {
    setCustomizationProps([{ id, axisAutoScale: autoScale }]);
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'catchItemDataFailure' });
  API.clientConfig['ROOT -> catchItemDataFailure'] = { type: 'invokeVoid' };
  API['ROOT -> catchItemDataFailure'] = function(id: string, cancellationGroup: string, error: SeeqError) {
    // provide a default value, otherwise it will reject
    catchItemDataFailure(id, cancellationGroup, error, '');
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'editNewFormula' });
  API.clientConfig['ROOT -> editNewFormula'] = { type: 'invokeVoid' };
  API['ROOT -> editNewFormula'] = function(formula: string, parameters: FormulaParameter[]) {
    editNewFormula(formula, parameters);
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'closeActiveTool' });
  API.clientConfig['ROOT -> closeActiveTool'] = { type: 'invokeVoid' };
  API['ROOT -> closeActiveTool'] = function() {
    closeInvestigationTool();
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'showDetailsPaneColumns' });
  API.clientConfig['ROOT -> showDetailsPaneColumns'] = { type: 'invokeVoid' };
  API['ROOT -> showDetailsPaneColumns'] = function(keys: DetailsPaneColumnKey[]) {
    pluginShowColumn(pluginIdentifier, TREND_PANELS.SERIES, keys.map(mapDetailsPaneColumnKey));
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'getLicensedFeature' });
  API.clientConfig['ROOT -> getLicensedFeature'] = { type: 'invoke' };
  API['ROOT -> getLicensedFeature'] = function(name: string): LicensedFeatureStatusOutputV1 {
    return sqLicenseManagementStore.getFeature(name) as LicensedFeatureStatusOutputV1;
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'callSdkApi' });
  API.clientConfig['ROOT -> callSdkApi'] = { type: 'invokeAsync' };
  API['ROOT -> callSdkApi'] = function <T = any>(params: CallApiParams): Promise<T> {
    if (!isObject(params)) {
      return Promise.reject(formatAsSeeqError('Data Lab API params must be provided'));
    }
    const path = trim(params.path);
    if (path === '') {
      return Promise.reject(formatAsSeeqError('The path is missing'));
    }
    return worker?.loaded?.then(() => worker.callSdkApi(path, params.method, params.query, params.body, params.headers) as Promise<T>);
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'callDataLabApi' });
  API.clientConfig['ROOT -> callDataLabApi'] = { type: 'invokeAsync' };
  API['ROOT -> callDataLabApi'] = function <T = any>(params: CallDataLabApiParams): Promise<T> {
    if (!isObject(params)) {
      return Promise.reject(formatAsSeeqError('Data Lab API params must be provided'));
    }
    if (!validateGuid(params.projectId)) {
      return Promise.reject(formatAsSeeqError('The projectId is not valid'));
    }
    const notebookName = trim(params.notebookName);
    if (notebookName === '') {
      return Promise.reject(formatAsSeeqError('The notebook name is missing'));
    }
    const path = trim(params.path);
    if (path === '') {
      return Promise.reject(formatAsSeeqError('The path is missing'));
    }
    return worker?.loaded?.then(() => worker.callDataLabApi(params.projectId, notebookName, path, params.method, params.query, params.body, params.headers)) as Promise<T>;
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'getDataLabProject' });
  API.clientConfig['ROOT -> getDataLabProject'] = { type: 'invokeAsync' };
  API['ROOT -> getDataLabProject'] = function(projectName: string): Promise<{
    projectId: string;
  }> {
    const trimmedProjectName = trim(projectName);
    if (trimmedProjectName === '') {
      return Promise.reject(formatAsSeeqError('The project name is missing'));
    }
    return worker?.loaded?.then(() => worker.getDataLabProject(trimmedProjectName)) as Promise<{
      projectId: string;
    }>;
  };

  API.clientConfig.functions.push({ path: 'ROOT', name: 'mailTo' });
  API.clientConfig['ROOT -> mailTo'] = { type: 'invokeVoid' };
  API['ROOT -> mailTo'] = function(emailInput: EmailInput): void {
    if (!emailInput || !(emailInput.to instanceof Array) || !emailInput.subject || !emailInput.body) {
      console.error('To, subject and body are mandatory fields. Please verify if they are correctly set.');
      return;
    }
    let computeEmailInfo = `mailto:${emailInput.to.join(',')}?`;
    if (emailInput.cc && emailInput.cc instanceof Array) {
      computeEmailInfo = `${computeEmailInfo}cc=${emailInput.cc.join(',')}&`;
    }
    if (emailInput.bcc && emailInput.cc instanceof Array) {
      computeEmailInfo = `${computeEmailInfo}bcc=${emailInput.bcc.join(',')}&`;
    }
    window.open(`${computeEmailInfo}subject=${emailInput.subject}&body=${emailInput.body}`);
  };


  function createStoreListener(exports: DataExport[], storeId: string, store: any, fields?: string[]) {
    const listeners = {};

    const lastValues = {};

    function sendExportData(dataExport) {
      const callName = `${dataExport.path} -> ${dataExport.functionName}`;
      if (listeners[callName]) {
        const data = store[dataExport.dataExport];
        const value = dataExport.transform(data);
        if (value !== undefined && !isEqual(lastValues[callName], value)) {
          sendMessage('listenerUpdate', { name: callName, value });
          lastValues[callName] = value;
        }
      }
    }

    function changeHandler() {
      exports.forEach(sendExportData);
    }

    function listen() {
      if (storeListenersUnsubscribes[storeId] !== undefined) {
        return;
      }
      const unsubscribe = fields ? flux.listenTo(store, fields, changeHandler) : flux.listenTo(store, changeHandler);
      storeListenersUnsubscribes[storeId] = unsubscribe;
    }

    exports.forEach(function(dataExport) {
      const callName = `${dataExport.path} -> ${dataExport.functionName}`;
      API[callName] = function() {
        listen();
        listeners[callName] = true;
        sendExportData(dataExport);
      };
      API.clientConfig.functions.push({
        path: dataExport.path,
        name: dataExport.functionName,
      });
      API.clientConfig[callName] = {
        type: 'listenTo',
      };
      unsubscribes[callName] = function() {
        delete listeners[callName];
        if (Object.keys(listeners).length === 0 && storeListenersUnsubscribes[storeId] !== undefined) {
          storeListenersUnsubscribes[storeId]();
          delete storeListenersUnsubscribes[storeId];
          delete lastValues[callName];
        }
      };
    });
  }

  const exportWorkstep = (currentWorkstep: CurrentWorkstep): Workstep => ({ id: currentWorkstep?.id });

  const workstepsStore_current_exports = [
    { dataExport: 'current', path: 'ROOT', functionName: 'subscribeToCurrentWorkstep', transform: exportWorkstep }
  ];
  createStoreListener(workstepsStore_current_exports, 'workstepsStore_current', sqWorkstepsStore, ['current']);

  const exportLanguage = (userLanguage: string): string => userLanguage;

  const workbenchStore_userLanguage_exports = [
    { dataExport: 'userLanguage', path: 'ROOT', functionName: 'subscribeToUserLanguage', transform: exportLanguage }
  ];
  createStoreListener(workbenchStore_userLanguage_exports, 'workbenchStore_userLanguage', sqWorkbenchStore, ['userLanguage']);

  const exportDarkMode = (darkMode: boolean): boolean => darkMode;

  const workbenchStore_darkMode_exports = [
    { dataExport: 'darkMode', path: 'ROOT', functionName: 'subscribeToDarkMode', transform: exportDarkMode }
  ];
  createStoreListener(workbenchStore_darkMode_exports, 'workbenchStore_darkMode', sqWorkbenchStore, ['darkMode']);

  const exportTrendSelectedRegion = (selectedRegion: SelectedRegion): SelectedRegion => selectedRegion;

  const trendStore_selectedRegion_exports = [
    { dataExport: 'selectedRegion', path: 'ROOT', functionName: 'subscribeToTrendSelectedRegion', transform: exportTrendSelectedRegion }
  ];
  createStoreListener(trendStore_selectedRegion_exports, 'trendStore_selectedRegion', sqTrendStore, ['selectedRegion']);

  const exportTimezone = (timezone: ServiceTimezone): Timezone => timezone;

  const worksheetStore_timezone_exports = [
    { dataExport: 'timezone', path: 'ROOT', functionName: 'subscribeToTimezone', transform: exportTimezone }
  ];
  createStoreListener(worksheetStore_timezone_exports, 'worksheetStore_timezone', sqWorksheetStore, ['timezone']);

  const exportRange = function(rangeExport: RangeExport): DateRange {
    return {
      start: rangeExport.start.valueOf() as number,
      end: rangeExport.end.valueOf() as number,
      duration: formatSimpleDuration(rangeExport.duration)
    };
  };

  const durationStore_displayRange_exports = [
    { dataExport: 'displayRange', path: 'ROOT', functionName: 'subscribeToDisplayRange', transform: exportRange }
  ];
  createStoreListener(durationStore_displayRange_exports, 'durationStore_displayRange', sqDurationStore, ['displayRange']);


  const durationStore_investigateRange_exports = [
    { dataExport: 'investigateRange', path: 'ROOT', functionName: 'subscribeToInvestigationRange', transform: exportRange }
  ];
  createStoreListener(durationStore_investigateRange_exports, 'durationStore_investigateRange', sqDurationStore, ['investigateRange']);

  const exportSignals = (signals: any[]): TrendSignal[] => signals.filter((item) => !item.isChildOf).map(exportSignal);

  const trendSeriesStore_items_exports = [
    { dataExport: 'items', path: 'ROOT', functionName: 'subscribeToSignals', transform: exportSignals }
  ];
  createStoreListener(trendSeriesStore_items_exports, 'trendSeriesStore_items', sqTrendSeriesStore, ['items']);

  const exportScalars = (scalars: any[]): TrendScalar[] => scalars.filter((item) => !item.isChildOf).map(exportScalar);

  const trendScalarStore_items_exports = [
    { dataExport: 'items', path: 'ROOT', functionName: 'subscribeToScalars', transform: exportScalars }
  ];
  createStoreListener(trendScalarStore_items_exports, 'trendScalarStore_items', sqTrendScalarStore, ['items']);

  const exportConditions = (conditions: any[]): TrendCondition[] => conditions.filter((item) => !item.isChildOf).map(exportCondition);

  const trendCapsuleSetStore_items_exports = [
    { dataExport: 'items', path: 'ROOT', functionName: 'subscribeToConditions', transform: exportConditions }
  ];
  createStoreListener(trendCapsuleSetStore_items_exports, 'trendCapsuleSetStore_items', sqTrendCapsuleSetStore, ['items']);

  const exportMetrics = (metrics: any[]): TrendMetric[] => metrics.filter((item) => !item.isChildOf).map(exportMetric);

  const trendMetricStore_items_exports = [
    { dataExport: 'items', path: 'ROOT', functionName: 'subscribeToMetrics', transform: exportMetrics }
  ];
  createStoreListener(trendMetricStore_items_exports, 'trendMetricStore_items', sqTrendMetricStore, ['items']);

  const exportTables = (tables: any[]): TrendTable[] => tables.filter((item) => !item.isChildOf).map(exportTable);

  const trendTableStore_items_exports = [
    { dataExport: 'items', path: 'ROOT', functionName: 'subscribeToTables', transform: exportTables }
  ];
  createStoreListener(trendTableStore_items_exports, 'trendTableStore_items', sqTrendTableStore, ['items']);

  const exportPluginState = function(pluginState: object): PluginState {
    return pluginState && pluginState[pluginIdentifier] ? pluginState[pluginIdentifier] : {};
  };

  const pluginStore_pluginState_exports = [
    { dataExport: 'pluginState', path: 'ROOT', functionName: 'subscribeToPluginState', transform: exportPluginState }
  ];
  createStoreListener(pluginStore_pluginState_exports, 'pluginStore_pluginState', sqPluginStore, ['pluginState']);

  const exportSelectedCapsules = function(): TrendCapsule[] {
    return sqTrendCapsuleStore.selectedCapsules;
  };

  const trendCapsuleStore_selectedCapsules_exports = [
    { dataExport: 'selectedCapsules', path: 'ROOT', functionName: 'subscribeToSelectedCapsules', transform: exportSelectedCapsules }
  ];
  createStoreListener(trendCapsuleStore_selectedCapsules_exports, 'trendCapsuleStore_selectedCapsules', sqTrendCapsuleStore, ['selectedCapsules']);

  const exportCapsules = function(): TrendCapsule[] {
    return sqTrendCapsuleStore.items as TrendCapsule[];
  };

  const trendCapsuleStore_items_exports = [
    { dataExport: 'items', path: 'ROOT', functionName: 'subscribeToCapsules', transform: exportCapsules }
  ];
  createStoreListener(trendCapsuleStore_items_exports, 'trendCapsuleStore_items', sqTrendCapsuleStore, ['items']);


  function extractErrorFields(error) {
    const extracted = omitBy(pick(error, ['status', 'xhrStatus']), isUndefined);
    extracted.data = omitBy(
      pick(error?.data, ['statusMessage', 'errorType', 'errorCategory', 'inaccessible']),
      isUndefined,
    );
    return extracted;
  }

  messagePort.onmessage = (event) => {
    if (event.isTrusted && event.data.type) {
      debug.log('message received from plugin', event.data);
      const { type, payload, nonce } = event.data;
      if (!initialized && type === 'init successful') {
        initialized = true;
        onInitialized && onInitialized();
        sendMessage('apiConfig', API.clientConfig);
      } else if (initialized && type === 'invokeVoid' && payload && API[payload.function]) {
        if (payload.unsubscribe === true) {
          API.unsubscribe(payload.function);
        } else {
          API[payload.function].apply(this, payload.args);
        }
      } else if (initialized && type === 'invoke' && payload && API[payload.function]) {
        const result = API[payload.function].apply(this, payload.args);
        sendMessage('invocationResult', { result }, nonce);
      } else if (initialized && type === 'invokeAsync' && payload && API[payload.function]) {
        API[payload.function]
          .apply(this, payload.args)
          .then((result) => {
            sendMessage('invocationResult', { result }, nonce);
          })
          .catch((error) => {
            sendMessage('invocationResult', { error: extractErrorFields(error) }, nonce);
          });
      } else {
        debug.warn('unexpected message', event.data);
      }
      debug.log('message received from plugin', event.data);
    } else {
      debug.warn('untrusted message or malformed message discarded');
    }
  };

  return API;
}
