import { TrendScalarStore } from '@/trendData/trendScalar.store';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';

export const DEFAULT_FORMULA_PANEL_HEIGHT = 575;
export const MIN_FORMULA_PANEL_HEIGHT = 420;
export const MIN_FORMULA_PANEL_WIDTH = 850;
export const FORMULA_HOME = '/formulas/docs/Seeq/index';
export const FORMULA_LIST = 'Formula List';
export const SEARCH = 'search';
export const DOCUMENTATION = 'documentation';
export const ASSISTANT = 'assistant';
export let FORMULA_TOOL_TREND_STORES: [TrendScalarStore, TrendCapsuleSetStore, TrendSeriesStore];
