// @ts-strict-ignore
import React from 'react';
import { useTranslation } from 'react-i18next';
import HttpCodes from 'http-status-codes';
import classNames from 'classnames';
import { Icon } from '@seeqdev/qomponents';
import { clearContentResize, forceRefreshContent, setStoreFromContent } from '@/annotation/reportContent.utilities';
import { setModalName } from '@/reportEditor/reportContent.actions';
import {
  CONTENT_MODEL_ATTRIBUTES,
  ContentCallbacks,
  CustomPlugin,
} from '@/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import {
  getPropertyForContent,
  setContentPropertyOverrides,
} from '@/annotation/ckEditorPlugins/components/content.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { logError } from '@/utilities/logger';
import { errorToast } from '@/utilities/toast.utilities';
import { Visualization } from '@/annotation/ckEditorPlugins/components/content.utilities.constants';
import { isAxiosError } from '@/requests/axios.utilities';
import { copyAgGridTableToClipboard } from '@/utilities/tableBuilderHelper.utilities';
import { CONTENT_STATE } from '@/reportEditor/report.constants';
import { sqReportStore } from '@/core/core.stores';
import { getCKEditorInstance } from '@/annotation/ckEditor.utilities';
import { Editor } from '@ckeditor/ckeditor5-core';
import { SEEQ_AG_GRID_ID, TableBuilderHeaderType } from '@/tableBuilder/tableBuilder.constants';
import { AgGridReact } from '@ag-grid-community/react';
import { COLUMNS_AND_STATS } from '@/trendData/trendData.constants';

interface ContentMenuProps {
  contentId: string;
  closeMenu: () => void;
  isReact: boolean;
  /** The current react properties for [contentId], if the content is interactive **/
  properties?: any;
  updateMeasurements: (height: number, width: number) => void;
}

/**
 * A menu containing extra functionality for Content related to updating, refreshing, and formatting content.
 */
export const ContentMenu: React.FunctionComponent<ContentMenuProps> = (props) => {
  const { contentId, closeMenu, isReact, properties, updateMeasurements } = props;

  const { t } = useTranslation();
  const url = `/api/content/${contentId}/sourceUrl`;
  const iconClasses = 'editorBtn fa-2x';

  const editor: Editor = getCKEditorInstance('reportEditor');
  const contentCallbacks = (editor.config.get(CustomPlugin.Content) as any).contentCallbacks as ContentCallbacks;
  const model = contentCallbacks.getCurrentModel(contentId);

  return (
    <div className="backgroundColorWhite flexColumnContainer flexSpaceBetween min-width-120">
      <Icon
        icon="fc-seeq-content fc-inverse"
        extraClassNames={classNames(iconClasses, 'contentBtn', 'ck-reset_all-excluded')}
        tooltip={t('REPORT.EDITOR.UPDATE_BUTTON_TITLE')}
        tooltipPlacement="top"
        testId="contentEdit"
        onClick={() => {
          setStoreFromContent(contentId)
            .then(() => {
              // This hack is because when running in a system test this causes the ReportContentModal to error
              // with the flux error of trying to dispatch while another is ongoing.
              setTimeout(() => setModalName(CONTENT_STATE.LOAD_PROPERTIES), 1);
            })
            .catch((error) => {
              const messageKey =
                isAxiosError(error) && error.response.status === HttpCodes.FORBIDDEN
                  ? 'REPORT.CONTENT.ACCESS_DENIED'
                  : 'REPORT.CONTENT.FAILED';
              errorToast({ messageKey });
              logError(error);
            })
            .finally(closeMenu);
        }}
      />
      <Icon
        extraClassNames={iconClasses}
        icon="fa-external-link"
        tooltip={t('OPEN_LINK')}
        tooltipPlacement="top"
        onClick={() => window.open(url)}
        testId="contentLink"
      />
      <Icon
        extraClassNames={iconClasses}
        icon="fc-redo"
        tooltip={t('REPORT.EDITOR.REFRESH_CONTENT')}
        tooltipPlacement="top"
        onClick={() => forceRefreshContent(contentId)}
        testId="contentRefresh"
      />
      {isReact && properties?.visualization === Visualization.TABLE && properties.isSimpleMode && (
        <Icon
          extraClassNames={iconClasses}
          icon="fc-bar-chart"
          testId="contentChartView"
          tooltip={t('TABLE_BUILDER.TOGGLE_CHART_VIEW')}
          tooltipPlacement="top"
          onClick={() => {
            const showChartView = !getPropertyForContent(
              'showChartView',
              model.getAttribute(CONTENT_MODEL_ATTRIBUTES.PROPERTY_OVERRIDES),
              properties,
            );

            setContentPropertyOverrides(editor, contentId, { showChartView });
            showChartView
              ? updateMeasurements(
                  sqReportStore.getContentById(contentId).height,
                  sqReportStore.getContentById(contentId).width,
                )
              : updateMeasurements(0, 0);
          }}
        />
      )}
      {isReact &&
        properties?.visualization === Visualization.TABLE &&
        !getPropertyForContent(
          'showChartView',
          model.getAttribute(CONTENT_MODEL_ATTRIBUTES.PROPERTY_OVERRIDES),
          properties,
        ) && (
          <Icon
            extraClassNames={iconClasses}
            icon="fa-copy"
            tooltip={t('TABLE_BUILDER.COPY_TO_CLIPBOARD_TOOLTIP')}
            tooltipPlacement="top"
            testId="contentTableCopy"
            onClick={() => {
              // We can share most of the copy logic by using sqTableBuilderHelper, but we have to disable some
              // CK/organizer specific state to be able to do it. Namely, taking off the noCopy class which prevents
              // copying of elements (which in CK can make copying a single element result in actually copying more
              // than 1) and turning off the content specific copy logic, which would otherwise result in the content
              // being pasted as an image.
              const contentSelector = `[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}="${contentId}"]`;
              const contentWrapper = document.querySelector(contentSelector).parentElement;
              contentWrapper.classList.remove('noCopy');
              contentCallbacks.setUseCkCopyLogic(false);
              const showConditionTableHeaders =
                !properties.isSimpleMode &&
                !!properties.conditionColumns.columns.find((column) => column.key === COLUMNS_AND_STATS.name.key) &&
                !properties.isTransposed;
              const showSimpleTableHeaders =
                properties.isSimpleMode &&
                properties.headers.type !== TableBuilderHeaderType.None &&
                !properties.isTransposed;
              const tableElement = document.querySelector(`${contentSelector} #${SEEQ_AG_GRID_ID}`);
              const agGrid: AgGridReact | undefined = tableElement['__AG_GRID__'];
              copyAgGridTableToClipboard(agGrid, showConditionTableHeaders || showSimpleTableHeaders);
              contentWrapper.classList.add('noCopy');
              contentCallbacks.setUseCkCopyLogic(true);
            }}
          />
        )}
      <>
        {!isReact && (
          <Icon
            extraClassNames={iconClasses}
            icon="fa-rectangle-xmark"
            tooltip={t('REPORT.EDITOR.CLEAR_RESIZE')}
            tooltipPlacement="top"
            testId="contentResizeClear"
            onClick={() => clearContentResize(contentId)}
          />
        )}
        <Icon
          extraClassNames={iconClasses}
          icon="fc-border-style"
          tooltip={t('REPORT.EDITOR.TOGGLE_BORDER')}
          tooltipPlacement="top"
          testId="contentBorderToggle"
          onClick={() => {
            const model = contentCallbacks.getCurrentModel(contentId);
            editor.model.change((writer) =>
              writer.setAttribute(
                CONTENT_MODEL_ATTRIBUTES.BORDER,
                !model.getAttribute(CONTENT_MODEL_ATTRIBUTES.BORDER),
                model,
              ),
            );
          }}
        />
        {!isReact && (
          <Icon
            extraClassNames={iconClasses}
            icon="fc-capsule-bar"
            tooltip={t('REPORT.EDITOR.NO_MARGIN')}
            tooltipPlacement="top"
            testId="contentMarginToggle"
            onClick={() => {
              const model = contentCallbacks.getCurrentModel(contentId);
              editor.model.change((writer) =>
                writer.setAttribute(
                  CONTENT_MODEL_ATTRIBUTES.NO_MARGIN,
                  !model.getAttribute(CONTENT_MODEL_ATTRIBUTES.NO_MARGIN),
                  model,
                ),
              );
            }}
          />
        )}
      </>
    </div>
  );
};
