// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { ASSET_PATH_SEPARATOR } from '@/main/app.constants';

export type PathComponent = {
  name: string;
  id: string;
  disabled: boolean;
};

interface PathComponentsIF {
  asset: any;
  actionOnComponent: (any) => void;
}

export const PathComponents: React.FunctionComponent<PathComponentsIF> = (props) => {
  const { asset, actionOnComponent } = props;

  const pathNames = _.split(asset.formattedName, ASSET_PATH_SEPARATOR);
  const pathComponents: PathComponent[] = _.zipWith(
    pathNames,
    asset.pathComponentIds,
    asset.pathComponentDisabled,
    (name, id: string, disabled: boolean) => ({ name, id, disabled }),
  );

  const getPathComponentSpan = (component: PathComponent) => (
    <span
      className={component.disabled ? 'sq-fairly-dark-gray disabledBehavior' : 'force-link-look cursorPointer'}
      data-stoppropagation="true"
      onClick={() => {
        actionOnComponent(component);
      }}>
      {component.name}
    </span>
  );

  return (
    <span data-testid="pathComponents" data-stoppropagation="true">
      {pathComponents
        .map((component) => getPathComponentSpan(component))
        .reduce((acc, c) =>
          acc === null ? (
            c
          ) : (
            <>
              {acc}{' '}
              {
                <span className="sq-fairly-dark-gray" data-stoppropagation="true">
                  {ASSET_PATH_SEPARATOR}
                </span>
              }{' '}
              {c}{' '}
            </>
          ),
        )}
    </span>
  );
};
