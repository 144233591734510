// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, InputGroup } from 'react-bootstrap';
import classNames from 'classnames';
import _ from 'lodash';
import { Icon } from '@seeqdev/qomponents';
import { HOME_SCREEN_TABS, SEARCH_ITEM_LOCATIONS, SEARCH_LOCATION_OPTIONS } from '@/main/app.constants';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { IconSelect } from '@/core/IconSelect.molecule';
import { ServerSideFilteringAndPaginatingTable } from '@/core/ServerSideFilteringAndPaginatingTable.molecule';
import { useKey } from '@/core/hooks/useKey.hook';
import { HomeScreenItemIconCell, NameCell } from '@/homescreen/CellRender.atom';
import { SearchResults } from '@/homescreen/SearchResults.molecule';
import { SortIcon } from '@/core/SortIcon.atom';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { CELL_TYPES } from '@/core/Table.atom';
import { getFilteredLocations, isFolder, translateBreadcrumb } from '@/utilities/homescreen.utilities';
import { getTabFolder } from '@/utilities/homescreen.helpers';
import { Checkbox } from '@/core/Checkbox.atom';
import { sqWorkbookStore } from '@/core/core.stores';
import { useFlux } from '@/core/hooks/useFlux.hook';

interface ExplorerModalBodyProps {
  show: boolean;
  insideAdvancedSearch?: boolean;
  next?: () => void;
  initialFolderId?: string;
  itemLocationFilter?: any;
  breadcrumbsPresent?: boolean;
  advancedSearchSelectedFolder?: {
    id: string;
  };
  myFolderContent?: any;
  corporateFolderId?: string;
  store: ExplorerModalBodySupportingStore;
  actions: ExplorerModalBodySupportingActions;
  allowedTypes: string[];
  isSelectable?: boolean;
}

export type ExplorerModalBodySupportingStore = {
  getItemTotalForTable: (table?: string) => number;
  getPageSizeByTable: (table?: string) => number;
  getPageNumberForTable: (table?: string) => number;
  searchParams: {
    textSearch: string;
    isPinned: boolean;
    types: string[];
    filter: string;
    folderId: string;
  };
  tableFilter: string;
  items: {
    id: string;
  }[];
  loading: boolean;
  folder: {
    name: string;
    ancestors: any;
    id: string;
    translationKey: string;
    owner: any;
  };
  initialFolderId: string;
  showResults: (table?: string) => boolean;
  sortProperty: string;
  sortAscending: boolean;
};

export type ExplorerModalBodySupportingActions = {
  setPageSizeForTable: ({ size, table }: { size: number; table: string }) => void;
  setPageNumberAndGo: (page: number, loadTable?: () => void, table?: string) => void;
  setShowResults: (showResults: boolean) => void;
  setTableFilter: (tableFilter: string) => void;
  setSearchParams: (field: string, value: any) => void;
  setFolder: (folder: object) => void;
  setSort: (sortProperty: string, sortAscending: boolean, forceSortDirection?: boolean) => void;
  setWorkbookId: (workbookId: string) => void;
  setTableRoot: (root: string) => void;
  getRootAndUpdateFilter: (folderId: string) => void;
  setInitialFolderId: (initialFolderId: string) => void;
  loadItems: () => void;
  clear: () => void;
};

export const ExplorerModalBody: React.FunctionComponent<ExplorerModalBodyProps> = ({
  next,
  show,
  store,
  actions,
  allowedTypes,
  insideAdvancedSearch,
  itemLocationFilter,
  initialFolderId,
  breadcrumbsPresent,
  advancedSearchSelectedFolder,
  myFolderContent,
  corporateFolderId,
  isSelectable,
}) => {
  const { t } = useTranslation();

  const [searchString, setSearchString] = useState(undefined);
  const [modalIsLoaded, setModalIsLoaded] = useState(false);
  const [firstRenderFolderId, setFirstRenderFolderId] = useState(undefined);
  const [selectedId, setSelectedId] = useState('');
  const storedSearchText = useFluxPath(store, () => store.searchParams.textSearch);
  const [searchText, setSearchText] = useState(storedSearchText);
  const { workbookId } = useFlux(sqWorkbookStore);

  const filterValue = useFluxPath(store, () => store.tableFilter);
  const items = useFluxPath(store, () => store.items);
  const totalItems = useFluxPath(store, () => store.getItemTotalForTable());
  const isLoading = useFluxPath(store, () => store.loading);
  const folder = useFluxPath(store, () => store.folder);
  const showResults = useFluxPath(store, () => store.showResults);
  const showRecent = allowedTypes.length > 1;
  const locationOptions = getFilteredLocations(SEARCH_LOCATION_OPTIONS, showRecent);
  const parentFolder = insideAdvancedSearch ? myFolderContent?.username === folder?.name : undefined;
  const setSearchParamsToLoadItems = () => {
    if (itemLocationFilter?.value === SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC && folder) {
      actions.setSearchParams('filter', SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC);
      actions.setSearchParams('folderId', folder.id);
    } else if (itemLocationFilter?.value === SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC && !folder) {
      actions.setInitialFolderId(null);
      actions.setSearchParams('folderId', undefined);
    } else {
      actions.setSearchParams('folderId', store.initialFolderId);
    }
  };

  useEffect(() => {
    if (insideAdvancedSearch) {
      setModalIsLoaded(true);
      setFirstRenderFolderId(store.initialFolderId);
      actions.setSearchParams('folderId', undefined);
      actions.setTableFilter(undefined);
      actions.setTableRoot(undefined);
    }
    actions.setShowResults(false);
    actions.setSearchParams('textSearch', undefined);
  }, []);

  useEffect(() => {
    if (
      insideAdvancedSearch &&
      ((firstRenderFolderId !== store.initialFolderId && modalIsLoaded) ||
        (itemLocationFilter?.value === SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC && !firstRenderFolderId))
    ) {
      actions.setSearchParams('textSearch', undefined);
      actions.setSearchParams('filter', undefined);
      setSearchParamsToLoadItems();
      actions.setSearchParams('types', allowedTypes);
      actions.setShowResults(false);

      if (store.initialFolderId || itemLocationFilter?.value === SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC) {
        if (itemLocationFilter?.value === SEARCH_ITEM_LOCATIONS.USERS && folder?.ancestors?.length === 0) {
          setDropdownAndGo(SEARCH_ITEM_LOCATIONS.USERS);
        } else if (
          itemLocationFilter?.value === SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC &&
          (!folder || advancedSearchSelectedFolder?.id)
        ) {
          if (initialFolderId === advancedSearchSelectedFolder?.id || !advancedSearchSelectedFolder) {
            actions.setSearchParams('folderId', advancedSearchSelectedFolder?.id);
            actions.setSearchParams('filter', SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC);
            actions.loadItems();
          }
        } else {
          actions.loadItems();
        }
      }
    }
  }, [initialFolderId]);

  useEffect(() => {
    if (
      !insideAdvancedSearch ||
      (firstRenderFolderId === store.initialFolderId && store.initialFolderId) ||
      (breadcrumbsPresent &&
        firstRenderFolderId !== store.initialFolderId &&
        store.initialFolderId &&
        firstRenderFolderId)
    ) {
      if (insideAdvancedSearch && (firstRenderFolderId === store.initialFolderId || breadcrumbsPresent)) {
        actions.setSearchParams('folderId', store.initialFolderId);
      }

      actions.setSearchParams('types', allowedTypes);
      actions.setTableRoot(filterValue === SEARCH_ITEM_LOCATIONS.USERS ? filterValue : undefined);

      if (store.initialFolderId) {
        if (
          itemLocationFilter?.value === SEARCH_ITEM_LOCATIONS.USERS &&
          (folder?.ancestors?.length === 0 || _.has(folder?.ancestors[0], 'translationKey'))
        ) {
          actions.setSearchParams('filter', SEARCH_ITEM_LOCATIONS.USERS);
        }
      }
      actions.loadItems();
    }
  }, [store.initialFolderId]);

  useEffect(() => {
    updateSearchString();
  }, [storedSearchText, filterValue, totalItems]);

  useEffect(() => {
    setSearchText(storedSearchText);
  }, [storedSearchText]);

  const executeSearch = () => {
    actions.setShowResults(true);
    actions.setTableFilter(SEARCH_ITEM_LOCATIONS.ALL);
    actions.setSearchParams('textSearch', searchText);
    actions.setSearchParams('onlyPinned', false);
    actions.setSearchParams('types', allowedTypes);
    actions.setSearchParams('filter', undefined);
    actions.setSearchParams('folderId', undefined);
    actions.setFolder(undefined);
    actions.setSort('name', true, true);
    actions.setPageNumberAndGo(1, actions.loadItems);
  };

  const enterKey = useKey(13);
  useEffect(() => {
    if (enterKey) {
      executeSearch();
    }
  }, [enterKey]);

  const updateSearchString = () => {
    const bold = (text) => <span className="text-bolder">{text}</span>;
    const resultString = filterValue && (
      <span>
        {totalItems ? t('HOME_SCREEN.SHOWING_RESULTS_FOR') : t('HOME_SCREEN.NO_RESULTS_FOR')} {bold(storedSearchText)}
        {` ${t('HOME_SCREEN.IN')}`} {bold(t((_.find(locationOptions, { value: filterValue }) as any).text))}.
      </span>
    );
    setSearchString(resultString);
  };

  const columns = []
    .concat(
      isSelectable
        ? [
            {
              accessor: 'id',
              sortable: false,
              filterable: false,
              cellRenderFunction: (item) =>
                isFolder(item) ? (
                  <></>
                ) : (
                  <Checkbox
                    type="radio"
                    id="cloneToAnotherWorkbook"
                    onClick={() => {
                      setSelectedId(item.id);
                    }}
                    isChecked={selectedId === item.id}
                    label={''}
                  />
                ),
              cellStyle: { width: 40, maxWidth: 40 },
            },
          ]
        : [],
    )
    .concat([
      {
        accessor: 'id',
        header: '',
        cellRenderFunction: (item) => <HomeScreenItemIconCell item={item} />,
        cellStyle: { width: 40 },
        filterable: false,
        sortable: false,
      },
      {
        accessor: 'name',
        header: 'EXPLORER_MODAL.NAME',
        cellRenderFunction: (item) => (
          <NameCell
            item={item}
            showBreadcrumbs={_.isUndefined(folder)}
            extraClassNames="cursorPointer"
            isCurrentWorkbook={workbookId === item.id}
          />
        ),
        filterable: false,
        sortable: filterValue !== SEARCH_ITEM_LOCATIONS.RECENT,
      },
      {
        accessor: 'owner.name',
        headerRenderFunction: () => renderOwnerHeader(),
        filterable: false,
        sortable: filterValue !== SEARCH_ITEM_LOCATIONS.RECENT,
      },
      {
        accessor: 'updatedAt',
        cellType: CELL_TYPES.DATE_TIME,
        header: 'EXPLORER_MODAL.LAST_UPDATED',
        filterable: false,
        sortable: filterValue !== SEARCH_ITEM_LOCATIONS.RECENT,
      },
    ]);

  const openFolder = (folder) => {
    if (itemLocationFilter?.value === SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC && insideAdvancedSearch) {
      actions.setInitialFolderId(null);
    }
    actions.setSort('name', true, true);
    actions.setShowResults(false);
    actions.setFolder(folder);
    actions.setSearchParams(
      'filter',
      filterValue === SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC ? SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC : undefined,
    );
    actions.setSearchParams(
      'filter',
      filterValue === SEARCH_ITEM_LOCATIONS.USERS ? SEARCH_ITEM_LOCATIONS.USERS : undefined,
    );
    actions.setWorkbookId(undefined);
    actions.setSearchParams('folderId', folder?.id);
    actions.setSearchParams('onlyPinned', false);
    actions.setSearchParams('textSearch', undefined);
    actions.setSearchParams('types', allowedTypes);
    actions.setPageNumberAndGo(1, actions.loadItems);
  };

  const openAnalysis = (workbookId) => {
    actions.setWorkbookId(workbookId);
    next();
  };

  const handleItemSelection = (item) => {
    if (isFolder(item)) {
      openFolder(item);
    } else {
      if (isSelectable) {
        setSelectedId(item.id);
      }
      openAnalysis(item.id);
    }
  };

  const folderTree = (location) => {
    switch (location) {
      case SEARCH_ITEM_LOCATIONS.MY_FOLDER:
        return getTabFolder(HOME_SCREEN_TABS.MY_FOLDER);
      case SEARCH_ITEM_LOCATIONS.CORPORATE:
        return getTabFolder(HOME_SCREEN_TABS.CORPORATE);
      case SEARCH_ITEM_LOCATIONS.USERS:
        return getTabFolder(HOME_SCREEN_TABS.USERS);
      default:
        return Promise.resolve(undefined);
    }
  };

  const setDropdownAndGo = (value) => {
    const isPinned = value === SEARCH_ITEM_LOCATIONS.PINNED;
    const isRecent = value === SEARCH_ITEM_LOCATIONS.RECENT;
    const folderPromise = folderTree(value);
    const folderLocations = [
      SEARCH_ITEM_LOCATIONS.MY_FOLDER,
      SEARCH_ITEM_LOCATIONS.CORPORATE,
      SEARCH_ITEM_LOCATIONS.USERS,
    ];

    folderPromise.then((folder) => {
      actions.setTableRoot(value === SEARCH_ITEM_LOCATIONS.USERS ? value : undefined);
      // We store this separately because we don't always use the value of the filter in the query
      actions.setTableFilter(value);
      actions.setFolder(folder);
      if (_.isNil(folder) && _.includes(folderLocations, value)) {
        // In the case where the My Folder has not yet been created
        actions.setSearchParams('folderId', value);
        actions.setSearchParams('filter', undefined);
      } else {
        actions.setSearchParams('folderId', folder?.id);
        actions.setSearchParams('filter', isPinned || isRecent ? undefined : value);
      }
      actions.setSearchParams('onlyPinned', isPinned);
      actions.setSearchParams('types', isRecent ? _.without(allowedTypes, 'Folder') : allowedTypes);
      actions.setSort(isRecent ? 'openedAt' : 'name', !isRecent, true);
      actions.setPageNumberAndGo(1, actions.loadItems);
    });
  };

  const clearSearch = () => {
    actions.setShowResults(false);
    actions.setSearchParams('textSearch', undefined);
    actions.setSearchParams('folderId', undefined);
    actions.setFolder(undefined);
    actions.setPageNumberAndGo(1, actions.loadItems);
  };

  const formatBreadcrumb = (crumb) => {
    const itemContainsOwner = _.has(crumb, 'owner');
    return (
      <div
        key={`explorerCrumbId_${crumb?.id}`}
        className="breadcrumbLink cursorPointer"
        onClick={() => clickBreadcrumb(crumb)}>
        {translateBreadcrumb(
          crumb,
          itemContainsOwner &&
            insideAdvancedSearch &&
            myFolderContent?.id &&
            itemLocationFilter?.value === SEARCH_ITEM_LOCATIONS.MY_FOLDER
            ? crumb.owner.id === myFolderContent?.id
            : false,
          corporateFolderId,
        )}
        <span className="mr3 ml3">»</span>
      </div>
    );
  };

  const clickBreadcrumb = (crumb) => {
    // Scrape the breadcrumbs from the current folder rather than having to make another call to the backend
    const newFolder = _.assign({}, crumb, {
      ancestors: _.slice(folder.ancestors, 0, _.indexOf(folder.ancestors, crumb)),
    });

    // If the crumb doesn't have an id, it is not a real folder, ie. 'Shared'
    const folderLocation =
      newFolder.name === 'Shared' ? SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC : _.lowerCase(newFolder.name);

    if (newFolder.id) {
      // Since the breadcrumbs don't include effective permissions, we have to get the folder details
      return sqItemsApi
        .getItemAndAllProperties({ id: newFolder.id })
        .then(({ data: response }) => openFolder(_.assign(response, newFolder)));
    }

    return setDropdownAndGo(folderLocation);
  };

  const sort = (sortProperty, sortAscending) => {
    actions.setSort(sortProperty, sortAscending);
    actions.setPageNumberAndGo(1, actions.loadItems);
  };

  const renderOwnerHeader = () => {
    const supportSorting = filterValue !== SEARCH_ITEM_LOCATIONS.RECENT;
    return (
      <div
        onClick={() => (supportSorting ? sort('owner', store.sortAscending) : _.noop())}
        className={classNames(
          'th',
          'flexColumnContainer',
          'mb5',
          { cursorPointer: supportSorting },
          { 'sq-text-info': store.sortProperty === 'owner' },
        )}>
        {t('EXPLORER_MODAL.OWNER')}
        {supportSorting && (
          <div data-testid="owner_sort">
            <SortIcon sortProperty={store.sortProperty} sortBy="owner" sortAsc={store.sortAscending} />
          </div>
        )}
      </div>
    );
  };

  // This component is used in a modal with state, return null if we aren't in the proper state to show this component
  if (!show) return null;

  return (
    <div className="flexRowContainer">
      <div className="flexRowContainer min-height-325 max-height-400">
        <InputGroup
          className="explorerModalSearchBar flexColumnContainer lightGreyBorder border-radius-4 form-control flexNoWrap mb10"
          data-testid="explorerModalSearchBar">
          <FormControl
            className="explorerModalSearchInput hide-ms-clear height-32"
            type="text"
            onFocus={(e) => e.target.select()}
            value={searchText ? searchText : ''}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={
              _.isEqual(allowedTypes, ['Folder'])
                ? t('EXPLORER_MODAL.SEARCH_FOLDER_PLACEHOLDER')
                : t('EXPLORER_MODAL.SEARCH_PLACEHOLDER')
            }
          />
          <InputGroup.Append>
            <InputGroup.Text className="searchButton cursorPointer search-icon">
              <span onClick={executeSearch} data-testid="explorerModalSearchButton">
                <Icon icon="fc-mag-glass-empty" type="text" extraClassNames="fa-fw colorGray" />
              </span>
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        {!insideAdvancedSearch && (
          <span className="mb10 width-250" data-testid="explorerModalLocationSelect">
            <IconSelect
              extraClassNames="specExplorerModalLocationSelect"
              selectOptions={locationOptions as any}
              name="location"
              value={filterValue}
              onChange={(option) => setDropdownAndGo(option.value)}
            />
          </span>
        )}

        {showResults && <SearchResults resultsString={searchString} isLoading={isLoading} onClear={clearSearch} />}

        {folder?.ancestors && (
          <div id="breadcrumbLinks" className="flexColumnContainer flexWrap">
            {_.map(folder.ancestors, formatBreadcrumb)}
            {translateBreadcrumb(
              folder,
              parentFolder && itemLocationFilter?.value === SEARCH_ITEM_LOCATIONS.MY_FOLDER,
              corporateFolderId,
            )}
          </div>
        )}

        <span className="overflowAuto max-height-325">
          <ServerSideFilteringAndPaginatingTable
            columns={columns}
            items={items}
            selectedIds={[selectedId]}
            rowClickCallback={handleItemSelection}
            rowSelectionCallback={_.noop}
            filterCallback={_.noop}
            sortCallback={sort}
            selectAllCallback={_.noop}
            store={store}
            actions={actions}
            insideModal={true}
            loadTable={actions.loadItems}
            testId="exploreWorkbookModalTable"
          />
        </span>
      </div>
    </div>
  );
};
