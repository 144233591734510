import _ from 'lodash';
import { ITEM_DATA_STATUS, ITEM_TYPES } from '@/trendData/trendData.constants';
import { BaseItemStore } from '@/trendData/baseItem.store';
import { PROPS_TO_DEHYDRATE } from '@/trendData/baseItem.constants';
import { InitializeMode } from '@/core/flux.service';
import { ConditionMonitorOutputV1 } from '@/sdk/model/ConditionMonitorOutputV1';

/**
 * A store for containing CapsuleSets. CapsuleSets are used to generate capsules.
 *
 * This store is augmented with additional functionality from BaseItemStore.
 */
export class TrendCapsuleSetStore extends BaseItemStore {
  static readonly storeName = 'sqTrendCapsuleSetStore';

  initialize(initializeMode: InitializeMode) {
    super.initialize(initializeMode);
  }

  /**
   * Adds a CapsuleSet item.
   *
   * @param {Object} payload - Object container for arguments
   */
  private addCapsuleSet = (payload: {
    /** ID of the new CapsuleSet */
    id: string;
    /** Name of the new CapsuleSet */
    name: string;
    /** Color hex code (e.g. #CCCCCC) */
    color: string;
    /** Lane for the new CapsuleSet */
    lane: number;
    /** Represents the thickness of capsules in new CapsuleSet */
    lineWidth: number;
  }) => {
    const props = _.pick(payload, ['color', 'lane', 'lineWidth']);
    this.state.push('items', this.createCapsuleSet(payload.id, payload.name, props));
  };

  /**
   * Private helper function to add a capsule set to the store using the specified properties.
   *
   * @param {String} id - ID to use for the new CapsuleSet
   * @param {String} name - Name to use for the new CapsuleSet
   * @param {Object} props - Object containing properties to apply to the new calculation
   *
   * @returns Newly created CapsuleSet object.
   */
  private createCapsuleSet = (id: string, name: string, props: any) => {
    return this.createItem(
      id,
      name,
      ITEM_TYPES.CAPSULE_SET,
      _.assign(
        {
          dataStatus: ITEM_DATA_STATUS.INITIALIZING,
        },
        props,
      ),
    );
  };

  protected readonly handlers = {
    ...super.baseHandlers,
    TREND_ADD_CAPSULE_SET: this.addCapsuleSet,
    TREND_SET_CONDITION_NOTIFICATION_IDS: ({
      conditionIds,
      conditionMonitorItems,
    }: {
      conditionIds: string[];
      conditionMonitorItems: ConditionMonitorOutputV1[];
    }) => {
      const initialIdMap = _.reduce(
        conditionIds,
        (ids, conditionId) => {
          ids.set(conditionId, []);

          return ids;
        },
        new Map<string, string[]>(),
      );
      const idMap = _.chain(conditionMonitorItems)
        .flatMap((conditionMonitorItem) =>
          conditionMonitorItem.conditionIds.map((id) => ({ conditionId: id, conditionMonitorItem })),
        )
        .reduce((ids, { conditionId, conditionMonitorItem }) => {
          ids.get(conditionId)!.push(conditionMonitorItem.id);

          return ids;
        }, initialIdMap)
        .value();

      idMap.forEach((conditionNotificationIds, conditionId) =>
        this.setProperty(conditionId, 'conditionNotificationIds', conditionNotificationIds),
      );
    },
  };

  /**
   * Exports state so it can be used to re-create the state later using `rehydrate`.
   *
   * @returns {Object} The dehydrated items.
   */
  dehydrate() {
    return {
      items: _.chain(this.state.get('items'))
        .filter(this.shouldDehydrateItem)
        .map((item) => _.pick(item, PROPS_TO_DEHYDRATE as any))
        .value(),
    };
  }

  /**
   * Re-creates the capsule sets.
   *
   * @param {Object} dehydratedState Previous state usually obtained from `dehydrate` method.
   */
  rehydrate(dehydratedState: any) {
    this.state.set(
      'items',
      _.map(dehydratedState.items, (item) => this.createCapsuleSet(item.id, item.name, _.omit(item, 'id', 'name'))),
    );
  }
}
