// @ts-strict-ignore
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Modal, Tabs } from '@seeqdev/qomponents';
import _ from 'lodash';
import { CORPORATE_DRIVE_NAME, DEFAULT_PERMISSIONS, HOME_SCREEN_TABS } from '@/main/app.constants';
import { AceOutputV1 } from '@/sdk/model/AceOutputV1';
import { PermissionsV1 } from '@/sdk/model/PermissionsV1';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { sqUserGroupsApi } from '@/sdk/api/UserGroupsApi';
import { emitPermissions } from '@/services/notifier.service';
import { sqHomeScreenStore, sqWorkbenchStore, sqWorkbookStore } from '@/core/core.stores';
import {
  Ace,
  Acl,
  aclIsModified,
  ADVANCED_TAB_INDEX,
  BASIC_TAB_INDEX,
  CHECK_ACCESS_TAB_INDEX,
  deDupeAceList,
  DEFAULT_ACE,
  DetailTableAce,
  determinePermissions,
  getAdditionalText,
  Identity,
} from '@/accessControl/itemAclModal.utilities';
import { AclModalMainTab } from '@/accessControl/AclModalMainTab.molecule';
import { AclModalAdvancedTab } from '@/accessControl/AclModalAdvancedTab.molecule';
import { logError } from '@/utilities/logger';
import { formatMessage } from '@/utilities/logger.utilities';
import { prettyPermissions } from '@/utilities/utilities';
import { errorToast, successToast } from '@/utilities/toast.utilities';
import { getItemACL, getItemACLDetails, getItemName, getSwapSourceIdIfSwap } from '@/utilities/acl.utilities';
import { doTrack } from '@/track/track.service';
import { EVERYONE_USERGROUP } from '@/administration/administration.constants';
import { JOURNAL_OR_REPORT } from '@/homescreen/homescreen.constants';
import { cancelGroup } from '@/requests/pendingRequests.utilities';
import { AclModalCheckAccessTab } from '@/accessControl/AclModalCheckAccessTab.molecule';
import { ClosedWhileSavingModal } from '@/accessControl/CloseWhileSavingModal.molecule';
import { UnsavedChangesModal } from '@/accessControl/UnsavedChangesModal.molecule';

interface ItemAclModalIF {
  item?: any;
  itemId: string;
  closeModal: () => void;
  itemName?: string;
  workbookId?: string;
  worksheetId?: string;
  // the get links section and corporate message are only included in the modal in the header and home screen actions
  includeLinksAndCorporateMessage?: boolean;
  folderId?: string;
  showOnlyFolderLinks?: boolean;
  isAclModalLocationInHeader?: boolean;
}

export const ItemAclModal: React.FunctionComponent<ItemAclModalIF> = ({
  itemId,
  item,
  closeModal,
  itemName,
  workbookId,
  worksheetId,
  includeLinksAndCorporateMessage,
  folderId,
  showOnlyFolderLinks,
  isAclModalLocationInHeader,
}) => {
  const { t } = useTranslation();

  const cancellationGroup = `aclUpdate_${itemId}`;

  let everyoneGroup;
  const isItemAncestorNotCorporate = !isAclModalLocationInHeader
    ? item?.ancestors[0].name !== CORPORATE_DRIVE_NAME
    : sqWorkbookStore.ancestors[0].name !== CORPORATE_DRIVE_NAME;

  const [newAce, setNewAce] = useState(_.clone(DEFAULT_ACE));
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [itemAcl, setItemAcl] = useState({} as any);
  const [localAceEntries, setLocalAceEntries] = useState([]);
  const [displayAceEntries, setDisplayAceEntries] = useState([]);
  const [isAclFromDatasource, setIsAclFromDatasource] = useState(false);
  const [originalItemAcl, setOriginalItemAcl] = useState(undefined);
  const [itemNameState, setItemNameState] = useState(itemName);
  const [currentTabIndex, setCurrentTabIndex] = useState(BASIC_TAB_INDEX);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [inheritedAcl, setInheritedAcl] = useState(null);
  const [detailTableEntries, setDetailTableEntries] = useState([]);
  const [originalLocalAces, setOriginalLocalAces] = useState(null);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [showCloseDuringSaveModal, setShowCloseDuringSaveModal] = useState(false);
  const [everyoneDefault, setEveryoneDefault] = useState(_.clone(DEFAULT_ACE));

  /**
   * Updates the itemId to the source item if this item is a swap.
   */
  const setItemIdIfSwap = () =>
    getSwapSourceIdIfSwap(itemId).then((id) => {
      // TODO: CRAB-30435
      /* eslint no-param-reassign: "off" */
      itemId = id;
    });

  useEffect(() => {
    sqUserGroupsApi.getUserGroups({ nameSearch: EVERYONE_USERGROUP }).then(({ data }) => {
      everyoneGroup = _.find(data.items, { name: EVERYONE_USERGROUP });
    });
    setItemIdIfSwap().finally(() => fetchACLData());
  }, []);

  /**
   * Fetches data for ACL modal
   */
  const fetchACLData = () => {
    const itemAclPromise = getItemACL(itemId);
    const itemNamePromise = itemName ? Promise.resolve(itemName) : getItemName(itemId);

    Promise.all([itemAclPromise, itemNamePromise])
      .then(([itemAclResponse, itemNameResponse]) => {
        const currentAcl = _.pick(itemAclResponse, ['entries', 'permissionsInheritanceDisabled']);
        setItemAcl(currentAcl);
        setIsAclFromDatasource(itemAclResponse.permissionsManagedByDatasource);
        setItemNameState(itemNameResponse);
        initializeAceDisplay(currentAcl.entries);
        setOriginalItemAcl({
          permissionsInheritanceDisabled: currentAcl.permissionsInheritanceDisabled,
          entries: _.map(currentAcl.entries, (entry) => ({
            identityId: entry.identity.id,
            permissions: _.clone(entry.permissions),
          })),
          // set original local aces so we can diff better on changes:
          localEntries: _.chain(currentAcl.entries)
            .reject('origin')
            .reject('role')
            .map((entry) => ({
              identityId: entry.identity.id,
              permissions: _.clone(entry.permissions),
            }))
            .value(),
        });
        return fetchInheritedAcl().then(() => setLoading(false));
      })
      .catch((error) => {
        errorToast({ httpResponseOrError: error, displayForbidden: true });
        close(null, false);
      });
  };

  /**
   * When an item is moved to the corporate drive, using the corporate drive message, the permissions get updated to
   * show the new inherited permissions for the Everyone group
   */
  const updatedInheritedPermissionsDisplay = () => {
    const itemAclPromise = getItemACL(itemId);
    let inheritedEveryonePermissions;

    Promise.all([itemAclPromise])
      .then(([itemAclResponse]) => {
        const currentAcl = _.pick(itemAclResponse, ['entries', 'permissionsInheritanceDisabled']);
        _.filter(currentAcl.entries, (entry) => {
          if (entry.identity?.name === EVERYONE_USERGROUP && entry.origin) {
            inheritedEveryonePermissions = entry;
          }
        });
        itemAcl.entries.push(inheritedEveryonePermissions);
        setItemAcl({ ...itemAcl });
        initializeAceDisplay(itemAcl.entries);
        return fetchInheritedAcl().then(() => setLoading(false));
      })
      .catch((error) => {
        errorToast({ httpResponseOrError: error, displayForbidden: true });
        close(null, false);
      });
  };

  /**
   * This function sets the localAceEntries and displayAceEntries.
   */
  const initializeAceDisplay = (entries: AceOutputV1[]) => {
    setLocalAceEntries(_.chain(entries).reject('origin').reject('role').value());

    setDisplayAceEntries(
      deDupeAceList(
        entries,
        sqHomeScreenStore.currentTab !== HOME_SCREEN_TABS.CORPORATE,
        includeLinksAndCorporateMessage,
      ),
    );

    setEveryoneDefault(everyoneGroup);
    const addEveryoneDefault = {
      id: '',
      identity: everyoneGroup || everyoneDefault,
      permissions: [],
      isNew: false,
    };

    if (
      isItemAncestorNotCorporate &&
      includeLinksAndCorporateMessage &&
      sqHomeScreenStore.currentTab !== HOME_SCREEN_TABS.CORPORATE
    ) {
      if (
        (everyoneGroup?.isEnabled || everyoneDefault?.isEnabled) &&
        !_.some(entries, (entry) => entry.identity.id === (everyoneGroup?.id || everyoneDefault?.id))
      ) {
        setLocalAceEntries((prevEntries) => [addEveryoneDefault, ...prevEntries]);
        setDisplayAceEntries((prevEntries) => [addEveryoneDefault, ...prevEntries]);
      }
    }
  };

  /**
   * Saves the changes made to the item ACL, with an option to preview the result of saving the changes without
   * actually persisting them to the backend.
   *
   * @param preview - true to preview the save operation, writing the result to the local view model and
   * leaving the modal open. The backend is not modified when preview is true. The default is false which modifies the
   * backend and closes the modal.
   * @param permInherOverride - the override value for permissions inheritance. If defined, defaults to the
   * itemAcl's permissions inheritance
   * @returns {Promise} a promise that resolved
   */
  const save = (preview = false, permInherOverride: boolean | undefined = undefined) => {
    doTrack('Access control', 'save');

    setSaving(!preview);

    let aceEntries = _.map(localAceEntries, (ace) => ({
      permissions: ace.permissions,
      identityId: ace.identity.id,
    }));

    if (
      isItemAncestorNotCorporate &&
      includeLinksAndCorporateMessage &&
      sqHomeScreenStore.currentTab !== HOME_SCREEN_TABS.CORPORATE
    ) {
      if (!everyoneDefault || everyoneDefault.isEnabled) {
        aceEntries = _.filter(aceEntries, (ace) => ace.permissions.read);
      }
    }

    const disablePermissionInheritance = _.isUndefined(permInherOverride)
      ? itemAcl.permissionsInheritanceDisabled
      : permInherOverride;
    const localizeInherited = preview; // Only localize inherited access control entries when previewing

    // if preview is true we need to keep track of the local permissions so that we can revert properly if the user
    // toggles the "disable permission inheritance" checkbox.
    // clicking "disable permission inheritance" adds a local permission for all existing permissions
    // un-checking the checkbox should remove all local permissions that have been added
    // to support this a copy of all "original" local ace entries is stored (and kept in sync upon further updates
    // by the user)
    if (preview) {
      if (disablePermissionInheritance) {
        setOriginalLocalAces(aceEntries);
      } else if (originalLocalAces !== null) {
        aceEntries = originalLocalAces;
      }
    }

    const body = {
      entries: aceEntries,
      disablePermissionInheritance,
      preview,
      localizeInherited,
    };

    return sqItemsApi
      .setAcl(body, { id: itemId }, { cancellationGroup })
      .then(({ data }) => {
        const { entries, permissionsInheritanceDisabled } = data;
        if (preview) {
          const acl = {
            entries,
            permissionsInheritanceDisabled,
          };

          setItemAcl(acl);
          initializeAceDisplay(acl.entries);
        } else {
          emitPermissions(sqWorkbenchStore.stateParams.workbookId, sqWorkbenchStore.stateParams.worksheetId, itemId);
          successToast({ messageKey: 'ACCESS_CONTROL.SAVED' });
          close(data, true);
        }
      })
      .catch((error) => {
        errorToast({ httpResponseOrError: error, displayForbidden: true });
      })
      .finally(() => {
        setSaving(false);
      });
  };

  /**
   * Closes the item ACL modal. Prompts the user if they are not saving and there are unsaved changes to give the
   * user the opportunity to save the changes if desired.
   *
   * @param {object} data - data to be passed back to the caller that opened the modal instance. Currently, only used
   * to pass the updated ACL from the backend back to the caller that opened the modal.
   * @param {boolean} forceClose - true if the modal should close, even with pending changes.
   * @param cancelRequest - true if the request should be cancelled.
   */
  const close = (data?, forceClose = false, cancelRequest = false) => {
    doTrack('Access Control', 'close');
    if (!forceClose && aclIsModified(itemAcl, originalItemAcl)) {
      setShowUnsavedChangesModal(true);
    } else {
      closeModal();
      if (cancelRequest) {
        cancelGroup(cancellationGroup);
      }
    }
  };

  /**
   * Adds an ACE entry based on the identity and access level selected.
   * Note: The change is only performed in memory. Changes are persisted when save() is called.
   */
  const addItemAce = () => {
    doTrack('Access Control', 'Add');
    itemAcl.entries.push(_.cloneDeep(_.omit(newAce, 'isNew')));
    setItemAcl({ ...itemAcl });

    initializeAceDisplay(itemAcl.entries);
    setNewAce({ ...DEFAULT_ACE });
    setEveryoneDefault(everyoneDefault);
  };

  /**
   * Toggles permission inheritance and updates the local itemAcl
   */
  const togglePermissionsInheritance = () => {
    setItemAcl({
      ...itemAcl,
      permissionsInheritanceDisabled: !itemAcl.permissionsInheritanceDisabled,
    });
    save(true, !itemAcl.permissionsInheritanceDisabled);
  };

  /**
   * This function is called when the user clicks a checkbox.
   *
   * Only "local" permissions can be set via the ACL Modal - inherited permissions must not be manipulated. Users
   * can however add local permission in addition to existing inherited permissions.
   * This function ensures that the proper local permissions entries are created/updated.
   *
   * @param permission - the name of the permission selected by the user.
   * @param ace - object representing an ace entry
   */
  const setPermissions = (permission: string, ace: Ace) => {
    const identityId = ace?.identity?.id;
    const isNew = ace?.isNew;
    let toUpdate;

    const getLocalAce = () => {
      const existingLocalAce = _.find(localAceEntries, ['identity.id', identityId]);
      if (existingLocalAce) {
        return existingLocalAce;
      } else {
        const inheritedAceForIdentity = _.find(itemAcl.entries, ['identity.id', identityId]);
        const newLocalAce = _.cloneDeep(_.omit(inheritedAceForIdentity, ['origin', 'role']));
        _.assign(newLocalAce, { permissions: _.clone(DEFAULT_PERMISSIONS) });
        return newLocalAce;
      }
    };

    toUpdate = isNew ? newAce : getLocalAce();
    toUpdate.permissions = determinePermissions(permission, toUpdate.permissions);

    if (!isNew) {
      const updatedItemAcl = { ...itemAcl };
      const updatedLocalAceEntries = [...localAceEntries];
      updatedItemAcl.entries.push(toUpdate);
      updatedLocalAceEntries.push(toUpdate);
      updateLocalAces(toUpdate, identityId, updatedItemAcl, updatedLocalAceEntries);
      syncOriginalLocalAces(identityId, toUpdate.permissions);
      setDisplayAceEntries(
        deDupeAceList(
          itemAcl.entries,
          sqHomeScreenStore.currentTab !== HOME_SCREEN_TABS.CORPORATE,
          includeLinksAndCorporateMessage,
        ),
      );

      const everyoneInUpdatedEntries = _.size(
        _.filter(updatedLocalAceEntries, (entry) => entry.identity.name === EVERYONE_USERGROUP),
      );
      const everyoneInItemAcl = _.size(
        _.filter(itemAcl.entries, (entry) => entry.identity.name === EVERYONE_USERGROUP),
      );

      if (
        isItemAncestorNotCorporate &&
        includeLinksAndCorporateMessage &&
        sqHomeScreenStore.currentTab !== HOME_SCREEN_TABS.CORPORATE
      ) {
        if (
          everyoneDefault?.isEnabled &&
          (toUpdate.identity.id === everyoneDefault?.id
            ? !toUpdate.permissions.read && displayAceEntries[0].permissions?.read && everyoneInUpdatedEntries === 0
            : !displayAceEntries[0].permissions?.read && everyoneInItemAcl === 0)
        ) {
          setDisplayAceEntries((prevEntries) => [
            {
              id: '',
              identity: everyoneDefault,
              permissions: [],
              isNew: false,
            },
            ...prevEntries,
          ]);
          setLocalAceEntries((prevEntries) => [
            {
              id: '',
              identity: everyoneDefault,
              permissions: [],
              isNew: false,
            },
            ...prevEntries,
          ]);
        }
      }
    } else {
      setNewAce({ ...toUpdate });
    }
  };

  /**
   * To prevent the aclIsModified function to return "false positives" when users check and then uncheck the same
   * permission for which no local entry existed, local entries that are all false are removed from the
   * localAceEntries as well as the itemAcl.entries. This ensures that the "save" button does not show as active if
   * I click and un-click the same permission on an entry that currently only shows inherited permissions.
   *
   * Even if we don't remove any entries, we need to ensure that the items mapping to the local entries have been
   * updated
   *
   * @param toUpdate - the ACE to update
   * @param identityId - the id of the identity (user/group)
   * @param {object} updatedItemAcl - the updated itemAcl
   * @param {object[]} updatedLocalAceEntries - The updated list of local aces
   */
  const updateLocalAces = (toUpdate: Ace, identityId: string, updatedItemAcl: Acl, updatedLocalAceEntries: Ace[]) => {
    const originalLocalIndex = _.findIndex(originalItemAcl.localEntries, {
      identityId: toUpdate.identity.id,
    });

    if (_.isEqual(toUpdate.permissions, DEFAULT_PERMISSIONS) && originalLocalIndex < 0) {
      // Local entry needs to be removed
      setLocalAceEntries(updatedLocalAceEntries.filter((entry) => entry.identity.id !== identityId));
      _.remove(
        updatedItemAcl.entries,
        (entry) => !_.has(entry, 'origin') && !_.has(entry, 'role') && _.get(entry, 'identity.id') === identityId,
      );
      setItemAcl({ ...updatedItemAcl });
    } else {
      setLocalAceEntries(updatedLocalAceEntries);
      setItemAcl(updatedItemAcl);
    }
  };

  /**
   * We need to be able to toggle between Enable/Disable permission inheritance so we need to keep track of all the
   * user changes to local permissions. This allows us to "undo" all system added local ACEs without loosing user
   * provided permissions.
   *
   * @param identityId - the id of the user/group for the given permissions
   * @param permissions - the permissions
   */
  const syncOriginalLocalAces = (identityId: string, permissions: PermissionsV1) => {
    if (originalLocalAces !== null) {
      const existingOriginalLocalAce: any = _.find(originalLocalAces, {
        identityId,
      });
      if (existingOriginalLocalAce) {
        existingOriginalLocalAce.permissions = permissions;
      } else {
        originalLocalAces.push({ identityId, permissions });
      }
      setOriginalLocalAces([...originalLocalAces]);
    }
  };

  /**
   * Maps an API AceOutput to an ace entry used in the details pane
   */
  const createDetailsEntry: (entry: AceOutputV1, currentUserId: string) => DetailTableAce = (entry, currentUserId) => {
    const nameRedacted = t('ACCESS_CONTROL.REDACTED');
    let inheritedFrom: string;
    if (_.get(entry, 'origin.isRedacted', false)) {
      inheritedFrom = nameRedacted;
    } else {
      inheritedFrom = _.get(entry, 'origin.name', '');
    }
    return {
      id: entry.id,
      name: `${_.get(entry, 'identity.name')} ${getAdditionalText(entry, t, currentUserId)}`,
      permissions: prettyPermissions(entry.permissions),
      inheritedFrom,
    };
  };

  /**
   * This function fetches the inherited acl data displayed on the details tab.
   */
  const fetchInheritedAcl = () => {
    setLoadingDetails(true);
    return getItemACLDetails(itemId, true)
      .then(({ entries }) => {
        setInheritedAcl(
          _.chain(entries)
            .filter('origin')
            .map((entry) => createDetailsEntry(entry, sqWorkbenchStore.currentUser.id))
            .value(),
        );
      })
      .catch((ex) => {
        logError(formatMessage`Error fetching ACL details: ${ex}`);
      })
      .finally(() => setLoadingDetails(false));
  };

  /**
   * Sets the tabIndex that determines which tab is displayed.
   */
  const changeTab: (tabIndex: string) => void = (tabIndex) => {
    // We don't want to do anything if we are clicking the tab we are already on (CRAB-17339)
    if (currentTabIndex === tabIndex || loading) {
      return;
    }

    if (tabIndex === ADVANCED_TAB_INDEX) {
      doTrack('ACL Modal', 'Details Tab');
      setDetailsEntries();
    } else {
      doTrack('ACL Modal', 'Manage Tab');
    }

    setCurrentTabIndex(tabIndex);
  };

  const setDetailsEntries = () => {
    setDetailTableEntries(
      _.chain(itemAcl.entries)
        .reject('origin')
        .filter((entry) => entry.permissions.read || entry.permissions.write || entry.permissions.manage)
        .map((entry) => createDetailsEntry(entry, sqWorkbenchStore.currentUser.id))
        .concat(itemAcl.permissionsInheritanceDisabled ? [] : inheritedAcl)
        .sortBy('name')
        .value(),
    );
  };

  const setIdentity = useCallback(
    (identity: Identity) => {
      if (identity.id) {
        setNewAce({ ...newAce, identity });
      } else {
        setNewAce(_.clone(DEFAULT_ACE));
      }
    },
    [newAce],
  );

  const titleIcon = (
    <a href="https://telemetry.seeq.com/support-link/kb/latest/cloud/authorization" target="_blank" rel="noopener">
      <Icon
        icon="fa-question-circle"
        large={true}
        tooltip={t('ITEM_ACL.CLICK_FOR_INFO')}
        extraClassNames="text-interactive"
      />
    </a>
  );

  return (
    <Modal
      onClose={() => (saving ? setShowCloseDuringSaveModal(true) : close())}
      onSubmit={() => save()}
      disableSubmitButton={!aclIsModified(itemAcl, originalItemAcl) || saving}
      open={true}
      size="4xl"
      testId="acl-modal"
      title={t('ITEM_ACL.HEADER')}
      titleIcon={titleIcon}
      titleIconPosition="right"
      subtitle={itemNameState}
      submitButtonLabel={t('SAVE')}
      cancelButtonLabel={t('CANCEL')}>
      <div className="m10 min-width-800">
        <Tabs
          extraClassNames="width-maximum tw-h-full"
          defaultActiveTab={BASIC_TAB_INDEX}
          transition={false}
          activeTab={currentTabIndex}
          onTabSelect={(key) => changeTab(key)}
          tabs={[
            {
              label: t('ACCESS_CONTROL.MANAGE'),
              id: BASIC_TAB_INDEX,
              tabContentExtraClassNames: 'tw-h-full',
              testId: `aclModalTabs-tab-${BASIC_TAB_INDEX}`,
              content: (
                <div data-testid={`aclModalTabs-tabpane-${BASIC_TAB_INDEX}`}>
                  <AclModalMainTab
                    isAclFromDatasource={isAclFromDatasource}
                    addItemAce={addItemAce}
                    newAce={newAce}
                    loading={loading}
                    saving={saving}
                    displayAceEntries={displayAceEntries}
                    currentUserId={sqWorkbenchStore.currentUser.id}
                    itemAcl={itemAcl}
                    setIdentity={setIdentity}
                    setPermissions={setPermissions}
                    workbookId={workbookId}
                    worksheetId={_.includes(JOURNAL_OR_REPORT, item?.type) ? item.worksheetId : worksheetId}
                    includeLinksAndCorporateMessage={includeLinksAndCorporateMessage}
                    folderId={folderId}
                    showOnlyFolderLinks={showOnlyFolderLinks}
                    itemId={itemId}
                    itemName={itemNameState}
                    isAclModalLocationInHeader={isAclModalLocationInHeader}
                    isItemAncestorNotCorporate={isItemAncestorNotCorporate}
                    item={item}
                    aclModified={aclIsModified(itemAcl, originalItemAcl)}
                    fetchInherited={updatedInheritedPermissionsDisplay}
                    onClose={close}
                  />
                </div>
              ),
            },
            {
              label: t('ACCESS_CONTROL.ADVANCED'),
              id: ADVANCED_TAB_INDEX,
              tabContentExtraClassNames: 'tw-h-full',
              testId: `aclModalTabs-tab-${ADVANCED_TAB_INDEX}`,
              content: (
                <div data-testid={`aclModalTabs-tabpane-${ADVANCED_TAB_INDEX}`}>
                  <AclModalAdvancedTab
                    loadingDetails={loadingDetails}
                    detailTableEntries={detailTableEntries}
                    togglePermissionsInheritance={togglePermissionsInheritance}
                    itemAcl={itemAcl}
                  />
                </div>
              ),
            },
            {
              label: t('ACCESS_CONTROL.CHECK_ACCESS'),
              id: CHECK_ACCESS_TAB_INDEX,
              testId: `aclModalTabs-tab-${CHECK_ACCESS_TAB_INDEX}`,
              content: (
                <div data-testid={`aclModalTabs-tabpane-${CHECK_ACCESS_TAB_INDEX}`}>
                  <AclModalCheckAccessTab itemAcl={itemAcl} />
                </div>
              ),
            },
          ]}
          id="aclModalTabs"
        />
        {showUnsavedChangesModal && (
          <UnsavedChangesModal
            onCancel={() => setShowUnsavedChangesModal(false)}
            onDiscard={() => {
              setShowUnsavedChangesModal(false);
              close(null, true, true);
            }}
          />
        )}
        {showCloseDuringSaveModal && (
          <ClosedWhileSavingModal
            onClose={() => {
              setShowCloseDuringSaveModal(false);
              close(null, true, true);
            }}
          />
        )}
      </div>
    </Modal>
  );
};
