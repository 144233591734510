import { ITEM_TYPES } from '@/trendData/trendData.constants';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';

export const Y_AXIS_MIN_TOTAL_WIDTH = 30;

export const GRAY_LANE_COLOR = 'rgba(207, 201, 202, 0.1)';
export const WHITE_LANE_COLOR = 'transparent';

export const CAPSULE_TYPES = [
  ITEM_TYPES.CAPSULE,
  ITEM_TYPES.UNCERTAIN_BOUNDED_CAPSULE,
  ITEM_TYPES.UNCERTAIN_UNBOUNDED_CAPSULE,
];

export const UNCERTAIN_CAPSULE_TYPES = [ITEM_TYPES.UNCERTAIN_BOUNDED_CAPSULE, ITEM_TYPES.UNCERTAIN_UNBOUNDED_CAPSULE];

export interface ChartRegion {
  xMin: number | null;
  xMax: number | null;
  yMin: number | null;
  yMax: number | null;
  id?: string;
  dateTime?: string;
  yValue?: number;
  lane?: number;
}

export const EMPTY_CHART_REGION = {
  xMin: null,
  xMax: null,
  yMin: null,
  yMax: null,
};

export interface XYPixelRegion {
  xMinPixel: number;
  xMaxPixel: number;
  yMinPixel: number;
  yMaxPixel: number;
  id: string;
  dateTime: string;
  yValue?: number;
  lane?: number;
}

/**
 * Contains the extra fields that we use to extend any SeriesOptions object from Highcharts
 */
export interface SeeqSeriesOptions {
  xNumberFormat: string;
  yNumberFormat: string;
  xSignalName: string;
  ySignalName: string;
}

export type PixelTranslationFunction = (
  chart: Highcharts.Chart,
  minVal: number,
  maxVal: number,
) => { minPixel: number; maxPixel: number };

export type SeriesGroupedByAxis = {
  primarySeries: any;
  series: any[];
  hide?: boolean;
};

export const LINE_WIDTHS = {
  [ITEM_TYPES.SERIES]: headlessRenderMode() ? 2 : 1,
  [ITEM_TYPES.SCALAR]: headlessRenderMode() ? 2 : 1,
};

export const SHADOW_AGGREGATE = {
  width: 4,
  opacity: 1,
  offsetX: 0,
  offsetY: 0,
};

export const BAR_CHART_ESSENTIALS = {
  type: 'column',
  pointPlacement: 'on',
  minPointLength: 2,
};

export const ALPHA_DIMMED = 0.3;

// Highcharts needs callbacks passed in to regenerate tick positions and other per axis settings at various points
// in the Highcarts lifecycle, so we can't rely on the initial chart or items passed in. Instead, pass in
// getters and provide the most up to date version of each.
export interface ChartGetters {
  getChart: () => Highcharts.Chart;
  getItems: () => any[];
}

export const ENABLED_MARKER = { enabled: true, radius: 2, symbol: 'circle' };
export const DISABLED_MARKER = { enabled: false, radius: 0 };
export const ENABLED_MARKER_ZERO = { enabled: true, radius: 3, symbol: 'diamond' };
export const ENABLED_MARKER_ZERO_UNCERTAIN = { enabled: true, radius: 2, symbol: 'diamond' };

export const CHART_FONT_STYLE = {
  fontFamily: 'Source Sans Pro',
  fontSize: '12px',
  lineHeight: '12px',
  textOutline: 'none',
};
