// @ts-strict-ignore
import { FormError } from '@/core/FormError.atom';
import React, { useState } from 'react';
import { Button, Select } from '@seeqdev/qomponents';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

type SelectOption = {
  value: string;
  label: string;
};

export type SelectAndAddProps = {
  selectedItemIds: string[];
  options: SelectOption[];
  selectedValue: SelectOption;
  onChange: (value: SelectOption) => void;
  onAddClick: () => void;
  isAssetAndItemSelected?: boolean;
  isSelectAllDisabled?: boolean;
};

/**
 * Contains dropdown and add button for multi-select.
 *
 * Manages loading state for add button
 */
export const SelectAndAdd: React.FunctionComponent<SelectAndAddProps> = ({
  selectedItemIds,
  options,
  selectedValue,
  onChange,
  onAddClick,
  isAssetAndItemSelected,
  isSelectAllDisabled,
}) => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);

  const renderResult = () => {
    if (isAssetAndItemSelected) {
      return <FormError errorText="MULTI_SELECT.ASSET_GROUP.ERROR" />;
    }

    if (selectedItemIds.length > 0) {
      return (
        <div className="flexColumnContainer flexAlignCenter" data-testid="selectAndAddEmptyWrapper">
          <Select
            id="selectActionsOptions"
            small={true}
            extraClassNames="flexFillOverflow mr10"
            value={selectedValue}
            options={options}
            onChange={onChange}
            isSearchable={false}
            isClearable={false}
          />
          <Button
            testId="addItems"
            variant="theme"
            extraClassNames="sq-btn-xs"
            disabled={isLoading}
            label={t('ADD')}
            icon={isLoading ? 'fa-spinner fa-spin-pulse' : undefined}
            iconPrefix={isLoading ? 'fa-solid' : ''}
            iconStyle={isLoading ? 'white' : undefined}
            onClick={() => {
              setLoading(true);
              onAddClick();
            }}
          />
        </div>
      );
    }

    if (isLoading) {
      setLoading(false);
    }

    return <span className={classNames({ disabledLook: isSelectAllDisabled })}>{t('SELECT_ALL')}</span>;
  };

  return (
    <div data-testid="selectAndAdd" className="ml5 width-maximum">
      {renderResult()}
    </div>
  );
};
