import React, { forwardRef } from 'react';
import { FormElement } from '@/formbuilder/formBuilder.constants';
import { FormBuilder } from '@/formbuilder/FormBuilder.page';

interface ToolPanelFormBuilderProps {
  // the formDefinition Array of FormElements defines the form to be rendered
  formDefinition: FormElement[];
  // the function that is called when the form is submitted
  submitFn: () => void;
  // the function that is called when the cancel button is clicked
  closeFn: () => any;
  // id of the tool (legacy - may no longer apply)
  toolId?: string;
  // id of the submitBtnId (legacy - may no longer apply)
  submitBtnId?: string;
  // if debug is enabled then the form values and form errors are rendered below this form;
  debug?: boolean;
  // option to have the submit button say something other than 'Execute'
  submitBtnLabel?: string;
  // option to hide the cancel button
  hideCancel?: boolean;
  // wrap the form in a panel
  wrapInPanel?: boolean;
  // extra classnames applied if not wrapped in panel
  extraClassNamesUnwrapped?: string;
  // option to pass a ref to access submit button status
  ref?: any;
}

/**
 * ToolPanelFormBuilder generates tool forms based on the provided form definition Array.
 **/
export const ToolPanelFormBuilder: React.FunctionComponent<ToolPanelFormBuilderProps> = forwardRef(
  function ToolPanelFormBuilder(props, ref) {
    return <FormBuilder {...props} ref={ref} />;
  },
);
