import React, { useState } from 'react';
import _ from 'lodash';
import { useAccordionToggle } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Icon } from '@seeqdev/qomponents';

interface CustomToggleProps {
  eventKey: string;
  label: string | React.ReactElement;
  showDivider?: boolean;
  Component: (...args: any[]) => JSX.Element;
}

/**
 * Custom toggle handler for react-bootstrap accordion
 */
export const CustomToggle: React.FunctionComponent<CustomToggleProps> = ({
  eventKey,
  label,
  showDivider = false,
  Component,
}) => {
  const [isCardOpen, setCardOpen] = useState(false);

  const decoratedOnClick = useAccordionToggle(eventKey, () => setCardOpen(!isCardOpen));

  return Component({
    onClick: decoratedOnClick,
    isCardOpen,
    label,
    showDivider,
  });
};

type TogglerProps = {
  isCardOpen: boolean;
  onClick: (e: any) => void;
  label: string | React.ReactElement;
  showDivider: boolean;
};

export const Toggler = ({ isCardOpen, onClick, label, showDivider }: TogglerProps) => {
  const { t } = useTranslation();

  return (
    <>
      <h4 className="flexColumnContainer flexSpaceBetween flexAlignCenter cursorPointer m0" onClick={onClick}>
        {_.isString(label) ? <span>{t(label)}</span> : label}
        <Icon icon={isCardOpen ? 'fa-angle-down' : 'fa-chevron-right'} testId="togglerIcon" extraClassNames="ml5" />
      </h4>
      {showDivider && <hr className="mt10 mb10" />}
    </>
  );
};
