// @ts-strict-ignore
import _ from 'lodash';
import { SEARCH_ITEM_LOCATIONS } from '@/main/app.constants';
import { InitializeMode, PersistenceLevel, Store } from '@/core/flux.service';

export class ExploreWorkbookModalStore extends Store {
  persistenceLevel: PersistenceLevel = 'WORKSHEET';
  static readonly storeName = 'sqExploreWorkbookModalStore';

  initialize(initializeMode?: InitializeMode) {
    const saveState = this.state && initializeMode !== 'FORCE';
    this.state = this.immutable({
      initialFolderId: undefined,
      tableFilter: saveState ? this.state.get('tableFilter') : SEARCH_ITEM_LOCATIONS.RECENT,
      itemTotalForTable: saveState ? this.state.get('itemTotalForTable') : 0,
      pageSize: saveState ? this.state.get('pageSize') : 5,
      pageNumber: saveState ? this.state.get('pageNumber') : 1,
      searchParams: saveState ? this.state.get('searchParams') : { types: ['Folder', 'Analysis'] },
      items: saveState ? this.state.get('items') : [],
      loading: saveState ? this.state.get('loading') : false,
      folder: saveState ? this.state.get('folder') : undefined,
      sortAscending: saveState ? this.state.get('sortAscending') : false,
      sortProperty: saveState ? this.state.get('sortProperty') : 'openedAt',
      showResults: saveState ? this.state.get('showResults') : false,
      workbookId: '',
      tableRoot: undefined,
    });
  }

  get initialFolderId() {
    return this.state.get('initialFolderId');
  }

  get workbookId() {
    return this.state.get('workbookId');
  }

  get tableFilter() {
    return this.state.get('tableFilter');
  }

  get searchParams() {
    return this.state.get('searchParams');
  }

  get items() {
    return this.state.get('items');
  }

  get loading() {
    return this.state.get('loading');
  }

  get folder() {
    return this.state.get('folder');
  }

  get sortAscending() {
    return this.state.get('sortAscending');
  }

  get sortProperty() {
    return this.state.get('sortProperty');
  }

  get showResults() {
    return this.state.get('showResults');
  }

  get tableRoot() {
    return this.state.get('tableRoot');
  }

  getItemTotalForTable() {
    return this.state.get('itemTotalForTable');
  }

  getPageSizeByTable() {
    return this.state.get('pageSize');
  }

  getPageNumberForTable() {
    return this.state.get('pageNumber');
  }

  dehydrate() {
    return _.omit(this.state.serialize(), [
      'effectivePermissions',
      'workbookId',
      'searchParams',
      'items',
      'folder',
      'tableFilter',
      'itemTotalForTable',
      'pageNumber',
      'sortAscending',
      'sortProperty',
      'loading',
      'showResults',
      'tableRoot',
    ]);
  }

  rehydrate(dehydratedState) {
    this.state.merge(dehydratedState);
  }

  protected readonly handlers = {
    /**
     * Clears the state for the currently loaded seeq content image.
     */
    EXPLORE_WORKBOOK_MODAL_CLEAR: () => {
      this.initialize();
    },

    EXPLORE_WORKBOOK_MODAL_SET_INITIAL_FOLDER_ID: (payload: { initialFolderId: string }) => {
      this.state.set('initialFolderId', payload.initialFolderId);
    },

    /**
     * Sets the workbook ID
     *
     * @param {Object} payload - Object container
     * @param {String} payload.workbookId - a workbook ID
     */
    EXPLORE_WORKBOOK_MODAL_SET_WORKBOOK_ID: (payload: { workbookId: string }) => {
      this.state.set('workbookId', payload.workbookId);
    },

    EXPLORE_WORKBOOK_MODAL_SET_TABLE_FILTER: (payload: { tableFilter: string }) => {
      this.state.set('tableFilter', payload.tableFilter);
    },

    EXPLORE_WORKBOOK_MODAL_SET_ITEM_TOTAL_FOR_TABLE: (payload: { itemTotalForTable: number }) => {
      this.state.set('itemTotalForTable', payload.itemTotalForTable);
    },

    EXPLORE_WORKBOOK_MODAL_SET_PAGE_SIZE: (payload: { pageSize: number }) => {
      this.state.set('pageSize', payload.pageSize);
    },

    EXPLORE_WORKBOOK_MODAL_SET_PAGE_NUMBER: (payload: { pageNumber: number }) => {
      this.state.set('pageNumber', payload.pageNumber);
    },

    EXPLORE_WORKBOOK_MODAL_SET_ITEMS: (payload: { items: { id: number; name: string }[] }) => {
      this.state.set('items', payload.items);
    },

    EXPLORE_WORKBOOK_MODAL_SET_SEARCH_PARAMS: ({ field, value }: { field: string; value: string }) => {
      const searchParams = this.state.get('searchParams');

      if (value === '') {
        this.state.set('searchParams', _.omit(searchParams, field));
      } else {
        this.state.set('searchParams', _.assign({}, searchParams, { [field]: value }));
      }
    },

    EXPLORE_WORKBOOK_MODAL_SET_LOADING: (payload: { loading: boolean }) => {
      this.state.set('loading', payload.loading);
    },

    EXPLORE_WORKBOOK_MODAL_SET_FOLDER: (payload: { folder: string }) => {
      this.state.set('folder', payload.folder);
    },

    EXPLORE_WORKBOOK_MODAL_SET_SORT: (payload: { sortAscending: boolean; sortProperty: string }) => {
      this.state.merge(_.pick(payload, ['sortAscending', 'sortProperty']));
    },

    EXPLORE_WORKBOOK_MODAL_SET_SHOW_RESULTS: (payload: { showResults: boolean }) => {
      this.state.set('showResults', payload.showResults);
    },

    EXPLORE_WORKBOOK_MODAL_SET_TABLE_ROOT: (payload: { root: string }) => {
      this.state.set('tableRoot', payload.root);
    },
  };
}
