// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import { KEY_CODES } from '@/main/app.constants';

export interface FormulaFullListViewProps {
  functions: {
    name: string;
    documentationHref: string;
    returnType: string;
    shortDescription: string;
  }[];
  functionFilter?: string;
  requestDocumentation: any;
}

export const FormulaFullListView: React.FunctionComponent<FormulaFullListViewProps> = ({
  functions,
  functionFilter,
  requestDocumentation,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {_.map(functions, (func, index) => (
        <div
          className="itemRow hoverable cursorPointer pt10 pb10 pl5"
          key={index}
          tabIndex={0}
          data-testid="fullListRow"
          onClick={() => requestDocumentation(func.documentationHref)}
          onKeyUp={(e) => e.keyCode === KEY_CODES.ENTER && requestDocumentation(func.documentationHref)}>
          <span className="sq-fairly-dark-gray">{func.returnType}</span>
          <a className="force-link-look">
            <strong>
              <Highlighter
                highlightClassName="highlight-search p0 sq-text-primary"
                autoEscape={true}
                searchWords={[functionFilter] ?? []}
                textToHighlight={func.name}
              />
            </strong>
          </a>
          &nbsp;-&nbsp;
          <span className="functionDescription">
            <Highlighter
              highlightClassName="highlight-search p0"
              autoEscape={true}
              searchWords={[functionFilter] ?? []}
              textToHighlight={func.shortDescription}
            />
          </span>
        </div>
      ))}

      {functions.length === 0 && <div className="text-center text-italic">{t('FORMULA.NO_SEARCH_RESULTS')}</div>}
    </>
  );
};
