// @ts-strict-ignore
import { sqDatafilesApi } from '@/sdk/api/DatafilesApi';
import _ from 'lodash';
import { DISPLAY_MODE } from '@/main/app.constants';
import { getAllItems } from '@/trend/trendDataHelper.utilities';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { FieldDelimiterEnum, ItemTypeEnum } from '@/sdk/model/DatafileInputV1';
import { sqTimezones } from '@/utilities/datetime.constants';
import { flux } from '@/core/flux.module';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import {
  COLUMN_INDEX,
  COLUMN_NAME,
  CONDITION_VALUE_COLUMN_OPTIONS,
  DATAFILE_ITEM_TYPES,
  DAY_MONTH_FIRST_OPTIONS,
  DELIMITERS,
  EMPTY_ROW_IDENTIFIER,
  FILE_OUTPUT_TYPES,
  INTERPOLATION_METHODS,
  SIGNAL_VALUE_COLUMN_OPTIONS,
  VALIDATION_MODES,
} from '@/tools/importDatafile/importDatafile.constants';
import { sqImportDatafileStore, sqWorkbenchStore } from '@/core/core.stores';
import { setActiveTool } from '@/toolSelection/investigate.actions';
import { fetchItemAndDependents } from '@/trendData/trend.actions';
import { tabsetChangeTab } from '@/worksheet/worksheet.actions';
import { ValidationModeEnum } from '@/sdk/model/DatafileOutputV1';
import { ValueWithUnitsItem } from '@/trend/ValueWithUnits.atom';

type CreateRehydrate = {
  filename: string;
  description: string;
  fieldDelimiter: FieldDelimiterEnum;
  append: boolean;
  firstDataRow: number;
  interpolationMethod: string;
  interpolationMethodRow: number;
  keyColumnIndex: number;
  keyColumnName: string;
  maximumInterpolation: string;
  maximumInterpolationRow: number;
  namePrefix: string;
  nameSuffix: string;
  nameRow: number;
  timeZone: string;
  valueUomRow: number;
  validationMode: ValidationModeEnum;
  lenientDaylightSavings: boolean;
  descriptionRow: number;
  valueColumnIndices: string;
  valueColumnNames: string;
  valueUom: string;
  maximumDuration: string;
  itemType: ItemTypeEnum;
  endColumnIndex: number;
  endColumnName: string;
  conditionName: string;
  dayFirstDefault: boolean;
};

/**
 * Sets the name of the file that the server returned after uploading it.
 *
 * @param {String} uploadFilename - The name of the file that the server returned after upload
 */
export function setUploadFilename(uploadFilename: string) {
  flux.dispatch('IMPORTDATAFILE_SET_UPLOAD_FILE_NAME', { uploadFilename });
}

/**
 * Sets the name of the file on the client's computer. Only used to help them know what file they used when they
 * created/updated the datafile.
 *
 * @param {String} filename - The name of the file on the client
 */
export function setFilename(filename: string) {
  flux.dispatch('IMPORTDATAFILE_SET_FILE_NAME', { filename });
}

/**
 * Sets the field delimiter for the file
 *
 * @param {String} fieldDelimiter - The character used as the field delimiter
 */
export function setFieldDelimiter(fieldDelimiter: { value: string }) {
  flux.dispatch('IMPORTDATAFILE_SET_FIELD_DELIMITER', { fieldDelimiter });
}

/**
 * Sets the file output type (append or replace)
 *
 * @param fileOutputType - The type used for the file output
 */
export function setFileOutputType(fileOutputType: { text: string; value: string }) {
  if (_.includes(FILE_OUTPUT_TYPES, fileOutputType)) {
    flux.dispatch('IMPORTDATAFILE_SET_FILE_OUTPUT_TYPE', { fileOutputType });
  }
}

/**
 * Sets the header row.
 *
 * @param {Number} nameRow - The row index to use for the name/header
 */
export function setNameRow(nameRow: number) {
  flux.dispatch('IMPORTDATAFILE_SET_NAME_ROW', { nameRow });
}

/**
 * Sets the key column's index.
 *
 * @param {Number} keyColumnIndex - The column index to use for the key
 */
export function setKeyColumnIndex(keyColumnIndex: number) {
  flux.dispatch('IMPORTDATAFILE_SET_KEY_COLUMN_INDEX', { keyColumnIndex });
}

/**
 * Sets the key column's name.
 *
 * @param {Number} keyColumnName - The column name to use for the key
 */
export function setKeyColumnName(keyColumnName: number) {
  flux.dispatch('IMPORTDATAFILE_SET_KEY_COLUMN_NAME', { keyColumnName });
}

/**
 * Sets the interpolation method (linear or step).
 *
 * @param {Object} interpolationMethod - The interpolation method to use
 */
export function setInterpolationMethod(interpolationMethod: { text: string; value: string }) {
  if (_.includes(INTERPOLATION_METHODS, interpolationMethod)) {
    flux.dispatch('IMPORTDATAFILE_SET_INTERPOLATION_METHOD', {
      interpolationMethod,
    });
  }
}

/**
 * Sets the maximum interpolation.
 *
 * @param {Object} maxInterpolation - The max interpolations
 */
export function setMaxInterpolation(maxInterpolation: ValueWithUnitsItem) {
  flux.dispatch('IMPORTDATAFILE_SET_MAX_INTERPOLATION', { maxInterpolation });
}

/**
 * Sets signal name prefix.
 *
 * @param {String} namePrefix - The prefix for the signal name
 */
export function setNamePrefix(namePrefix: number) {
  flux.dispatch('IMPORTDATAFILE_SET_NAME_PREFIX', { namePrefix });
}

/**
 * Sets the signal name nameSuffix
 *
 * @param {number} nameSuffix - The nameSuffix for the signal name
 */
export function setNameSuffix(nameSuffix: number) {
  flux.dispatch('IMPORTDATAFILE_SET_NAME_SUFFIX', { nameSuffix });
}

/**
 * Sets the first row with data.
 *
 * @param {Number} firstDataRow - The index of the first row with data
 */
export function setFirstDataRow(firstDataRow: number) {
  flux.dispatch('IMPORTDATAFILE_SET_FIRST_DATA_ROW', { firstDataRow });
}

/**
 * Sets the max interpolation row.
 *
 * @param {Number} maxInterpolationRow - The index of the max interpolation maxInterpolationRow
 */
export function setMaxInterpolationRow(maxInterpolationRow: number) {
  flux.dispatch('IMPORTDATAFILE_SET_MAX_INTERPOLATION_ROW', {
    maxInterpolationRow,
  });
}

/**
 * Sets the interpolation method row
 *
 * @param {Number} interpolationMethodRow - The index of the interpolation method interpolationMethodRow
 */
export function setInterpolationMethodRow(interpolationMethodRow: number) {
  flux.dispatch('IMPORTDATAFILE_SET_INTERPOLATION_METHOD_ROW', {
    interpolationMethodRow,
  });
}

/**
 * Sets the value uom row.
 *
 * @param {Number} valueUomRow - The index of the uom row
 */
export function setValueUomRow(valueUomRow: number) {
  flux.dispatch('IMPORTDATAFILE_SET_VALUE_UOM_ROW', { valueUomRow });
}

/**
 * Sets the timezone to use with the timestamps
 *
 * @param {Object} timezone - The timezone to use with the timestamps
 */
export function setTimezone(timezone) {
  flux.dispatch('IMPORTDATAFILE_SET_TIMEZONE', { timezone });
}

/**
 * Sets how to handle invalid data in the CSV
 *
 * @param {Object} validationMode - Tells which way to handle invalid data
 */
export function setValidationMode(validationMode: { text: string; value: ValidationModeEnum }) {
  if (_.includes(VALIDATION_MODES, validationMode)) {
    flux.dispatch('IMPORTDATAFILE_SET_VALIDATION_MODE', { validationMode });
  }
}

/**
 * Sets whether daylight savings time is treated leniently or not
 *
 * @param {boolean} lenientDaylightSavings - True to treat as lenient, false otherwise
 */
export function setLenientDaylightSavings(lenientDaylightSavings: boolean) {
  flux.dispatch('IMPORTDATAFILE_SET_LENIENT_DAYLIGHT_SAVINGS', {
    lenientDaylightSavings,
  });
}

/**
 * Sets the index of the description row
 *
 * @param {Number} descriptionRow- The index of the description row
 */
export function setDescriptionRow(descriptionRow: number) {
  flux.dispatch('IMPORTDATAFILE_SET_DESCRIPTION_ROW', { descriptionRow });
}

/**
 * Sets the column indices which contain signal values
 *
 * @param {Number[]} valueColumnIndices- The array of column indexes which contain signal values. Starts from 1.
 */
export function setValueColumnIndices(valueColumnIndices: number) {
  flux.dispatch('IMPORTDATAFILE_SET_VALUE_COLUMN_INDICES', {
    valueColumnIndices,
  });
}

/**
 * Sets the column names which contain signal values
 *
 * @param {Number[]} valueColumnNames- The array of column names which contain signal values.
 */
export function setValueColumnNames(valueColumnNames: number) {
  flux.dispatch('IMPORTDATAFILE_SET_VALUE_COLUMN_NAMES', {
    valueColumnNames,
  });
}

/**
 * Sets the description for the datafile
 *
 * @param {number} description - description of the datafile
 */
export function setDescription(description: number) {
  flux.dispatch('IMPORTDATAFILE_SET_DESCRIPTION', { description });
}

/**
 * Sets the data column option
 *
 * @param {Object} valueColumnOption - the data column option
 */
export function setValueColumnOption(valueColumnOption: { text: string; value: string }) {
  let optionsToCheck;
  if (sqImportDatafileStore.isSignalSelected()) {
    optionsToCheck = SIGNAL_VALUE_COLUMN_OPTIONS;
  } else if (sqImportDatafileStore.isConditionSelected()) {
    optionsToCheck = CONDITION_VALUE_COLUMN_OPTIONS;
  }
  if (_.includes(optionsToCheck, valueColumnOption)) {
    flux.dispatch('IMPORTDATAFILE_SET_VALUE_COLUMN_OPTION', {
      valueColumnOption,
    });
  }
}

/**
 * Sets the uom for the whole datafile
 *
 * @param {String} valueUom - the uom
 */
export function setValueUom(valueUom: string) {
  flux.dispatch('IMPORTDATAFILE_SET_VALUE_UOM', { valueUom });
}

/**
 * Sets the maximum duration
 *
 * @param {object} maxDuration - the maximum duration
 */
export function setMaxDuration(maxDuration: ValueWithUnitsItem) {
  flux.dispatch('IMPORTDATAFILE_SET_MAX_DURATION', { maxDuration });
}

/**
 * Sets the item type
 *
 * @param itemType - the type of the item (signal or condition). One of DATAFILE_ITEM_TYPES.
 */
export function setItemType(itemType: { text: string; value: ItemTypeEnum }) {
  if (_.includes(DATAFILE_ITEM_TYPES, itemType)) {
    flux.dispatch('IMPORTDATAFILE_SET_ITEM_TYPE', { itemType });
  }
}

/**
 * Sets the end column index
 *
 * @param {number} endColumnIndex - the end column index
 */
export function setEndColumnIndex(endColumnIndex: number) {
  flux.dispatch('IMPORTDATAFILE_SET_END_COLUMN_INDEX', { endColumnIndex });
}

/**
 * Sets the end column name
 *
 * @param {String} endColumnName - the end column name
 */
export function setEndColumnName(endColumnName: number) {
  flux.dispatch('IMPORTDATAFILE_SET_END_COLUMN_NAME', { endColumnName });
}

/**
 * Sets the condition name
 *
 * @param {String} conditionName - the name of the condition that will be created by the import
 */
export function setConditionName(conditionName: string) {
  flux.dispatch('IMPORTDATAFILE_SET_CONDITION_NAME', { conditionName });
}

/**
 * Sets the dayFirstDefault
 *
 * @param {Object} dayFirstDefault - one of DAY_MONTH_FIRST_OPTIONS
 */
export function setDayFirstDefault(dayFirstDefault: { text: string; value: string }) {
  flux.dispatch('IMPORTDATAFILE_SET_DAY_FIRST_DEFAULT', { dayFirstDefault });
}

/**
 * Creates a datafile in the backend using the passed in parameters.
 *
 * @returns {Promise} - A promise for the response from creating a datafile
 */
export function createDatafile() {
  return sqDatafilesApi.createDatafile({
    ...sqImportDatafileStore.asApiObject(),
    scopedTo: sqWorkbenchStore.stateParams.workbookId,
  });
}

/**
 * Updates a datafile in the backend using the passed in parameters.
 *
 * @return {Promise} - A promise for the response from editing a datafile
 */
export function updateDatafile() {
  return sqDatafilesApi.updateDatafile(
    {
      ...sqImportDatafileStore.asApiObject(),
      scopedTo: sqWorkbenchStore.stateParams.workbookId,
    },
    { id: sqImportDatafileStore.id },
  );
}

/**
 * Creates the payload for a rehydrateForEdit importdatafile store call
 *
 * @param {Object} data - the datafile output to turn into a rehydrate for edit payload
 * @returns {Object} - a rehydrate for edit payload
 */
export function createRehydratePayload(data: CreateRehydrate) {
  // Splits the max interpolation/duration string based on where the numbers are.
  const valueWithUnitsSplitter = (valueWithUnits) =>
    _.chain(valueWithUnits)
      .split(/(\d+)/)
      .thru(([data, value, units]) => ({ value: parseInt(value, 10), units }))
      .value();
  const maxInterpolation = valueWithUnitsSplitter(data.maximumInterpolation);
  const maxDuration = valueWithUnitsSplitter(data.maximumDuration);
  let valueColumnNames;
  let valueColumnIndices;
  let valueColumnOption;
  if (data.valueColumnNames !== '') {
    valueColumnNames = data.valueColumnNames;
    valueColumnIndices = undefined;
    valueColumnOption = COLUMN_NAME;
  } else if (data.valueColumnIndices !== '') {
    valueColumnNames = undefined;
    valueColumnIndices = data.valueColumnIndices;
    valueColumnOption = COLUMN_INDEX;
  } else {
    valueColumnNames = undefined;
    valueColumnIndices = undefined;
    valueColumnOption = _.first(SIGNAL_VALUE_COLUMN_OPTIONS);
  }

  return {
    filename: data.filename,
    description: data.description,
    endColumnIndex: data.endColumnIndex,
    endColumnName: data.endColumnName,
    fieldDelimiter: _.find(DELIMITERS, { value: data.fieldDelimiter }),
    fileOutputType: data.append === false ? _.first(FILE_OUTPUT_TYPES) : _.last(FILE_OUTPUT_TYPES),
    firstDataRow: data.firstDataRow,
    interpolationMethod: _.find(INTERPOLATION_METHODS, {
      value: data.interpolationMethod,
    }),
    interpolationMethodRow:
      data.interpolationMethodRow === EMPTY_ROW_IDENTIFIER ? undefined : data.interpolationMethodRow,
    keyColumnIndex: data.keyColumnIndex,
    keyColumnName: data.keyColumnName,
    maxDuration,
    maxInterpolation,
    maxInterpolationRow:
      data.maximumInterpolationRow === EMPTY_ROW_IDENTIFIER ? undefined : data.maximumInterpolationRow,
    namePrefix: data.namePrefix,
    nameSuffix: data.nameSuffix,
    nameRow: data.nameRow,
    timezone: _.find(sqTimezones.timezones, { displayName: data.timeZone }),
    valueUomRow: data.valueUomRow === EMPTY_ROW_IDENTIFIER ? undefined : data.valueUomRow,
    validationMode: _.find(VALIDATION_MODES, { value: data.validationMode }),
    lenientDaylightSavings: data.lenientDaylightSavings,
    descriptionRow: data.descriptionRow === EMPTY_ROW_IDENTIFIER ? undefined : data.descriptionRow,
    valueColumnIndices,
    valueColumnNames,
    valueColumnOption,
    valueUom: data.valueUom,
    type: TREND_TOOLS.IMPORTDATAFILE,
    itemType: _.find(DATAFILE_ITEM_TYPES, { value: data.itemType }),
    conditionName: data.conditionName,
    dayFirstDefault: _.find(DAY_MONTH_FIRST_OPTIONS, {
      value: data.dayFirstDefault,
    }),
  };
}

/**
 * Load a datafile from appserver to edit its parameters, or optionally duplicate the file's settings
 *
 * @param {String} id - The id of the datafile to edit
 * @param {boolean} duplicate - Whether or not the file is being loaded to duplicate
 * @returns {Promise} - Promise from getting the datafile
 */
export function loadDatafile(id: string, duplicate = false) {
  return sqDatafilesApi.getDatafile({ id }).then(({ data }) => {
    const payload: any = exposedForTesting.createRehydratePayload(data as CreateRehydrate);
    if (!duplicate) {
      payload.id = data.id;
      payload.name = data.name;
    }
    flux.dispatch('TOOL_REHYDRATE_FOR_EDIT', payload);
    tabsetChangeTab('sidebar', 'investigate');
    setActiveTool(TREND_TOOLS.IMPORTDATAFILE, DISPLAY_MODE.EDIT);
  });
}

/**
 * Archives a datafile and all of its hosted items
 *
 * @param {String} id - The id of the datafile being archived
 * @returns {Promise} - a promise that contains the response from archiving the datafile
 */
export function archiveDatafile(id: string) {
  return sqDatafilesApi.archiveDatafile({ id });
}

/**
 * Get the datafile via a promise
 *
 * @param {string} id - the id of the datafile
 * @returns {Promise} - a promise that returns the datafile
 */
export function getDatafile(id: string) {
  return sqDatafilesApi.getDatafile({ id });
}

/**
 * Refreshes all items hosted by the datafile and all dependent items
 *
 * @param {string} id - the id of the datafile
 */
export function refreshDatafileItems(id: string) {
  sqDatafilesApi.getItemsHostedByDatafile({ id }).then(({ data: { items } }) => {
    const trendItemsIds = _.map(getAllItems({}), 'id');
    const datafileItemIds = _.map(items, 'id');
    _.chain(trendItemsIds)
      .map((id) =>
        _.includes(datafileItemIds, id)
          ? Promise.resolve(id)
          : sqItemsApi.getFormulaDependencies({ id }).then(({ data: { dependencies } }) =>
              _.chain(dependencies)
                .map('id')
                .intersection(datafileItemIds)
                .thru((intersections) => (intersections.length ? id : null))
                .value(),
            ),
      )
      .thru((promises) => Promise.all(promises))
      .value()
      .then((ids) =>
        _.chain(ids)
          .compact()
          .uniq()
          .forEach((id) => fetchItemAndDependents(id))
          .value(),
      );
  });
}

export const exposedForTesting = {
  createRehydratePayload,
};
