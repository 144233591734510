// @ts-strict-ignore
import { useContext, useEffect } from 'react';
import { useForceUpdate } from '@/core/hooks/useForceUpdate.hook';
import { FluxContext } from '@/core/flux.context';
import { BaobabEvent } from '@/core/flux.types';

/**
 * This hook enables using data from a flux store in React such that the component will be re-rendered when
 * the store changes.
 *
 * Performance note: This hook will cause the component to render for _any_ change in the store regardless of the
 * properties actually used. If other portions of the store change frequently, then this hook may cause unnecessary
 * performance overhead. The `useFluxPath` hook can be used to mitigate this by only listening for changes within
 * a path in the store.
 *
 * @example
 * const Component = ({ sqTrendStore }) => {
 *   const { view, selectedRegion } = useFlux(sqTrendStore);
 *   return <OtherComponent view={view} selectedRegion={selectedRegion} targetView={TREND_VIEWS.CALENDAR} />
 * }
 *
 * @example
 * const Component = ({ sqTrendStore }) => {
 *   // using the return value is not required, but helps with readability - don't forget to `useFlux` for all
 *   // values from stores otherwise the component won't update when values change
 *   useFlux(sqTrendStore);
 *   return <OtherComponent
 *     view={sqTrendStore.view}
 *     selectedRegion={sqTrendStore.selectedRegion}
 *     targetView={TREND_VIEWS.CALENDAR} />
 * }
 *
 * @param store - The flux store export object that will be passed to flux.listenTo
 * @return the store passed in will be returned to enable destructuring the desired parameters
 */
export function useFlux<T>(store: T, callback?: (e: BaobabEvent) => void, shouldforceUpdateInCallback = false): T {
  const flux = useContext(FluxContext);
  const forceUpdate = useForceUpdate();
  const callbackFn = callback
    ? (e: BaobabEvent) => {
        shouldforceUpdateInCallback && forceUpdate();
        callback(e);
      }
    : forceUpdate;

  useEffect(() => {
    flux.listenTo(store, undefined, callbackFn, false);
  }, [flux, store]);

  return store;
}
