import _ from 'lodash';
import { evaluateVisualizationOptions } from '@/annotation/reportContent.utilities';
import { flux } from '@/core/flux.module';
import { PUSH_IGNORE } from '@/core/flux.service';
import {
  CONTENT_STATE,
  DEFAULT_SHAPE_KEY,
  DEFAULT_SIZE_KEY,
  REPORT_CONTENT,
  ReportContentShape,
  ReportContentSize,
  ReportContentSummary,
} from '@/reportEditor/report.constants';
import { sqReportContentStore } from '@/core/core.stores';

interface ContentAttributes {
  // value to set as the workbook id
  workbookId: string;
  // value to set as the worksheet id
  worksheetId: string;
  // value to set as the workstep id
  workstepId: string;
  // ID for the content. May be empty if new and hasn't been saved to API
  id: string;
  // a height in pixels
  height: number;
  // a width in pixels
  width: number;
  // scale for font size
  scale: string;
  // ID of the date range used by this content
  dateRangeId: string;
  // ID of the asset selection used by this content
  assetSelectionId: string;
  // true if the size of the screenshot is not defined by the user
  useSizeFromRender: boolean;
  sourceUrl: string;
  summary: ReportContentSummary;
  isReact: boolean;
}

/**
 * Sets all the parameters of a piece of report content to the store at once
 * @returns - A promise that resolves when the report content store is populated
 */
export function setContent(attrs: ContentAttributes): Promise<void> {
  return evaluateVisualizationOptions(attrs.workbookId, attrs.worksheetId, attrs.workstepId).then(() => {
    setContentId(attrs.id);
    setWorkbookId(attrs.workbookId);
    setWorksheetId(attrs.worksheetId);
    setWorkstepId(attrs.workstepId);
    setSourceUrl(attrs.sourceUrl);
    setDateRangeId(attrs.dateRangeId);
    setScale(attrs.scale);
    setSummary(attrs.summary);
    setAssetSelectionId(attrs.assetSelectionId);
    setIsReact(attrs.isReact);

    // Pick the correct size/shape key from the width/height
    const width = attrs.width;
    const height = attrs.height;

    const { size, shape } = calculateSizeAndShape(width, height);

    if (shape) {
      setShape(shape.key, false);
    }
    setSize(size.key);

    setWidth(width);
    setHeight(height);
  });
}

/**
 * @param contentId - ID of this content
 */
export function setContentId(contentId: string): void {
  flux.dispatch('REPORT_CONTENT_SET_CONTENT_ID', contentId, PUSH_IGNORE);
}

/**
 * @param workbookId - a workbook ID
 */
export function setWorkbookId(workbookId: string): void {
  flux.dispatch('REPORT_CONTENT_SET_WORKBOOK_ID', workbookId, PUSH_IGNORE);
}

/**
 * @param modalName - modal name that should be opened
 */
export function setModalName(modalName: CONTENT_STATE | ''): void {
  flux.dispatch('REPORT_CONTENT_SET_MODAL_NAME', modalName, PUSH_IGNORE);
}

/**
 * @param worksheetId - a worksheet ID
 */
export function setWorksheetId(worksheetId: string): void {
  flux.dispatch('REPORT_CONTENT_SET_WORKSHEET_ID', worksheetId, PUSH_IGNORE);
}

/**
 * Set the report content scale. One of REPORT_CONTENT.SCALE keys
 *
 * @param scale - a scale key
 */
export function setScale(scale: string): void {
  flux.dispatch('REPORT_CONTENT_SET_SCALE', { scale }, PUSH_IGNORE);
}

/**
 * Sets the summary for the content. One of REPORT_CONTENT.SUMMARY, with a potentially modified value
 */
export function setSummary(summary: ReportContentSummary): void {
  flux.dispatch('REPORT_CONTENT_SET_SUMMARY', { summary }, PUSH_IGNORE);
}

export function setAssetSelectionId(id: string): void {
  flux.dispatch('REPORT_CONTENT_SET_ASSET_SELECTION_ID', id, PUSH_IGNORE);
}

/**
 * @param workstepId - a workstep ID
 */
export function setWorkstepId(workstepId: string): void {
  flux.dispatch('REPORT_CONTENT_SET_WORKSTEP_ID', workstepId, PUSH_IGNORE);
}

/**
 * @param width - width of content, in pixels
 */
export function setWidth(width: number): void {
  flux.dispatch('REPORT_CONTENT_SET_WIDTH', width, PUSH_IGNORE);
}

/**
 * @param height - height of content, in pixels
 */
export function setHeight(height: number): void {
  flux.dispatch('REPORT_CONTENT_SET_HEIGHT', height, PUSH_IGNORE);
}

/**
 * @param sizeKey - key from REPORT_CONTENT.SIZE object
 */
export function setSize(sizeKey: string): void {
  flux.dispatch('REPORT_CONTENT_SET_SIZE_KEY', sizeKey, PUSH_IGNORE);
  updateWidthHeightFromSizeShape();
}

/**
 * @param shapeKey - key from REPORT_CONTENT.SHAPE object
 * @param updateHeightWidth - whether or not to update the height and width
 */
export function setShape(shapeKey: string, updateHeightWidth = true): void {
  flux.dispatch('REPORT_CONTENT_SET_SHAPE_KEY', shapeKey, PUSH_IGNORE);
  if (updateHeightWidth) updateWidthHeightFromSizeShape();
}

/**
 * Helper function to automatically update the width/height properties based on the current store settings for
 * size/shape.
 */
export function updateWidthHeightFromSizeShape(): void {
  const size = sqReportContentStore.sizeConstant || DEFAULT_SIZE_KEY;
  if (size.key === REPORT_CONTENT.SIZE.CUSTOM.key) return;

  const shape = sqReportContentStore.shapeConstant || DEFAULT_SHAPE_KEY;
  setWidth(size.width);
  setHeight(calculateHeight(size.width, shape));
}

/**
 * Sets the id of the date range used for this content
 *
 * @param id - id of a dateRange
 */
export function setDateRangeId(id: string): void {
  flux.dispatch('REPORT_CONTENT_SET_DATE_RANGE_ID', id, PUSH_IGNORE);
}

/**
 * Sets the source URL
 *
 * @param sourceUrl - a URL to the worksheet from which the content was generated
 */
export function setSourceUrl(sourceUrl: string | undefined): void {
  flux.dispatch('REPORT_CONTENT_SET_SOURCE_URL', sourceUrl, PUSH_IGNORE);
}

/**
 * Set the boolean flag that indicates whether or not the content screenshot should be based on the size of the
 * content.
 *
 * @param useSizeFromRender - true if the content screenshot should be based on the size of the
 *   content. See worksheet.module.js for a description of how a view can be configured to support screenshots
 *   that are sized to the content.
 */
export function setUseSizeFromRender(useSizeFromRender: boolean): void {
  flux.dispatch('REPORT_CONTENT_SET_USE_SIZE_FROM_RENDER', useSizeFromRender, PUSH_IGNORE);
}

/**
 * Sets whether or not the specific content can use react JSON
 */
export function setCanUseReact(canUseReact: boolean): void {
  flux.dispatch('REPORT_CONTENT_SET_CAN_USE_REACT', canUseReact, PUSH_IGNORE);
}

/**
 * Sets if the content will use the React JSON blob for visualizations as opposed to the image
 */
export function setIsReact(isReact: boolean): void {
  flux.dispatch('REPORT_CONTENT_SET_IS_REACT', isReact, PUSH_IGNORE);
}

/**
 * Clears the data for the currently loaded seeq content image.
 */
export function clearReportContent(): void {
  flux.dispatch('REPORT_CONTENT_CLEAR', undefined, PUSH_IGNORE);
}

/**
 * Clears ALL the data for the currently loaded seeq content image.
 */
export function forceClearReportContent(): void {
  flux.dispatch('REPORT_CONTENT_CLEAR_FORCE', undefined, PUSH_IGNORE);
}

/**
 * Calculates the size and shape from the height and width
 * @returns - an object containing the size and shape
 */
export function calculateSizeAndShape(
  width: number,
  height: number,
): { size: ReportContentSize; shape: ReportContentShape | undefined } {
  let size = _.find(REPORT_CONTENT.SIZE, { width }) || REPORT_CONTENT.SIZE.CUSTOM;
  let shape;
  if (size !== REPORT_CONTENT.SIZE.CUSTOM) {
    shape = _.find(REPORT_CONTENT.SHAPE, (shape) => calculateHeight(size.width, shape) === height);
  }

  return { size, shape };
}

/**
 * Calculates the matching height for the given width/shape combo
 * @returns the height of the given width/shape combo
 */
export function calculateHeight(width: number, shape: { height: number; width: number }): number {
  return Math.round((width * shape.height) / shape.width);
}
