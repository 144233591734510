// @ts-strict-ignore
import _ from 'lodash';
import { PluginOutputV1 } from '@/sdk/model/PluginOutputV1';
import {
  ASSET_GROUP_VIEW,
  TABLE_DEFINITION_VIEW,
  WORKSHEET_TARGET,
  WORKSHEET_VIEWS,
  WorksheetView,
} from '@/worksheet/worksheet.constants';
import { sqLicenseManagementStore, sqPluginStore } from '@/core/core.stores';

/**
 * Returns all the views that are available for display on a worksheet.
 *
 * @returns {Object[]]} The collection of views
 */
export function viewsWorkSheetView() {
  return _.compact(
    _.concat(
      WORKSHEET_VIEWS,
      TABLE_DEFINITION_VIEW,
      sqLicenseManagementStore.hasAssetGroups() ? ASSET_GROUP_VIEW : null,
      pluginViews(),
    ),
  );
}

/**
 * Returns the default view.
 *
 * @returns {Object} The default view
 */
export function getDefault() {
  return _.find(WORKSHEET_VIEWS, 'default');
}

/**
 * Returns the view for the given key, or the default if it is not found.
 *
 * @param {String} key - A key that corresponds to WORKSHEET_VIEWS.key
 * @returns {Object} The view that corresponds to the key or the default
 */
export function findWorkSheetView(key): WorksheetView {
  return _.find(viewsWorkSheetView(), ['key', key]) || getDefault();
}

export function pluginViews(): WorksheetView[] {
  return _.chain(sqPluginStore.displayPanePlugins() as any)
    .map(createPluginWorksheetView)
    .sortBy(['name'])
    .value();
}

export function createPluginWorksheetView(plugin: PluginOutputV1): WorksheetView {
  return {
    key: plugin.identifier,
    selectedItemsRealm: 'TREND',
    target: WORKSHEET_TARGET.DATA_PANEL,
    name: plugin.name,
    icon: plugin.icon,
  };
}
