import { Message } from '@/aiAssistant/Message.molecule';
import { AgentType, AssistantOrigin, ChatMessage } from '@/aiAssistant/aiAssistant.types';
import React from 'react';
import { AiAssistantInformation } from '@/aiAssistant/AiAssistantInformation.atom';

interface AiAssistantChatViewProps {
  messages: ChatMessage[];
  insertFormulaSnippet: (snippet: string) => void;
  children?: React.ReactNode;
  isRunning: boolean;
  selectedAgent?: AgentType;
  onAgentChange?: (agent: AgentType, messageIdx: number) => void;
  origin: AssistantOrigin;
  submitPrompt: (prompt: string, origin: AssistantOrigin, context?: Record<string, unknown>) => void;
  scrollToBottom: () => void;
  showInformation?: boolean;
  onQuestionChange: (question: string, messageIdx: number) => void;
}

export const AiAssistantChatView = React.forwardRef<HTMLDivElement, AiAssistantChatViewProps>(
  (
    {
      isRunning,
      messages,
      insertFormulaSnippet,
      selectedAgent,
      onAgentChange,
      origin,
      submitPrompt,
      scrollToBottom,
      children,
      showInformation,
      onQuestionChange,
    },
    ref,
  ) => {
    const length = messages.length - 1;
    const [hiddenChatIds, setHiddenChatIds] = React.useState<string[]>([]);

    if (showInformation) return <AiAssistantInformation />;

    return (
      <div className="flexColumnContainer flexFill">
        {messages.length === 0 ? (
          <div className="flexRowContainer overflowAuto mt15 width-maximum">{children}</div>
        ) : (
          <div ref={ref} className="flexRowContainer overflowAuto pr5 width-maximum">
            {messages.map((message, idx) =>
              hiddenChatIds.includes(message.id) ? null : (
                <Message
                  key={message.id}
                  message={message}
                  isRunning={isRunning}
                  last={idx === length}
                  insertFormulaSnippet={insertFormulaSnippet}
                  selectedAgent={message.agentType ? (message.agentType as AgentType) : selectedAgent}
                  onAgentChange={(agent: AgentType) => {
                    onAgentChange && onAgentChange(agent, idx);
                  }}
                  origin={origin}
                  submitPrompt={submitPrompt}
                  scrollToBottom={scrollToBottom}
                  isHidingAnswer={hiddenChatIds.includes(messages[idx + 1]?.id)}
                  toggleAnswerVisibility={() =>
                    setHiddenChatIds((prev) => {
                      const answerId = messages[idx + 1]?.id;
                      if (!answerId) return prev;

                      if (prev.includes(answerId)) {
                        return prev.filter((id) => id !== answerId);
                      }
                      return [...prev, answerId];
                    })
                  }
                  onQuestionChange={(question) => onQuestionChange(question, idx)}
                />
              ),
            )}
          </div>
        )}
      </div>
    );
  },
);
