import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useParams, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { Header } from '@/header/Header.atom';
import { Footer } from '@/footer/Footer.molecule';
import { fetchEveryoneDisabled } from '@/services/systemConfiguration.utilities';
import { setCurrentUser, setStateParams, setTriggerConfetti } from '@/workbench/workbench.actions';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { open as openSocket } from '@/utilities/socket.utilities';
import { sqLicenseManagementStore, sqStateSynchronizer, sqWorkbenchStore, sqWorkbookStore } from '@/core/core.stores';
import { getCsrfToken } from '@/utilities/auth.utilities';
import { getWorkbench } from '@/workbench/workbench.utilities';
import {
  isAIStandalone,
  isWorkbooks,
  isWorksheet,
  navigateToCorrectErrorPage,
  setCurrentPath,
  wasWorksheet,
} from '@/main/routing.utilities';
import { LoadingBar } from '@/main/LoadingBar.molecule';
import { LicenseExpirationWarning } from '@/licenseManagement/LicenseExpirationWarning';
import { load as loadPlugins } from '@/plugin/plugin.actions';
import { SystemWarning } from '@/systemWarning/SystemWarning.organism';
import { checkLicenseStatus } from '@/licenseManagement/licenseManagement.actions';
import { TabTitle } from '@/core/TabTitle.molecule';
import { initializeRedactionService } from '@/utilities/redaction.utilities';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { onAllServerRequestsCanceled } from '@/services/notifier.service';
import { setupNotifierForWorkbook } from '@/workbook/workbook.actions';
import { setupNotifierForAnnotation } from '@/annotation/annotation.actions';
import { setupNotifierForHomescreen } from '@/homescreen/homescreen.actions';
import { successToast, warnToast } from '@/utilities/toast.utilities';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { getPendingRequestCount } from '@/requests/axios.utilities';
import { cancelAll, cancelRequestsOnUnload } from '@/requests/pendingRequests.utilities';
import { cleanup } from '@/trendData/duration.actions';
import { cleanupOnUnload } from '@/main/cleanup.utilities';
import { switchLanguage } from '@/utilities/i18n.utilities';
import { isPresentationWorkbookMode, switchModeAndTheme } from '@/utilities/utilities';
import { applyWorkbenchConfigUpgrade } from '@/workbench/workbenchUpgrader.utilities';
import { WORKBENCH_SCHEMA_VERSION } from '@/workbench/workbench.constants';
import { TConductorInstance } from 'react-canvas-confetti/dist/types';
import Fireworks from 'react-canvas-confetti/dist/presets/fireworks';
import { ResizableWindowWrapper } from '@/main/ResizableWindowWrapper';
import { isSystemTest } from '@/core/utilities';
import { fetchHeadlessCaptureMetadata } from '@/utilities/screenshot.utilities';

/**
 * Wrapper component contains all the components that need to access workbench state.
 * This component takes care of subscribing to websocket channels, setting up state in the stores ...
 */
export const WorkbenchWrapperPage: React.FunctionComponent = () => {
  let confettiTimeOutOne: ReturnType<typeof setTimeout>;
  let confettiTimeOutTwo: ReturnType<typeof setTimeout>;
  let confettiTimeOutThree: ReturnType<typeof setTimeout>;
  const [dataLoaded, setDataLoaded] = useState(false);
  const { pathname } = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const notDisplayingPresentationMode = !_.includes(pathname, 'present/');
  const notAuditTrail = !_.includes(pathname, '/auditTrail');
  const displayLicenseWarning = useFluxPath(
    sqLicenseManagementStore,
    () => sqLicenseManagementStore.displayLicenseWarning,
  );
  const [conductor, setConductor] = useState<TConductorInstance>();
  const onConfettiInit = ({ conductor }: { conductor: TConductorInstance }) => {
    setConductor(conductor);
  };

  useEffect(
    () => () => {
      clearTimeout(confettiTimeOutOne);
      clearTimeout(confettiTimeOutTwo);
      clearTimeout(confettiTimeOutThree);
    },
    [],
  );

  const triggerConfetti = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.triggerConfetti);
  if (triggerConfetti && !isSystemTest() && !headlessRenderMode()) {
    conductor?.shoot();
    confettiTimeOutOne = setTimeout(() => conductor?.shoot(), 1000);
    confettiTimeOutTwo = setTimeout(() => conductor?.shoot(), 1500);
    confettiTimeOutThree = setTimeout(() => conductor?.shoot(), 2000);
    if (sqWorkbenchStore.confettiMessageKey) {
      successToast({ messageKey: sqWorkbenchStore.confettiMessageKey });
    }
    setTriggerConfetti(false, '');
  }

  const isReport = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.isReportBinder);
  const darkMode = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.darkMode);
  useEffect(() => {
    switchModeAndTheme(darkMode, isReport || isWorkbooks() || !isWorksheet());
  }, [isReport, darkMode]);

  useEffect(() => {
    // The order of operations and the actions taken are important for preventing worksteps leaking!
    setCurrentPath(pathname);
    // Dismiss toasts when transitioning from homescreen to worksheet or vice versa
    if (wasWorksheet() !== isWorksheet()) {
      toast.dismiss();
    }
    const urlSearchParams = new URLSearchParams(searchParams);
    const searchParamsAsObject = Object.fromEntries(urlSearchParams);
    const newStateParams = { ...params, ...searchParamsAsObject };
    // If we are already transitioning, it is unsafe to start another transition without canceling or
    // otherwise letting the in progress transition finish normally. It isn't safe because loading most
    // states requires asynchronous requests that change global state (the stores), if two transitions are
    // running at the same time then that leads to race conditions concerning the final global state. The
    // only way to effectively cancel the transition is to cause the page to reload. This isn't ideal for
    // the user experience, but it is better than letting state from two worksheets mingle (CRAB-35397).
    if (
      wasWorksheet() &&
      (getPendingRequestCount() > 0 || sqStateSynchronizer.isRehydrating) &&
      sqWorkbenchStore.stateParams.worksheetId !== newStateParams.worksheetId
    ) {
      // The getPendingRequestCount() should ensure that if any requests remain the page is reloaded, but just
      // in case something was not picked up we cancel all outstanding requests that were initiated on the worksheet
      cancelRequestsOnUnload().finally(() => {
        window.location.assign(_.join(_.compact([pathname, urlSearchParams.toString()]), '?'));
      });
      return;
    }
    // Prevents reloading when the user double clicks
    if (!_.isEqual(sqWorkbenchStore.stateParams, newStateParams)) {
      if (wasWorksheet()) {
        // The getPendingRequestCount() should ensure that if any requests remain the page is reloaded, but just
        // in case something was not picked up we cancel all outstanding requests that were initiated on the worksheet
        cancelAll().finally(() => {
          // Ensures all websocket subscriptions are closed
          cleanupOnUnload();
          cleanup();
          setStateParams(newStateParams);
        });
      } else {
        setStateParams(newStateParams);
      }
    }
  }, [pathname, params, searchParams]);

  useEffect(() => {
    // set up notifiers
    setupNotifierForWorkbook();
    setupNotifierForAnnotation();
    setupNotifierForHomescreen();

    // Notify the user if an admin user cancels all server requests
    onAllServerRequestsCanceled(() => {
      warnToast({ messageKey: 'REQUEST_CANCELLATION.ALL_BY_ADMIN' });
    });

    initializeRedactionService();

    Promise.all([
      // Fetch initial data
      fetchEveryoneDisabled(),
      // setCurrentUser() must be before loading the plugins so admins only all in-development Add-ons
      setCurrentUser().then(() => loadPlugins().catch(_.noop)),
      checkLicenseStatus(pathname),
      // Miscellaneous setup
      fetchHeadlessCaptureMetadata(),
      openSocket(sqWorkbenchStore.interactiveSessionId, getCsrfToken()),
    ])
      .then(() => {
        const workbenchState = getWorkbench();
        const upgradedState = applyWorkbenchConfigUpgrade(
          workbenchState,
          workbenchState?.stores?.sqWorkbenchStore?.version || 1,
          WORKBENCH_SCHEMA_VERSION,
        );

        sqStateSynchronizer.rehydrate(upgradedState, {
          persistenceLevel: 'WORKBENCH',
          initializeMode: 'SOFT',
        });
      })
      .then(() => {
        // Set language and force loading of translation files
        // This has to happen AFTER the workbench state is available
        return switchLanguage(sqWorkbenchStore.userLanguage);
      })
      .then(() => {
        setDataLoaded(true);
      })
      .catch((error) => {
        navigateToCorrectErrorPage(error);
      });
  }, []);

  const aiStandalone = isAIStandalone();

  return (
    <>
      <TabTitle />

      {dataLoaded ? (
        <div
          className={classNames('flexRowContainer', 'fullViewport', {
            // CRAB-38439: Without 'fixed' the entire app shifts up in the viewport when a long <Select> is opened
            fixed: notDisplayingPresentationMode && notAuditTrail,
          })}
          id="mainView"
          data-testid="mainView">
          {!headlessRenderMode() && !isPresentationWorkbookMode() ? <LoadingBar /> : null}
          {notDisplayingPresentationMode && displayLicenseWarning ? <LicenseExpirationWarning /> : null}
          {notDisplayingPresentationMode ? <SystemWarning /> : null}
          {notDisplayingPresentationMode && !aiStandalone ? <Header /> : null}
          <div className="flexFill flexColumnContainer">
            <ResizableWindowWrapper>
              <Outlet />
            </ResizableWindowWrapper>
          </div>
          {notDisplayingPresentationMode && !aiStandalone ? <Footer /> : null}
        </div>
      ) : null}
      {<Fireworks onInit={onConfettiInit} />}
    </>
  );
};
