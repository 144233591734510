import { PersistenceLevel, Store } from '@/core/flux.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { ColumnDefinitionInputV1, TableDefinitionInputV1, TableDefinitionOutputV1 } from '@/sdk';
import { ColumnTypeEnum } from '@/sdk/model/ColumnDefinitionInputV1';
import {
  DEFAULT_TABLE_DEFINITION_NAME,
  tableDefinitionOutputToTableDefinitionInput,
} from '@/tableDefinitionEditor/tableDefinition.utilities';
import { MaterializedTable } from '@/tableDefinitionEditor/tableDefinition.types';

export const ID_COLUMN: ColumnDefinitionInputV1 = {
  columnName: SeeqNames.MaterializedTables.ItemIdColumn,
  columnType: ColumnTypeEnum.UUID,
  columnRules: [{ eventProperty: { propertyName: 'id' } }],
};

const DATUM_ID_COLUMN: ColumnDefinitionInputV1 = {
  columnName: SeeqNames.MaterializedTables.DatumIdColumn,
  columnType: ColumnTypeEnum.TEXT,
  columnRules: [{ eventProperty: { propertyName: '' } }],
};

const DEFAULT_NAME_COLUMN: ColumnDefinitionInputV1 = {
  columnName: 'Item',
  columnType: ColumnTypeEnum.TEXT,
  columnRules: [{ itemProperty: { propertyName: 'name', columnIndex: 1 } }],
};

const DEFAULT_COLUMN_DEFINITION_INPUTS = [ID_COLUMN, DATUM_ID_COLUMN, DEFAULT_NAME_COLUMN];

export class TableDefinitionStore extends Store {
  persistenceLevel: PersistenceLevel = 'NONE';
  static readonly storeName = 'sqTableDefinitionStore';

  initialize() {
    this.state = this.immutable({
      id: '',
      subscriberId: '',
      name: DEFAULT_TABLE_DEFINITION_NAME,
      description: '',
      scopedTo: undefined,
      columnDefinitions: DEFAULT_COLUMN_DEFINITION_INPUTS,
      tableDefinitionInput: this.monkey(
        ['subscriberId'],
        ['name'],
        ['description'],
        ['scopedTo'],
        ['columnDefinitions'],
        function (subscriberId, name, description, scopedTo, columnDefinitions) {
          return {
            subscriptionId: subscriberId,
            name,
            description,
            scopedTo,
            columnDefinitions,
          };
        },
      ),
      tableDefinitionOutput: undefined,
      materializedTable: undefined,
      doTableReload: false,
    });
  }

  get id(): string {
    return this.state.get('id');
  }

  get subscriberId(): string {
    return this.state.get('subscriberId');
  }

  get name(): string {
    return this.state.get('name');
  }

  get description(): string {
    return this.state.get('description');
  }

  get scopedTo(): string | undefined {
    return this.state.get('scopedTo');
  }

  get columnDefinitions(): ColumnDefinitionInputV1[] {
    return this.state.get('columnDefinitions');
  }

  get tableDefinitionInput(): TableDefinitionInputV1 {
    return this.state.get('tableDefinitionInput');
  }

  get tableDefinitionOutput(): TableDefinitionOutputV1 | undefined {
    return this.state.get('tableDefinitionOutput');
  }

  get materializedTable(): MaterializedTable | undefined {
    return this.state.get('materializedTable');
  }

  get doTableReload(): boolean {
    return this.state.get('doTableReload');
  }

  protected readonly handlers = {
    TABLE_DEFINITION_SET_SUBSCRIBER_ID: ({ subscriberId }: { subscriberId: string | undefined }) => {
      this.state.set('subscriberId', subscriberId);
    },

    TABLE_DEFINITION_SET_NAME: ({ name }: { name: string }) => {
      this.state.set('name', name);
    },

    TABLE_DEFINITION_SET_DESCRIPTION: ({ description }: { description: string }) => {
      this.state.set('description', description);
    },

    TABLE_DEFINITION_SET_DO_TABLE_RELOAD: ({ doTableReload }: { doTableReload: boolean }) => {
      this.state.set('doTableReload', doTableReload);
    },

    TABLE_DEFINITION_SET_TABLE_DEFINITION: ({ tableDefinition }: { tableDefinition: TableDefinitionOutputV1 }) => {
      this.state.set('id', tableDefinition.id);
      this.state.set('tableDefinitionOutput', tableDefinition);

      const tableDefinitionInput = tableDefinitionOutputToTableDefinitionInput(tableDefinition);
      this.state.set('subscriberId', tableDefinitionInput.subscriptionId);
      this.state.set('name', tableDefinitionInput.name);
      this.state.set('description', tableDefinitionInput.description);
      this.state.set('scopedTo', tableDefinitionInput.scopedTo);
      this.state.set('columnDefinitions', tableDefinitionInput.columnDefinitions);
    },

    TABLE_DEFINITION_SET_MATERIALIZED_TABLE: ({ materializedTable }: { materializedTable: MaterializedTable }) => {
      this.state.set('materializedTable', materializedTable);
    },

    TABLE_DEFINITION_RESET: () => {
      this.state.set('id', '');
      this.state.set('subscriberId', '');
      this.state.set('name', DEFAULT_TABLE_DEFINITION_NAME);
      this.state.set('description', '');
      this.state.set('scopedTo', undefined);
      this.state.set('columnDefinitions', DEFAULT_COLUMN_DEFINITION_INPUTS);
      this.state.set('tableDefinitionOutput', undefined);
      this.state.set('materializedTable', undefined);
      this.state.set('doTableReload', false);
    },
  };
}
