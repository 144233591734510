import { flux } from '@/core/flux.module';
import { dispatchAndSetChanged } from '@/assetGroupEditor/assetGroup.actions';

export function setName(name: string) {
  dispatchAndSetChanged('ITEM_FINDER_SET_NAME', { name });
}

export function addSearch() {
  flux.dispatch('ITEM_FINDER_ADD_SEARCH');
}

export function removeSearch(index: number) {
  flux.dispatch('ITEM_FINDER_REMOVE_SEARCH', index);
}

export function updateSearch(payload: { value: any; index: number }) {
  flux.dispatch('ITEM_FINDER_UPDATE_SEARCH', payload);
}

export function setPredicatesAndValues(searches: any) {
  dispatchAndSetChanged('ITEM_FINDER_SET_PREDICATES_AND_VALUES', { searches });
}

export function addPredicate(searchIndex: number) {
  flux.dispatch('ITEM_FINDER_ADD_PREDICATE', searchIndex);
}

export function removePredicate(predicateIndex: number) {
  flux.dispatch('ITEM_FINDER_REMOVE_PREDICATE', predicateIndex);
}

export function updatePredicate(payload: { value: any; index: number }) {
  flux.dispatch('ITEM_FINDER_UPDATE_PREDICATE', payload);
}

export function addValue(payload: { predicateId: string; searchId: string }) {
  flux.dispatch('ITEM_FINDER_ADD_VALUE', payload);
}

export function removeValue(valueIndex: number) {
  flux.dispatch('ITEM_FINDER_REMOVE_VALUE', valueIndex);
}

export function updateValue(payload: { value: any; index: number }) {
  flux.dispatch('ITEM_FINDER_UPDATE_VALUE', payload);
}

export function resetItemFinder() {
  dispatchAndSetChanged('RESET_ITEM_FINDER', {});
}

export function setItemFinder({ value: { name, searches } }: { value: { name: string; searches: [] } }) {
  dispatchAndSetChanged('SET_EXISTING_ITEM_FINDER', { value: { name, searches } });
}
