// @ts-strict-ignore
import _ from 'lodash';
import { DatafileInputV1, ItemTypeEnum, ValidationModeEnum } from '@/sdk/model/DatafileInputV1';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import {
  APPEND,
  COLUMN_INDEX,
  COLUMN_NAME,
  DATAFILE_ITEM_TYPES,
  DAY_MONTH_FIRST_OPTIONS,
  DELIMITERS,
  EMPTY_ROW_IDENTIFIER,
  FILE_OUTPUT_TYPES,
  INTERPOLATION_METHODS,
  SIGNAL_VALUE_COLUMN_OPTIONS,
  VALIDATION_MODES,
} from '@/tools/importDatafile/importDatafile.constants';
import { dayFirstDefault, delimiterDefault, isConfigurationLoaded } from '@/services/systemConfiguration.utilities';
import { BaseToolStore } from '@/toolSelection/baseTool.store';
import { BASE_TOOL_COMMON_PROPS } from '@/toolSelection/baseTool.constants';

export class ImportDatafileStore extends BaseToolStore {
  type = TREND_TOOLS.IMPORTDATAFILE;
  static readonly storeName = 'sqImportDatafileStore';

  initialize() {
    this.state = this.immutable(
      _.assign(
        {
          uploadFilename: '',
          filename: '',
          keyColumnIndex: 1,
          keyColumnName: '',
          interpolationMethod: _.find(INTERPOLATION_METHODS, {
            value: 'Linear',
          }),
          validationMode: _.find(VALIDATION_MODES, {
            value: ValidationModeEnum.Fail,
          }),
          fileOutputType: _.find(FILE_OUTPUT_TYPES, { value: 'Replace' }),
          fieldDelimiter: isConfigurationLoaded()
            ? _.find(DELIMITERS, (delimiter) => (delimiter.value as any).toLowerCase() === delimiterDefault())
            : undefined,
          maxInterpolation: {
            value: 40,
            units: 'h',
          },
          namePrefix: '',
          nameSuffix: '',
          firstDataRow: 2,
          maxInterpolationRow: undefined,
          interpolationMethodRow: undefined,
          valueUomRow: undefined,
          timezone: undefined,
          descriptionRow: undefined,
          valueColumnIndices: undefined,
          valueColumnNames: undefined,
          nameRow: 1,
          description: '',
          valueColumnOption: _.find(SIGNAL_VALUE_COLUMN_OPTIONS, {
            value: 'All',
          }),
          valueUom: '',
          lenientDaylightSavings: false,
          itemType: _.find(DATAFILE_ITEM_TYPES, {
            value: ItemTypeEnum.Signal,
          }),
          maxDuration: {
            value: 40,
            units: 'h',
          },
          endColumnIndex: undefined,
          endColumnName: '',
          conditionName: '',
          dayFirstDefault: isConfigurationLoaded()
            ? _.find(DAY_MONTH_FIRST_OPTIONS, {
                value: dayFirstDefault(),
              })
            : undefined,
        },
        BASE_TOOL_COMMON_PROPS,
        {},
      ),
    );
  }

  get uploadFilename() {
    return this.state.get('uploadFilename');
  }

  get filename() {
    return this.state.get('filename');
  }

  get keyColumnIndex() {
    return this.state.get('keyColumnIndex');
  }

  get keyColumnName() {
    return this.state.get('keyColumnName');
  }

  get interpolationMethod() {
    return this.state.get('interpolationMethod');
  }

  get maxInterpolation() {
    return this.state.get('maxInterpolation');
  }

  get fileOutputType() {
    return this.state.get('fileOutputType');
  }

  get fieldDelimiter() {
    return this.state.get('fieldDelimiter');
  }

  get namePrefix() {
    return this.state.get('namePrefix');
  }

  get nameSuffix() {
    return this.state.get('nameSuffix');
  }

  get firstDataRow() {
    return this.state.get('firstDataRow');
  }

  get maxInterpolationRow() {
    return this.state.get('maxInterpolationRow');
  }

  get interpolationMethodRow() {
    return this.state.get('interpolationMethodRow');
  }

  get valueUomRow() {
    return this.state.get('valueUomRow');
  }

  get timezone() {
    return this.state.get('timezone');
  }

  get validationMode() {
    return this.state.get('validationMode');
  }

  get lenientDaylightSavings() {
    return this.state.get('lenientDaylightSavings');
  }

  get descriptionRow() {
    return this.state.get('descriptionRow');
  }

  get valueColumnIndices() {
    return this.state.get('valueColumnIndices');
  }

  get valueColumnNames() {
    return this.state.get('valueColumnNames');
  }

  get nameRow() {
    return this.state.get('nameRow');
  }

  get description() {
    return this.state.get('description');
  }

  get valueColumnOption() {
    return this.state.get('valueColumnOption');
  }

  get valueUom() {
    return this.state.get('valueUom');
  }

  get itemType() {
    return this.state.get('itemType');
  }

  get maxDuration() {
    return this.state.get('maxDuration');
  }

  get endColumnName() {
    return this.state.get('endColumnName');
  }

  get endColumnIndex() {
    return this.state.get('endColumnIndex');
  }

  get conditionName() {
    return this.state.get('conditionName');
  }

  get dayFirstDefault() {
    return this.state.get('dayFirstDefault');
  }

  /**
   * Returns true if signal is selected
   */
  isSignalSelected(): boolean {
    return this.state.get('itemType').value === ItemTypeEnum.Signal;
  }

  /**
   * Return true if condition is selected
   */
  isConditionSelected(): boolean {
    return this.state.get('itemType').value === ItemTypeEnum.Condition;
  }

  /**
   * Returns the store as a datafile api input object
   *
   * @returns The store as an api input object
   */
  asApiObject(): DatafileInputV1 {
    let valueColumnIndices;
    if (
      this.state.get('itemType').value === ItemTypeEnum.Condition &&
      _.isEqual(this.state.get('valueColumnOption').value, 'None')
    ) {
      valueColumnIndices = 0;
    } else if (_.isEqual(this.state.get('valueColumnOption'), COLUMN_INDEX)) {
      valueColumnIndices = this.state.get('valueColumnIndices');
    } else {
      valueColumnIndices = undefined;
    }

    return {
      uploadFilename: this.state.get('uploadFilename'),
      filename: this.state.get('filename'),
      description: this.state.get('description'),
      name: this.state.get('name'),
      fieldDelimiter: this.state.get('fieldDelimiter').value,
      append: _.isEqual(this.state.get('fileOutputType').value, APPEND),
      firstDataRow: this.state.get('firstDataRow'),
      interpolationMethod: this.state.get('interpolationMethod').value,
      interpolationMethodRow: this.state.get('interpolationMethodRow')
        ? this.state.get('interpolationMethodRow')
        : EMPTY_ROW_IDENTIFIER,
      keyColumnIndex: this.state.get('keyColumnIndex'),
      keyColumnName: this.state.get('keyColumnName'),
      maximumInterpolation: this.state.get('maxInterpolation').value + this.state.get('maxInterpolation').units,
      maximumInterpolationRow: this.state.get('maxInterpolationRow')
        ? this.state.get('maxInterpolationRow')
        : EMPTY_ROW_IDENTIFIER,
      namePrefix: this.state.get('namePrefix'),
      nameSuffix: this.state.get('nameSuffix'),
      nameRow: this.state.get('nameRow'),
      timeZone: this.state.get('timezone') ? this.state.get('timezone').displayName : undefined,
      valueUomRow: this.state.get('valueUomRow') ? this.state.get('valueUomRow') : EMPTY_ROW_IDENTIFIER,
      validationMode: this.state.get('validationMode').value,
      lenientDaylightSavings: this.state.get('lenientDaylightSavings'),
      descriptionRow: this.state.get('descriptionRow') ? this.state.get('descriptionRow') : EMPTY_ROW_IDENTIFIER,
      valueColumnIndices,
      valueColumnNames: _.isEqual(this.state.get('valueColumnOption'), COLUMN_NAME)
        ? this.state.get('valueColumnNames')
        : undefined,
      valueUom: this.state.get('valueUom'),
      endColumnIndex: this.state.get('endColumnIndex'),
      endColumnName: this.state.get('endColumnName'),
      maximumDuration: this.state.get('maxDuration').value + this.state.get('maxDuration').units,
      itemType: this.state.get('itemType').value,
      conditionName:
        this.state.get('itemType').value === ItemTypeEnum.Condition ? this.state.get('conditionName') : null,
      dayFirstDefault: this.state.get('dayFirstDefault').value,
    };
  }

  /**
   * Exports the state that is not ephemeral (such as progress indicators).
   * @returns {Object} The dehydrated state.
   */
  dehydrate() {
    return this.state.serialize();
  }

  /**
   * Re-creates the profile search results.
   *
   * @param {Object} dehydratedState Previous state usually obtained from `dehydrate` method.
   */
  rehydrate(dehydratedState) {
    this.state.merge(dehydratedState);
  }

  rehydrateForEdit(payload) {
    if (payload.type === TREND_TOOLS.IMPORTDATAFILE) {
      this.state.merge(_.omit(payload, ['type']));
    }
  }

  readonly handlers = {
    ...this.baseHandlers,
    /**
     * Sets the store fields on tool load. Overrides base rehydrateForEdit
     *
     * @param {Object} payload - Object container for entire store
     */
    TOOL_REHYDRATE_FOR_EDIT: this.rehydrateForEdit,
    /**
     * Sets the name of the file that the server returned after uploading it.
     *
     * @param {Object} payload - Object container
     * @param {String} payload.uploadFilename - The name of the uploaded file
     */
    IMPORTDATAFILE_SET_UPLOAD_FILE_NAME: (payload) => {
      this.state.set('uploadFilename', payload.uploadFilename);
    },
    /**
     * Sets the name of the file on the client's computer.
     *
     * @param {Object} payload - Object container
     * @param {String} payload.filename - The name of the file on the client
     */
    IMPORTDATAFILE_SET_FILE_NAME: (payload) => {
      this.state.set('filename', payload.filename);
    },
    /**
     * Sets the key column index.
     *
     * @param {Object} payload - Object container
     * @param {Number} payload.keyColumnIndex - The column index to use for the key
     */
    IMPORTDATAFILE_SET_KEY_COLUMN_INDEX: (payload) => {
      this.state.set('keyColumnIndex', payload.keyColumnIndex);
    },
    /**
     * Sets the key column name.
     *
     * @param {Object} payload - Object container
     * @param {Number} payload.keyColumnName - The column name to use for the key
     */
    IMPORTDATAFILE_SET_KEY_COLUMN_NAME: (payload) => {
      this.state.set('keyColumnName', payload.keyColumnName);
    },
    /**
     * Sets the interpolation method (linear or step).
     *
     * @param {Object} payload - Object container
     * @param {Object} payload.interpolationMethod - The interpolation method to use
     */
    IMPORTDATAFILE_SET_INTERPOLATION_METHOD: (payload) => {
      this.state.set('interpolationMethod', payload.interpolationMethod);
    },
    /**
     * Sets the maximum interpolation.
     *
     * @param {Object} payload - Object container
     * @param {Object} payload.maxInterpolation - The max interpolations
     */
    IMPORTDATAFILE_SET_MAX_INTERPOLATION: (payload) => {
      this.state.set('maxInterpolation', payload.maxInterpolation);
    },
    /**
     * Sets the file output type (append or replace)
     *
     * @param {Object} payload - Object container
     * @param {Object} payload.fileOutputType - The type used for the file output
     */
    IMPORTDATAFILE_SET_FILE_OUTPUT_TYPE: (payload) => {
      this.state.set('fileOutputType', payload.fileOutputType);
    },
    /**
     * Sets the field delimiter for the file
     *
     * @param {Object} payload - Object container
     * @param {String} payload.fieldDelimiter - The character used as the field delimiter
     */
    IMPORTDATAFILE_SET_FIELD_DELIMITER: (payload) => {
      this.state.set('fieldDelimiter', payload.fieldDelimiter);
    },
    /**
     * Sets signal name prefix.
     *
     * @param {Object} payload - Object container
     * @param {String} payload.prefix - The prefix for the signal name
     */
    IMPORTDATAFILE_SET_NAME_PREFIX: (payload) => {
      this.state.set('namePrefix', payload.namePrefix);
    },
    /**
     * Sets the signal name suffix
     *
     * @param {Object} payload - Object container
     * @param {String} payload.nameSuffix - The suffix for the signal name
     */
    IMPORTDATAFILE_SET_NAME_SUFFIX: (payload) => {
      this.state.set('nameSuffix', payload.nameSuffix);
    },
    /**
     * Sets the first row with data.
     *
     * @param {Object} payload - Object container
     * @param {Number} payload.firstDataRow - The index of the first row with data
     */
    IMPORTDATAFILE_SET_FIRST_DATA_ROW: (payload) => {
      this.state.set('firstDataRow', payload.firstDataRow);
    },
    /**
     * Sets the max interpolation row.
     *
     * @param {Object} payload - Object container
     * @param {Number} payload.maxInterpolationRow - The index of the max interpolation row
     */
    IMPORTDATAFILE_SET_MAX_INTERPOLATION_ROW: (payload) => {
      this.state.set('maxInterpolationRow', payload.maxInterpolationRow);
    },
    /**
     * Sets the interpolation method row
     *
     * @param {Object} payload - Object container
     * @param {Number} payload.interpolationMethodRow - The index of the interpolation method row
     */
    IMPORTDATAFILE_SET_INTERPOLATION_METHOD_ROW: (payload) => {
      this.state.set('interpolationMethodRow', payload.interpolationMethodRow);
    },
    /**
     * Sets the uom row.
     *
     * @param {Object} payload - Object container
     * @param {Number} payload.valueUomRow - The index of the uom row
     */
    IMPORTDATAFILE_SET_VALUE_UOM_ROW: (payload) => {
      this.state.set('valueUomRow', payload.valueUomRow);
    },
    /**
     * Sets the timezone to use with the timestamps
     *
     * @param {Object} payload - Object container
     * @param {String} payload.timezone- The timezone object
     */
    IMPORTDATAFILE_SET_TIMEZONE: (payload) => {
      this.state.set('timezone', payload.timezone);
    },
    /**
     * Sets how to handle invalid data in the CSV
     *
     * @param {Object} payload - Object container
     * @param {Object} payload.validationMode - Tells which way to handle invalid data
     */
    IMPORTDATAFILE_SET_VALIDATION_MODE: (payload) => {
      this.state.set('validationMode', payload.validationMode);
    },
    /**
     * Sets whether daylight savings time is treated leniently or not
     *
     * @param {Object} payload - Object container
     * @param {boolean} payload.lenientDaylightSavings - True to treat as lenient, false otherwise
     */
    IMPORTDATAFILE_SET_LENIENT_DAYLIGHT_SAVINGS: (payload) => {
      this.state.set('lenientDaylightSavings', payload.lenientDaylightSavings);
    },
    /**
     * Sets the index of the description row
     *
     * @param {Object} payload - Object container
     * @param {Number} payload.descriptionRow - The index of the description row
     */
    IMPORTDATAFILE_SET_DESCRIPTION_ROW: (payload) => {
      this.state.set('descriptionRow', payload.descriptionRow);
    },
    /**
     * Sets the column indices which contain signal values
     *
     * @param {Object} payload - Object container
     * @param {Number[]} payload.valueColumnIndices - The array of column indexes which contain signal values.
     *   Starts from 1.
     */
    IMPORTDATAFILE_SET_VALUE_COLUMN_INDICES: (payload) => {
      this.state.set('valueColumnIndices', payload.valueColumnIndices);
    },
    /**
     * Sets the column names which contain signal values
     *
     * @param {Object} payload - Object container
     * @param {Number[]} payload.valueColumnNames - The array of names indexes which contain signal values.
     */
    IMPORTDATAFILE_SET_VALUE_COLUMN_NAMES: (payload) => {
      this.state.set('valueColumnNames', payload.valueColumnNames);
    },
    /**
     * Sets the name row
     *
     * @param {Object} payload - Object container
     * @param {Number} payload.nameRow - The index of the name row
     */
    IMPORTDATAFILE_SET_NAME_ROW: (payload) => {
      this.state.set('nameRow', payload.nameRow);
    },
    /**
     * Sets the description for the datafile
     *
     * @param {Object} payload - Object container
     * @param {string} payload.description - description of the datafile
     */
    IMPORTDATAFILE_SET_DESCRIPTION: (payload) => {
      this.state.set('description', payload.description);
    },
    /**
     * Sets the data column option
     *
     * @param {Object} payload - Object container
     * @param {boolean} payload.valueColumnOption- data column option
     */
    IMPORTDATAFILE_SET_VALUE_COLUMN_OPTION: (payload) => {
      this.state.set('valueColumnOption', payload.valueColumnOption);
    },
    /**
     * Sets the uom for the whole datafile
     *
     * @param {Object} payload- Object container
     * @param {String} payload.valueUom - the uom
     */
    IMPORTDATAFILE_SET_VALUE_UOM: (payload) => {
      this.state.set('valueUom', payload.valueUom);
    },
    /**
     * Sets the maximum duration
     *
     * @param {Object} payload- Object container
     * @param {Object} payload.maxDuration - the maximum duration
     */
    IMPORTDATAFILE_SET_MAX_DURATION: (payload) => {
      this.state.set('maxDuration', payload.maxDuration);
    },
    /**
     * Sets the end column index
     *
     * @param {Object} payload- Object container
     * @param {number} payload.endColumnIndex - the end column index
     */
    IMPORTDATAFILE_SET_END_COLUMN_INDEX: (payload) => {
      this.state.set('endColumnIndex', payload.endColumnIndex);
    },
    /**
     * Sets the end column name
     *
     * @param {Object} payload- Object container
     * @param {String} payload.endColumnName - the end column name
     */
    IMPORTDATAFILE_SET_END_COLUMN_NAME: (payload) => {
      this.state.set('endColumnName', payload.endColumnName);
    },
    /**
     * Sets the item type
     *
     * @param {Object} payload- Object container
     * @param {Object} payload.itemType - the item type
     */
    IMPORTDATAFILE_SET_ITEM_TYPE: (payload) => {
      this.state.set('itemType', payload.itemType);
      if (payload.itemType.value === ItemTypeEnum.Signal && this.state.get('valueColumnOption').value === 'None') {
        // Other isn't a valid option for signals
        this.state.set('valueColumnOption', _.find(SIGNAL_VALUE_COLUMN_OPTIONS, { value: 'All' }));
      }
    },
    /**
     * Sets the condition name
     *
     * @param {Object} payload- Object container
     * @param {String} payload.conditionName - the name of the condition
     */
    IMPORTDATAFILE_SET_CONDITION_NAME: (payload) => {
      this.state.set('conditionName', payload.conditionName);
    },
    /**
     *Sets whether day is first or month is first in MDY/DMY styles dates
     *
     * @param {Object} payload - Object container
     * @param {Object} payload.dayFirstDefault - One of DAY_MONTH_FIRST_OPTIONS
     */
    IMPORTDATAFILE_SET_DAY_FIRST_DEFAULT: (payload) => {
      this.state.set('dayFirstDefault', payload.dayFirstDefault);
    },
  };
}
