import _ from 'lodash';

import { DISPLAY_MODE } from '@/main/app.constants';
import { FormulaParameter } from '@/tools/formula/formulaTool.types';
import { warnToast } from '@/utilities/toast.utilities';
import { flux } from '@/core/flux.module';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { trackPowerSearchCompletedInfo } from '@/track/track.service';
import { setActiveTool } from '@/toolSelection/investigate.actions';
import { sqFormulaToolStore } from '@/core/core.stores';
import { panelExecuteCalculatedItem } from '@/services/toolRunner.service';

/**
 * Runs the formula and creates a calculated series, capsule set, or scalar.
 * @param {string} color - Color hex code (e.g. #CCCCCC)
 *
 * @return {Promise} - a promise that resolves when the power search completes.
 */
export function search(color: string): Promise<void> {
  const parameters = _.transform(
    sqFormulaToolStore.parameters,
    (accum: Record<string, string>, parameter: { identifier: string; item: { id: string } }) => {
      accum[parameter.identifier] = parameter.item.id;
    },
    {},
  );

  const searchCompletePayload = {
    name: sqFormulaToolStore.name,
    formula: sqFormulaToolStore.formula,
    parameters: sqFormulaToolStore.parameters,
    // isNew in particular must be determined before calling generate because it will create the id
    isNew: !sqFormulaToolStore.id,
  };

  return Promise.resolve()
    .then(() =>
      panelExecuteCalculatedItem(
        sqFormulaToolStore.name,
        sqFormulaToolStore.formula,
        parameters,
        sqFormulaToolStore.configParams,
        sqFormulaToolStore.id,
        color,
        { notifyOnError: false },
      ),
    )
    .catch((errorMessage) => {
      trackPowerSearchCompletedInfo(
        _.assign(searchCompletePayload, {
          success: false,
          errorMessage,
          id: sqFormulaToolStore.id,
        }),
      );

      return Promise.reject(errorMessage);
    })
    .then(() => {
      trackPowerSearchCompletedInfo(
        _.assign(searchCompletePayload, {
          success: true,
          id: sqFormulaToolStore.id,
        }),
      );
    });
}

/**
 * Sets the search formula
 *
 * @param {string} formula - the formula for the search.
 */
export function setFormula(formula: string): void {
  flux.dispatch('FORMULA_SET_FORMULA', { formula });
}

/**
 * Adds a parameter.
 *
 * @param {Object} parameter - The parameter to add
 * @param {string} parameter.identifier - The symbolic identifier for the parameter
 * @param {Object} parameter.item - Object containing item properties
 * @param {string} parameter.item.id - The id of the item referenced by the parameter
 * @param {string} parameter.item.name - The name of the item referenced by the parameter
 */
export function addParameter(parameter: FormulaParameter & { name?: string }): void {
  flux.dispatch('FORMULA_ADD_PARAMETER', { parameter });
}

/**
 * Adds items from details pane to the formula as parameters if they don't already exist there.
 */
export function addDetailsPaneParameters(): void {
  flux.dispatch('FORMULA_ADD_DETAILS_PANE_PARAMETERS');
}

/**
 * Updates a parameter.
 *
 * @param {Object} parameter - The parameter to add
 * @param {string} parameter.identifier - The symbolic identifier for the parameter
 * @param {Object} parameter.item - Object containing item properties
 * @param {string} parameter.item.id - The id of the item referenced by the parameter
 * @param {string} parameter.item.name - The name of the item referenced by the parameter
 */
export function updateParameter(
  parameter: FormulaParameter,
  originalParameter: FormulaParameter,
): Promise<never> | undefined {
  if (!parameter.identifier.trim()) {
    warnToast({ messageKey: 'INVALID_NAME' });

    return Promise.reject();
  }

  const index: number = _.findIndex(sqFormulaToolStore.parameters, {
    identifier: originalParameter.identifier,
  });
  const isUnique = _.every(
    sqFormulaToolStore.parameters,
    (v: any, i: number) => v.identifier !== parameter.identifier || i === index,
  );

  if (!isUnique) {
    warnToast({ messageKey: 'FORMULA.VARIABLE_UNIQUE' });

    return Promise.reject();
  }

  flux.dispatch('FORMULA_UPDATE_PARAMETER', { index, parameter });
}

export function selectParameter(parameter: FormulaParameter) {
  // The index may have changed due to user removing or reordering parameters
  const index = _.findIndex(sqFormulaToolStore.parameters, { identifier: parameter.identifier });
  flux.dispatch('FORMULA_UPDATE_PARAMETER', { index, parameter });
}

/**
 * Removes a parameter.
 *
 * @param {string} identifier - The name (identifier) of the parameter to remove
 */
export function removeParameter(identifier: string): void {
  flux.dispatch('FORMULA_REMOVE_PARAMETER', { identifier });
}

/**
 * Removes all parameters.
 */
export function removeAllParameters(): void {
  flux.dispatch('FORMULA_REMOVE_ALL_PARAMETERS');
}

/**
 * Sets the formula editor navigation stack
 *
 * @param {string} navigationStack - the navigation stack
 */
export function setNavigationStack(navigationStack: string[]): void {
  flux.dispatch('FORMULA_SET_NAVIGATION_STACK', { navigationStack });
}

/**
 * Sets the formula documentation search filter
 *
 * @param {string} filterTerm - the search term
 */
export function setFormulaFilter(filterTerm?: string): void {
  flux.dispatch('FORMULA_SET_FILTER', { filter: filterTerm });
}

/**
 * Open the formula tool for edit with the supplied formula and parameters
 *
 * @param formula - the formula
 * @param parameters - the formula parameters
 */
export function editNewFormula(formula: string, parameters: FormulaParameter[]) {
  setActiveTool(TREND_TOOLS.FORMULA, DISPLAY_MODE.NEW, true);
  removeAllParameters();
  _.forEach(parameters, (parameter) => addParameter(parameter));
  setFormula(formula);
}

export function formulaTypeClass(type: string): string {
  return `formula-type-${type.toLowerCase()}`;
}

export function toggleHelp(): void {
  flux.dispatch('FORMULA_TOGGLE_HELP_SHOWN');
}

export function setHelpView(view: string): void {
  flux.dispatch('FORMULA_SET_HELP_VIEW', { view });
}
