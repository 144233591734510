// @ts-strict-ignore
import _ from 'lodash';
import moment from 'moment-timezone';
import { getAllItems } from '@/trend/trendDataHelper.utilities';
import { DISPLAY_MODE } from '@/main/app.constants';
import { ITEM_TYPES } from '@/trendData/trendData.constants';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { sqDurationStore, sqTrendStore, sqWorkbenchStore, sqWorkbookStore, sqWorksheetStore } from '@/core/core.stores';
import { BaseToolStore } from '@/toolSelection/baseTool.store';
import { BASE_TOOL_COMMON_PROPS } from '@/toolSelection/baseTool.constants';
import { isItemRedacted } from '@/utilities/redaction.utilities';
import { ExportExcelPanelStore } from '@/tools/exportExcel/exportExcelPanel.store';
import { GRID_OPTION, ODATA_EXPORT_SEPARATOR, SAMPLES_TABLE_MODE } from '@/tools/exportOData/odata.constants';
import { sqTimezones } from '@/utilities/datetime.constants';
import { ExportItemsOutputV1 } from '@/sdk/model/ExportItemsOutputV1';

export class ExportODataPanelStore extends BaseToolStore {
  static readonly storeName = 'sqExportODataPanelStore';
  type = TREND_TOOLS.EXPORT_ODATA;
  parameterDefinitions = {
    exportSignals: {
      predicate: ['name', 'a'],
      multiple: true,
    },
    exportConditions: {
      predicate: ['name', 'a'],
      multiple: true,
    },
  };

  initialize() {
    this.state = this.immutable(
      _.assign({}, BASE_TOOL_COMMON_PROPS, {
        name: `${sqWorkbookStore.name}_${sqWorkbookStore.getWorksheetName(sqWorkbenchStore.stateParams.worksheetId)}`,
        autoupdateTimeRange: false,
        timeRange: {
          start: sqDurationStore.displayRange.start.valueOf(),
          end: sqDurationStore.displayRange.end.valueOf(),
        },
        exportTimeZone: sqWorksheetStore.timezone,
        gridOption: GRID_OPTION.CUSTOM,
        gridSize: this.getDefaultGridSize(),
        gridOrigin: moment().tz(sqWorksheetStore.timezone.name).startOf('year').valueOf(),
        gridOriginEnabled: false,
        exportNameSuffix: '',
        chainView: sqTrendStore.isTrendViewChainView(),
        capsuleTime: sqTrendStore.isTrendViewCapsuleTime(),
        exportCapsules: undefined,
        worksheetLink: '',
        samplesMode: this.getSamplesMode(sqTrendStore.isTrendViewChainView(), sqTrendStore.isTrendViewCapsuleTime()),
      }),
    );
  }

  get autoupdateTimeRange() {
    return this.state.get('autoupdateTimeRange');
  }

  get exportSignals() {
    return this.state.get('exportSignals');
  }

  get exportConditions() {
    return this.state.get('exportConditions');
  }

  get timeRange() {
    const state = this.state;
    const start = state.get('timeRange', 'start');
    const end = state.get('timeRange', 'end');

    return {
      duration: moment.duration(end - start),
      end: moment.utc(end),
      start: moment.utc(start),
    };
  }

  get exportTimeZone() {
    return this.state.get('exportTimeZone');
  }

  get gridOption() {
    return this.state.get('gridOption');
  }

  get gridSize() {
    return this.state.get('gridSize');
  }

  get gridOrigin() {
    return moment(this.state.get('gridOrigin')).tz(sqWorksheetStore.timezone.name);
  }

  get gridOriginEnabled() {
    return this.state.get('gridOriginEnabled');
  }

  get exportNameSuffix() {
    return this.state.get('exportNameSuffix');
  }

  get capsuleTime() {
    return this.state.get('capsuleTime');
  }

  get chainView() {
    return this.state.get('chainView');
  }

  get samplesMode() {
    return this.getSamplesMode(this.chainView, this.capsuleTime);
  }

  get exportCapsules() {
    return this.state.get('exportCapsules');
  }

  get worksheetLink() {
    return this.state.get('worksheetLink');
  }

  /**
   * Exports state, so it can be used to re-create the state later using `rehydrate`.
   *
   * @return {Object} State for the store
   */
  dehydrate() {
    return this.state.serialize();
  }

  /**
   * Sets the prediction panel state
   *
   * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
   */
  rehydrate(dehydratedState) {
    this.state.merge(dehydratedState);
  }

  setChainViewAndCapsuleTime(chainView: boolean, capsuleTime: boolean) {
    this.state.set('chainView', chainView);
    this.state.set('capsuleTime', capsuleTime);
  }

  getSamplesMode(chainView, capsuleTime) {
    if (chainView) {
      return SAMPLES_TABLE_MODE.CHAIN;
    } else if (capsuleTime) {
      return SAMPLES_TABLE_MODE.CAPSULE;
    } else {
      return SAMPLES_TABLE_MODE.CALENDAR;
    }
  }

  getDefaultGridSize() {
    return ExportExcelPanelStore.generateDefaultGridSize(
      moment.duration(sqDurationStore.displayRange.end.valueOf() - sqDurationStore.displayRange.start.valueOf()),
    );
  }

  protected readonly handlers = {
    ...this.baseHandlers,
    /**
     * Initializes the items to their default values. Done here so that this store doesn't dehydrate a bunch of
     * items when it is not in use.
     *
     * @param {Object} payload - Object containing state information
     * @param {String} payload.mode - The display mode being set, one of DISPLAY_MODE
     * @param {String} payload.type - The name of the tool, one of TREND_TOOLS
     */
    INVESTIGATE_SET_DISPLAY_MODE: (payload: { mode: string; type: string }) => {
      const getExportItems = (itemType) => {
        return _.chain(
          getAllItems({
            workingSelection: true,
            itemTypes: [itemType],
          }),
        )
          .reject(isItemRedacted)
          .map((item) => _.pick(item, this.TOOL_ITEM_PROPS))
          .value();
      };
      this.reset(payload);
      if (payload.mode === DISPLAY_MODE.NEW && payload.type === TREND_TOOLS.EXPORT_ODATA) {
        this.state.set('exportSignals', getExportItems(ITEM_TYPES.SERIES));
        this.state.set('exportConditions', getExportItems(ITEM_TYPES.CAPSULE_SET));
      }
    },

    /**
     * Sets the Samples export mode
     *
     * @param {Object} payload - Object container
     * @param {SAMPLES_TABLE_MODE} payload.samplesMode - Value for samples table mode
     */
    EXPORT_ODATA_SAMPLES_MODE: (payload: { samplesMode: string }) => {
      const mode = payload.samplesMode;
      if (mode === SAMPLES_TABLE_MODE.CHAIN) {
        this.setChainViewAndCapsuleTime(true, false);
      } else if (mode === SAMPLES_TABLE_MODE.CAPSULE) {
        this.setChainViewAndCapsuleTime(false, true);
      } else {
        this.setChainViewAndCapsuleTime(false, false);
      }
    },

    /**
     * Sets whether autoupdate is enabled for the time range
     *
     * @param {Object} payload - Object container
     * @param {boolean} payload.autoupdateTimeRange - True to enable autoupdate
     */
    EXPORT_ODATA_AUTOUPDATE_TIME_RANGE: (payload: { autoupdateTimeRange: boolean }) => {
      this.state.set('autoupdateTimeRange', payload.autoupdateTimeRange);
    },

    /**
     * Set the time range end value that is used in the form. Ensures that start is not before end.
     *
     * @param {Object} payload - Object container
     * @param {moment} payload.start - The start time
     */
    EXPORT_ODATA_TIME_RANGE_START: (payload: { start: Date }) => {
      const newStart = payload.start.valueOf();
      this.state.set(['timeRange', 'start'], newStart);
    },

    /**
     * Set the time range end value that is used in the form. Ensures that end is not after start.
     *
     * @param {Object} payload - Object container
     * @param {moment} payload.end - The start time
     */
    EXPORT_ODATA_TIME_RANGE_END: (payload: { end: Date }) => {
      const newEnd = payload.end.valueOf();
      this.state.set(['timeRange', 'end'], newEnd);
    },

    /**
     * Set the time zone that is used in the form.
     *
     * @param {Object} payload - Object container
     * @param {Object} payload.exportTimeZone - The time zone object from Moment.JS
     * @param {String} payload.exportTimeZone.name - The time zone name to be used by the backend
     */
    EXPORT_ODATA_EXPORT_TIME_ZONE: (payload: { exportTimeZone: { name: string } }) => {
      this.state.set(['exportTimeZone'], payload.exportTimeZone);
    },

    /**
     * Updates the duration of the time range by shifting the start date.
     *
     * @param {Object} payload - Object container
     * @param {moment.duration} payload.duration - The duration
     */
    EXPORT_ODATA_DURATION: (payload: { duration: moment.Duration }) => {
      this.state.set(['timeRange', 'start'], this.state.get('timeRange', 'end') - payload.duration.asMilliseconds());
    },

    /**
     * Set the selected option for gridding.
     *
     * @param {Object} payload - Object container
     * @param {String} payload.gridOption - The grid option
     */
    EXPORT_ODATA_GRID_OPTION: (payload: { gridOption: string }) => {
      this.state.set('gridOption', payload.gridOption);
      if (payload.gridOption !== GRID_OPTION.CUSTOM) {
        this.state.set('gridOriginEnabled', false);
      }
      this.state.set('gridSize', this.getDefaultGridSize());
    },

    /**
     * If GRID_OPTION.CUSTOM is chosen, the duration between samples to be used for gridding.
     *
     * @param {Object} payload - Object container
     * @param {Object} payload.gridSize - The grid size, value and unit
     */
    EXPORT_ODATA_GRID_SIZE: (payload: { gridSize: { value: number; units: string } }) => {
      this.state.set('gridSize', payload.gridSize);
    },

    /**
     * If GRID_OPTION.CUSTOM is chosen, the timestamp to originate the gridding through.
     *
     * @param {Object} payload - Object container
     * @param {Object} payload.gridOrigin - The origin of the grid, in the form of a Moment
     */
    EXPORT_ODATA_GRID_ORIGIN: (payload: { gridOrigin: Date }) => {
      this.state.set('gridOrigin', payload.gridOrigin.valueOf());
    },

    /**
     * The boolean to indicate whether the gridOrigin will be used.
     *
     * @param {Object} payload - Object container
     * @param {boolean} payload.gridOriginEnabled - True if the gridOrigin should be used
     */
    EXPORT_ODATA_GRID_ORIGIN_ENABLED: (payload: { gridOriginEnabled: boolean }) => {
      this.state.set('gridOriginEnabled', payload.gridOriginEnabled);
    },

    /**
     * Set OData id
     *
     * @param {Object} payload - Object container
     * @param {string} payload.id - OData id
     */
    EXPORT_ODATA_ID: (payload: { id: string }) => {
      this.state.set('id', payload.id);
    },

    /**
     * Set OData worksheetLink
     *
     * @param {Object} payload - Object container
     * @param {string} payload.worksheetLink - OData worksheetLink
     */
    EXPORT_ODATA_WORKSHEET_LINK: (payload: { worksheetLink: string }) => {
      this.state.set('worksheetLink', payload.worksheetLink);
    },

    /**
     * Set OData Export for edit
     *
     * @param {Object} payload - Object container
     */
    SET_ODATA_EXPORT: (payload: { odata: ExportItemsOutputV1; additionalItems: any[] }) => {
      const getExportItems = (itemType) => {
        const storeItems = getAllItems({
          workingSelection: true,
          itemTypes: [itemType],
        });

        const nonStoreItems = payload.additionalItems.filter((item) => item.itemType === itemType);
        const allItems = _.concat(storeItems, nonStoreItems);
        return _.chain(allItems)
          .uniqWith((arrVal, othVal) => arrVal.id === othVal.id)
          .reject(isItemRedacted)
          .reject((item) => !_.includes(_.map(payload.odata.items, 'id'), item.id))
          .map((item) => _.pick(item, this.TOOL_ITEM_PROPS))
          .value();
      };

      const nameParts = payload.odata.exportName.split(ODATA_EXPORT_SEPARATOR);
      let gridSize: string | { value: number; units: string } = payload.odata.gridSize;
      if (payload.odata.gridSize !== 'false') {
        const unitsIndex = /[a-z]/i.exec(payload.odata.gridSize).index;
        gridSize = {
          value: parseInt(payload.odata.gridSize.slice(0, unitsIndex), 10),
          units: payload.odata.gridSize.slice(unitsIndex),
        };
      }

      let gridOption;
      if (payload.odata.originalTimestampsEnabled === true) {
        gridOption = GRID_OPTION.ORIGINAL;
      } else if (payload.odata.gridSize === 'false') {
        gridOption = GRID_OPTION.AUTOMATIC;
      } else {
        gridOption = GRID_OPTION.CUSTOM;
      }

      this.state.set('id', payload.odata.id);
      this.state.set('name', nameParts[0]);
      this.state.set('autoupdateTimeRange', payload.odata.autoupdateTimeRange);
      this.state.set(['timeRange', 'start'], moment.utc(payload.odata.items[0].start).valueOf());
      this.state.set(['timeRange', 'end'], moment.utc(payload.odata.items[0].end).valueOf());
      this.state.set('gridOption', gridOption);
      this.state.set('gridSize', gridSize);
      this.state.set('gridOriginEnabled', payload.odata.gridEnabled);
      this.state.set('exportSignals', getExportItems(ITEM_TYPES.SERIES));
      this.state.set('exportConditions', getExportItems(ITEM_TYPES.CAPSULE_SET));
      this.state.set('gridOrigin', payload.odata.gridOrigin);
      this.state.set('exportNameSuffix', nameParts[1]);
      this.state.set(['exportTimeZone'], _.find(sqTimezones.timezones, ['name', payload.odata.timeZone]));
      this.state.set('capsuleTime', payload.odata.capsuleTime);
      this.state.set('chainView', payload.odata.chainView);
      this.state.set('exportCapsules', payload.odata.exportCapsules);
      this.state.set('samplesMode', this.getSamplesMode(this.chainView, this.capsuleTime));
    },
  };
}
