// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Overlay, Popover } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/Overlay';
import { TooltipPosition } from '@seeqdev/qomponents/dist/Tooltip/Tooltip.types';
import { Icon } from '@seeqdev/qomponents';
import { FakeLink } from '@/core/FakeLink';

interface PriorityPickerProps {
  onChange: (value) => void;
  placement: Placement;
  buttonTooltip?: string;
  buttonTooltipPlacement?: TooltipPosition;
  buttonTestId: string;
  buttonClasses: string;
  value: any;
}

export const PriorityPicker: React.FunctionComponent<PriorityPickerProps> = ({
  onChange,
  buttonTooltip,
  buttonTooltipPlacement,
  placement,
  buttonTestId,
  buttonClasses,
  value: priorities,
}) => {
  const { t } = useTranslation();
  const [target, setTarget] = useState(null);

  const [showPriorityPicker, setShowPriorityPicker] = useState(false);

  useEffect(() => {
    // handler used to close the picker on outside click
    const handleClick = (e) => {
      if (e.target.getAttribute('data-itemid') === buttonTestId) {
        return;
      }
      closePriorityPicker();
    };

    // add when mounted
    document.querySelector('#mainView').addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.querySelector('#mainView')?.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const closePriorityPicker = () => setShowPriorityPicker(false);
  const selectPriority = (event, priority) => {
    onChange(priority.level);
    isPriorityAvailable();
    event.stopPropagation();
  };

  /**
   * Determines if a priority is is still available (i.e. they are not already all in use)
   *
   * @returns true if available, false otherwise.
   */
  const isPriorityAvailable = (): boolean => _.chain(priorities).reject({ level: 0 }).some({ inUse: false }).value();

  const togglePriorityPicker = (event) => (showPriorityPicker ? closePriorityPicker() : openPriorityPicker(event));

  const openPriorityPicker = (event) => {
    setShowPriorityPicker(true);
    setTarget(event.currentTarget);
  };

  return (
    <div className="width-25 cursorPointer">
      <div data-testid={buttonTestId}>
        <FakeLink onClick={togglePriorityPicker}>
          <Icon
            icon={buttonClasses}
            tooltip={t(buttonTooltip)}
            tooltipPlacement={buttonTooltipPlacement}
            extraClassNames="mr5"
          />
        </FakeLink>
      </div>

      <Overlay
        target={target}
        show={showPriorityPicker}
        placement={placement}
        transition={false}
        popperConfig={{
          modifiers: [
            {
              name: 'preventOverflow',
              options: { boundariesElement: 'window' },
            },
          ],
        }}>
        <Popover
          id="priorityPickerPopover"
          data-testid="priorityPickerPopover"
          className="popover priorityPopover"
          arrowProps={{ ref: null, style: { bottom: '-20px', left: '6px' } }}>
          <Popover.Title className="popover-title">
            {t('INVESTIGATE_TOOLS.THRESHOLD_METRIC.CHOOSE_PRIORITY_LEVEL')}
          </Popover.Title>
          <Popover.Content>
            <div className="picker-close" onClick={closePriorityPicker}>
              <Icon icon="fa-close cursorPointer" />
            </div>
            <div className="flexColumnContainer text-bolder dividerBorderBottom">
              <div className="text-nowrap">{t('INVESTIGATE_TOOLS.THRESHOLD_METRIC.DEFAULT_COLOR')}</div>
              <div className="flexFill text-center">{t('NAME')}</div>
            </div>
            <div className="flexRowContainer positionRelative overflowYAuto msOverflowStyleAuto max-height-275">
              {_.map(
                priorities.filter((priority) => priority.level),
                (priority) => (
                  <div
                    key={priority.level}
                    data-testid={`${priority.name}ThresholdPriority`}
                    className={classNames('positionRelative flexColumnContainer flexNoGrowNoShrink lightGreyBorder', {
                      'cursorPointer hoverBorder focusBorder': !priority.inUse,
                    })}
                    onClick={(event) => selectPriority(event, priority)}>
                    <div
                      className={classNames({
                        selectedOverlay: priority.inUse,
                      })}
                    />
                    <div style={{ backgroundColor: priority.color }} className="width-70" />
                    <div className="flexFill aggressiveWordBreak pl5">{priority.name}</div>
                  </div>
                ),
              )}
              {!isPriorityAvailable() && (
                <div className="positionAbsoluteFill flexRowContainer flexCenter">
                  <div className="mt5 sq-fairly-dark-gray text-italic">
                    {t('INVESTIGATE_TOOLS.THRESHOLD_METRIC.ALL_PRIORITIES_IN_USE')}
                  </div>
                </div>
              )}
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
};
