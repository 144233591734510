/* istanbul ignore file */
import { Plugin } from '@ckeditor/ckeditor5-core';

/**
 * Allows configuration of Seeq specific dependencies for other plugins. Add additional dependencies to
 * `CKEditorPlugins.constants::BasePluginDependencies`
 */
export class PluginDependencies extends Plugin {
  static pluginName = 'PluginDependencies';

  init() {
    // Empty on purpose
  }
}
