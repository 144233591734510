import _ from 'lodash';
import { findItemIn } from '@/trend/trendDataHelper.utilities';
import { sqTrendCapsuleSetStore, sqTrendSeriesStore, sqWorksheetStore } from '@/core/core.stores';
import { groupSignalToCondition } from '@/worksheet/worksheet.actions';
import { TREND_STORES } from '@/trendData/trendData.constants';

/**
 * This file consolidates condition auto grouping logic into a utility that can be called where needed.
 * Auto grouping extends the existing Group capability by automatically grouping signals in the details pane with
 * conditions in the details pane if they share the same parent asset.
 */

/**
 * Auto groups signals under conditions when in capsule group mode
 */
export function evaluate(): void {
  autoGroupSignals({
    payload: {},
  });
}

/**
 * Adds a signal to a condition group when in capsule group mode
 */
export function addSignal({ id, trendStores }: { id: string; trendStores: typeof TREND_STORES }): void {
  autoGroupSignals({
    payload: {
      signals: [findItemIn(trendStores, id)],
      allowAddToPopulatedGroup: true,
    },
  });
}

/**
 * Adds a condition group and groups existing signals when in capsule group mode
 */
export function addCondition({ id }: { id: string }): void {
  autoGroupSignals({
    payload: {
      conditions: [findItemIn([sqTrendCapsuleSetStore], id)],
      allowAddToPopulatedGroup: true,
    },
  });
}

function autoGroupSignals({
  payload: { signals, conditions, allowAddToPopulatedGroup = false },
}: {
  payload: {
    signals?: { id: string; assets: { id: string }[] }[];
    conditions?: { id: string; assets: { id: string }[] }[];
    allowAddToPopulatedGroup?: boolean;
  };
}) {
  if (sqWorksheetStore.capsuleGroupMode) {
    signals = signals ?? sqTrendSeriesStore.nonCapsuleSeriesAndPreview;
    conditions = conditions ?? sqTrendCapsuleSetStore.items;

    _.forEach(conditions, (condition) => {
      const groupHasNoSignals = _.isEmpty(sqWorksheetStore.conditionToSeriesGrouping[condition.id]);

      if (allowAddToPopulatedGroup || groupHasNoSignals) {
        _.forEach(signals, (signal) => {
          const signalAndGroupShareAsset = _.intersectionBy(signal?.assets, condition?.assets, 'id').length > 0;
          const notAlreadyAdded = !_.includes(sqWorksheetStore.conditionToSeriesGrouping[condition.id], signal.id);

          if (signalAndGroupShareAsset && notAlreadyAdded) {
            groupSignalToCondition(signal.id, condition.id);
          }
        });
      }
    });
  }
}
