// @ts-strict-ignore
import HttpCodes from 'http-status-codes';
import _ from 'lodash';
import { sqRequestsApi } from '@/sdk/api/RequestsApi';
import { AxiosResponse } from 'axios';
import { sqWorkbenchStore, sqWorkbookStore } from '@/core/core.stores';
import { base64guid } from '@/utilities/utilities';

/**
 * Test if the argument represents a http response object that is the result of a canceled seeq request. If the
 * argument doesn't appear to be a http response object false is returned
 */
export function isCanceled(response: any) {
  return (
    _.has(response, 'status') &&
    // -1 status indicates that a response didn't come back from the server - this can happen during cancellation
    //   because we hang up on the server rather than waiting for it to respond with a 503. For non-async requests
    //   or the initial part of the async request the underlying xhr request is aborted leaving xhrStatus=abort. For
    //   an async request waiting for a websocket response the cancellation will not change the xhrStatus since
    //   the initial request to the server was successful (xhrStatus=complete). xhrStatus=error likely indicates
    //   a network related problem on the client side
    // 503 'Service Unavailable' is used by the backend to indicate canceled requests, but it can also be used
    //   to indicate other errors such as the server being overloaded (CRAB-14207). For cancellations the server will
    //   respond with a 'The request was canceled.' statusMessage that we can test against.
    ((response.status === -1 && (response.xhrStatus === 'abort' || response.xhrStatus === 'complete')) ||
      (response.status === HttpCodes.SERVICE_UNAVAILABLE &&
        _.includes(_.get(response, 'data.statusMessage', ''), 'canceled')) ||
      (response.status === -1 && response?.statusText === 'canceled'))
  );
}

/**
 * Returns true if the response represents a forbidden (403) respones
 *
 * @param {object} response - the response object
 * @param {number} response.status - the response status code
 * @returns {boolean}
 */
export function isForbidden(response) {
  return response.status === HttpCodes.FORBIDDEN || response.response?.status === HttpCodes.FORBIDDEN;
}

/**
 * Handles forbidden responses by setting redacted to true. The worksheet watches the redacted state and displays
 * a banner if redacted is true.  Returns a caller-supplied deniedResponse in place of the forbidden response so
 * promise chains can continue and subsequent processing can take place.
 *
 * @param {object} response - the response object
 * @param {number} response.status - the response status code
 * @param {string} response.data.statusMessage - the response message
 * @param {any} deniedResponse - the value to be returned in place of the error for forbidden responses. If the
 * status code was not 403, then the original error response is returned.
 */
export function handleForbidden(response, deniedResponse = {}) {
  if (isForbidden(response)) {
    return deniedResponse;
  } else {
    return Promise.reject(response);
  }
}

/**
 * Test if the argument looks like it is an http config objects
 */
export function isHttpConfig(config: any) {
  return _.has(config, 'url') && _.has(config, 'method') && _.has(config, 'headers');
}

/**
 * Test if the argument looks like it is an http response object
 */
export function isHttpResponse(response: any): response is AxiosResponse<any> {
  return _.has(response, 'status') && isHttpConfig(_.get(response, 'config'));
}

/**
 * Test if the argument looks like it is a websocket response with a status message
 */
export function isWebsocketStatusMessage(response: any) {
  return _.has(response, 'channelId') && _.has(response, 'statusMessage');
}

/**
 * Cancels any running request after the preview modal has been closed.
 *
 * @param {string} requestId - The ID of the request to cancel
 * @returns {Promise} that resolves when the request is cancelled
 */
export function cancelRunningRequest(requestId) {
  return sqRequestsApi.cancelRequest({ requestId }).catch(function (response) {
    if (_.get(response, 'status') !== HttpCodes.NOT_FOUND) {
      return Promise.reject(response);
    }
  });
}

/**
 * Generates a request ID by combining a GUID with a prefix grabbed from stateParams
 *
 * @returns (String) the requestId
 */
export function generateRequestId() {
  const baseRequestPrefix = 'R^';
  const uniqueId = baseRequestPrefix + base64guid();
  const prefix = sqWorkbenchStore.stateParams.requestIdPrefix;
  return _.chain([prefix, uniqueId]).compact().join('').value();
}

/**
 * This will retrieve the OriginURL used for Data Consumption metric tracking. If the Analysis is being rendered for
 * a piece of Topic content, the URL will be the view only URL of the Topic in question. Otherwise it will be the
 * view only URL for the current Analysis. For more info:
 * https://seeq.atlassian.net/wiki/spaces/SQ/pages/2355462313/CRAB-29167+Data+Consumption+OriginLabel+and+OriginURL+Tracking
 */
export function getOriginUrl(): string {
  const maybeOriginOverride = sqWorkbenchStore.stateParams.originURL;
  const workbookGuid = sqWorkbenchStore.stateParams.workbookId;
  const worksheetGuid = sqWorkbenchStore.stateParams.worksheetId;
  if ((_.isNil(workbookGuid) || _.isNil(worksheetGuid)) && !maybeOriginOverride) {
    return '';
  }
  return maybeOriginOverride || `/view/worksheet/${workbookGuid}/${worksheetGuid}`;
}

/**
 * This will retrieve the OriginLabel used for Data Consumption metric tracking. The label will be the combined name
 * of the Workbook and Worksheet of the Analysis in question, UNLESS the Analysis is being run by renderer for use
 * as Topic content. In this case, it will be the combined name of the Topic and the Document that this piece of
 * content is in. For more info:
 * https://seeq.atlassian.net/wiki/spaces/SQ/pages/2355462313/CRAB-29167+Data+Consumption+OriginLabel+and+OriginURL+Tracking
 */
export function getOriginLabel(): string {
  const maybeOriginOverride = sqWorkbenchStore.stateParams.originLabel;
  return encodeURIComponent(
    maybeOriginOverride ||
      _.chain([sqWorkbookStore.name, sqWorkbookStore.getWorksheetName(sqWorkbenchStore.stateParams.worksheetId)])
        .reject(_.isEmpty)
        .thru((parts) => (parts.length === 2 ? parts.join(' - ') : ''))
        .value(),
  );
}
