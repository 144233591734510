import { flux } from '@/core/flux.module';
import { TableDefinitionOutputV1 } from '@/sdk';
import { MaterializedTable } from '@/tableDefinitionEditor/tableDefinition.types';

export function setSubscriptionId(id: string | undefined) {
  flux.dispatch('TABLE_DEFINITION_SET_SUBSCRIBER_ID', { subscriberId: id });
}

export function setName(name: string) {
  flux.dispatch('TABLE_DEFINITION_SET_NAME', { name });
}

export function setDescription(description: string) {
  flux.dispatch('TABLE_DEFINITION_SET_DESCRIPTION', { description });
}

export function setTableDefinition(output: TableDefinitionOutputV1) {
  flux.dispatch('TABLE_DEFINITION_SET_TABLE_DEFINITION', { tableDefinition: output });
}

export function resetTableDefinition() {
  flux.dispatch('TABLE_DEFINITION_RESET');
}

export function setMaterializedTable(materializedTable: MaterializedTable | undefined) {
  flux.dispatch('TABLE_DEFINITION_SET_MATERIALIZED_TABLE', { materializedTable });
}

export function setDoTableReload(doTableReload: boolean) {
  flux.dispatch('TABLE_DEFINITION_SET_DO_TABLE_RELOAD', { doTableReload });
}
