import _ from 'lodash';
import { InitializeMode, Store } from '@/core/flux.service';
import {
  DATASOURCES_TO_EXCLUDE,
  HOME_BREADCRUMB,
  SEARCH_BREADCRUMB,
  SEARCH_RESULT_TYPES,
  SearchModes,
  SearchPanes,
} from '@/search/search.constants';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { SEEQ_DATASOURCE } from '@/main/app.constants';
import { ANNOTATION_TYPE } from '@/annotation/annotation.constants';
import { CheckBoxSelectOption } from '@/core/CheckboxSelect.types';
import { AnyProperty } from '@/utilities.types';
import { Datasource, SearchBreadcrumb } from '@/search/search.types';

interface SearchFilters {
  filters: string | string[];
  types: string | string[];
}

const LOCAL_DATASOURCE_CLASSES = [
  SeeqNames.LocalDatasources.FileSignalStorage.DatasourceClass,
  SeeqNames.LocalDatasources.PostgresDatums.DatasourceClass,
  SeeqNames.LocalDatasources.SeeqCalculationItemStorage.DatasourceClass,
  SeeqNames.LocalDatasources.SeeqMetadataItemStorage.DatasourceClass,
];

export abstract class BaseSearchStore extends Store {
  abstract pane: SearchPanes;

  initialize(initializeMode?: InitializeMode) {
    const saveState = this.state && initializeMode === 'SOFT';
    this.state = this.immutable({
      nameFilter: '',
      descriptionFilter: '',
      documentFilter: '',
      typeFilter: [],
      datasourceFilter: [],
      sortBy: '',
      currentAsset: '',
      breadcrumbs: [],
      items: [],
      mode: 'overview',
      isExactName: false,
      hasNextPage: false,
      searching: undefined,
      isPaginating: false,
      isAdvancedMode: false,
      isAsyncInitialized: false,
      localDatasources: saveState ? this.state.get('localDatasources') : [],
      datasources: saveState ? this.state.get('datasources') : [],
      selectAllChecked: false,
      selectedItemIds: [],
      highlightItemId: '',
      displayLoadingId: '',
    });
  }

  get isAsyncInitialized(): boolean {
    return this.state.get('isAsyncInitialized');
  }

  get baseHandlers() {
    return this.handlers;
  }

  get nameFilter(): string {
    return this.state.get('nameFilter');
  }

  get isExactName(): boolean {
    return this.state.get('isExactName');
  }

  get descriptionFilter(): string {
    return this.state.get('descriptionFilter');
  }

  get documentFilter() {
    return this.state.get('documentFilter');
  }

  get typeFilter(): string[] {
    return this.state.get('typeFilter');
  }

  get datasourceFilter(): Datasource[] {
    return this.state.get('datasourceFilter');
  }

  get sortBy() {
    return this.state.get('sortBy');
  }

  get currentAsset() {
    return this.state.get('currentAsset');
  }

  get breadcrumbs(): SearchBreadcrumb[] {
    return this.state.get('breadcrumbs');
  }

  get items() {
    return this.state.get('items');
  }

  get hasNextPage(): boolean {
    return this.state.get('hasNextPage');
  }

  get searching() {
    return this.state.get('searching');
  }

  get isPaginating(): boolean {
    return this.state.get('isPaginating');
  }

  get isAdvancedMode(): boolean {
    return this.state.get('isAdvancedMode');
  }

  get mode(): SearchModes {
    return this.state.get('mode');
  }

  get localDatasources() {
    return this.state.get('localDatasources');
  }

  get datasources(): Datasource[] {
    return this.state.get('datasources');
  }

  get selectAllChecked() {
    return this.state.get('selectAllChecked');
  }

  get selectedItemIds() {
    return this.state.get('selectedItemIds');
  }

  get highlightItemId() {
    return this.state.get('highlightItemId');
  }

  get displayLoadingId() {
    return this.state.get('displayLoadingId');
  }

  /**
   * Switches to advanced or simple mode
   *
   * @param {Object} payload - Object container for arguments
   * @param {String} payload.pane - The search pane, one of SearchPanes
   * @param {Boolean} payload.isAdvancedMode - True for advanced mode, false for simple mode
   */
  setIsAdvancedMode(payload: { pane: SearchPanes; isAdvancedMode: boolean }) {
    if (payload.pane === this.pane) {
      this.state.set('isAdvancedMode', payload.isAdvancedMode);
    }
  }

  /**
   * Clears the search filters.
   *
   * @param {Object} payload - Object container for arguments
   * @param {String} payload.pane - The search pane, one of SearchPanes
   */
  clearFilters(payload: { pane: SearchPanes }) {
    if (payload.pane === this.pane) {
      this.state.merge({
        nameFilter: '',
        descriptionFilter: '',
        documentFilter: '',
        typeFilter: [],
        datasourceFilter: [],
        sortBy: '',
      });
    }
  }

  /**
   * Clears the highlight item
   *
   * @param {Object} payload - Object container for arguments
   * @param {String} payload.pane - The search pane, one of SearchPanes
   */
  clearHighlightItem(payload: { pane: SearchPanes }) {
    if (payload.pane === this.pane) {
      this.state.set('highlightItemId', '');
    }
  }

  /**
   * Switches to one of the search result modes.
   *
   * @param {Object} payload - Object container for arguments
   * @param {String} payload.pane - The search pane, one of SearchPanes
   * @param {Boolean} payload.mode - One of SEARCH_MODES
   */
  setMode(payload: { pane: SearchPanes; mode: string }) {
    if (payload.pane === this.pane) {
      // Don't need to do anything if we're already in the correct mode
      if (payload.mode === this.state.get('mode')) {
        return;
      }

      // Some modes require setting portions of the state.
      if (payload.mode === 'overview') {
        // Clear and refresh when going to Overview Mode
        this.state.merge({
          searching: undefined,
          isPaginating: false,
          currentAsset: '',
          breadcrumbs: [],
          items: [],
        });
        this.clearFilters(payload);
      } else if (payload.mode === 'pinned') {
        // Set breadcrumbs when going to Pinned Mode
        this.state.set('breadcrumbs', [
          HOME_BREADCRUMB,
          {
            name: 'SEARCH_DATA.PINNED',
            id: 'PINNED',
            type: 'VIEW_MODE',
          },
        ]);
        this.clearFilters(payload);
        this.setIsAdvancedMode({
          pane: payload.pane,
          isAdvancedMode: false,
        });
      } else if (payload.mode === 'recent') {
        // Set breadcrumbs when going to Recently Accessed Mode
        this.state.set('breadcrumbs', [
          HOME_BREADCRUMB,
          {
            name: 'SEARCH_DATA.RECENTLY_ACCESSED',
            id: 'RECENTLY_ACCESSED',
            type: 'VIEW_MODE',
          },
        ]);
        this.setIsAdvancedMode({
          pane: payload.pane,
          isAdvancedMode: false,
        });
        this.clearFilters(payload);
      } else if (payload.mode === 'search') {
        if (this.state.get('mode') === 'tree') {
          // When going from Asset Tree Mode to Search Mode, add Search Results to the end of the breadcrumbs
          this.state.set('breadcrumbs', this.state.get('breadcrumbs').concat(SEARCH_BREADCRUMB));
        } else {
          // Otherwise, set the breadcrumbs to Home -> Search Results
          this.state.set('breadcrumbs', [HOME_BREADCRUMB, SEARCH_BREADCRUMB]);
        }
      } else if (payload.mode === 'asset-groups') {
        // Set breadcrumbs when going to Recently Accessed Mode
        this.state.set('breadcrumbs', [
          HOME_BREADCRUMB,
          {
            name: 'SEARCH_DATA.ASSET_GROUPS',
            id: 'ASSET_GROUPS',
            type: 'VIEW_MODE',
          },
        ]);
        this.setIsAdvancedMode({
          pane: payload.pane,
          isAdvancedMode: false,
        });
        this.clearFilters(payload);
      } else if (payload.mode === 'usages') {
        this.clearFilters(payload);
      }

      this.state.set('mode', payload.mode);
    }
  }

  private getFilterTransform(filterKey: string): (value: string) => string {
    const filterTransforms: AnyProperty<(value: string) => string> = {
      name: this.isExactName ? (value: string) => `/^${value}$/i` : _.identity,
    };

    return filterTransforms[filterKey] ?? _.identity;
  }

  /**
   * Get filters for the ItemsApi search query
   *
   * @param payload - Object container for arguments
   * @param payload.searchTypes - array of item types to search for if the typeFilter is empty
   */
  getSearchFilters(searchTypes?: string[]): SearchFilters {
    let types: string | string[];
    let filters: string | string[];

    const andFilters = _.chain(['name', 'description'])
      .map((filterKey) => [filterKey, this.state.get(`${filterKey}Filter`)])
      .reject(([filterKey, value]) => _.isEmpty(value))
      .map(([filterKey, value]) => `${filterKey}~=${this.getFilterTransform(filterKey)(value)}`)
      .join('&&')
      .value();

    // Replace general Seeq local datasource with actual local datasources (e.g. PostgresDatums)
    let datasourceFilter = this.state.get('datasourceFilter');
    if (_.some(datasourceFilter, (datasource) => datasource.datasourceClass === SEEQ_DATASOURCE.datasourceClass)) {
      datasourceFilter = datasourceFilter.concat(this.state.get('localDatasources'));
      datasourceFilter = _.reject(
        datasourceFilter,
        (datasource) => datasource.datasourceClass === SEEQ_DATASOURCE.datasourceClass,
      );
    }
    const orFilters = _.map(
      datasourceFilter,
      (datasource: any) =>
        `datasource class==${datasource.datasourceClass}&&datasource id==${datasource.datasourceId}&&${andFilters}`,
    );

    // If the user has supplied a document filter, then search journal entries
    const documentFilter = this.state.get('documentFilter');
    const typeFilter = this.state.get('typeFilter');
    if (!_.isEmpty(documentFilter)) {
      // Search the 'Plain Text Document' field to avoid returning results for HTML
      filters = `Plain Text Document~=${documentFilter}`;
      types = [ANNOTATION_TYPE.JOURNAL];
    } else {
      filters = orFilters.length ? orFilters : andFilters;
      types = _.isEmpty(typeFilter) ? searchTypes : typeFilter;
    }

    return { filters, types };
  }

  readonly handlers = {
    /**
     * Marks that this store has had its initial async data fetched.
     *
     * @param {Object} payload - Object container for arguments
     * @param {string} payload.pane - The search pane, one of SearchPanes
     * @param {boolean} payload.isAsyncInitialized - True if the initial async data has been fetched
     */

    SEARCH_ASYNC_INITIALIZED: (payload: { pane: SearchPanes; isAsyncInitialized: boolean }) => {
      if (payload.pane === this.pane) {
        this.state.set('isAsyncInitialized', payload.isAsyncInitialized);
      }
    },

    /**
     * Initiates a search.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.pane - The search pane, one of SearchPanes
     */

    SEARCH_INITIATE: (payload: { pane: SearchPanes }) => {
      if (payload.pane === this.pane) {
        this.state.merge({
          searching: SEARCH_RESULT_TYPES.ITEMS,
          isPaginating: false,
          items: [],
          selectAllChecked: false,
          selectedItemIds: [],
        });
        this.state.commit();
        this.setMode({ pane: payload.pane, mode: 'search' });
      }
    },

    /**
     * Initiates a search for usages.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.pane - The search pane, one of SearchPanes
     */
    SEARCH_INITIATE_USAGES: (payload: { pane: SearchPanes }) => {
      if (payload.pane === this.pane) {
        this.state.merge({
          searching: SEARCH_RESULT_TYPES.ITEMS,
          isPaginating: false,
          items: [],
        });
        this.state.commit();
        this.setMode({ pane: payload.pane, mode: 'usages' });
      }
    },

    /**
     * Finishes a search.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.pane - The search pane, one of SearchPanes
     * */

    SEARCH_FINISH: (payload: { pane: SearchPanes }) => {
      if (payload.pane === this.pane) {
        this.state.merge({ searching: undefined, isPaginating: false });
      }
    },

    /**
     * Sets the name filter.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.pane - The search pane, one of SearchPanes
     * @param {String} payload.nameFilter - The name for which to search
     */
    SEARCH_SET_NAME_FILTER: (payload: { pane: SearchPanes; nameFilter: string }) => {
      if (payload.pane === this.pane) {
        this.state.set('nameFilter', payload.nameFilter);
      }
    },

    /**
     * Sets `isExactName`, indicating if search should be done with the exact name (/^<name>$/)
     */
    SEARCH_IS_EXACT_NAME: (payload: { pane: SearchPanes; isExactName: boolean }) => {
      if (payload.pane === this.pane) {
        this.state.set('isExactName', payload.isExactName);
      }
    },

    /**
     * Sets the description filter.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.pane - The search pane, one of SearchPanes
     * @param {String} payload.descriptionFilter - The description for which to search
     */
    SEARCH_SET_DESCRIPTION_FILTER: (payload: { pane: SearchPanes; descriptionFilter: string }) => {
      if (payload.pane === this.pane) {
        this.state.set('descriptionFilter', payload.descriptionFilter);
      }
    },

    /**
     * Sets the document filter.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.pane - The search pane, one of SearchPanes
     * @param {String} payload.documentFilter - The document for which to search
     */
    SEARCH_SET_DOCUMENT_FILTER: (payload: { pane: SearchPanes; documentFilter: string }) => {
      if (payload.pane === this.pane) {
        this.state.set('documentFilter', payload.documentFilter);
      }
    },

    /**
     * Sets the breadcrumbs that should be shown in the data tab.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.pane - The search pane, one of SearchPanes
     * @param {Object[]} payload.breadcrumbs - an array of breadcrumbs containing breadcrumb objects
     */
    SEARCH_SET_BREADCRUMBS: (payload: {
      pane: SearchPanes;
      breadcrumbs: { name?: string; id?: string; type?: string }[];
    }) => {
      if (payload.pane === this.pane) {
        this.state.set('breadcrumbs', payload.breadcrumbs);
      }
    },

    /**
     * Append the breadcrumb to breadcrumbs or lose everything after the provided breadcrumb if it already exists
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.pane - The search pane, one of SearchPanes
     * @param {Object} payload.breadcrumb - a breadcrumb to append or slice at
     */
    SEARCH_APPEND_OR_REPLACE_BREADCRUMB: (payload: {
      pane: SearchPanes;
      breadcrumb: { name?: string; id?: string; type?: string };
    }) => {
      if (payload.pane === this.pane) {
        const index = _.findIndex(this.state.get('breadcrumbs'), ['id', payload.breadcrumb.id]);

        if (index >= 0) {
          this.state.splice('breadcrumbs', [index + 1]);
        } else {
          this.state.push('breadcrumbs', payload.breadcrumb);
        }
      }
    },
    // SEARCH_CUT_BREADCRUMBS: 'cutBreadcrumbs',

    /**
     * Update the state with the new list of checked options, overwriting the old list
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.pane - The search pane, one of SearchPanes
     * @param {String} payload.types - The item types to filter by
     */
    SEARCH_SET_SELECTED_TYPES: (payload: { pane: SearchPanes; types: CheckBoxSelectOption[] }) => {
      if (payload.pane === this.pane) {
        const values = _.map(payload.types, (type) => type.value);
        this.state.set('typeFilter', values);
      }
    },

    /**
     * Sets the asset browser to the specified asset and sets the searching flag
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.pane - The search pane, one of SearchPanes
     * @param {String} payload.asset - the id that specifies an item
     */
    SEARCH_EXPLORE_ASSET: (payload: { pane: SearchPanes; asset: string }) => {
      if (payload.pane === this.pane) {
        this.state.merge({
          searching: SEARCH_RESULT_TYPES.ASSETS,
          mode: payload.asset === '' ? 'overview' : 'tree',
          currentAsset: payload.asset,
          items: [],
        });
        this.state.commit();
        this.clearHighlightItem(payload);
      }
    },

    /**
     * Toggles a Datasource in the list of datasource filters. If datasource is found it is removed, else it is
     * added.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.pane - The search pane, one of SearchPanes
     * @param {Object} payload.datasources - The datasources to filter by.
     */
    SEARCH_SET_SELECTED_DATASOURCES: (payload: {
      pane: SearchPanes;
      datasources: {
        label: string;
        value: Datasource;
      }[];
    }) => {
      if (payload.pane === this.pane) {
        const values = _.map(payload.datasources, (datasource) => datasource.value);
        this.state.set('datasourceFilter', values);
      }
    },

    SEARCH_SET_MODE: this.setMode,
    SEARCH_SET_ADVANCED_MODE: this.setIsAdvancedMode,

    /**
     * Process the results of fetching the search results.
     * We will always concatenate results to items as we reset them to an empty array at the beginning of each
     * search.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.pane - The search pane, one of SearchPanes
     * @param {Object[]} payload.items - The search results
     * @param {Boolean} payload.hasNextPage - True if there are more results that can be fetched
     */
    SEARCH_RESULTS_SUCCESS: (payload: { pane: SearchPanes; items: { id: string }[]; hasNextPage: boolean }) => {
      if (payload.pane === this.pane) {
        this.state.set('items', _.uniqBy([...this.state.get('items'), ...payload.items], 'id'));
        this.state.set('hasNextPage', payload.hasNextPage);
      }
    },

    /**
     * Begin the process of loading the next page by setting paginating to true
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.pane - The search pane, one of SearchPanes
     */
    SEARCH_LOAD_NEXT_PAGE: (payload: { pane: SearchPanes }) => {
      if (payload.pane === this.pane) {
        this.state.set('isPaginating', true);
      }
    },

    /**
     * Clears search state.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.pane - The search pane, one of SearchPanes
     */
    SEARCH_CLEAR: (payload: { pane: SearchPanes }) => {
      if (payload.pane === this.pane) {
        this.initialize('SOFT');
      }
    },

    /**
     * Set datasources to show in dropdown, and local datasources to query when we encounter SEEQ_DATASOURCE
     *
     * @param payload - Object container for arguments
     * @param payload.pane - The search pane, one of SearchPanes
     * @param payload.datasources - List of datasources to
     */
    SEARCH_SET_DATASOURCES: (payload: {
      pane: SearchPanes;
      datasources: {
        id: string;
        name: string;
        datasourceClass: string;
        datasourceId: string;
      }[];
    }) => {
      if (payload.pane === this.pane) {
        const localDatasources = _.filter(payload.datasources, (datasource) =>
          _.includes(LOCAL_DATASOURCE_CLASSES, datasource.datasourceClass),
        );
        this.state.set('localDatasources', localDatasources);

        const datasourcesToSet = _.chain(payload.datasources)
          .map(({ id, name, datasourceId, datasourceClass }) => ({
            id,
            name,
            datasourceId,
            datasourceClass,
          }))
          .reject((datasource) => _.includes(DATASOURCES_TO_EXCLUDE, datasource.datasourceClass))
          .reject((datasource) => _.includes(LOCAL_DATASOURCE_CLASSES, datasource.datasourceClass))
          .concat(SEEQ_DATASOURCE)
          .value();
        this.state.set('datasources', datasourcesToSet);
      }
    },

    /**
     * Sets the sort order.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.pane - The search pane, one of SearchPanes
     * @param {String} payload.sortBy - The property to sort by
     */
    SEARCH_SET_SORT_BY: (payload: { pane: SearchPanes; sortBy: string }) => {
      if (payload.pane === this.pane) {
        this.state.set('sortBy', payload.sortBy);
      }
    },

    /**
     * Sets the ID of the item to highlight
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.pane - The search pane, one of SearchPanes
     * @param {String} payload.id- The ID of the item to highlight
     */
    SEARCH_SET_HIGHLIGHT_ITEM: (payload: { pane: SearchPanes; id: string }) => {
      if (payload.pane === this.pane) {
        this.state.set('highlightItemId', payload.id);
      }
    },

    /**
     * Sets the ID of the display that is currently loading
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.pane - The search pane, one of SearchPanes
     * @param {boolean} payload.id - the id of the display being loaded
     */
    SEARCH_SET_DISPLAY_LOADING: (payload: { pane: SearchPanes; id: string }) => {
      if (payload.pane === this.pane) {
        this.state.set('displayLoadingId', payload.id);
      }
    },
  };
}
