// @ts-strict-ignore
import { sqFormulasApi } from '@/sdk/api/FormulasApi';
import _ from 'lodash';
import { FORMULA_RETURN_FIELDS, FORMULA_RETURN_TYPES, RunFormulaInput } from './formula.constants';
import { encodeParameters, generateTemporaryId } from './utilities';

/**
 * Run a formula and return its result. This has convenience parameters to allow for fetching the results for a
 * specific item by allowing the id to be passed in and defaulting the formula to $series.
 *
 * @param typeKey - The type(s) of result, one of the keys from FORMULA_RETURN_TYPES
 * @param args - The arguments for running the formula
 *
 * @returns {Promise} Resolves with the result that corresponds to the returnType. Rejects if the specified
 *   returnType does not match the returnType returned by the API.
 */
export function runFormula(typeKey: string | string[], args: RunFormulaInput): Promise<any> {
  typeKey = _.flatten([typeKey]);
  const formattedStart = _.isObject(args.range?.start) ? (args.range.start as any).toISOString() : args.range?.start;
  const formattedEnd = _.isObject(args.range?.end) ? (args.range.end as any).toISOString() : args.range?.end;
  const body = {
    start: _.isEmpty(args.range) ? undefined : formattedStart,
    end: _.isEmpty(args.range) ? undefined : formattedEnd,
    limit: args.limit,
    formula: undefined,
    parameters: undefined,
    root: args.root,
    reduceFormula: args.reduceFormula,
    timeFormat: 'Nanoseconds',
  };

  /**
   * If there is/are formula fragment(s), then the ID is the ID of a previously compiled formula functions that we
   * need to evaluate with the fragment(s). Otherwise it's a standard formula with parameters.
   */
  if (args.fragments) {
    _.assign(body, {
      _function: args.id,
      fragments: encodeParameters(args.fragments),
    });
  } else {
    if (args.formula || args.id) {
      body.formula = args.formula || '$series';
    }

    if (args.parameters || args.id) {
      body.parameters = encodeParameters(args.parameters || { series: args.id });
    }
  }

  const run = (body, config) =>
    args.usePost ? sqFormulasApi.runFormula_1(body, config) : sqFormulasApi.runFormula(body, config);

  return run(body, { cancellationGroup: args.cancellationGroup }).then(({ headers: rawHeaders, data }) => {
    let returnKey;
    const allowedTypes = _.filter(FORMULA_RETURN_TYPES, (value, key) => _.includes(typeKey, key));

    if (!_.includes(allowedTypes, data.returnType)) {
      return Promise.reject(`Expected formula to return ${allowedTypes.join(', ')}, but data is ${data.returnType}`);
    }

    returnKey = _.findKey(FORMULA_RETURN_TYPES, _.partial(_.isEqual, data.returnType));

    const headers = {} as any;
    if (!_.isNil(rawHeaders['server-timing'])) {
      headers.timingInformation = rawHeaders['server-timing'];
    }
    if (!_.isNil(rawHeaders['server-meters'])) {
      headers.meterInformation = rawHeaders['server-meters'];
    }

    // Ensures that no capsules are missing IDs which can happen with unbounded capsules
    if (_.has(data, 'capsules')) {
      data.capsules.capsules = _.map(data.capsules.capsules, (capsule) =>
        capsule.id ? capsule : _.assign({}, capsule, { id: generateTemporaryId() }),
      );
    }

    const returnFields = FORMULA_RETURN_FIELDS[returnKey];
    if (!_.isArray(returnFields)) {
      return _.assign(headers, data[returnFields], _.pick(data, ['warningCount', 'warningLogs']));
    }

    if (returnKey === 'SAMPLE_AND_STATS') {
      (data as any).valueUnitOfMeasure = data.samples.valueUnitOfMeasure ? data.samples.valueUnitOfMeasure : '';
      (data as any).samples = data.samples.samples;
      (data as any).interpolationMethod = data.metadata['Interpolation Method'];
    }

    return _.assign(headers, _.pick(data, _.union(returnFields, ['warningCount', 'warningLogs'])));
  });
}
