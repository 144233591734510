import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { FormulaDocumentationHeader } from '@/formula/FormulaDocumentationHeader.molecule';
import { FORMULA_HOME, FORMULA_LIST, SEARCH } from '@/tools/formula/formulaTool.constants';
import { FormulaFullListView } from '@/formula/FormulaFullListView.molecule';
import { FormulaPageView } from '@/formula/FormulaPageView.organism';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { getFormulaDocumentation } from '@/formula/formula.utilities';
import { useIsMounted } from '@/core/hooks/useIsMounted.hook';
import { EnglishOnlyWarning } from '@/core/EnglishOnlyWarning.atom';
import { FunctionDocItem } from '@/tools/formula/formulaTool.types';
import { sqFormulaToolStore } from '@/core/core.stores';
import { setFormulaFilter, setNavigationStack } from '@/tools/formula/formulaTool.actions';

interface FormulaDocumentationProps {
  insertFormulaSnippet: (snippet: string) => void;
  operators: any;
  functions: any;
  height?: number;
}

export const FormulaDocumentation: React.FunctionComponent<FormulaDocumentationProps> = ({
  insertFormulaSnippet,
  operators,
  functions,
  height,
}) => {
  const [helpText, setHelpText] = useState<any>();
  const [fullListView, setFullListView] = useState(false);
  const [backEnabled, setBackEnabled] = useState(true);
  const [functionFilter, setFunctionFilter] = useState<string>();
  const [functionsList, setFunctionsList] = useState([]);
  const [functionHasNoHelp, setFunctionHasNoHelp] = useState(false);
  const [displayedNavStack, setDisplayedNavStack] = useState<string[]>();

  const navigationStack = useFluxPath(sqFormulaToolStore, () => sqFormulaToolStore.navigationStack);
  const functionFilterFromStore = useFluxPath(sqFormulaToolStore, () => sqFormulaToolStore.formulaFilter);

  const isMounted = useIsMounted();
  // context-sensitive help for more than one match
  useEffect(() => {
    if (functionFilterFromStore !== undefined && functionFilterFromStore !== functionFilter) {
      setFunctionFilter(functionFilterFromStore);
      clearHelpText(functionFilterFromStore);
      setFormulaFilter(undefined);
    }
  }, [functionFilterFromStore]);

  // context-sensitive help for exact match
  useEffect(() => {
    if (_.last(displayedNavStack) !== _.last(navigationStack) && !functionFilterFromStore) {
      setFunctionFilter('');
      navigateByStep(_.last(navigationStack));
    }
  }, [navigationStack]);

  useEffect(() => {
    if (!functionFilterFromStore) {
      navigateByStep(_.last(navigationStack));
    }
  }, []);

  const loadFullList = () => {
    setFunctionFilter(undefined);
    setFunctionsList(functions);
    setHelpText('');
    setFullListView(true);

    addToNavigationStack(FORMULA_LIST);
  };

  const updateNavigationStack = (newNavigationStack: string[]) => {
    setDisplayedNavStack(newNavigationStack);
    setNavigationStack(newNavigationStack);
  };

  const navigateByStep = (step?: string) => {
    switch (step) {
      case undefined:
        return navigate(FORMULA_HOME);
      case FORMULA_LIST:
        return loadFullList();
      case SEARCH:
        return goBack();
      default:
        return navigate(step);
    }
  };

  const goBack = () => {
    setFormulaFilter(undefined);
    setFunctionFilter(undefined);
    let tempNavigationStack: string[] = _.dropRight(navigationStack);
    // in theory navigateByStep should take care of this but there is a delay in getting the updated navigationStack
    // from the store that results in a stack overflow exception. Filtering out the search step here prevents this
    // and ensures expected behavior
    while (_.last(tempNavigationStack) === SEARCH) {
      tempNavigationStack = _.dropRight(tempNavigationStack);
    }

    updateNavigationStack(tempNavigationStack);
    navigateByStep(_.last(tempNavigationStack));
  };

  const goHome = () => {
    setFormulaFilter(undefined);
    setFunctionFilter(undefined);
    setFullListView(false);

    if (navigationStack.length !== 0 && helpText?.documentationHref !== FORMULA_HOME) {
      updateNavigationStack(_.concat(navigationStack, FORMULA_HOME));
    }

    return navigate(FORMULA_HOME);
  };

  const navigate = (documentationHref: string, goBack = false) => {
    setBackEnabled(false);
    return getFormulaDocumentation(documentationHref)
      .then((data) => {
        if (isMounted.current) {
          const formulaHelp = document.querySelector('.formulaHelp .overflowAuto');
          if (formulaHelp) {
            formulaHelp.scrollTop = 0;
          }
          setHelpText(data.data as string);
          setFunctionHasNoHelp(false);
        }
      })
      .catch(() => {
        setHelpText(undefined);
        setFunctionHasNoHelp(true);
        // Best to restart with a clean navigationStack because the top page is invalid
        updateNavigationStack([]);
      })
      .finally(() => {
        if (isMounted.current) {
          setBackEnabled(true);
          setFullListView(false);
        }
      });
  };

  const requestDocumentation = (documentationHref: string) => {
    setFunctionFilter(undefined);
    updateNavigationStack(_.concat(navigationStack, documentationHref));

    return navigate(documentationHref);
  };

  const clearHelpText = (searchText: string) => {
    setFunctionFilter(searchText);
    setHelpText(undefined);
    setFunctionHasNoHelp(false);
    setFullListView(true);
    setFunctionsList(searchText ? getFilteredFunctions(searchText) : operators);
    addToNavigationStack(SEARCH);
  };

  const addToNavigationStack = (item: any) => {
    if (_.last(navigationStack) !== item) {
      updateNavigationStack(_.concat(navigationStack, item));
    }
  };

  const getFilteredFunctions = (searchText: string) => {
    const lowerCasedSearchText = searchText.toLowerCase();

    const search = (pred: (FunctionDocItem: any) => boolean): FunctionDocItem[] =>
      _.chain(operators)
        .filter(pred)
        .orderBy((item: FunctionDocItem) => item.name.toLowerCase())
        .value();

    const excelSynonymEquals = search((item) =>
      _.some(item.excelSynonyms, (syn) => _.startsWith(syn.toLowerCase(), lowerCasedSearchText)),
    );
    const nameStartMatches = search((item) => _.startsWith(item.name.toLowerCase(), lowerCasedSearchText));
    const nameMatches = search((item) => _.includes(item.name.toLowerCase(), lowerCasedSearchText));
    const shortDescriptionMatches = search((item) =>
      _.includes(item.shortDescription.toLowerCase(), lowerCasedSearchText),
    );
    const keywordMatches = search((item) =>
      _.some(item.keywords, (kw) => _.includes(kw.toLowerCase(), lowerCasedSearchText)),
    );
    const descriptionMatches = search((item) => _.includes(item.description.toLowerCase(), lowerCasedSearchText));

    return _.chain<FunctionDocItem[]>([])
      .concat(excelSynonymEquals)
      .concat(nameStartMatches)
      .concat(nameMatches)
      .concat(shortDescriptionMatches)
      .concat(keywordMatches)
      .concat(descriptionMatches)
      .uniqWith(_.isEqual)
      .value();
  };

  return (
    <>
      <FormulaDocumentationHeader
        backEnabled={backEnabled}
        functionFilter={functionFilter}
        clearHelpText={clearHelpText}
        loadFullList={loadFullList}
        goBack={goBack}
        goHome={goHome}
      />
      <div style={{ height: height ? height - 120 : '560px' }} className="overflowYAuto pb16">
        <div className="mt10">
          <EnglishOnlyWarning />
        </div>

        <div className="mt15 pr5">
          {!helpText && !functionHasNoHelp && fullListView && (
            <FormulaFullListView
              functions={functionsList}
              functionFilter={functionFilter}
              requestDocumentation={requestDocumentation}
            />
          )}

          {helpText && !fullListView && (
            <FormulaPageView
              insertFormulaSnippet={insertFormulaSnippet}
              helpText={helpText}
              functionHasNoHelp={functionHasNoHelp}
              requestDocumentation={requestDocumentation}
            />
          )}
        </div>
      </div>
    </>
  );
};
