export enum WORKBOOK_DISPLAY {
  EDIT = 'WORKBOOK_DISPLAY.EDIT',
  VIEW = 'WORKBOOK_DISPLAY.VIEW',
  PRESENT = 'WORKBOOK_DISPLAY.PRESENT',
}

export const DEFAULT_WORKBOOK_STATE = { stores: {} };

/**
 * The Flux store for workbook state
 */
export const LOAD_STATUS = {
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  ERROR: 'ERROR',
  NOT_LOADED: 'NOT_LOADED',
};
