// @ts-strict-ignore
import { TrackerService } from '@/track/tracker.service';
import { PIWIK_SITE_ID, PIWIK_URL } from '@/track/track.constants';
import { seeqVersion } from '@/services/systemConfiguration.utilities';

export const PIWIK_HEARTBEAT_SECONDS = 60;

export default class PiwikTrackingService extends TrackerService {
  private static serviceInstance?: PiwikTrackingService;
  readonly piwikTrack?;

  constructor({ companyName, serverEmail, userName, userEmail, contractNumber, processPiwikRequest }) {
    super();
    if (PiwikTrackingService.serviceInstance) {
      return PiwikTrackingService.serviceInstance;
    }
    this.piwikTrack = Piwik.getTracker(PIWIK_URL, PIWIK_SITE_ID);
    this.piwikTrack.setUserId(userEmail);
    this.piwikTrack.enableLinkTracking();
    this.piwikTrack.setCustomVariable(1, 'companyName', companyName, 'visit');
    this.piwikTrack.setCustomVariable(2, 'serverEmail', serverEmail, 'visit');
    this.piwikTrack.setCustomVariable(3, 'serverVersion', seeqVersion(), 'visit');
    this.piwikTrack.setCustomVariable(4, 'userName', userName, 'visit');
    this.piwikTrack.setCustomVariable(5, 'contractNumber', contractNumber, 'visit');
    // Essential to ensure accurate page visit time. Otherwise visit time is only tracked when events fire, and many
    // parts of our app do not fire tracking events.
    this.piwikTrack.enableHeartBeatTimer(PIWIK_HEARTBEAT_SECONDS);
    this.piwikTrack.setCustomRequestProcessing(processPiwikRequest);
    PiwikTrackingService.serviceInstance = this;
  }

  trackEvent(category, action, information, uniqueEventId) {
    this.piwikTrack.trackEvent(category, action, information, uniqueEventId);
  }

  // Piwik always tracks because the processPiwikRequest logs to the local server and then takes care of checking if
  // it should also log to remote the piwik server
  shouldTrack(): boolean {
    return true;
  }

  reset() {
    delete PiwikTrackingService.serviceInstance;
  }
}
