import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { APPSERVER_API_CONTENT_TYPE } from '@/main/app.constants';
import {
  cancellationRequestInterceptor,
  cancellationResponseErrorInterceptor,
  cancellationResponseInterceptor,
} from '@/requests/interceptors/requestCancellation.interceptor';
import {
  asyncHttpRequestInterceptor,
  asyncHttpResponseInterceptor,
  asyncResponseErrorInterceptor,
} from '@/requests/interceptors/async.interceptor';
import { postInterceptor } from '@/requests/interceptors/post.interceptor';
import {
  authenticationRequestInterceptor,
  authenticationResponseInterceptor,
} from '@/requests/interceptors/authentication.interceptor';
import { requestOriginInterceptor } from '@/requests/interceptors/requestOrigin.interceptor';
import { sessionIdInterceptor } from '@/requests/interceptors/sessionId.interceptor';
import { handleInterceptError } from '@/requests/interceptors/error.interceptor';
import { versionInterceptor } from '@/requests/interceptors/version.interceptor';
import { conflictRetryInterceptor } from '@/requests/interceptors/retry.interceptor';
import HttpCodes from 'http-status-codes';
import { addRequest, removeRequest } from './axios.actions';
import { sqAxiosStore } from '@/core/core.stores';

const axiosInstance = axios.create({});
axiosInstance.defaults.headers.common['Accept'] = APPSERVER_API_CONTENT_TYPE;
axiosInstance.interceptors.request.use(postInterceptor, handleInterceptError);
axiosInstance.interceptors.request.use(authenticationRequestInterceptor, handleInterceptError);
axiosInstance.interceptors.request.use(requestOriginInterceptor, handleInterceptError);
axiosInstance.interceptors.request.use(sessionIdInterceptor, handleInterceptError);
axiosInstance.interceptors.request.use(asyncHttpRequestInterceptor, handleInterceptError);
axiosInstance.interceptors.request.use(cancellationRequestInterceptor, handleInterceptError);
axiosInstance.interceptors.request.use(incrementPendingRequests, decrementPendingRequestsError);

// response interceptor first callback is if status is <=200, second callback to handle errors
axiosInstance.interceptors.response.use((response) => response, authenticationResponseInterceptor);
axiosInstance.interceptors.response.use(versionInterceptor, handleInterceptError);
axiosInstance.interceptors.response.use((response) => response, conflictRetryInterceptor);
axiosInstance.interceptors.response.use((response) => response, handleInterceptError);
axiosInstance.interceptors.response.use(asyncHttpResponseInterceptor, asyncResponseErrorInterceptor);
axiosInstance.interceptors.response.use(cancellationResponseInterceptor, cancellationResponseErrorInterceptor);
axiosInstance.interceptors.response.use(decrementPendingRequests, decrementPendingRequestsError);

/**
 * Exposes an instance of our properly configured axios-instance to the SDK
 */
export function getAxiosInstance() {
  return axiosInstance;
}

function decrementPendingRequests(response: SeeqAxiosResponse) {
  removeRequest();
  return Promise.resolve(response);
}

function incrementPendingRequests(config: SeeqInternalAxiosRequestConfig) {
  addRequest();
  return config;
}

function decrementPendingRequestsError(error: any) {
  removeRequest();
  return Promise.reject(error);
}

export function getPendingRequestCount() {
  return sqAxiosStore.pendingRequests;
}

/**
 * In order for Notifications to properly display we need to ensure we return a proper Error.
 * This functions extracts a proper Error from the axios error that is returned from http Requests that are
 * unsuccessful.
 */
export function extractError(errorOrResponse: any) {
  if (errorOrResponse?.response?.status >= HttpCodes.BAD_REQUEST) {
    return new Error(errorOrResponse.response.data?.statusMessage || errorOrResponse.response.data);
  }
  if (errorOrResponse.request && !errorOrResponse.data) {
    return new Error(errorOrResponse.request);
  }
  if (errorOrResponse.config && !errorOrResponse.data) {
    return new Error(errorOrResponse.config);
  }
  return errorOrResponse?.data?.statusMessage || errorOrResponse;
}

/**
 * Determines if the error originated from axios. For some reason the check for isAxiosError is not always reliable.
 *
 * @param error The error
 */
export function isAxiosError(error: unknown): error is AxiosError {
  return axios.isAxiosError(error) || (error as any)?.name === 'AxiosError';
}

export interface SeeqAxiosRequestConfig<D = any> extends AxiosRequestConfig<D> {
  cancellationGroup?: string;
  cancelOnServer?: boolean;
  useManualAsync?: boolean;
  asyncDisabled?: boolean;
  ignoreLoadingBar?: boolean;
}

export interface SeeqInternalAxiosRequestConfig extends SeeqAxiosRequestConfig {
  headers: AxiosRequestHeaders;
}

export interface SeeqAxiosResponse extends AxiosResponse {
  config: SeeqInternalAxiosRequestConfig;
}
