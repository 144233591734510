// @ts-strict-ignore
import { flux } from '@/core/flux.module';
import { generateFormula } from '@/tools/manualSignal/manualSignal.utilities.service';
import { cancelPreviewSeries, generatePreviewSeries, removePreviewSeries } from '@/trendData/trend.actions';
import { PREVIEW_ID } from '@/trendData/trendData.constants';
import { sqInvestigateStore, sqManualSignalStore } from '@/core/core.stores';
import { DEBOUNCE } from '@/core/core.constants';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { asyncDebounce } from '@/utilities/asyncDebounce';

export function setInterpolationMethod(interpolationMethod) {
  manualSignalDispatch('MANUAL_SIGNAL_SET_INTERPOLATION_METHOD', {
    interpolationMethod,
  });
}

export function setMaximumInterpolation(maximumInterpolation) {
  manualSignalDispatch('MANUAL_SIGNAL_SET_MAXIMUM_INTERPOLATION', {
    maximumInterpolation,
  });
}

export function setUom(uom) {
  manualSignalDispatch('MANUAL_SIGNAL_SET_UOM', { uom });
}

export function addSample(sample) {
  manualSignalDispatch('MANUAL_SIGNAL_ADD_SAMPLE', { sample });
}

export function removeSample(id) {
  manualSignalDispatch('MANUAL_SIGNAL_REMOVE_SAMPLE', { id });
}

export function updateSample(sample) {
  manualSignalDispatch('MANUAL_SIGNAL_UPDATE_SAMPLE', { sample });
}

export function setEditingSampleId(editingSampleId) {
  manualSignalDispatch('MANUAL_SIGNAL_SET_EDITING_SAMPLE_ID', { editingSampleId });
}

export function setSort(sort) {
  manualSignalDispatch('MANUAL_SIGNAL_SET_SORT', { sort });
}

export function setColor(color: string): void {
  manualSignalDispatch('MANUAL_SIGNAL_SET_COLOR', { color });
}

const triggerManualSignalPreviewDebounced = asyncDebounce(generateManualSignalPreview, DEBOUNCE.PREVIEW);

export function manualSignalDispatch(handler: string, payload: any): void {
  flux.dispatch(handler, payload);
  triggerManualSignalPreviewDebounced();
}

export function generateManualSignalPreview() {
  removePreviewSeries();
  const { samples, id, color } = sqManualSignalStore;
  if (sqInvestigateStore.activeTool === TREND_TOOLS.MANUAL_SIGNAL && samples.length > 0) {
    const formula = generateFormula();
    return generatePreviewSeries(formula, {}, id ? id : PREVIEW_ID, color).catch(() => removePreviewSeries());
  } else {
    cancelPreviewSeries();
  }
}
