import React, { useEffect } from 'react';
import { ChartView, ChartViewActions, ChartViewConfiguration, ChartViewData } from '@/trend/ChartView.organism';
import { DisplayRangeSelector } from '@/trend/DisplayRangeSelector.molecule';

export interface ChartViewContentProperties {
  onContentLoad: () => void;
  afterChartUpdate: () => void;
  data: ChartViewData;
  configuration: ChartViewConfiguration;
  actions: ChartViewActions;
  darkMode?: boolean;
  onHighchartsLoad: () => void;
}

/**
 * This component is for rendering interactive content in trend view
 */
export const ChartViewContent: React.FunctionComponent<ChartViewContentProperties> = (props) => {
  const { onContentLoad, afterChartUpdate, darkMode = false, onHighchartsLoad } = props;

  useEffect(() => onContentLoad(), []);
  props.actions.onContentUpdate = onHighchartsLoad;
  props.configuration.darkMode = darkMode;
  props.actions.afterChartUpdate = afterChartUpdate;
  return (
    <>
      <ChartView actions={props.actions} configuration={props.configuration} data={props.data} />
      <DisplayRangeSelector
        displayRange={props.data.sqDurationStoreData.displayRange}
        rangeEditingEnabled={false}
        autoUpdateDisabled={true}
        label={props.data.summaryLabel}
        timezone={props.data.selectedTimezone}
        investigateRangeCopyDisabled={true}
        isInTopic={true}
      />
    </>
  );
};
