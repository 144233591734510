// @ts-strict-ignore
import React from 'react';
import DOMPurify from 'dompurify';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const JOURNAL_LINK_SRC_SNIPPET = '/links?type=';

interface ContainerWithHTMLProps {
  content: string;
  testId?: string;
  isBlock?: boolean;
  id?: string;
  extraClassNames?: string;
}

// Without this the target attribute is stripped off and links will open
// in the same window. See https://github.com/cure53/DOMPurify/issues/317
DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  if ('target' in node && !_.includes(node.href, JOURNAL_LINK_SRC_SNIPPET)) {
    // set all elements owning target to target=_blank
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
});

/**
 * Generic binding mechanism for HTML, similar to AngularJS’s ng-bind-html.
 */
export const ContainerWithHTML: React.FunctionComponent<ContainerWithHTMLProps> = (props) => {
  const { content, testId, isBlock = false, extraClassNames, ...rest } = props;

  return React.createElement(isBlock ? 'div' : 'span', {
    ...rest,
    'data-testid': testId,
    'className': extraClassNames,
    'dangerouslySetInnerHTML': { __html: DOMPurify.sanitize(content) },
  });
};

interface TranslationWithHTMLProps {
  translationKey: string;
  translationParams?: object;
  extraClassName?: string;
}

/**
 * Binding mechanism that first translates and substitutes a provided key and properties before binding.
 * Note that translation strings should not include HTML. This component can be used when translation parameters are
 * used to display HTML inline, such as is necessary for inline icons.
 */
export const TranslationWithHTML: React.FunctionComponent<TranslationWithHTMLProps> = (props) => {
  const { translationKey, translationParams = {}, extraClassName } = props;
  const { t } = useTranslation();

  return (
    <ContainerWithHTML
      content={t(translationKey, {
        ...translationParams,
        interpolation: { escapeValue: false },
      })}
      extraClassNames={extraClassName}
    />
  );
};
