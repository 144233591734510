// @ts-strict-ignore
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { Icon } from '@seeqdev/qomponents';
import { FormulaDisplayOnly } from '@/formula/FormulaDisplayOnly.atom';
import { ContainerWithHTML } from '@/core/ContainerWithHTML.atom';

export interface FormulaExamplesProps {
  examples: { description: string; formula: string }[];
  insertFormulaSnippet: (snippet: string) => void;
  requestDocumentation: (href: string) => any;
}

export const FormulaExamples: React.FunctionComponent<FormulaExamplesProps> = ({
  examples,
  insertFormulaSnippet,
  requestDocumentation,
}) => {
  const { t } = useTranslation();

  const isLast = (index) => _.toNumber(index) === examples.length - 1;

  const addExampleToFormula = (formula) => {
    insertFormulaSnippet(formula);
  };

  return (
    examples?.length > 0 && (
      <>
        <h2>{t('FORMULA.DOCUMENTATION.EXAMPLES')}</h2>
        {_.map(examples, (example, index) => (
          <div data-testid="formulaExample" className={classNames(isLast(index) ? 'mb15' : 'mb10')} key={index}>
            <ContainerWithHTML content={example.description} />
            <div className="flexColumnContainer flexSpaceBetween">
              <div className="flexFill formulaExample">
                <FormulaDisplayOnly formula={example.formula} />
              </div>
              <Icon
                icon="fc-copy"
                tooltip={t('COPY')}
                extraClassNames="cursorPointer pl5 pt5 pr5"
                testId="exampleCopyBtn"
                onClick={() => addExampleToFormula(example.formula)}
              />
            </div>
          </div>
        ))}
      </>
    )
  );
};
