// @ts-strict-ignore
import { HOME_SCREEN_TABS, SEARCH_ITEM_LOCATIONS, USERS_FOLDER_NAME } from '@/main/app.constants';
import _ from 'lodash';
import { sqFoldersApi } from '@/sdk/api/FoldersApi';
import { emitWorkbooks, onWorkbooks } from '@/services/notifier.service';
import { formatTime } from '@/datetime/dateTime.utilities';
import { addWorksheetToWorkbook as addWorksheetToWorkbookAction, loadWorkbook } from '@/workbook/workbook.actions';
import { equalsIgnoreCase } from '@/utilities/utilities';
import { flux } from '@/core/flux.module';
import { PUSH_WORKBENCH } from '@/core/flux.service';
import i18next from 'i18next';
import {
  HOME_SCREEN_CANCELLATION_GROUP,
  HOME_SCREEN_SORT,
  HOME_SCREEN_TABLE_TYPE,
  ITEM_TYPES,
  TrainingPromptDisplay,
} from '@/homescreen/homescreen.constants';
import { sqHomeScreenStore, sqWorkbenchStore, sqWorkbookStore, sqWorksheetStore } from '@/core/core.stores';
import moment from 'moment';
import { canModifyWorkbook } from '@/services/authorization.service';
import {
  createFolder,
  createProject,
  createWorkbook,
  getDefaultFolderName,
  getFolder,
  getFolderRoot,
  getTabFolderName,
  getWorkbook,
  setArchived,
} from '@/utilities/homescreen.utilities';
import { getTabFolder } from '@/utilities/homescreen.helpers';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { cancelAll } from '@/requests/pendingRequests.utilities';
import { FolderNavigationOutputV1 } from '@/sdk/model/FolderNavigationOutputV1';
import { getLoginLink, goTo, isWorkbooks, returnToPreviousState } from '@/main/routing.utilities';
import { AUTH_CHANGE_BROADCAST_CHANNEL, subscribeToBroadcastChannel } from '@/services/broadcastChannel.utilities';
import { isCsrfSet } from '@/utilities/auth.utilities';
import { isProtractor } from '@/core/utilities';

export function setCurrentTab(tab: string) {
  flux.dispatch('SET_CURRENT_HOME_SCREEN_TAB', { tab }, PUSH_WORKBENCH);
}

export function setCurrentFolder(folderId: string) {
  flux.dispatch('SET_CURRENT_HOME_SCREEN_FOLDER', { folderId }, PUSH_WORKBENCH);
}

export function addExpandedFolderIds(expandedFolderIds: string[]) {
  flux.dispatch('ADD_EXPANDED_FOLDER_IDS', { expandedFolderIds });
}

export function removeExpandedFolderIds(collapsedFolderIds: string[]) {
  flux.dispatch('REMOVE_EXPANDED_FOLDER_IDS', { collapsedFolderIds });
}

export function mergeSubfolderTree(tree: FolderNavigationOutputV1[]) {
  flux.dispatch('MERGE_HOME_SCREEN_FOLDER_TREE', { tree });
}

export function setTableSort(sortProperty: string, sortAsc: boolean, table: string) {
  flux.dispatch('SET_HOME_SCREEN_TABLE_SORT', { sortProperty, sortAsc, table }, PUSH_WORKBENCH);
}

export function clearBreadcrumbs() {
  flux.dispatch('CLEAR_BREADCRUMBS');
}

export function clearItemsHomescreen() {
  flux.dispatch('CLEAR_HOME_SCREEN_ITEMS');
}

export function setPageSizeForTable({ table, size }) {
  flux.dispatch('SET_PAGE_SIZE_FOR_TABLE', { table, size }, PUSH_WORKBENCH);
}

export function setSearchParams(searchParams) {
  flux.dispatch('SET_HOME_SCREEN_SEARCH_PARAMS', { searchParams });
}

export function setIsExact(isExact: boolean) {
  flux.dispatch('SET_HOME_SCREEN_SEARCH_IS_EXACT', { isExact });
}

export function setPageNumber(pageNumber, table) {
  flux.dispatch('SET_HOME_SCREEN_PAGE_NUMBER', { pageNumber, table });
}

export function setPageNumberAndGo(pageNumber, loadTable, table) {
  setPageNumber(pageNumber, table);
  loadTable(HOME_SCREEN_TABLE_TYPE.SEARCH ? sqHomeScreenStore.searchParams : null);
}

export function loadPinnedTable() {
  const table = HOME_SCREEN_TABLE_TYPE.PINNED;
  const pinnedSort = sqHomeScreenStore.getSortForTable(HOME_SCREEN_TABLE_TYPE.PINNED);
  const searchParams = {
    sortOrder: `${pinnedSort.sortProperty} ${pinnedSort.sortAsc ? 'asc' : 'desc'}`,
    onlyPinned: true,
  };
  return loadTable({ searchParams, table });
}

export function loadRecentTable() {
  const table = HOME_SCREEN_TABLE_TYPE.RECENT;
  const lruSort = sqHomeScreenStore.getSortForTable(HOME_SCREEN_TABLE_TYPE.RECENT);
  const searchParams = {
    sortOrder: `${lruSort.sortProperty} ${lruSort.sortAsc ? 'asc' : 'desc'}`,
  };

  return loadTable({ searchParams, table });
}

export function loadTabTable() {
  const table = HOME_SCREEN_TABLE_TYPE.TAB;
  const tableSort = sqHomeScreenStore.getSortForTable(table);
  const searchParams = getSearchParams({
    currentTab: sqHomeScreenStore.currentTab,
    folderId: sqHomeScreenStore.currentFolderId,
    sortProperty: tableSort.sortProperty,
    sortAsc: tableSort.sortAsc,
  });
  return loadTable({ searchParams, table });
}

export function loadSearchTable({
  searchParams = undefined,
  isCurrentFolderNested = false,
  advancedSearchSelectedFolder = '',
  locationChangedInAdvanced = false,
  breadcrumbClicked = false,
}) {
  const table = HOME_SCREEN_TABLE_TYPE.SEARCH;
  const searchSort = sqHomeScreenStore.getSortForTable(table);
  const sortOrder = `${searchSort.sortProperty} ${searchSort.sortAsc ? 'asc' : 'desc'}`;
  searchParams
    ? _.assign(searchParams, { sortOrder })
    : setSearchParams({ ...sqHomeScreenStore.searchParams, sortOrder });
  searchParams = searchParams || sqHomeScreenStore.searchParams;
  return loadTable({
    searchParams,
    table,
    isCurrentFolderNested,
    advancedSearchSelectedFolder,
    locationChangedInAdvanced,
    breadcrumbClicked,
  });
}

export async function loadTable({
  searchParams,
  table,
  isCurrentFolderNested = false,
  advancedSearchSelectedFolder = '',
  locationChangedInAdvanced = false,
  breadcrumbClicked = false,
}) {
  flux.dispatch('SET_HOME_SCREEN_TABLE_LOADING', { table });

  const limit = sqHomeScreenStore.getPageSizeByTable(table);
  const currentPageNumber = sqHomeScreenStore.getPageNumberForTable(table);
  const offset = (currentPageNumber - 1) * limit;
  const folderId =
    searchParams.folderId ||
    (!isCurrentFolderNested && advancedSearchSelectedFolder !== '') ||
    (advancedSearchSelectedFolder === '' && (locationChangedInAdvanced || breadcrumbClicked))
      ? searchParams.folderId
      : sqHomeScreenStore.currentFolderId;

  let filter = searchParams.filter;
  if (folderId) {
    await getFolder(folderId).then((folder) => {
      if (folder?.name === USERS_FOLDER_NAME) {
        filter = 'Users';
      }
    });
  }

  const getFolders = async (limit: number, offset: number) => {
    const queryParams = {
      ...searchParams,
      filter: !filter ? undefined : filter,
      folderId,
      limit,
      offset,
    };

    // TODO CRAB-31571: Add report templates to homescreen search by default (remove types)
    queryParams.types ||= [
      SeeqNames.Types.Folder,
      SeeqNames.Types.Analysis,
      SeeqNames.Types.Topic,
      SeeqNames.Types.Project,
    ];
    const { data } = await sqFoldersApi.getFolders(queryParams, { cancellationGroup: HOME_SCREEN_CANCELLATION_GROUP });

    return _.pick(data, ['content', 'totalResults', 'path']);
  };

  return getFolders(limit, offset)
    .then((data) => {
      if (_.isEmpty(data.content) && currentPageNumber > 1) {
        // This can happen when items were deleted from the current page, and there are no more items to display
        // for it.  We simply re-fetch by setting the page to the previous one
        setPageNumber(currentPageNumber - 1, table);
        return getFolders(limit, (currentPageNumber - 2) * limit);
      }
      return data;
    })
    .then((data) => {
      flux.dispatch('SET_HOME_SCREEN_ITEMS_FOR_TABLE', {
        table,
        items: data.content,
        totalResults: data?.totalResults,
      });
      if (data?.path) {
        flux.dispatch('SET_BREADCRUMBS', { crumbs: data?.path });
      } else {
        clearBreadcrumbs();
      }
    })
    .finally(() => flux.dispatch('SET_HOME_SCREEN_TABLE_LOADING', { table }));
}

export function loadFolderTree(folderId: string, currentTab: string) {
  let tabFolderPromise;
  let expandToFolder = false;

  // Check to see if folder is actually a tab,then use it
  tabFolderPromise = getTabFolderName(folderId);

  return tabFolderPromise.then((tabFolder) => {
    const newTab = _.isUndefined(tabFolder) ? currentTab : tabFolder;
    const folderRoot = getFolderRoot(newTab);
    let promise;

    if (folderId && _.isUndefined(tabFolder)) {
      expandToFolder = true;
      promise = sqFoldersApi.getAncestors(
        { folderId, root: folderRoot },
        { cancellationGroup: HOME_SCREEN_CANCELLATION_GROUP },
      );
    } else if (folderRoot) {
      promise = sqFoldersApi.getSubfolders(
        { folderId: folderRoot },
        { cancellationGroup: HOME_SCREEN_CANCELLATION_GROUP },
      );
      if (newTab !== sqHomeScreenStore.currentTab) {
        exposedForTesting.clearItemsHomescreen();
        exposedForTesting.setCurrentTab(newTab);
      }
    } else {
      return Promise.resolve({});
    }
    return promise.then(({ data: { id, subfolders } }) => {
      mergeSubfolderTree(subfolders);

      if (expandToFolder) {
        if (folderRoot === null && id) {
          if (id === SEARCH_ITEM_LOCATIONS.MY_FOLDER) {
            setCurrentTab(HOME_SCREEN_TABS.MY_FOLDER);
          } else if (id === SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC) {
            if (currentTab !== HOME_SCREEN_TABS.SHARED) {
              setCurrentTab(HOME_SCREEN_TABS.SHARED);
              // CRAB-29112: The SHARED folder has special logic regarding how items are shown users so we must load
              // the folder with the `currentTab` correctly set
              exposedForTesting.clearItemsHomescreen();
              return exposedForTesting
                .loadFolder(folderId, HOME_SCREEN_TABS.SHARED)
                .then(() => exposedForTesting.addExpandedFolderIds(_.map(sqHomeScreenStore.breadcrumbs, 'id')));
            }
          } else if (id === SEARCH_ITEM_LOCATIONS.CORPORATE) {
            setCurrentTab(HOME_SCREEN_TABS.CORPORATE);
          } else if (id === SEARCH_ITEM_LOCATIONS.USERS) {
            setCurrentTab(HOME_SCREEN_TABS.USERS);
          }
        }

        addExpandedFolderIds(_.map(sqHomeScreenStore.breadcrumbs, 'id'));
      } else if (folderRoot) {
        // Set the tab folder as the current folder for the three that are actually folders
        if (folderRoot === SEARCH_ITEM_LOCATIONS.MY_FOLDER) {
          getTabFolder(HOME_SCREEN_TABS.MY_FOLDER).then((folder) => {
            exposedForTesting.setCurrentFolder(folder.id);
          });
        } else if (folderRoot === SEARCH_ITEM_LOCATIONS.CORPORATE) {
          getTabFolder(HOME_SCREEN_TABS.CORPORATE).then((folder) => {
            exposedForTesting.setCurrentFolder(folder.id);
          });
        } else if (folderRoot === SEARCH_ITEM_LOCATIONS.USERS) {
          getTabFolder(HOME_SCREEN_TABS.USERS).then((folder) => {
            exposedForTesting.setCurrentFolder(folder.id);
          });
        }
      }
    });
  });
}

export function loadHomeTab() {
  return Promise.all([loadPinnedTable(), loadRecentTable()]);
}

export function loadTab(currentTab: string, folderId: string) {
  return Promise.all([exposedForTesting.loadTabTable(), exposedForTesting.loadFolderTree(folderId, currentTab)]);
}

// This function is used by the Folder Explorer Modal only - currently there is no way to sort or filter items
// from there
export function loadFolderContents(folderId) {
  return sqFoldersApi
    .getFolders(
      { folderId, types: [ITEM_TYPES.FOLDER], limit: 1000 },
      { cancellationGroup: HOME_SCREEN_CANCELLATION_GROUP },
    )
    .then((response) => response.data.content);
}

export function getSearchParams({ currentTab, folderId, sortProperty, sortAsc }) {
  const searchParams = {
    sortOrder: `${sortProperty} ${sortAsc ? 'asc' : 'desc'}`,
  };
  if (folderId) {
    return _.assign(searchParams, {
      folderId,
      filter: currentTab === HOME_SCREEN_TABS.SHARED ? SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC : undefined,
    });
  } else {
    switch (currentTab) {
      case HOME_SCREEN_TABS.USERS:
        return _.assign(searchParams, {
          folderId: SEARCH_ITEM_LOCATIONS.USERS,
        });
      case HOME_SCREEN_TABS.MY_FOLDER:
        return _.assign(searchParams, {
          folderId: SEARCH_ITEM_LOCATIONS.MY_FOLDER,
        });
      case HOME_SCREEN_TABS.SHARED:
        return _.assign(searchParams, {
          filter: SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC,
        });
      case HOME_SCREEN_TABS.CORPORATE:
        return _.assign(searchParams, {
          folderId: SEARCH_ITEM_LOCATIONS.CORPORATE,
        });
      case HOME_SCREEN_TABS.TRASH:
        return _.assign(searchParams, { isArchived: 'true' });
      default:
        return searchParams;
    }
  }
}

export function loadFolder(folderId?, currentTab?): Promise<any> {
  cancelAll();
  currentTab = currentTab || HOME_SCREEN_TABS.HOME;

  if (currentTab !== sqHomeScreenStore.currentTab) {
    clearItemsHomescreen();
  }

  // the API returns a magic string for the "root" tab of an item, so that we can show the selected tab without
  // encoding it in the URL.
  // Items can appear in more than one "tab". For example: my folder A is My Folder, but it's also under my home
  // folder in the Users tab.
  // Tabs are not persisted in the store between reloads - so if we have no currentTab we use the one the API
  // returns, if we have a tab we use that, as to avoid a jumpy tab selection.
  if (
    currentTab !== sqHomeScreenStore.currentTab ||
    (!(_.isNil(folderId) && _.isNil(sqHomeScreenStore.currentFolderId)) &&
      !equalsIgnoreCase(folderId, sqHomeScreenStore.currentFolderId))
  ) {
    exposedForTesting.setCurrentTab(currentTab);
    exposedForTesting.setCurrentFolder(folderId);
  }

  if (!folderId && currentTab === HOME_SCREEN_TABS.HOME) {
    return loadHomeTab() as Promise<any>;
  } else {
    return loadTab(currentTab, folderId) as Promise<any>;
  }
}

export function collapseFolder(folderId: string) {
  removeExpandedFolderIds([folderId]);
}

export function getSortedTableContents(sortProperty, table) {
  const tableSort = sqHomeScreenStore.getSortForTable(table);
  const sortAsc = tableSort.sortProperty === sortProperty ? !tableSort.sortAsc : true;
  setPageNumber(1, table);

  flux.dispatch('SET_HOME_SCREEN_TABLE_SORT', { sortProperty, sortAsc, table }, PUSH_WORKBENCH);

  switch (table) {
    case HOME_SCREEN_TABLE_TYPE.PINNED:
      return loadPinnedTable();
    case HOME_SCREEN_TABLE_TYPE.RECENT:
      return loadRecentTable();
    case HOME_SCREEN_TABLE_TYPE.SEARCH:
      return loadSearchTable({});
    default:
      return loadTabTable();
  }
}

// open a folder navigation tree but don't load the folder contents
export function expandFolder(folderId: string, folderRoot = null) {
  addExpandedFolderIds(_.uniq([...sqHomeScreenStore.expandedFolderIds, folderId]));
  sqFoldersApi.getSubfolders({ folderId, root: folderRoot }).then((response) => {
    exposedForTesting.mergeSubfolderTree([response.data]);
  });
}

export function resetStore() {
  flux.dispatch('RESET_HOME_SCREEN_STORE');
}

export function clearSearchResults() {
  flux.dispatch('SET_HOME_SCREEN_ITEMS_FOR_TABLE', {
    table: HOME_SCREEN_TABLE_TYPE.SEARCH,
    items: [],
  });
}

export function addFolder({ parentFolderId, branchFrom = undefined, name = undefined, ownerId = undefined }) {
  const folderName = name ? name : getDefaultFolderName();

  return exposedForTesting.canCreateItemInFolder(parentFolderId).then((folderWriteAccess) => {
    setTableSort(HOME_SCREEN_SORT.CREATED_AT, false, HOME_SCREEN_TABLE_TYPE.TAB);
    return createFolder(
      folderName,
      folderWriteAccess && parentFolderId ? parentFolderId : null,
      branchFrom,
      ownerId,
    ).then((folder) => {
      exposedForTesting.setCurrentFolder(folder.parentFolderId);
      return folder;
    });
  });
}

export function addWorkbook({
  name = '',
  addNewWorksheet = true,
  isReportBinder = false,
  branchFrom = undefined,
  folderId = undefined,
}) {
  const prefix = i18next.t(`ITEM_TYPES.${isReportBinder ? 'REPORT' : 'WORKBOOK'}`);
  const worksheetPrefix = isReportBinder ? i18next.t('ITEM_TYPES.DOCUMENT') : '';
  moment.locale(_.truncate(sqWorkbenchStore.userLanguage, { length: 2, omission: '' }));
  name = name || `${prefix} ${formatTime(new Date(), sqWorksheetStore.timezone)}`;

  return exposedForTesting
    .canCreateItemInFolder(folderId)
    .then((folderWriteAccess) =>
      createWorkbook(name, branchFrom, folderWriteAccess ? folderId : null, isReportBinder ? 'Topic' : 'Analysis'),
    )
    .then((workbook: any) => {
      if (addNewWorksheet && !branchFrom) {
        if (sqWorkbookStore.workbookId !== workbook.workbookId) {
          return loadWorkbook(workbook.workbookId).then(() =>
            addWorksheetToWorkbook(workbook.workbookId, {
              prefix: worksheetPrefix,
            }),
          );
        } else {
          return addWorksheetToWorkbook(workbook.workbookId, {
            prefix: worksheetPrefix,
          });
        }
      } else if (branchFrom) {
        return getWorkbook(workbook.workbookId);
      } else {
        return workbook;
      }
    })
    .then((workbook) => _.tap(workbook, emitWorkbooks));
}

export function addProject({ folderId = null, name = null }) {
  const prefix = i18next.t('ITEM_TYPES.PROJECT');
  name = name || `${prefix} ${formatTime(new Date(), sqWorksheetStore.timezone)}`;

  return exposedForTesting
    .canCreateItemInFolder(folderId)
    .then((folderWriteAccess) => createProject(name, folderWriteAccess ? folderId : null))
    .then((project) => {
      flux.dispatch('WORKBENCH_ADD_PROJECT', project);
      emitWorkbooks();
      return project;
    });
}

export function addWorksheetToWorkbook(workbookId, options?) {
  return addWorksheetToWorkbookAction(workbookId, options).then((worksheet) => {
    // addWorksheet sets the worksheet into the store and this makes the object immutable (freezes the object)
    // to add workbookId on the result we need to work with a clone
    return _.set(_.cloneDeep(worksheet), 'workbookId', workbookId);
  });
}

export function canCreateItemInFolder(folderId) {
  if (folderId) {
    return getFolder(folderId).then((folder) => canModifyWorkbook(folder));
  }

  return Promise.resolve(true);
}

export function removeWorkbook(workbook) {
  return Promise.resolve()
    .then(() => setArchived(workbook.workbookId, true))
    .then((workbook) => _.tap(workbook, emitWorkbooks));
}

export function restoreWorkbook(workbook) {
  return Promise.resolve()
    .then(() => setArchived(workbook.workbookId, false))
    .then((workbook) => _.tap(workbook, emitWorkbooks));
}

/**
 * A helper function that sets up websocket workbooks notification and reloads workbooks if a workbook has
 * been added, removed, or renamed.
 */
export function setupNotifierForHomescreen() {
  onWorkbooks(() => {
    if (isWorkbooks()) {
      return loadFolder(sqHomeScreenStore.currentFolderId, sqHomeScreenStore.currentTab);
    }
  });
}

export function subscribeToAuthChangeChannel() {
  // Redirect to the logout page if auth changed by another tab or redirect from the login page if authenticated
  subscribeToBroadcastChannel({
    channelId: AUTH_CHANGE_BROADCAST_CHANNEL,
    onMessage() {
      // The csrf is used as a proxy for authentication here if the other tab set the csrf then it is likely
      // that we are authenticated. Similarly, if the csrf has been cleared we are unauthenticated.
      if (isCsrfSet() && _.includes(window.location.pathname, '/login')) {
        returnToPreviousState();
      } else if (!isCsrfSet() && !_.includes(window.location.pathname, '/login')) {
        goTo(getLoginLink(window.location.pathname + window.location.search));
      }
    },
  });
}

export function prepareForTests() {
  if (isProtractor()) {
    const originalDebounce = _.debounce;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    _.debounce = (fn: any, wait: any, { isAllowedDuringTest, ...options } = { isAllowedDuringTest: false }) => {
      return isAllowedDuringTest
        ? originalDebounce(fn, wait, options)
        : _.assign(fn, { cancel: _.noop, flush: _.noop });
    };
  }
}

export function setTabFolder(key, folder) {
  flux.dispatch('SET_TAB_FOLDER', { key, folder });
}

export function setTrainingPromptDisplay(trainingPromptDisplay: TrainingPromptDisplay) {
  flux.dispatch('SET_HOME_SCREEN_TRAINING_PROMPT_DISPLAY', { trainingPromptDisplay }, PUSH_WORKBENCH);
}

export function setDisplayedAddOnIdentifier(displayedAddOnIdentifier: string) {
  flux.dispatch('SET_HOME_SCREEN_DISPLAYED_ADDON_IDENTIFIER', { displayedAddOnIdentifier }, PUSH_WORKBENCH);
}

export function clearDisplayedAddOnIdentifier() {
  setDisplayedAddOnIdentifier('');
}

export const exposedForTesting = {
  addFolder,
  addProject,
  addWorkbook,
  clearBreadcrumbs,
  clearItemsHomescreen,
  collapseFolder,
  expandFolder,
  loadFolder,
  loadFolderTree,
  loadTabTable,
  mergeSubfolderTree,
  removeWorkbook,
  resetStore,
  restoreWorkbook,
  setCurrentFolder,
  setCurrentTab,
  setPageSizeForTable,
  addWorksheetToWorkbook,
  canCreateItemInFolder,
  addExpandedFolderIds,
  loadSearchTable,
  setPageNumber,
  cancelAll,
  loadTable,
  setSearchParams,
};
