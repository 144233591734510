import { TranslationWithHTML } from '@/core/ContainerWithHTML.atom';
import { sqWorkstepsStore } from '@/core/core.stores';
import { aiAssistantEnabled } from '@/services/systemConfiguration.utilities';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AssistantOrigin } from './aiAssistant.types';

interface FormulaAiAssistantIntroductionProps {
  submitPrompt: (prompt: string, origin: AssistantOrigin, context?: Record<string, unknown>) => void;
}

export const FormulaAiAssistantIntroduction: React.FunctionComponent<FormulaAiAssistantIntroductionProps> = ({
  submitPrompt,
}) => {
  const { t } = useTranslation();

  const prompts = [
    t('FORMULA.ASSISTANT.PROMPT_ONE'),
    t('FORMULA.ASSISTANT.PROMPT_TWO'),
    t('FORMULA.ASSISTANT.PROMPT_THREE'),
  ];

  return (
    <div className="flexRowContainer flexFill flexCenter">
      <div className="p50 text-center" data-testid="assistantIntroduction">
        {aiAssistantEnabled() ? (
          t('FORMULA.ASSISTANT.INTRODUCTION')
        ) : (
          <>
            <div className="pb15">{t('FORMULA.ASSISTANT.DISABLED_INTRODUCTION')}</div>
            <TranslationWithHTML
              translationKey="FORMULA.ASSISTANT.DISABLED_INTRODUCTION_SUPPORT"
              translationParams={{
                support: '<a href="https://seeq.atlassian.net/servicedesk/customer/portal/3">Seeq support</a>',
              }}
            />
          </>
        )}
      </div>

      {aiAssistantEnabled() && (
        <>
          {prompts.map((prompt, id) => (
            <span
              key={id}
              onClick={() => submitPrompt(prompt, 'workbench', sqWorkstepsStore.current)}
              className="text-underline ml15 cursorPointer sq-text-primary p5">
              {prompt}
            </span>
          ))}
          <div className="p50 text-center">
            <TranslationWithHTML
              translationKey="FORMULA.ASSISTANT.CONCLUSION"
              translationParams={{
                sendIcon: "<i class='fa-sharp fa-regular fa-paper-plane'></i>",
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};
