// @ts-strict-ignore
import _ from 'lodash';
import { DISPLAY_DROPDOWN_ACTION_CUSTOM_ID } from '@/displays/displays.constants';
import { DisplayTemplateOutputV1 } from '@/sdk/model/DisplayTemplateOutputV1';
import { ItemPreviewWithAssetsV1 } from '@/sdk/model/ItemPreviewWithAssetsV1';
import { sqDisplaysApi } from '@/sdk/api/DisplaysApi';
import { sqTreesApi } from '@/sdk/api/TreesApi';

/**
 * Computes the asset that should be displayed when trying to move a Display item.
 *
 * @param item - the Display item
 * @returns the ID of the asset that should be displayed
 */
export function computeMoveDisplayAsset(item: { id: string; ancestors: { id: string }[] }) {
  let assetId;
  const depth = item.ancestors?.length || 0;
  if (depth > 1) {
    assetId = item.ancestors[depth - 2].id;
  } else if (depth === 1) {
    assetId = item.ancestors[depth - 1].id;
  }
  return assetId;
}

/**
 * Computes if a scaled display can be overwritten (i.e. have its display template's source workstep replaced).
 *  - Replacement is allowed for scaled displays only if the displayParentAssetId is present in the asset
 *    path of at least one item in the details pane.
 *
 * @param displayParentAssetId - the ID of the display's parent asset
 * @param trendStoreItems - the items in the details pane
 * @returns true if the display can be overwritten; false otherwise.
 */
export function canOverwriteScaledDisplay(
  displayParentAssetId: string,
  trendStoreItems: { assets: { pathComponentIds: string[] }[] }[],
): boolean {
  const assetPathHasDisplayParent = (asset) => _.includes(asset?.pathComponentIds, displayParentAssetId);
  const itemAssetHasDisplayParent = (item) => _.some(item?.assets, assetPathHasDisplayParent);
  const trendItemAssetPathHasDisplayParent = _.some(trendStoreItems, itemAssetHasDisplayParent);
  return trendItemAssetPathHasDisplayParent;
}

/**
 * Prevents the event handler from being called if it originated from a display dropdown element.
 * Clicks on display dropdown elements need special handling because we have to let them bubble, so that
 * clicking on another display dropdown icon will cause the previously-opened dropdown to close, but we
 * don't want them to cause the display to be loaded.
 * @see DISPLAY_DROPDOWN_ACTION_CUSTOM_ID
 *
 * @param handler - an event handler
 * @returns an event handler that filters events from the display dropdown
 */
export function onEventExceptDisplayDropdown(handler: (event?: any) => void): (event?: any) => void {
  return (event) => {
    if (event?.target?.dataset?.customid !== DISPLAY_DROPDOWN_ACTION_CUSTOM_ID) {
      handler(event);
    }
  };
}

/**
 * Get the display template data from the display item
 *
 * @param display The display item
 */
export function getDisplayTemplate(display: {
  id: string;
  name: string;
  type: string;
}): Promise<DisplayTemplateOutputV1> {
  return sqDisplaysApi.getDisplay({ id: display.id }).then(({ data: display }) => {
    return display.template;
  });
}

/**
 * Get the count of scaled displays for the given display item
 *
 * @param display The display item
 * @return {Promise<number>} The the number of scaled displays
 */
export function getDisplayCount(display: { id: string; name: string; type: string }) {
  return getDisplayTemplate(display).then((data) => {
    return data.displayCount;
  });
}

/**
 * Returns a promise containing an array of IDs that are the tree roots scoped to the specified workbook
 *
 * @param workbookId - The workbook to look for local asset tree roots in
 */
export function getLocalTreeRoots(workbookId: string): Promise<string[]> {
  return sqTreesApi
    .getTreeRootNodes({
      scope: [workbookId],
      limit: 1000,
      excludeGloballyScoped: true,
    })
    .then(({ data: { children } }) => _.map(children, 'id'));
}

/**
 * Returns a promise for an the asset that the display tool should select by default. If none should be selected by
 * default, returns undefined.
 *
 * The recommendation is determined by:
 * - Finding which local asset tree/group contains the most items from the details pane
 * - Finding the deepest asset in that tree that has each asset from that tree in the details pane as either an
 *    ancestor or a descendant
 *
 * @param rootIds - IDs for the roots of the trees that recommendations should come from. For now this is locally
 * scoped asset trees and groups
 * @param displayItems - The items from the details pane
 */
export function getRecommendedAsset(rootIds: string[], displayItems: any[]): Promise<ItemPreviewWithAssetsV1> {
  if (!rootIds) return;
  const assetPaths = _.chain(displayItems)
    .flatMap('assets')
    .map('pathComponentIds')
    .filter((path) => _.isArray(path) && !_.isEmpty(path))
    .value();
  const rootCounts = _.chain(assetPaths)
    .countBy(_.head)
    .pickBy((count, id) => _.includes(rootIds, id))
    .value();
  const mostCommonRoot = _.chain(rootCounts)
    .keys()
    .maxBy((root) => rootCounts[root])
    .value();
  if (!mostCommonRoot) return;

  // The unified path is the longest path that:
  // - Has every asset path starting with mostCommonRoot as either a sub-path or super-path
  // - Is not longer than any given asset path
  // [A, B, C], [A, B, D]   -->     unifiedPath = [A, B]
  // [A, B, C], [A, B]      -->     unifiedPath = [A, B, C]
  let restrictedLength = false;
  const unifyPaths = (path, otherPath) => {
    const [shorterPath, longerPath] = path.length < otherPath.length ? [path, otherPath] : [otherPath, path];
    const unifiedPath = _.takeWhile(
      longerPath,
      (id, index) => (!restrictedLength && shorterPath.length <= index) || shorterPath[index] === id,
    );
    restrictedLength = unifiedPath.length < longerPath.length;
    return unifiedPath;
  };
  const recommendedAssetId = _.chain(assetPaths)
    .filter((path) => path[0] === mostCommonRoot)
    .reduce(unifyPaths)
    .last()
    .value() as any;

  return sqTreesApi
    .getTree({
      id: recommendedAssetId,
      limit: 0,
    })
    .then(({ data: { item } }) => item);
}

/**
 * Get the valid assets where the user can choose to insert a scaled display. These are ancestors of details pane
 * items that live in locally scoped asset trees.
 *
 * @param validRootIds - Root IDs for trees that can have displays inserted (locally scoped trees)
 * @param items - items from the details pane
 */
export function getValidAncestors(validRootIds: string[], items: any[]): string[] {
  if (!validRootIds) return;
  return _.chain(items)
    .flatMap('assets')
    .map('pathComponentIds')
    .filter((path) => _.includes(validRootIds, _.head(path)))
    .flatMap()
    .uniq()
    .value();
}
