import { isTelemetryEnabled } from '@/services/systemConfiguration.utilities';
import { TrackInformation } from './track.types';

export abstract class TrackerService {
  protected constructor() {}

  abstract trackEvent(category: string, action: string, information: TrackInformation, uniqueEventId: number): void;

  shouldTrack() {
    return isTelemetryEnabled();
  }

  abstract reset(): void;
}
