// @ts-strict-ignore
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button, Icon } from '@seeqdev/qomponents';
import { FormElement } from '@/formbuilder/formBuilder.constants';
import { ToolPanelFormBuilder } from '@/formbuilder/ToolPanelFormBuilder.page';
import { ResizableToolPanel } from '@/core/ResizableToolPanel.atom';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { ShowFormulaHelp } from '@/formula/ShowFormulaHelp.atom';
import {
  addDetailsPaneParameters,
  addParameter,
  removeParameter,
  search,
  selectParameter,
  setFormula,
  setHelpView,
  toggleHelp,
  updateParameter,
} from '@/tools/formula/formulaTool.actions';
import { sqFormulasApi } from '@/sdk/api/FormulasApi';
import { addTextAtCursor, validateFormula } from '@/formula/formula.utilities';
import { AddParameterBtnAndModal } from '@/formula/AddParameterBtnAndModal.atom';
import { MIN_FORMULA_PANEL_HEIGHT, MIN_FORMULA_PANEL_WIDTH } from '@/tools/formula/formulaTool.constants';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { warnToast } from '@/utilities/toast.utilities';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { sqFormulaToolStore, sqWorksheetStore } from '@/core/core.stores';
import { setDisplayHeight, setDisplayResizeEnabled, setDisplayWidth } from '@/worksheet/worksheet.actions';
import {
  closeInvestigationTool,
  setSearchName as setSearchNameSqInvestigateActions,
} from '@/toolSelection/investigate.actions';
import { FormulaHelp } from '@/formula/FormulaHelp.organism';
import { sqItemsApi } from '@/sdk';

export const FORMULA_HELP_WIDTH = 400;
export const FormulaTool: React.FunctionComponent = () => {
  const {
    id,
    formula: existingFormula,
    parameters,
    originalParameters,
    name,
    helpShown: formulaHelpExpanded,
    helpView: helpView,
  } = useFlux(sqFormulaToolStore);

  const { t } = useTranslation();
  const resizeEnabled = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.resizeEnabled);
  const panelDisplayHeight = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.displayHeight);
  const panelDisplayWidth = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.displayWidth);

  const ref = useRef(null);
  const [color, setColor] = useState('');
  const [constants, setConstants] = useState([]);
  const [operators, setOperators] = useState([]);
  const [formulaErrors, setFormulaErrors] = useState([]);
  const [generalFormulaError, setGeneralFormulaError] = useState('');
  const [editor, setEditor] = useState(undefined);
  const [formula, setLocalFormula] = useState(existingFormula);

  useEffect(() => {
    setLocalFormula(existingFormula);
  }, [existingFormula]);

  const setSearchName = (searchName) => setSearchNameSqInvestigateActions(TREND_TOOLS.FORMULA, searchName);
  const insertTextInFormula = useCallback((text: string) => addTextAtCursor(text, editor), [editor]);
  const togglePanel = () => setDisplayResizeEnabled(!sqWorksheetStore.resizeEnabled);

  const addFormulaParameter = ({ name, item, identifier }) => {
    if (_.some(parameters as any, { identifier })) {
      warnToast({ messageKey: 'FORMULA.VARIABLE_UNIQUE' });
    } else {
      sqItemsApi
        .getContextCondition({ id: item.id })
        .catch(() => undefined)
        .then((maybeContextCondition) => {
          addParameter({ name, identifier, item: { ...item, contexConditionId: maybeContextCondition?.id } });
        });
    }
  };

  const updateLocalParameter = (updatedParam, originalParam) => {
    // Make sure the formula is set in the store so we can update variables names if needed
    setFormula(formula);

    return updateParameter(updatedParam, originalParam);
  };

  const onSave = (formula: string) => {
    setFormula(formula);

    return validateFormula(formula, parameters)
      .then(() => {
        setFormulaErrors([]);

        return true;
      })
      .catch((formulaErrors) => {
        setFormulaErrors(formulaErrors);

        return false;
      });
  };

  const saveOrUpdate = () =>
    onSave(formula)
      .then((valid) => valid && search(color))
      .catch((e) => setGeneralFormulaError(e));

  useEffect(() => {
    sqFormulasApi.getConstantNameList().then(({ data }) => setConstants(data));

    sqFormulasApi.getFormulaDocs({}).then(({ data }) => setOperators(data?.functions));
  }, []);

  ///////////////// Form Fields ////////////////////////
  const formDataSetup: FormElement[] = [
    {
      component: 'FormRow',
      name: 'formulaSearchNameRow',
      components: [
        {
          component: 'SearchTitleFormComponent',
          name: 'formulaName',
          value: name,
          onChange: setSearchName,
          id,
          onColorChange: setColor,
          searchIconClass: 'fc-formula',
          defaultName: 'FORMULA.HEADER',
          extraClassNames: resizeEnabled ? 'mtn10 mb0' : 'mb0',
        },
        {
          component: 'DisplayOnlyFormElementWrapper',
          name: 'expandIcon',
          includeIf: !resizeEnabled,
          extraClassNames: 'width-30',
          children: (
            <Icon
              testId="expandFormulaPanel"
              icon="fc-expand-open"
              large={true}
              type="theme"
              onClick={togglePanel}
              extraClassNames="cursorPointer sq-text-primary pt25"
            />
          ),
        },
        {
          component: 'DisplayOnlyFormElementWrapper',
          name: 'showHelp',
          includeIf: resizeEnabled && !formulaHelpExpanded,
          extraClassNames: 'formulaShowHelp',
          children: <ShowFormulaHelp onClick={toggleHelp} />,
        },
      ],
    },
    {
      component: 'FormGroup',
      name: 'variableGroupWrapper',
      displayNumber: true,
      extraClassNames: 'mt-0',
      components: [
        {
          component: 'FormRow',
          name: 'variableFormRowHolder',
          extraClassNames: 'pb5 border-bottom',
          components: [
            {
              component: 'LabelFormComponent',
              value: 'FORMULA.VARIABLES',
              name: 'variablesHeader',
            },
            {
              component: 'DisplayOnlyFormElementWrapper',
              extraClassNames: 'flexColumnContainer flexFill mb0',
              children: (
                <>
                  <div className="flexFill" />
                  <AddParameterBtnAndModal
                    tooltip="FORMULA.TOOLTIP_SEARCH"
                    parameters={parameters}
                    addParameter={addFormulaParameter}
                  />
                  <Button
                    icon="fa-chart-area"
                    extraClassNames="ml8"
                    label={t('DETAILS')}
                    tooltip={t('FORMULA.TOOLTIP_DETAILS')}
                    onClick={addDetailsPaneParameters}
                  />
                </>
              ),
              name: 'buttonsWrapper',
            },
          ],
        },
        {
          component: 'FormulaVariablesFormComponent',
          name: 'parametersTable',
          resizeEnabled,
          parameters,
          additionalItems: originalParameters,
          updateParameterCallback: updateLocalParameter,
          removeParameterCallback: removeParameter,
          insertParameter: insertTextInFormula,
          onItemSelect: selectParameter,
          addFormulaParameter,
          value: '',
        },
      ],
    },
    {
      component: 'FormGroup',
      name: 'formulaEditorGroupWrapper',
      displayNumber: true,
      extraClassNames: 'flexFillOverflow min-height-130 mt-0',
      components: [
        {
          component: 'LabelFormComponent',
          name: 'formulaHeader',
          value: 'FORMULA.HEADER',
          extraClassNames: 'mb-0',
        },
        {
          component: 'FormulaEditorFormComponent',
          name: 'formulaEditorFormComponent',
          value: `${_.isEmpty(formulaErrors)}`,
          formula,
          constants,
          operators,
          showLineNumbers: sqWorksheetStore.resizeEnabled,
          exposeEditorToParent: setEditor,
          exposeFormulaToParent: setLocalFormula,
          parameters,
          onSave,
          formulaErrors,
          generalFormulaError,
          clearGeneralFormulaError: () => setGeneralFormulaError(''),
          setFormulaErrors,
          readOnly: false,
        },
      ],
    },
  ];

  const renderToolPanel = (wrapInPanel: boolean) => (
    <ToolPanelFormBuilder
      wrapInPanel={wrapInPanel}
      extraClassNamesUnwrapped="flexRowContainer flexFill"
      formDefinition={formDataSetup}
      submitFn={saveOrUpdate}
      closeFn={closeInvestigationTool}
      toolId={TREND_TOOLS.FORMULA}
      submitBtnId="formulaButton"
    />
  );

  return resizeEnabled ? (
    <div className="positionAbsolute" id="formulaContainer" ref={ref}>
      <ResizableToolPanel
        refLink={ref}
        width={panelDisplayWidth}
        height={panelDisplayHeight}
        minimumWidth={MIN_FORMULA_PANEL_WIDTH}
        minimumHeight={MIN_FORMULA_PANEL_HEIGHT}
        setHeight={setDisplayHeight}
        setWidth={setDisplayWidth}>
        <div className="flexColumnContainer flexFill">
          <div className="flexFill">{renderToolPanel(false)}</div>
          {formulaHelpExpanded && (
            <FormulaHelp
              height={panelDisplayHeight}
              helpView={helpView}
              setHelpView={setHelpView}
              insertFormulaSnippet={insertTextInFormula}
              formulaHelpToggleFunction={toggleHelp}
              operators={operators}
            />
          )}
        </div>
        <Icon
          tooltip={t('FORMULA.PANEL_COLLAPSE')}
          icon="fc-expand-close"
          large={true}
          type="theme"
          onClick={togglePanel}
          testId="collapseFormulaPanel"
          extraClassNames="positionAbsolute cursorPointer sq-text-primary ml15 pt10 right-5 top-0"
        />
      </ResizableToolPanel>
    </div>
  ) : (
    renderToolPanel(true)
  );
};
