// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import classNames from 'classnames';
import _ from 'lodash';
import { Button, Icon } from '@seeqdev/qomponents';
import { useFlux } from '@/core/hooks/useFlux.hook';
import {
  fetchAssetGroups,
  fetchPinnedItems,
  fetchRecentlyAccessedItems,
  fetchTableDefinitions,
} from '@/workbook/workbook.actions';
import { IconWithSpinner } from '@/core/IconWithSpinner.atom';
import { ErrorWithBackground } from '@/core/ErrorWithBackground.atom';
import { SearchResult } from '@/search/SearchResult.molecule';
import { SearchGroup } from '@/search/SearchGroup.molecule';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { SearchResultAssetIcons } from '@/assetGroupEditor/SearchResultAssetIcons.molecule';
import { SearchResultIcons } from '@/search/SearchResultIcons.molecule';
import { SearchFilters } from '@/search/SearchFilters.molecule';
import { KEY_CODES } from '@/main/app.constants';
import { AssetGroupMultiSelect } from '@/search/multiSelect/AssetGroupMultiSelect.molecule';
import { TrendMultiSelect } from '@/search/multiSelect/TrendMultiSelect.molecule';
import { isAsset, isDatafile, isTableDefinition } from '@/utilities/utilities';
import { errorToast } from '@/utilities/toast.utilities';
import { sqLicenseManagementStore, sqWorkbookStore, sqWorksheetStore } from '@/core/core.stores';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.constants';
import { ASSET_GROUP_VIEW, TABLE_DEFINITION_VIEW } from '@/worksheet/worksheet.constants';
import { SearchModes, SearchPanes } from '@/search/search.constants';
import {
  clear,
  exploreAssetSearchActions,
  fetchItemUsages,
  getStoreForPane,
  initializeSearchActions,
  loadNextPageSearchActions,
  reset,
  setSelectAllItems,
} from '@/search/search.actions';
import { isTableDefinitionEditorEnabled } from '@/services/systemConfiguration.utilities';
import { ItemSearchPreviewV1, sqTableDefinitionsApi } from '@/sdk';
import { resetTableDefinition, setTableDefinition } from '@/tableDefinitionEditor/tableDefinition.actions';
import { setReturnViewKey, setView } from '@/worksheet/worksheet.actions';

export interface SearchWidgetProps {
  pane: SearchPanes;
  searchTypes: string[];
  iconClasses?: string;
  scopeIds?: string[];
  excludeGloballyScoped?: boolean;
  restrictExploration?: boolean;
  indicateSelection?: boolean;
  selectedItemId?: string;
  onItemClick?: (item: any, mode?: SearchModes, pane?: SearchPanes) => Promise<any> | void;
  searchResultIcons?: (item) => Promise<React.ReactNode>;
  showOnlyResults?: boolean;
  allowAssetReplacement: boolean;
  isSelectingAsset?: boolean;
  predefinedSearchTypes?: string[];
  searchResultsClassNames?: string;
  // Used to determine whether or not to clear the modal stores on closing the search widget
  modalId?: string;
  onSelectItem?: (item: any) => void;
  isPanelSearch?: boolean;
}

let lastModalId: string | undefined;

export const SearchWidget: React.FunctionComponent<SearchWidgetProps> = (props) => {
  const {
    pane,
    searchTypes,
    iconClasses,
    scopeIds,
    excludeGloballyScoped = false,
    onItemClick = _.noop,
    allowAssetReplacement,
    searchResultIcons = defaultSearchResultIcons,
    showOnlyResults = false,
    restrictExploration = false,
    indicateSelection = false,
    selectedItemId = undefined,
    isSelectingAsset = false,
    predefinedSearchTypes,
    modalId = undefined,
    searchResultsClassNames,
    onSelectItem = _.noop,
    isPanelSearch = false,
  } = props;
  if (lastModalId === '') {
    lastModalId = modalId;
  }

  const { t } = useTranslation();
  const isAssetGroupView = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.view.key === ASSET_GROUP_VIEW.key);
  const isTableDefinitionView = useFluxPath(
    sqWorksheetStore,
    () => sqWorksheetStore.view.key === TABLE_DEFINITION_VIEW.key,
  );
  const [nameFilter, setNameFilter] = useState('');
  const LIMIT_IN_OVERVIEW = 8;
  const store = getStoreForPane(pane);
  const scrollParentRef = useRef(null);
  const selectedItemIds = useFluxPath(store, () => store.selectedItemIds);
  // NOTE: please do not "simplify" the below to useFlux instead. We're intentionally using useFluxPath to avoid
  // performance hits when typing into the search field.
  const hasNextPage = useFluxPath(store, (store) => store.hasNextPage);
  const mode = useFluxPath(store, (store) => store.mode);
  const searching = useFluxPath(store, (store) => store.searching);
  const isPaginating = useFluxPath(store, (store) => store.isPaginating);
  const currentAsset = useFluxPath(store, (store) => store.currentAsset);
  const breadcrumbs = useFluxPath(store, (store) => store.breadcrumbs);
  const items = useFluxPath(store, (store) => store.items);
  const isAsyncInitialized = useFluxPath(store, (store) => store.isAsyncInitialized);
  const highlightItemId = useFluxPath(store, (store) => store.highlightItemId);
  const {
    pinned,
    recentlyAccessed,
    recentlyAccessedLoadStatus,
    pinnedLoadStatus,
    assetGroups,
    tableDefinitions,
    tableDefinitionsLoadStatus,
    assetGroupsLoadStatus,
    workbookId,
    workbookDisplay,
  } = useFlux(sqWorkbookStore);

  // No need for this to be a state variable as computing it is simple and this ensures it is never stale (CRAB-39094)
  const currentItems: any[] = (() => {
    switch (mode) {
      case 'pinned':
        return pinned;
      case 'recent':
        return recentlyAccessed;
      case 'asset-groups':
        return assetGroups;
      case 'table-definitions':
        return tableDefinitions;
      default:
        return items;
    }
  })();

  const placeholderCategory: string | undefined = (() => {
    switch (mode) {
      case 'tree':
      case 'search':
        return _.get(_.last(_.reject(breadcrumbs, ['type', 'VIEW_MODE'])), 'name');
      case 'pinned':
        return 'SEARCH_DATA.PINNED';
      case 'recent':
        return 'SEARCH_DATA.RECENTLY_ACCESSED';
      case 'asset-groups':
        return 'SEARCH_DATA.ASSET_GROUPS';
      case 'table-definitions':
        return 'SEARCH_DATA.SCALING';
      case 'usages':
        return 'SEARCH_DATA.USAGES';
    }
  })();

  const isAllUnselectable = isAssetGroupView && currentItems.some((item) => item.hasChildren || isAsset(item));

  useEffect(() => {
    if (!isAsyncInitialized) {
      // Not ideal to initiate the async fetch from a useEffect, but there are so many modals that open this widget
      // up that this seems better than the alternative of having all the modals call it when the modal opens.
      // Note that this only gets called for modals, stateSynchronizer handles the initialization for the main panel
      // to enable fast follow to work (CRAB-26634)
      initializeSearchActions(pane, searchTypes, restrictExploration, scopeIds, excludeGloballyScoped).catch((error) =>
        errorToast({ httpResponseOrError: error }),
      );
    }

    if (pane === 'modal' && (modalId !== lastModalId || showOverviewMode)) {
      reset(pane);
      initializeSearchActions(pane, searchTypes, restrictExploration, scopeIds, excludeGloballyScoped).catch((error) =>
        errorToast({ httpResponseOrError: error }),
      );
    }

    return () => {
      lastModalId = modalId;
    };
  }, []);

  const limit = mode === 'overview' ? LIMIT_IN_OVERVIEW : undefined;
  const isDoneLoadingResults = () => !searching && !isPaginating;
  const hasNoResults = () => isDoneLoadingResults() && items.length === 0 && currentItems.length === 0;

  const filterByRestriction = (item) => (restrictExploration ? _.includes(searchTypes, item.type) : true);

  const filterByName = (item) =>
    _.includes(['overview', 'search', 'tree'], mode)
      ? true
      : item?.name.toLowerCase().includes(nameFilter.toLowerCase());

  const limitAndFilterItems = (items) =>
    _.chain(items).filter(filterByName).filter(filterByRestriction).slice(0, limit).value();

  const exploreAsset = (asset) => {
    return exploreAssetSearchActions(pane, asset.id, searchTypes, restrictExploration, scopeIds, excludeGloballyScoped);
  };

  const editTableDefinition = (tableDefinition: ItemSearchPreviewV1) => {
    resetTableDefinition();
    sqTableDefinitionsApi.getTableDefinition({ id: tableDefinition.id }).then(({ data: tableDefinition }) => {
      setTableDefinition(tableDefinition);
      setReturnViewKey(sqWorksheetStore.view.key);
      setView(TABLE_DEFINITION_VIEW.key);
    });
  };

  const fetchUsages = (item) => {
    if (item.id) {
      return fetchItemUsages(item, pane, workbookId ? [workbookId] : [], false);
    } else {
      setNameFilter('');
      return exploreAssetSearchActions(
        pane,
        item.id,
        searchTypes,
        restrictExploration,
        undefined,
        excludeGloballyScoped,
      );
    }
  };

  const viewItem = (item) => {
    if (isAsset(item) || isDatafile(item) || item.hasChildren) {
      exploreAsset(item);
    } else if (isTableDefinition(item)) {
      editTableDefinition(item);
    } else if (!isAssetGroupView || !isTableDefinitionView || pane !== 'main') {
      onItemClick(item, mode, pane);
    }
  };

  const loadNextPage = () => {
    if (!searching && !isPaginating && hasNextPage) {
      return loadNextPageSearchActions(pane, searchTypes, restrictExploration, scopeIds, excludeGloballyScoped).then(
        ({ items: newItems }) => {
          if (isAssetGroupView || isTableDefinitionView) {
            setSelectAllItems(undefined);
          } else {
            const hasLeafItem = !!newItems?.find((item) => !(item.hasChildren || isAsset(item)));
            hasLeafItem && setSelectAllItems(undefined);
          }
        },
      );
    }
  };

  function defaultSearchResultIcons(item) {
    return Promise.resolve(
      <>
        {allowAssetReplacement && <SearchResultAssetIcons item={item} isAssetGroupView={isAssetGroupView} />}
        {!isAssetGroupView && !isTableDefinitionView && (
          <SearchResultIcons
            item={item}
            pinned={pinned}
            workbookDisplay={workbookDisplay}
            workbookId={workbookId}
            onItemClick={onItemClick}
            allowAssetReplacement={allowAssetReplacement}
            restrictExploration={restrictExploration}
          />
        )}
      </>,
    );
  }

  // In pinned, recently accessed or asset group mode, users can filter by the name, pulling this out of the
  // <SearchFilters/> component allows us to not have to rerender every time a user types in an input (CRAB-24095)
  const renderOnlyNameFilter = (
    <div className="card card-default">
      <div className="card-body" data-testid="searchPanel">
        <FormControl
          className="mb10 height-34"
          id="searchInput"
          name="nameFilter"
          data-testid="nameOnlyFilter"
          placeholder={t('SEARCH_DATA.NAME_CONTAINS')}
          onChange={(name) => setNameFilter(name.target.value)}
          value={nameFilter}
        />
        <Button
          label={t('SEARCH_DATA.RESET')}
          onClick={() => {
            clear(pane, searchTypes, restrictExploration, scopeIds, excludeGloballyScoped);
            setNameFilter('');
          }}
          testId="resetButton"
        />
      </div>
    </div>
  );

  const showSearchFilters = _.includes(['tree', 'overview', 'search'], mode);
  const showUsages = mode === 'usages';
  const showAssetTrees = mode !== 'overview' && !showUsages;
  const showOverviewMode = mode === 'overview';
  const showSpinner = !isDoneLoadingResults();
  const showError = hasNoResults() && !currentAsset && !showUsages;
  const showUsagesError = hasNoResults() && showUsages;
  const showPinnedItems = !showOnlyResults && !showUsages;
  const showAssetGroups = !showUsages;
  const showTableDefinitions = !showUsages && isPanelSearch && isTableDefinitionEditorEnabled();
  const showRecentlyAccessed = !showOnlyResults && !showUsages;

  const showInfinite = !_.includes(['pinned', 'recent', 'asset-groups', 'usages'], mode);
  const showErrorNoResults =
    (isDoneLoadingResults() && !hasNoResults() && _.filter(currentItems, filterByName).length === 0) ||
    (hasNoResults() && currentAsset);
  const placeholderCategoryTranslated = placeholderCategory ? t(placeholderCategory) : '';

  return (
    <div className="flexFill flexRowContainer overflowHidden searchWidget pl7 pr3" data-testid="searchWidget">
      {showSearchFilters ? (
        <SearchFilters
          pane={pane}
          searchTypes={searchTypes}
          scopeIds={scopeIds}
          excludeGloballyScoped={excludeGloballyScoped}
        />
      ) : (
        renderOnlyNameFilter
      )}
      {showAssetTrees && (
        <ul
          data-testid="searchBreadcrumbsAssetTrees"
          className="unstyled-list searchBreadcrumbs flexColumnContainer flexWrap flexNoGrowNoShrink mb0 heading">
          {_.map(breadcrumbs, (breadcrumb, index: number) => {
            const isViewMode = breadcrumb.type === 'VIEW_MODE';
            const isLast = breadcrumbs.length - 1 === index;
            return (
              <li
                data-testid="searchBreadcrumbAssetTrees"
                tabIndex={isLast ? undefined : 0}
                onKeyUp={(e) =>
                  e.keyCode === KEY_CODES.ENTER && !isLast && !breadcrumb.isLoading && exploreAsset(breadcrumb)
                }
                onClick={() => !breadcrumb.isLoading && !isLast && exploreAsset(breadcrumb)}
                key={breadcrumb.id}>
                {breadcrumb.isLoading && <IconWithSpinner spinning={true} />}
                {!breadcrumb.id && <Icon icon="fa-house-chimney" extraClassNames="pl5" testId="breadcrumbHomeIcon" />}
                <span className="fs15">{isViewMode ? t(breadcrumb.name) : breadcrumb.name}</span>
                {!breadcrumb.isLoading && breadcrumbs.length === 1 && (
                  <span className="fs15 pl5">{t('SEARCH_DATA.ASSET_TREES')}</span>
                )}
              </li>
            );
          })}
        </ul>
      )}
      {showError && <ErrorWithBackground errorText="SEARCH_DATA.NO_RESULTS" dismissible={false} type="Warning" />}
      {showInfinite && !showOverviewMode && !showErrorNoResults && pane !== 'modal' && !_.isEmpty(currentItems) ? (
        <div className="pt10 pb10 itemRow">
          {isAssetGroupView ? (
            <AssetGroupMultiSelect pane={pane} items={currentItems} />
          ) : (
            <TrendMultiSelect pane={pane} items={currentItems} />
          )}
        </div>
      ) : null}
      <div
        id="spec-search-results"
        data-testid="spec-search-results"
        className={classNames('flexRowContainer overflowAuto height-maximum', searchResultsClassNames)}
        ref={scrollParentRef}>
        {showUsages && (
          <>
            <ul
              data-testid="searchBreadcrumbsUsages"
              className="unstyled-list searchBreadcrumbs flexColumnContainer flexWrap flexNoGrowNoShrink mb0 heading">
              {_.map(breadcrumbs, (breadcrumb, index) => {
                const isTitle = breadcrumb.id === 'ITEM_USAGES';
                const isItemOrHomeBreadcrumb = !(breadcrumbs.length - 1 === _.toNumber(index) || isTitle);
                return (
                  <li
                    data-testid="searchBreadcrumbUsages"
                    tabIndex={!isItemOrHomeBreadcrumb ? undefined : 0}
                    onKeyUp={(e) =>
                      e.keyCode === 13 && isItemOrHomeBreadcrumb && !breadcrumb.isLoading && fetchUsages(breadcrumb)
                    }
                    onClick={() => {
                      !breadcrumb.isLoading && isItemOrHomeBreadcrumb && fetchUsages(breadcrumb);
                    }}
                    key={breadcrumb.id}>
                    {breadcrumb.isLoading && <IconWithSpinner spinning={true} />}
                    {!breadcrumb.id && <Icon icon="fa-house-chimney" testId="usagesHomeIcon" />}
                    {isTitle && <Icon icon="fc-usage" extraClassNames="pl5 pr5 sq-icon-dark-gray " />}
                    <span className={isTitle ? 'text-bold title fs15' : 'fs15'}>{t(breadcrumb.name)} </span>
                  </li>
                );
              })}
            </ul>
            <SearchGroup
              isSelectingAsset={isSelectingAsset}
              predefinedSearchTypes={predefinedSearchTypes}
              loadStatus={searching}
              label="USAGES"
              searchMode="usages"
              searchTypes={[]}
              scopeIds={scopeIds}
              restrictExploration={restrictExploration}
              pane={pane}
              onErrorClick={_.noop}
              onClickItem={viewItem}
              searchResultIcons={searchResultIcons}
              mode={mode}
              items={limitAndFilterItems(items)}
              indicateSelection={indicateSelection}
              selectedItemId={selectedItemId}
            />
            {showSpinner && (
              <div className="pt10 pb10 flexNoGrowNoShrink flexColumnContainer flexCenter">
                <IconWithSpinner spinning={true} large={true} />
              </div>
            )}
          </>
        )}

        {showUsagesError && (
          <ErrorWithBackground errorText="SEARCH_DATA.NO_RESULTS" dismissible={false} type="Warning" />
        )}

        {showPinnedItems && (
          <SearchGroup
            isSelectingAsset={isSelectingAsset}
            predefinedSearchTypes={predefinedSearchTypes}
            loadStatus={pinnedLoadStatus}
            label="PINNED"
            pane={pane}
            searchMode="pinned"
            searchTypes={searchTypes}
            scopeIds={scopeIds}
            restrictExploration={restrictExploration}
            onErrorClick={() => fetchPinnedItems(workbookId)}
            onClickItem={viewItem}
            searchResultIcons={searchResultIcons}
            mode={mode}
            items={limitAndFilterItems(pinned)}
            indicateSelection={indicateSelection}
            selectedItemId={selectedItemId}
          />
        )}

        {showAssetGroups && (
          <SearchGroup
            isSelectingAsset={isSelectingAsset}
            predefinedSearchTypes={predefinedSearchTypes}
            loadStatus={assetGroupsLoadStatus}
            label="ASSET_GROUPS"
            searchMode="asset-groups"
            searchTypes={searchTypes}
            scopeIds={scopeIds}
            restrictExploration={restrictExploration}
            onErrorClick={() => fetchAssetGroups([workbookId])}
            pane={pane}
            onClickItem={viewItem}
            searchResultIcons={searchResultIcons}
            mode={mode}
            noItemsMessage={
              sqLicenseManagementStore.hasAssetGroups() && sqWorkbookStore.workbookDisplay === WORKBOOK_DISPLAY.EDIT
                ? 'SEARCH_DATA.NO_ASSET_GROUPS'
                : undefined
            }
            items={limitAndFilterItems(assetGroups)}
            indicateSelection={indicateSelection}
            selectedItemId={selectedItemId}
          />
        )}

        {showTableDefinitions && (
          <SearchGroup
            isSelectingAsset={isSelectingAsset}
            predefinedSearchTypes={predefinedSearchTypes}
            loadStatus={tableDefinitionsLoadStatus}
            label="SCALING"
            searchMode="table-definitions"
            searchTypes={searchTypes}
            scopeIds={scopeIds}
            restrictExploration={restrictExploration}
            onErrorClick={() => fetchTableDefinitions([workbookId])}
            pane={pane}
            onClickItem={viewItem}
            searchResultIcons={searchResultIcons}
            mode={mode}
            noItemsMessage={
              sqWorkbookStore.workbookDisplay === WORKBOOK_DISPLAY.EDIT ? 'SEARCH_DATA.NO_SCALING' : undefined
            }
            items={limitAndFilterItems(tableDefinitions)}
            indicateSelection={indicateSelection}
            selectedItemId={selectedItemId}
          />
        )}

        {showRecentlyAccessed && (
          <SearchGroup
            isSelectingAsset={isSelectingAsset}
            loadStatus={recentlyAccessedLoadStatus}
            label="RECENTLY_ACCESSED"
            searchMode="recent"
            searchTypes={searchTypes}
            scopeIds={scopeIds}
            restrictExploration={restrictExploration}
            pane={pane}
            onErrorClick={() => fetchRecentlyAccessedItems(workbookId)}
            onClickItem={viewItem}
            searchResultIcons={searchResultIcons}
            mode={mode}
            items={limitAndFilterItems(recentlyAccessed)}
            indicateSelection={indicateSelection}
            selectedItemId={selectedItemId}
          />
        )}

        {showOverviewMode && (
          <div
            data-testid="overviewHeading"
            className="flexColumnContainer flexWrap flexNoGrowNoShrink ml2 mb0 heading">
            <span className="fs15 text-interactive">{t('SEARCH_DATA.ASSET_TREES')}</span>
          </div>
        )}

        {showInfinite && (
          <>
            <InfiniteScroll
              pageStart={0}
              hasMore={hasNextPage}
              loadMore={loadNextPage}
              useWindow={false}
              initialLoad={false}
              getScrollParent={() => scrollParentRef.current}>
              {_.map(currentItems, (item) => (
                <SearchResult
                  isSelectingAsset={isSelectingAsset}
                  searchTypes={predefinedSearchTypes}
                  customIconClasses={iconClasses}
                  key={item.id}
                  items={currentItems}
                  searchResultIcons={searchResultIcons}
                  item={item}
                  onClickItem={() => viewItem(item)}
                  isSelected={indicateSelection && selectedItemId === item.id}
                  itemSelected={_.includes(selectedItemIds, item.id)}
                  showItemSelection={!showOverviewMode && pane !== 'modal'}
                  isAllUnselectable={isAllUnselectable}
                  highlightItem={highlightItemId === item.id}
                  onSelectItem={onSelectItem}
                />
              ))}
            </InfiniteScroll>

            {showSpinner && (
              <div className="pt10 pb10 flexNoGrowNoShrink flexColumnContainer flexCenter">
                <IconWithSpinner testId="loadingResults" spinning={true} large={true} />
              </div>
            )}
          </>
        )}
      </div>
      {showErrorNoResults && !showUsages && (
        <ErrorWithBackground
          type="Warning"
          dismissible={false}
          errorText="SEARCH_DATA.NO_RESULTS_WITHIN_CATEGORY"
          errorParameters={{ category: placeholderCategoryTranslated }}
        />
      )}
    </div>
  );
};
